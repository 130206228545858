import { strings as stringGlobal } from '../utils/globalLocalization'
import { useSearchParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useEffect } from 'react';
import moment from 'moment';
import { GlobalStateInterface, useGlobalState } from '../utils/globalStateProvider';
import React from 'react';
import { getLangByLocalStorage } from '../utils/helpers';

export const Status404 = (props: {entity?:'contract'|'document'|'installation'|'incident'}) => {

  const { state, setState } = useGlobalState()
  const icon404 = <svg xmlns="http://www.w3.org/2000/svg" width="100%" enableBackground="new 0 0 48 48" viewBox="0 0 48 48" id="404-error"><path d="M43,48H5c-2.757,0-5-2.243-5-5V5c0-2.757,2.243-5,5-5h38c2.757,0,5,2.243,5,5v38C48,45.757,45.757,48,43,48z M5,2
      C3.346,2,2,3.346,2,5v38c0,1.654,1.346,3,3,3h38c1.654,0,3-1.346,3-3V5c0-1.654-1.346-3-3-3H5z"></path><path d="M47 10H1c-.552 0-1-.447-1-1s.448-1 1-1h46c.552 0 1 .447 1 1S47.552 10 47 10zM6 6C5.87 6 5.74 5.97 5.62 5.92 5.5 5.87 5.39 5.8 5.29 5.71 5.2 5.609 5.13 5.5 5.08 5.38 5.03 5.26 5 5.13 5 5s.03-.26.08-.38C5.13 4.5 5.2 4.39 5.29 4.29 5.39 4.2 5.5 4.13 5.62 4.08c.37-.16.81-.07 1.09.21C6.8 4.39 6.87 4.5 6.92 4.62 6.97 4.74 7 4.87 7 5S6.97 5.26 6.92 5.38C6.87 5.5 6.8 5.609 6.71 5.71 6.61 5.8 6.5 5.87 6.38 5.92S6.13 6 6 6zM10 6C9.87 6 9.74 5.97 9.62 5.92 9.5 5.87 9.39 5.8 9.29 5.71 9.2 5.609 9.13 5.5 9.08 5.38 9.03 5.26 9 5.13 9 5s.03-.26.08-.38C9.13 4.5 9.2 4.39 9.29 4.29 9.39 4.2 9.5 4.13 9.62 4.08c.37-.16.81-.07 1.09.21.09.1.16.21.21.33C10.97 4.74 11 4.87 11 5s-.03.26-.08.38c-.05.12-.12.229-.21.33-.1.09-.21.16-.33.21S10.13 6 10 6zM36 35H12c-.552 0-1-.447-1-1s.448-1 1-1h24c.552 0 1 .447 1 1S36.552 35 36 35zM33 39H15c-.552 0-1-.447-1-1s.448-1 1-1h18c.552 0 1 .447 1 1S33.552 39 33 39zM25 31h-2c-1.654 0-3-1.346-3-3v-8c0-1.654 1.346-3 3-3h2c1.654 0 3 1.346 3 3v8C28 29.654 26.654 31 25 31zM23 19c-.551 0-1 .448-1 1v8c0 .552.449 1 1 1h2c.551 0 1-.448 1-1v-8c0-.552-.449-1-1-1H23zM17 27h-6c-.328 0-.635-.161-.822-.431-.187-.27-.229-.613-.115-.921l3-8c.195-.517.771-.775 1.288-.585.517.194.779.771.585 1.288L12.443 25H17c.552 0 1 .447 1 1S17.552 27 17 27z"></path><path d="M16 31c-.552 0-1-.447-1-1v-8c0-.553.448-1 1-1s1 .447 1 1v8C17 30.553 16.552 31 16 31zM37 27h-6c-.328 0-.635-.161-.822-.431-.187-.27-.229-.613-.115-.921l3-8c.195-.517.771-.775 1.288-.585.517.194.779.771.585 1.288L32.443 25H37c.552 0 1 .447 1 1S37.552 27 37 27z"></path><path d="M36,31c-0.552,0-1-0.447-1-1v-8c0-0.553,0.448-1,1-1s1,0.447,1,1v8C37,30.553,36.552,31,36,31z"></path></svg>


  useEffect(() => {
    I18n.putVocabularies(stringGlobal);
    if(!state.language){
      let lang = getLangByLocalStorage()
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }
  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  return (
    <>
      <Header isLoggedout={true} />
      <div className="flex flex-col m:flex-row w-full m:w-7/12 max-w-lg m-auto  items-center justify-center pt-20 pb-20 text-center pl-4 pr-4 h-s min-h-[50vh]">
        {props.entity === 'contract' && 
          <>
            <div className="w-2/5 m:w-2/5 pb-10 m:pb-0">{icon404}</div> 
            <div className="w-11/12 m:w-3/5 text-center m:text-left m:pl-4"><h2>{I18n.get('Message404Contract')}</h2></div>
          </>
        }
        {props.entity === 'document' && 
          <>
            <div className="w-2/5 m:w-2/5 pb-10 m:pb-0">{icon404}</div> 
            <div className="w-11/12 m:w-3/5 text-center m:text-left m:pl-4"><h2>{I18n.get('Message404Document')}</h2></div>
          </>
        }
        {props.entity === 'installation' && 
          <>
            <div className="w-2/5 m:w-2/5 pb-10 m:pb-0">{icon404}</div> 
            <div className="w-11/12 m:w-3/5 text-center m:text-left m:pl-4"><h2>{I18n.get('Message404Installation')}</h2></div>
          </>
        }
        {props.entity === 'incident' && 
          <>
            <div className="w-2/5 m:w-2/5 pb-10 m:pb-0">{icon404}</div> 
            <div className="w-11/12 m:w-3/5 text-center m:text-left m:pl-4"><h2>{I18n.get('Message404Incident')}</h2></div>
          </>
        }
        {!props.entity && 
          <>
            <div className="w-2/5 m:w-2/5 pb-10 m:pb-0">{icon404}</div> 
            <div className="w-11/12 m:w-3/5 text-center m:text-left m:pl-4"><h2>{I18n.get('Message404')}</h2></div>
          </>
        }
      </div>
      <Footer />
    </>
  );
};


