import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { strings as stringGlobal } from '../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import React from 'react';
import { getLangByLocalStorage } from '../utils/helpers';

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export const LoginPage = () => {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();
  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : '/welcome';

  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);

  useEffect(() => {

    if (inviteMatches && orgMatches) {
      loginWithRedirect({
        authorizationParams:{ 
          organization:orgMatches[1],
          invitation:inviteMatches[1],
        },
        appState: {
          returnTo: '/',
        },
      });
    }

  }, [inviteMatches,orgMatches ]);

  return (
    <div className="flex w-full h-screen items-center justify-center flex-col">
        <img src="/assets/images/organizations/aufzughelden.png" />
        <br/>
        <br/>
        {I18n.get('messageRedirect')}
    </div>
  );
};
