import React from "react";

export interface TabProps {
  isActive?: boolean;
  label: string;
  onClick: () => void;
}

export const Tab = ({ isActive = false, label, onClick }: TabProps) => {
  return (
    <div
      onClick={onClick}
      className={`text-center rounded-[10px] cursor-pointer px-4 h-[46px] flex justify-center items-center text-nowrap 
      ${isActive ? "bg-blueActive text-white" : "bg-inActiveButton text-blueDark"}`}>
      <span className="text-lbase">{label}</span>
    </div>
  );
};
