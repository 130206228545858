import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useGlobalState, GlobalStateInterface } from '../../../utils/globalStateProvider';
import { Button } from '../../../components/atoms/Button';
import moment from 'moment';
import { mergeDeepRight } from 'ramda';
import { strings as stringLocal} from './OpenCaseLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Installation } from '../../../interfaces/Installation';
import { INCIDENTS_API_URL } from '../../../utils/consts';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { getAuth0Token } from '../../../utils/helpers';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));

function OpenCase(props:{installation:Installation,closerFunction:Function}) {
  const { state, setState } = useGlobalState()
  const [caseState, setCaseState] = useState<'open'|'success'|'error'|'showContactInformation'>('open')
  const [readyToSubmit,setReadyToSubmit] = React.useState<boolean>(false)
  const [incidentType,setIncidentType] = React.useState<string>('Question')
  const [incidentMessage,setIncidentMessage] = React.useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userPressedSubmit, setUserPressedSubmit] = useState<boolean>(false);
  const [labelInstallation, setLabelInstallation] = React.useState<string>('')
  let {installationId} = useParams();
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    }, []);  

    useEffect(() => {

      if(props.installation.customLabel && props.installation.customLabel.length > 0){
        setLabelInstallation(props.installation.customLabel)
      }
      else{
        setLabelInstallation(props.installation.label)
      }    

    }, [props.installation]); 
    

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  useEffect(() => {
    if(incidentType !== '' && incidentMessage.length > 0 ){
      setReadyToSubmit(true)
    }
    else{
      setReadyToSubmit(false)
    }
  }, [incidentType,incidentMessage,caseState]);

  const handleSubmit = async () => {
    setUserPressedSubmit(true)
    if (!incidentType || !incidentMessage) {
      console.log("Incident type and problem description are required.");
      return;
    }

    if (!["Emergency", "Breakdown", "Question"].includes(incidentType)) {
      console.log("Invalid incident type. Please select a valid type.");
      return;
    }

    const url = INCIDENTS_API_URL + '/v1/cases/';
    try {
      const payload = {
        message: incidentMessage,
        type: incidentType,
        emailAddress: user?.email,
        assetId: installationId,
      };
      const accessToken = await getAuth0Token(getAccessTokenSilently)

      setIsLoading(true);
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      setIsLoading(false);

      const { status } = response;
      if (status === 201) {
        setCaseState('success');
      } else {
        setCaseState('error');
      }
    } catch (error) {
      console.error('Error submitting incident:', error);
      setCaseState('error');
    }
  };

  return (
    <>
      {isLoading ?  (<div className="flex justify-center items-center h-full p-8 ">
          <h1 className="normal inline-block">
            <Spin size="large" spinning={isLoading} key="spin" />
          </h1>
        </div>)
      : (<>
          {caseState === 'success' &&
            <div className="flex flex-col justify-center items-center text-center mt-10 m:mt-0">
              <CheckOutlined style={{ fontSize: '26px', color: '#000' }} />
              <h3 className="mt-4">{I18n.get('Success')}</h3>
              <p>{I18n.get('Successfully reported a problem with asset')} {props.installation.label}</p>
            </div>
          }
          {caseState === 'error' &&
            <div className="flex flex-col justify-center items-center text-center mt-10 m:mt-0">
              <div className="flex flex-col items-center justify-center text-center">
                <CloseCircleOutlined style={{ fontSize: '26px', color: '#000' }} />
                <h3 className="mt-4">{I18n.get('Error')}</h3>
                <p className="text-center">
                  {I18n.get('Please try')}{' '}
                  <span className="text-blueLight cursor-pointer" onClick={() => { setCaseState('open') }}>{I18n.get('again')}</span>{' '}
                  {I18n.get('or contact via')}{' '}
                  <span className="text-blueLight cursor-pointer" onClick={() => { setCaseState('showContactInformation') }}>{I18n.get('phone')}</span>.
                </p>
              </div>
            </div>
          }
          {caseState === 'showContactInformation' &&  
            <div className="flex flex-col justify-center text-center mt-10 m:mt-0">
              <h3 className="mt-4">{I18n.get('Welcome_to_the_Aufzughelden_support')}</h3>
              <div className=" mt-7">
                <h3 className="font-bold">Customer service hotline:</h3>
                <p className="text-sm m:text-base xxl:text-lg"><a href="tel:+49 30 62 93 21 40" className="hover:text-blueDark underline">+49 30 62 93 21 40</a></p>
                <p className="text-sm m:text-base xxl:text-lg"><a href="mailto:support@aufzughelden.com" className="hover:text-blueDark underline">support@aufzughelden.com</a></p>
              </div>
            </div>
          }
          {caseState === 'open' && 
            <>
              <h3 className="font-bold hidden m:block mb-4 ">{I18n.get('Report_a_problem')} {labelInstallation}</h3>
              <p>
                <b>{I18n.get('Name')}:</b> {props.installation.label}<br/>
                <b>{I18n.get('Location')}:</b> {props.installation.building?.label}
              </p>
              <p className="text-blueDark font-normal text-sm mt-4">{I18n.get('Report_a_problem_desc')}</p>
              <div className=' mt-7 '>
                <Select 
                      className="w-full s:w-48"
                      defaultValue={incidentType}
                      onChange={(value: string)=>{
                        setIncidentType(value)
                      }}
                      loading={false}
                      options={[
                        {
                          value: '',
                          label: I18n.get('Incident Type'),
                          disabled: true,
                        },
                        {
                          value: 'Emergency',
                          label: I18n.get('Incident_Emergency'),
                        },
                        {
                          value: 'Breakdown',
                          label: I18n.get('Incident_Breakdown'),
                        },
                        {
                          value: 'Question',
                          label: I18n.get('Incident_Question'),
                        }
                      ]}
                    />   
      
                <p className=" pt-4"> <TextArea 
                defaultValue={incidentMessage}
                  rows={8} 
                  placeholder={I18n.get('Problem description')}
                  maxLength={20000} 
                  onChange={(e)=>{
                    setIncidentMessage(e.target.value)
                  }}
                  /></p>
              <div className=" pt-6 m:text-right flex justify-end items-center">
                <div className={"text-warnRed mr-8" +  (!readyToSubmit && userPressedSubmit ? '':' hidden') }>
                  {I18n.get('Please_fill_case_form')}
                </div>
                <Button
                  disabled={!readyToSubmit}
                  theme='light'
                  size='medium'
                  ownStyle=' '
                  onClick={handleSubmit}
                  label={I18n.get('Submit')} />
              </div>
              </div>
            </>
          }
        </>)}
      </>
    );
}

export default OpenCase;
