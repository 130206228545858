import React from "react";
import TableInstallations from "../welcome/TableInstallations";
import './SelectMoreInstallations.css'

import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { getLangByLocalStorage } from "../../../utils/helpers";

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

const SelectMoreInstallation = (props:{onClose: () => void }) => {

    return (
    <section>
        <div className="Content MoreInstallationsCloser bg-blueBaby text-blueDark text-lg p-7 m:p-32">
            <div className=" flex " onClick={props.onClose}>
                <svg className=" ml-auto closer -mr-9 -mb-5 m:-mr-9 m:-mb-5" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22" cy="22" r="22" fill="#102A68"/>
                    <path d="M27.922 29.0411L22.3662 23.4852L16.8104 29.0411L14.9584 27.1891L20.5143 21.6333L14.9584 16.0775L16.8104 14.2255L22.3662 19.7814L27.922 14.2255L29.774 16.0775L24.2182 21.6333L29.774 27.1891L27.922 29.0411Z" fill="white"/>
                </svg>
            </div>
            <div className="bg-white MoreInstallations">
                <div className=" text-center m:text-left w-full"><div className="button cursor-pointer mt-10 mb-0 m:ml-10" onClick={props.onClose}>{I18n.get('takeOver')}</div></div>
                <TableInstallations isAddMode={true} />
                <div className=" text-center m:text-left w-full"><div className="button cursor-pointer mt-10 mb-20 m:ml-10" onClick={props.onClose}>{I18n.get('takeOver')}</div></div>
            </div>
        </div>
    </section>
    )
}

export default SelectMoreInstallation;