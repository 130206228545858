import { I18n } from "@aws-amplify/core";
import { useEffect, useRef, useState } from "react";
import { useGlobalState } from "../../../utils/globalStateProvider";
import React from "react";
import { Spin } from "antd";
import { DateRange } from "../../../interfaces/DateRange";
import { fetchRunsBetweenAllFloors, setOrReportError } from "../../../utils/helpersFetch";
import { useAuth0 } from "@auth0/auth0-react";
import { DeviceInfo } from "../../../interfaces/DeviceInfo";
import TurnsBetweenLevelsChart from "./TurnsBetweenLevelsChart";
import { RunBetweenFloorsResponse } from "../../../interfaces/RunBetweenFloors";
import { getAuth0Token } from "../../../utils/helpers";

const TurnsBetweenLevelsChartMemo = React.memo(TurnsBetweenLevelsChart)

const TurnsBetweenLevelsChartWrapper = (props: { device: DeviceInfo | null; dateRange:DateRange; speakingTimePeriod:string }) => {

  const { state, setState } = useGlobalState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [runsBetweenAllFloors, setRunsBetweenAllFloors] = useState<null | RunBetweenFloorsResponse>(null)
  const [countRunsPerFloor, setCountRunsPerFloor] = useState<number | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const controllerRef1 = useRef<AbortController | null>()

  const fetchAllData = async (deviceId: string,accessToken:string, dateRange?: DateRange) => {

    const queryString = dateRange ? `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}` : ''
  
    return Promise.all([
        fetchRunsBetweenAllFloors(deviceId, queryString,accessToken,controllerRef1),
      ]);
    };

  const fetchData = async () => {
    setIsLoading(true)

    if (props.device?._id) {
      const [runsBetweenAllFloors] = await fetchAllData(props.device._id, await getAuth0Token(getAccessTokenSilently), props.dateRange)
      
      setRunsBetweenAllFloors(null)
      setCountRunsPerFloor(null)
      setOrReportError(setRunsBetweenAllFloors, runsBetweenAllFloors)
      if ( Array.isArray(runsBetweenAllFloors) && runsBetweenAllFloors?.length) {
        let count = 0;
        runsBetweenAllFloors.forEach(function (item) {
          count += item.runCount;
        });
        setCountRunsPerFloor(count);
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language]);

  useEffect(() => {
    fetchData()
  }, [ props.dateRange,props.device?.deviceId]);


  return (
    <>
      <section>
        <div className="Content bg-blueBaby text-blueDark text-base xxl:text-lg">
          <div className="flex flex-col m:flex-row pt-6 xxl:pt-12 pb-6 xxl:pb-12">
            <div className=" w-full m:w-1/2 m:pr-24 m:leading-10">
              <h2 className="uppercase xl:text-3xl">{I18n.get('runsBetweenLevels')}</h2>
              <div className=" pt-2 xxl:pt-4  xl:text-lg font-bold">{I18n.get(props.speakingTimePeriod)}</div>
              <div className="text-sm  pt-2 xl:text-lg  xxl:pt-4">{I18n.get('messageRunsBetweenStops')}</div>
            </div>
            <div className=" w-full m:w-1/2 flex pt-4 m:pt-0">
              <div className="">
                <h3 className="pr-8 m:pr-0 pb-2 m:pb-4 uppercase">{I18n.get('runsTotal')}</h3>
                <div><h1 className=" normal inline-block"><Spin size="default" spinning={isLoading} key="spin">{countRunsPerFloor?countRunsPerFloor:'-'}</Spin></h1></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Content">
        <div className=" pb-14 pt-14">
          <TurnsBetweenLevelsChartMemo response={runsBetweenAllFloors} isLoading={isLoading} device={props.device} />
        </div>
      </section>
     </>
  );
};
export default TurnsBetweenLevelsChartWrapper;
