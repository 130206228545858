import { I18n } from "@aws-amplify/core";
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { GlobalStateInterfaceApp } from "./globalStateProviderApp";
import { fetchContractIntallations } from "./helpersFetch";
import { getLangByLocalStorage } from "../../utils/helpers";


I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export const getInstallationsByContract = async (setState: React.Dispatch<React.SetStateAction<any | null>>, page = 1, pageSize = 10, contractId:string, sortBy = '', accessToken: string) => {
  const installationsResponse = await fetchContractIntallations(page, pageSize, sortBy,contractId, accessToken);
  let dataGlobal: Partial<GlobalStateInterfaceApp> = {
    installations: installationsResponse.assets,
    installationCount: installationsResponse.totalCount,
  }
  setState((prev: any) => ({ ...prev, ...dataGlobal }))
};
