
export const strings = {
    en: {
      CreateTicket: 'Create Ticket',
      ViewIncident: 'View Incident',
      TimeLabel: '',
      IncidentPrio1Title: 'This asset is marked as being non-functional. The associated incident is still being processed.',
      IncidentPrio2Title: 'An irregularity has been detected for this installation. Our team is investigating.',
      IncidentPrio3Title: 'There are currently one or more open incidents for this installation. We are working on the solution.',
      IncidentPrio4Title: 'An incident has been resolved recently and the asset is back in operation.'
    },
    de: {
      CreateTicket: 'Ticket erstellen',
      ViewIncident: 'Vorfall anzeigen',
      TimeLabel: 'Uhr',
      IncidentPrio1Title: 'Dieses Gerät ist in Störung. Der zugehörige Vorfall ist noch in Bearbeitung.',
      IncidentPrio2Title: 'Es wurden Unregelmäßigkeiten bei diesem Gerät festgestellt. Unser Team ermittelt.',
      IncidentPrio3Title: 'Derzeit gibt es einen oder mehrere offene Vorfälle für diese Installation. Wir arbeiten an der Lösung.',
      IncidentPrio4Title: 'Eine Störung wurde kürzlich behoben, das Gerät ist wieder in Betrieb.'
    },
  };