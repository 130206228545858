import { strings as stringLocal } from '../devices/components/welcome/TableInstallationsLocalization'
import { strings as stringGlobal } from '../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { mergeDeepRight } from 'ramda'
import moment from 'moment'
import { AutoComplete } from 'antd'
import Search from 'antd/lib/input/Search'
import { SearchOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { getLangByLocalStorage, setSearchHistory } from '../utils/helpers'

I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal))
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)
moment.locale(lang ? lang : 'de')

const SearchHistory = (props: {
  setSearchValue:Function,
  disableAutocomplete?:boolean,
  closerFunction?:Function
}) => {

  const [inputValue, setInputValue] = React.useState<string | undefined>(undefined)
  const [options, setOptions] = React.useState<{label:string,value:string}[]>([])
  const { search } = useLocation();
  let query =  new URLSearchParams(search);
  useEffect(() => {
    const searchDefaulvalue = query.get("search")    
    if(searchDefaulvalue){
      setInputValue(searchDefaulvalue)
    }
  }, [])

  useEffect(() => {
    if(inputValue === undefined){
      return
    }

    let storedValues = localStorage.getItem('search')
    if (storedValues) {
      let searchOption = JSON.parse(storedValues)
      let autoCompleteOptions: { label: string; value: string }[] = []
      searchOption.forEach((elem:string)=>{
        if(elem.includes(inputValue) && inputValue.length > 0){
          autoCompleteOptions.push({label:elem,value:elem})
        }
      })
      if(!props.disableAutocomplete){
        setOptions(autoCompleteOptions)
      }
    }

    const timeOutId = setTimeout(() => props.setSearchValue(inputValue), 100);
    return () => clearTimeout(timeOutId);  

    
  }, [inputValue])  


  const handleSearchChange = (searchTerm: string) => {
    setSearchHistory(searchTerm)
    props.setSearchValue(searchTerm)
  }

  const handleSearchChangeWithCloserfunction = (searchTerm: string) => {
    handleSearchChange(searchTerm)
    if(props.closerFunction !== undefined){
      props.closerFunction()
    }
  }

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{ width: '100%' }}
      options={options}
      onSelect={(e: any) => {
        handleSearchChange(e)
      }}
      value={inputValue}
      onChange={(e: any)=>{ setInputValue(e)}}
      >
      <Search
        prefix={<SearchOutlined style={{ color: '#102A68' }} />}
        size='large'
        placeholder={I18n.get('search')}
        allowClear
        enterButton='Search'
        onSearch={(value,event) => {
          if(event?.nativeEvent.type === 'keydown'){// enter pressed
            handleSearchChangeWithCloserfunction(value)
          }
          else{// clear icon pressed
            handleSearchChange(value)
          }
        }}
      />
    </AutoComplete>
  )
}

export default SearchHistory
