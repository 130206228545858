import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { ReactZoomPanPinchRef, TransformComponent,TransformWrapper } from "react-zoom-pan-pinch";
import { DsFile } from "../../interfaces/File";
import { getFile, getFileByType } from "../../utils/helpersFetch";
import { Button } from "../../../components/atoms/Button";
import { ApiError } from "../../utils/api-error";
import { useNavigate } from "react-router-dom";
import { noThumbnailImg } from "../../utils/helpers";
import { getAuth0Token } from "../../../utils/helpers";

 const DocumentDialogImage: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently,user } = useAuth0();
  const [previewUrl,setPreviewUrl] = useState<string|undefined>(undefined)
  const [orgFileLoaded,setOrgFileLoaded] = useState<boolean>(false)
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  useEffect(() => {
    setPreviewUrlByType('preview',props.file)
    setOrgFileLoaded(false)
    setStartedZoom(false)
  }, [props.file]);

  const  setPreviewUrlByType = async ( type:'preview'|'org' , file:DsFile ) => {
    let accessToken =  await getAuth0Token(getAccessTokenSilently)
    if(type === 'preview'){
      try{
        let loadedFile = await getFileByType(file.id,'preview',accessToken)
        if(loadedFile.previewUrl){
          setPreviewUrl(loadedFile.previewUrl)
        }
      }
      catch (error){
        setPreviewUrl(noThumbnailImg)
      }
    }
    else{
      try {
        let loadedFile = await getFile(file.id,accessToken)
      
        if(loadedFile?.originalUrl){
          setPreviewUrl(loadedFile.originalUrl)   
          setOrgFileLoaded(true) 
        }
      } catch (error) {
        if (error instanceof ApiError) {
          if (error.statusCode === 403) {
            navigate('/404')
          } else if (error.statusCode === 404) {
            navigate('/404')
          }
        } else {
          // Handle general errors
          console.error('An error occurred:', error);
        }
      }
      finally {
       
      }
      let loadedFile = await getFile(file.id,accessToken)
      if(loadedFile?.originalUrl){
        setPreviewUrl(loadedFile.originalUrl)   
        setOrgFileLoaded(true) 
      }
    }
  }

  const backgroundColor = "white";
  const scaleUp = true;
  const zoomFactor = 40;
  
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);
  const [startedZoom, setStartedZoom] = useState<boolean>(false);

  const imageScale = useMemo(() => {
    if (
      containerWidth === 0 ||
      containerHeight === 0 ||
      imageNaturalWidth === 0 ||
      imageNaturalHeight === 0
    )
      return 0;

    var scale:number
    if(imageNaturalWidth >= imageNaturalHeight){
      // landscape gets scale 1
      scale = 1
      // lets check if the height gets a problem
      if(containerWidth*imageNaturalHeight/imageNaturalWidth > containerHeight){
        scale = imageNaturalWidth * containerHeight / imageNaturalHeight / containerWidth
      }
    }
    else{
      scale = imageNaturalWidth * containerHeight / imageNaturalHeight / containerWidth
      //lets check if the width gets a problem
      if(containerHeight*imageNaturalWidth/imageNaturalHeight > containerWidth){
        scale = 1
      }
    }

    return scaleUp ? scale : Math.max(scale, 1);
  }, [
    scaleUp,
    containerWidth,
    containerHeight,
    imageNaturalWidth,
    imageNaturalHeight
  ]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerWidth(rect.width);
      setContainerHeight(rect.height);
    } else {
      setContainerWidth(0);
      setContainerHeight(0);
    }
  }, [container]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleImageOnLoad = (image: HTMLImageElement) => {
    setImageNaturalWidth(image.naturalWidth);
    setImageNaturalHeight(image.naturalHeight);
  };

  useEffect(() => {
    if(previewUrl){
      const image = new Image();
      image.onload = () => handleImageOnLoad(image);
      image.src = previewUrl;
    }
  }, [previewUrl]);

  const loadOrgFileIfNotDoneAllready = (file:DsFile) => {
    if(!orgFileLoaded){
      setPreviewUrlByType("org",file)  
    }
  }

  useEffect(() => {
    if(startedZoom){
      loadOrgFileIfNotDoneAllready(props.file)
    }
  }, [startedZoom]);
  
  return (
    <div className=" w-full h-full"
      style={{
        backgroundColor,
      }}
      ref={(el: HTMLDivElement | null) => setContainer(el)}
    >
      {imageScale > 0 && (
        <TransformWrapper
          key={`${containerWidth}x${containerHeight}`}
          initialScale={imageScale}
          minScale={imageScale}
          maxScale={imageScale * zoomFactor}
          centerOnInit
          onPanning={()=>{}}
          onPinching={()=>{}}
          onWheel={()=>{}}
          onZoom={()=>{setStartedZoom(true)}}
          onTransformed={()=>{}}
        >
           {({ zoomIn, zoomOut,resetTransform}) => {

          useEffect(() => {
            resetTransform()
          }, [props.file]); 

          return <div className="group w-full h-full cp_modaldialog_file"><TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <img alt={props.file.title} src={previewUrl} />
              </TransformComponent>
              <div className=" flex justify-center select-none relative z-20 -mt-10 w-full opacity-50 group-hover:opacity-100 ">
                <Button onClick={() => { setStartedZoom(true) , zoomIn()}} label="+" size="extra small" theme="dark" ownStyle=" ml-2 mr-2 border border-white" />
                <Button onClick={() => zoomOut()} label="-" size="extra small" theme="dark" ownStyle=" ml-2 mr-2  border border-white" />
              </div>
            </div>
        }}
        </TransformWrapper>
      )}
    </div>
  );
};

export default DocumentDialogImage