import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../../utils/globalStateProvider';
import moment from 'moment';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { Button } from '../../../components/atoms/Button';
import useWindowDimensions from '../../../utils/UseWindowDimensions';
import { DeviceFilter, DeviceFilterEnum } from '../../../interfaces/DeviceFilters';
import { getIcon } from '../../../utils/icons';
import { ContractFilter, ContractFilterEnum } from '../../../interfaces/ContractFilters';

I18n.putVocabularies(stringGlobal);

function MobileFilterOverlay(props:{closerFunction:Function,filterByUrlParam:DeviceFilter | ContractFilter | undefined,handleChange:React.Dispatch<React.SetStateAction<any | undefined>>, filterData:DeviceFilterEnum[]|ContractFilterEnum[]   }) {
  const { state, setState } = useGlobalState()
  const { height, width } = useWindowDimensions()
  const [filterByUrlParam, setFilterByUrlParam] = React.useState<DeviceFilter | ContractFilter | undefined>()

  useEffect(() => {
      if(width>=769){
          document.body?.classList.remove('modalOpen')
          props.closerFunction()
      }
  }, [width])

  useEffect(() => {
    setFilterByUrlParam(props.filterByUrlParam)
}, [props.filterByUrlParam])

  useEffect(() => {
      I18n.putVocabularies(stringGlobal);
  }, [])

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  return (
    <>
        <div className=" font-bold text-base mb-3">Status</div>
        {props.filterData.map((item) => { return <div onClick={()=>{setFilterByUrlParam(item)}} className=" flex justify-between pl-10 mr-5 h-12 cursor-pointer ">
          <div className="content-center">{I18n.get(item)}</div>
          <div className="content-center">{(item === filterByUrlParam)?getIcon({ type:'Checkmark',ownClassName:'fill-blueDark'}):<></>}</div></div> })}
        <Button label={I18n.get('Btn_Apply')} size='medium' theme='dark' ownStyle='w-full flex justify-center mt-[44px]' onClick={()=>{props.handleChange(filterByUrlParam),props.closerFunction()}} />
    </>
  );
}

export default MobileFilterOverlay;
