import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../utils/globalStateProvider';
import moment from 'moment';
import { mergeDeepRight } from 'ramda';
import { strings as stringLocal} from './ModalSelectInstallationLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { useAuth0 } from '@auth0/auth0-react';
import { Installation } from '../../interfaces/Installation';
import OpenCase from '../../devices/components/detail/OpenCase';
import TableMain, { TableProps } from '../../components/molecules/TableMain';
import { Pagination, Spin } from 'antd';
import { fetchUserIntallations } from '../../utils/helpersFetch';
import { Button } from '../../components/atoms/Button';
import SearchHistory from '../../components/SearchHistory';
import { getAuth0Token } from '../../utils/helpers';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));

function ModalSelectInstallation(props:{closerFunction:Function}) {
  const { state, setState } = useGlobalState()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [installation, setInstallation] = useState<Installation|undefined>(undefined);
  const [installations, setInstallations] = useState<Installation[]>([]);
  const { getAccessTokenSilently, user } = useAuth0();
  const [pageParam, setPageParam] = React.useState<number>(1)
  const [searchValueParam, setSearchValueParam] = React.useState<string>('')
  const [totalNumberInstallations, setTotalNumberInstallations] = React.useState<number>(0)
  const [tableProps, setTableProps] = useState<TableProps | null >(null)

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    }, []);  

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  useEffect(() => {
    // load installations
    const loadAssets = async () => {
      setIsLoading(true)
      try {
        const accessToken =  await getAuth0Token(getAccessTokenSilently)
        let assets = await fetchUserIntallations(pageParam, 10, searchValueParam, '', '',accessToken)

        if(assets){
          setTotalNumberInstallations(assets.totalCount)
          setInstallations(assets.assets)
        }
      }
      catch (error) {
        console.error('Error fetching incidents:', error.message)
      } 
      finally { 
        setIsLoading(false)
      }
    }
    loadAssets()

  }, [ pageParam, searchValueParam ]);

  useEffect(() => {

    let tableP:TableProps = {th:[],tr:[],noDataLabel:I18n.get('NoAffectedAssets'),height:'unset',thead:'select-installation'}

    tableP.th = [
      
      // mobile
      {
        text:<><span className="mr-4 -ml-4">{I18n.get('elevator')}</span></>,
        class:'flex l:hidden w-3/12 items-center',
        ellipsis:true
      },          
      {
        text:<><span className="mr-4 -ml-4">{I18n.get('address')}</span></>,
        class:'flex l:hidden w-9/12 items-center ',
        ellipsis:true
      },

      // desktop
      {
        text:<><span className="mr-4 l:-ml-4 l:-mb-4">{I18n.get('elevator')}</span></>,
        class:'hidden l:flex w-3/12 items-center  ',
        ellipsis:true
      },          
      {
        text:<><span className="mr-4 l:-ml-4 l:-mb-4">{I18n.get('address')}</span></>,
        class:'hidden l:flex w-8/12 items-center ',
        ellipsis:true
      },
      {text:<></>,class:'hidden l:flex w-1/12 '}
    ]

    if(installations.length > 0){
      tableP.tr = installations.map((itemInstallation, index) => {
        let dataRow:TableProps["tr"][0] =  {cells:[
          // mobile
          {text:<>{getLabel(itemInstallation)}</>,textPlain:getLabel(itemInstallation) ,class:'flex l:hidden text-left items-center w-3/12 hover:cursor-pointer'},
          {text:<>{itemInstallation.building?.label}</>,textPlain:itemInstallation.building?.label,class:'flex l:hidden w-9/12 hover:cursor-pointer'},

          // desktop
          {text:<>{getLabel(itemInstallation)}</>,textPlain:getLabel(itemInstallation) ,class:'hidden l:flex text-left items-center w-3/12 hover:cursor-pointer '},
          {text:<>{itemInstallation.building?.label}</>,textPlain:itemInstallation.building?.label,class:'hidden l:flex w-7/12 hover:cursor-pointer'},

          {text:<><Button label={I18n.get('Select')} size='extra small' theme='light' /></>, class:'hidden l:flex flex-col w-2/12 justify-center hover:cursor-pointer mr-4'}
        ],trOnClick:(e)=>{onRowClicked(itemInstallation)}}
        return dataRow
      })
    }

    setTableProps(tableP)

  }, [installations]);

  const getLabel = (installation:Installation) => {
    if(installation.customLabel && installation.customLabel.trim().length > 0 
      && installation.customLabel?.trim() !== installation.label){
      return installation.customLabel + '/' + installation.label
    }
    else{
      return installation.label
    }
  }

  const onRowClicked = (installation: Installation) => {
    setInstallation(installation)
  }

  const handleSearchChange = (searchTerm: string) => {    
    if (searchValueParam !== searchTerm) {
      setPageParam(1)
    }
    setSearchValueParam(searchTerm)
  }

  return (
    <>
    { !installation &&
      <Spin size="large" spinning={isLoading} key="spin" >
        <h3 className="font-bold hidden m:block mb-4 ">{I18n.get('Report_a_problem')} </h3>
        <p>{I18n.get('Please select an installation')} </p>
        <div className="idTableInstallations mt-0 pb-4 m:pb-0 " >
          <div className=" w-full m:w-96 pt-10 pb-10">
            <SearchHistory setSearchValue={handleSearchChange} />
          </div>
          <TableMain tableContent={tableProps}  />
          <div className=" h-12 bg-blueBaby flex justify-center items-center">
            <Pagination current={pageParam} pageSize={10} showSizeChanger={false} total={totalNumberInstallations} onChange={(page, pageSize) => {
                setPageParam(page);
            }} />
          </div>
        </div>
      </Spin>
    }
    { installation && <OpenCase installation={installation} closerFunction={props.closerFunction}  /> }
    </>
  );
}

export default ModalSelectInstallation;
