import { useEffect, useState } from 'react';
import { DeviceInfo } from '../../../interfaces/DeviceInfo';
import { FeedMessage } from '../../../interfaces/Lifefeed';
import React from 'react';
import { getStatus, getStatusMessage } from '../../../utils/helpers';
import { Case } from "../../../incidents/interfaces/Case";
import { getIcon } from '../../../utils/icons';
import { Badge } from 'antd';
 
export const ListItemStatus = ({
    deviceInfo,
    installationHasDevice,
    isLoadingDeviceState,
    liveData,
    incidentsData
  }: {
    deviceInfo:DeviceInfo | null,
    installationHasDevice:boolean,
    isLoadingDeviceState:boolean,
    liveData: FeedMessage[] | undefined,
    incidentsData: Case[] | undefined
  }) => {
    const [lastDeviceErrorStateByLivefeed, setLastDeviceErrorStateByLivefeed] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(liveData && liveData.length > 0 ){
            if(liveData[0].body?.name === 'deviceErrorState' && (liveData[0].body.attributes?.errorState === 1 || liveData[0].body.attributes?.errorState === 0 )){
                setLastDeviceErrorStateByLivefeed(liveData[0].body.attributes.errorState.toString())
            }
        }
    }, [liveData,deviceInfo]);

    return  <td className={" hidden m:table-cell pl-0 "} title={getStatusMessage(incidentsData, deviceInfo, installationHasDevice,lastDeviceErrorStateByLivefeed)}>
        <div className="flex items-center">
          {getStatus(incidentsData,deviceInfo, ' w-5 m:w-6 ml-4 ', isLoadingDeviceState, undefined, installationHasDevice,lastDeviceErrorStateByLivefeed)}
          {incidentsData && <div className="mt-1 ml-1">
            <Badge count={incidentsData.length} size='default' overflowCount={9}>
              {getIcon({ type: 'Incidents', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-6 h-6' })}
            </Badge>
          </div>}
        </div>
      </td>
}