import React from "react";
import { I18n } from "@aws-amplify/core";
import { Tab } from "../../components/Tab";

const StatusTabs = (props: {
  selectedTab: string | undefined;
  setFilter: React.Dispatch<React.SetStateAction<any>>;
  valuesArray: string[];
  defaultTab: string;
}) => {
  return (
    <div className="flex-1 flex justify-end gap-2">
      {props.valuesArray.map<React.ReactNode>((tab) => (
        <Tab
          key={tab}
          isActive={props.selectedTab === tab || (tab === props.defaultTab && !props.selectedTab)}
          label={I18n.get(tab)}
          onClick={() => props.setFilter(tab)}
        />
      ))}
    </div>
  );
};

export default StatusTabs;