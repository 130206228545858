import React, { useEffect, useRef, useState } from "react";
import { DsFileRecentlyViewed, DsFileStored } from "../../interfaces/File";
import { I18n } from "@aws-amplify/core";
import { useGlobalState } from "../../../utils/globalStateProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { Library } from "../../interfaces/Library";
import TableMain, { TableProps } from "../../../components/molecules/TableMain";
import { DOCUMENTS_LAST_VIEWED } from "../../../utils/consts";
import moment from "moment";
import { IsStorybook, formatBytes } from "../../../utils/helpers";
import { CookieConsent } from "../../utils/helpers";
import { Installation } from "../../../interfaces/Installation";


const RecentlyViewedFiles = (props:{
  libraryIdToFilterFor:string|undefined,
  setSelectedFile:Function
  isOverView:boolean
  }) => {
    const { state, setState } = useGlobalState()
    const { user } = useAuth0();
    const ref = useRef<any>();
    const [tableProps, setTableProps] = useState<TableProps >({height:"unset",noDataLabel:'',th:[],tr:[]})
  
    useEffect(() => {
      if(state.language){
        I18n.setLanguage(state.language)
        document.documentElement.lang = state.language
      }
    }, [state.language]);

    useEffect(() => {

      if(!user?.sub && !IsStorybook()){
        return 
      }
      let lastDocumentsViewed:DsFileStored = {}
      let tableProps:TableProps = {height:"unset",noDataLabel:'',th:[],tr:[]}

      let storedLastDocumentsViewed:string|null = localStorage.getItem(DOCUMENTS_LAST_VIEWED)
      if(storedLastDocumentsViewed !== null){
        lastDocumentsViewed = JSON.parse(storedLastDocumentsViewed)
      }

      let organozationUserIdentifier = ''
      if(IsStorybook()){
        organozationUserIdentifier = 'user.sub' + '-' + 'state.organizationIdentifier'
      }
      else{
        organozationUserIdentifier = user?.sub + '-' + state.organizationIdentifier
      }
      
      let lastDocumentsViewedThisUser = lastDocumentsViewed[organozationUserIdentifier]
      if(!lastDocumentsViewedThisUser || lastDocumentsViewedThisUser.length <= 0){
        return
      }

      // apply filter for library
      if(props.libraryIdToFilterFor ){
        let lastDocumentsViewedThisUserForCurrentLibrary = lastDocumentsViewedThisUser.filter((elem) => {
          if(elem.library === props.libraryIdToFilterFor ){return true}
        })

        if(lastDocumentsViewedThisUserForCurrentLibrary.length <= 0){ return }

        lastDocumentsViewedThisUser = lastDocumentsViewedThisUserForCurrentLibrary
      }

      // show only 10
      lastDocumentsViewedThisUser = lastDocumentsViewedThisUser.splice(0,10)  

      if(props.isOverView){

        tableProps.th = [
          /* desktop */
          {
            text:<>{I18n.get('SubCategory')}</>,
            class:'hidden m:flex w-2/12 pb-4 ',
            ellipsis:true,
          },
          {
            text:<>{I18n.get('Title')}</>,
            class:'hidden m:flex w-4/12 pb-4 ',
            ellipsis:true,
          },
          {
            text:<>{I18n.get('Type')}</>,
            class:'hidden m:flex w-2/12 pb-4 ',
          },
          {
            text:<>{I18n.get('FabricationContractNumberFirst') + ' / ' + I18n.get('FabricationContractNumberSecond')}</>,
            class:'hidden m:flex w-2/12 text-left pb-4',
          },
          {text:<>{I18n.get('Size')}</>,class:'hidden m:flex w-2/12 pb-4 '},
      
  
          /* mobile */
          {
            text:<span className="flex flex-col s:flex-row">
              <span className="flex items-center" >{I18n.get('SubCategory')}</span>
              <span className="hidden s:inline-block mr-3 ml-3">/</span>
              <span className="flex items-center" ><span className="s:hidden mr-3">/</span>{I18n.get('Title')}</span>
              </span>,
            class:'flex items-center pb-0 text-left m:hidden w-6/12 ',
            ellipsis:true
          },
          {
            text:<>{I18n.get('FabricationContractNumberFirst') + ' / ' + I18n.get('FabricationContractNumberSecond')}</>,
            class:'flex items-center pb-0 text-left m:hidden w-3/12 ',
            ellipsis:true,
          },
          {text:<>{I18n.get('Size')}</>,class:'flex m:hidden w-3/12 items-center '},
        ]
  
        tableProps.tr = lastDocumentsViewedThisUser.map((file)=>{
        let f = file
  
        return {
          trOnClick:()=>{props.setSelectedFile(file)},
          cells:[
            {text:<>{state.language === 'de' ? f.category.titleGerman : f.category.titleEnglish}</>,class:"hidden m:flex w-2/12"},
            {text:<>{f.title}</>,class:"hidden m:flex w-4/12"},
            {text:<>{ getType(f.infoLibrary)}</>,class:"hidden m:flex w-2/12"},
            {text:<>{ getLabelIdentifier(f)}</>,class:"hidden m:flex w-2/12"},
            {text:<>{formatBytes(f.sizeKb)}</>,class:"hidden m:flex w-2/12"},
  
            {text:<><div className="font-semibold">{state.language === 'de' ? f.category.titleGerman : f.category.titleEnglish}</div>{f.title}</>,class:' m:hidden w-6/12 ',ellipsis:true},
            {text:<>{getLabelIdentifier(f)}<br/>{getType(f.infoLibrary)}</>,class:' m:hidden w-3/12 ',ellipsis:true},
            {text:<>{formatBytes(f.sizeKb)}</>,class:"flex m:hidden w-3/12"},
          ]}
        })
      }
      else{

        tableProps.th = [
          /* desktop */
          {
            text:<>{I18n.get('SubCategory')}</>,
            class:'hidden m:flex w-2/12 pb-4 ',
            ellipsis:true,
          },
          {
            text:<>{I18n.get('Title')}</>,
            class:'hidden m:flex w-6/12 pb-4 ',
            ellipsis:true,
          },
          {
            text:<>{I18n.get('Uploaded')}</>,
            class:'hidden m:flex w-2/12 pb-4 ',
          },
          {text:<>{I18n.get('Size')}</>,class:'hidden m:flex w-2/12 pb-4 '},
      
  
          /* mobile */
          {
            text:<span className="flex flex-col s:flex-row">
              <span className="flex items-center" >{I18n.get('SubCategory')}</span>
              <span className="hidden s:inline-block mr-3 ml-3">/</span>
              <span className="flex items-center" ><span className="s:hidden mr-3">/</span>{I18n.get('Title')}</span>
              </span>,
            class:'flex items-center pb-0 text-left m:hidden w-6/12 ',
            ellipsis:true
          },
          {
            text:<>{I18n.get('Uploaded')}</>,
            class:'flex items-center pb-0 text-left m:hidden w-3/12 ',
            ellipsis:true,
          },
          {text:<>{I18n.get('Size')}</>,class:'flex m:hidden w-3/12 items-center '},
        ]
  
        tableProps.tr = lastDocumentsViewedThisUser.map((file)=>{
        let f = file
  
        return {
          trOnClick:()=>{props.setSelectedFile(file)},
          cells:[
            {text:<>{state.language === 'de' ? f.category.titleGerman : f.category.titleEnglish}</>,class:"hidden m:flex w-2/12"},
            {text:<>{f.title}</>,class:"hidden m:flex w-6/12"},
            {text:<>{moment(f.createdAt).format('DD.MM.YYYY' )}</>,class:"hidden m:flex w-2/12"},
            {text:<>{formatBytes(f.sizeKb)}</>,class:"hidden m:flex w-2/12"},
  
            {text:<><div className="font-semibold">{state.language === 'de' ? f.category.titleGerman : f.category.titleEnglish}</div>{f.title}</>,class:' m:hidden w-6/12 ',ellipsis:true},
            {text:<>{moment(f.createdAt).format('DD.MM.YYYY' )}</>,class:' m:hidden w-3/12 ',ellipsis:true},
            {text:<>{formatBytes(f.sizeKb)}</>,class:"flex m:hidden w-3/12"},
          ]}
        })
      }

      setTableProps(tableProps)
    }, [state.language,user,state.organizationIdentifier,props.libraryIdToFilterFor]);

    const getLabelIdentifier = (file:DsFileRecentlyViewed) => {
      if(file.infoContract || file.infoLibrary?.title.includes('contract')){
        // contract
        if(file.infoContract && file.infoContract.contractNumber){
          return file.infoContract.contractNumber
        }
        else{
          return I18n.get('Unknown')
        }
      }
      else{
        // asset
        if(file.infoLibrary?.assets && file.infoLibrary?.assets[0]){
          let asset:Installation = file.infoLibrary.assets[0]
          return (asset.customLabel && asset.customLabel.length > 0)?asset.customLabel:asset.label
        }
        else{
          return I18n.get('Unknown')
        }
      }
  }

  const getType = (library:Library|undefined) => {

    let type = I18n.get('Unknown')

    if(library && library.title === "sf_contract_lib"){
      type = I18n.get('Contract')
    }
    else if(library && library.title === "sf_asset_lib"){
      type = I18n.get('Installation')
    }
    return type
  }

  return (
    <>{ CookieConsent() && tableProps.tr.length > 0 && 
      <>
        <h1 data-testid="headline_recently_viewed" className=" mb-5 mt-10">{I18n.get('RecentlyViewed')}</h1>
        <TableMain tableContent={tableProps} />
      </>}
    </>
  );
}
export default RecentlyViewedFiles