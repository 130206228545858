import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../../utils/globalStateProvider';
import moment from 'moment';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import SearchHistory from '../../../components/SearchHistory';
import { Button } from '../../../components/atoms/Button';
import { getIcon } from '../../../utils/icons';
import { setSearchHistory } from '../../../utils/helpers';
import useWindowDimensions from '../../../utils/UseWindowDimensions';

I18n.putVocabularies(stringGlobal);

function MobileSearchOverlay(props:{closerFunction:Function,handleSearchChange(searchterm: string) }) {
  const { state, setState } = useGlobalState()
  const [options, setOptions] = React.useState<{label:string,value:string}[]>([])
  const [inputValue, setInputValue] = React.useState<string>('')
  const { height, width } = useWindowDimensions()

    useEffect(() => {
        if(width>=769){
            document.body?.classList.remove('modalOpen')
            props.closerFunction()
        }
    }, [width])

    useEffect(() => {
        I18n.putVocabularies(stringGlobal);
    }, [])

    useEffect(() => {

        let storedValues = localStorage.getItem('search')
        if (storedValues) {
            let searchOption = JSON.parse(storedValues)
            let autoCompleteOptions: { label: string; value: string }[] = []
            searchOption.forEach((elem:string)=>{
            if(inputValue.length <= 0){
                autoCompleteOptions.push({label:elem,value:elem})
            }
            else if(elem.toLowerCase().includes(inputValue.toLowerCase()) && inputValue.length > 0){
                autoCompleteOptions.push({label:elem,value:elem})
            }
            })
            setOptions(autoCompleteOptions.slice(0,10))        
        }

    }, [inputValue])  

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  const handleSearchChangeOverlay = (searchTerm: string) => {
    setInputValue(searchTerm)
    props.handleSearchChange(searchTerm)
  }

  const highlightMatch = (text:string) => {
    return getHighlightedText(text,inputValue)
  }

  const getHighlightedText = (text:string, highlight:string) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return <span> { parts.map((part, i) => 
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {} }>
            { part }
        </span>)
    } </span>
}

  return (
    <>
        <div>
            <SearchHistory setSearchValue={ handleSearchChangeOverlay} disableAutocomplete={true} closerFunction={props.closerFunction} />
            <Button label={I18n.get('Btn_Search')} size='medium' theme='dark' ownStyle='w-full flex justify-center mt-[14px]' onClick={()=>{setSearchHistory(inputValue),props.closerFunction()}} />
        </div>
        <div className="text-sm mt-3">
            {(inputValue === undefined || (inputValue.trim() === '')) ? <span className="min-h-14 content-center block">{I18n.get('LastSearched')}</span>:<></>}
            {options.map(elem => {
                return <div className="min-h-14 border-greyLighter border-b first-of-type:border-t flex " key={elem.value} onClick={()=>{setSearchHistory(elem.value),props.handleSearchChange(elem.value),props.closerFunction()}}>
                    <div className="content-center mr-3 ml-2">{getIcon({type:'Select-Circle',ownClassName:''})}</div>
                    <div className="content-center">{highlightMatch(elem.label)}</div>
                </div>
            })}
        </div>
    </>
  );
}

export default MobileSearchOverlay;
