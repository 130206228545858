import { useAuth0 } from '@auth0/auth0-react'
import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import DocumentDialog from '../../../components/detail/documentDialog'
import { Category } from '../../../interfaces/Category'
import { DsFilePreloaded, DsFileResponse } from '../../../interfaces/File'
import { Library, LibraryResponse } from '../../../interfaces/Library'
import { getAllCategories, getFile, getLibraryBySalesforceEntity, getLibraryForEntity, getfilesForCategory } from '../../../utils/helpersFetch'
import { getAuth0Token } from '../../../../utils/helpers'
const SalesforceDocumentsAssetModalPreview = () => {
  
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { isAuthenticated,getAccessTokenSilently,loginWithRedirect } = useAuth0()
  const [selectedFile, setSelectedFile] = React.useState<DsFilePreloaded | null>(null)
  const [library, setLibrary] = useState<Library|undefined>(undefined)
  const [categories, setCategories] = useState<Category[]>([])
  const [category, setCategory] = useState<Category|undefined>(undefined)
  const [files, setFiles] = useState<DsFileResponse | null>(null);

  let { assetId,contractId,libraryId,categoryMainId,fileId } = useParams();

  useEffect(() => {

    const loadData = async () => {

        if( !(assetId || contractId) || !libraryId || !categoryMainId || !fileId ){
          return
        }
 
        setIsLoading(true)
        
        let accessToken =  await getAuth0Token(getAccessTokenSilently)

        let type : 'Asset' | 'Contract' = 'Asset'
        let sfId

        if(assetId){
          type = 'Asset'
          sfId = assetId
        }
        else if(contractId){
          type = 'Contract'
          sfId = contractId
        }

        try{
          const [entity,categoriesResult,fileResult,fileList ] = await fetchAllData(sfId,type,fileId,libraryId,categoryMainId,accessToken);

          if(!fileResult){
            return
          }
  
          let library:LibraryResponse|null = { count:0,libraries:[]}
  
          if(assetId){
            library = await getLibraryForEntity(entity.id,'Asset',accessToken)
          }
          else if(contractId){
            library = await getLibraryForEntity(entity.id,'Contract',accessToken)
          }
        
          if(library && library.libraries[0]){ setLibrary(library.libraries[0]) }
  
          if(fileList){
            setFiles(fileList)
          }
          setSelectedFile({file:fileResult,imageSrc:null})
          setCategories(categoriesResult)
          categoriesResult.forEach((category)=>{if(category.id === categoryMainId){setCategory(category)}})
  
          setIsLoading(false)

        }      
        catch(error){
          console.log(error)
          // expect to not have the right permissions
          // https://digitalspine.atlassian.net/browse/AP-2473
          loginWithRedirect({
            appState: {
              returnTo: window.location.href,
            },
          })
          return
        }
    }

    loadData()
    
  }, []);

  const fetchAllData = async (Id: string,type: 'Asset'|'Contract', fileId:string,libraryId:string,categoryMainId:string, accessToken:string) => {

        return Promise.all([
          getLibraryBySalesforceEntity(Id,type,accessToken),
          getAllCategories(accessToken,type),
          getFile(fileId,accessToken),
          getfilesForCategory(libraryId,categoryMainId,accessToken,null,null,undefined,true)
        ]);    
  };

  return (
    <div className={isAuthenticated? " h-screen flex items-center justify-center": 'hidden' }>
      <Spin tip="Loading..." spinning={isLoading}>
      { selectedFile && library && category && files && <DocumentDialog 
        isLastSeenMode={false}
        allFileResponse={files} 
        file={selectedFile} 
        setSelectedFile={setSelectedFile} 
        paginationPage={null} 
        paginationSize={null}
        category={category}
        library={library}
        sorter={{columnKey:'',order:''}}
        mode='Installation'
        />}
      </Spin>
    </div>
  );
};

export default SalesforceDocumentsAssetModalPreview;