export const strings = {
    en: {
        HeadlineContracts: 'Contracts',
        HeadlineDevices: 'Assets',
        HeadlineMessages: 'Messages',
        Operational: 'Operational',
        AttentionNeeded: 'Attention needed',
        IssueDetected: 'Issue detected',
        ContractActive:'Active',
        ContractExpired:'Expired',
        ContractSignNeeded:'Signature required',
        Today:'Today',
        LastWeek:'Last week',
        'Latest Documents':'Latest Documents',
        'DocumentName':'Document',
        'Category':'Category',
        'Asset/ContractLibraryname':'Number',
        'ResourceType':'Type',
        'UploadDate':'Upload Date'

      },
      de: {
        HeadlineContracts: 'Verträge',
        HeadlineDevices: 'Anlagen',
        HeadlineMessages: 'Mitteilungen',
        Operational: 'Funktionsfähig',
        AttentionNeeded: 'Aufmerksamkeit benötigt',
        IssueDetected: 'Problem erkannt',
        ContractActive:'Aktiv',
        ContractExpired:'Ausgelaufen',
        ContractSignNeeded:'Unterschrift nötig',
        Today:'Heute',
        LastWeek:'Letzte Woche',
        'Latest Documents':'Neueste Dokumente',
        'DocumentName':'Dokument',
        'Category':'Kategorie',
        'Asset/ContractLibraryname':'Nummer ',
        'ResourceType':'Typ',
        'UploadDate':'Upload Datum'
      },
};