import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from 'jwt-decode';
import { JwtPayloadAuth0 } from '../interfaces/JwtPayloadAuth0';
import { GlobalStateInterface, useGlobalState } from './globalStateProvider';
import { fetchOrganizationName } from './helpersFetch';
import React from 'react';
import { getSilentTokenOrLogout } from './helpers';

export const RequireAuth = () => {

  const { user, logout , isAuthenticated,loginWithRedirect,getAccessTokenSilently } = useAuth0();
  const { state, setState } = useGlobalState();

  useEffect(() => {
    if (isAuthenticated) {
      const onAuthentication = async( )=>{

        let accessToken =  await getSilentTokenOrLogout({getAccessTokenSilently,logout }) 
        try{
          let tokenObj= jwt_decode<JwtPayloadAuth0>(accessToken)

          if(tokenObj?.org_id){
            let dataGlobal:Partial<GlobalStateInterface> = {organizationIdentifier:tokenObj.org_id}
            setState((prev) => ({ ...prev, ...dataGlobal }));
  
            let organization = await fetchOrganizationName(tokenObj.org_id)
            if(organization ){
              let dataGlobal:Partial<GlobalStateInterface> = {
                organizationName:organization.name,
                organizationDisplayName:organization.displayName
              }
              setState((prev) => ({ ...prev, ...dataGlobal }));            
            }
          }  
        }
        catch(error){
          console.log(error)
        }
      } 
      if(window.location.pathname !== '/callback'){
        onAuthentication()
      }
    }
  }, [isAuthenticated]);

  if (!user) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.href,
      },
    })
  }
  
  return <Outlet />;
};
