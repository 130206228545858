import { I18n } from '@aws-amplify/core';
import { useEffect, useState } from 'react'
import React from 'react';
import { strings as stringHeader } from './InformationDetailsLocalization';
import { strings as stringGlobal } from '../utils/globalLocalization';
import { mergeDeepRight } from 'ramda';
import { useGlobalState } from '../utils/globalStateProvider';
import useWindowDimensions from '../utils/UseWindowDimensions';
import { getTransformedIncidentStatus } from '../incidents/utils/helpers';
import { getLangByLocalStorage } from '../utils/helpers';

export default function InformationDetails( props:{data:JSX.Element[],headline:string, bottomMargin?:boolean} ) {

  const { state, setState } = useGlobalState()
  const { height, width } = useWindowDimensions();
  const [preparedData, setPreparedData] = useState<any[]>([]);

  useEffect(() => {
    if(!state.language){
      I18n.putVocabularies(mergeDeepRight(stringHeader,stringGlobal));
      I18n.setLanguage(getLangByLocalStorage())
    }
  }, []);  

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
    }
  }, [state.language]);

  useEffect(() => {
    setPreparedData(divideData(props.data))
  }, [props.data,width]);

  const divideData = (data:JSX.Element[]) => {

    let shiftDividerAtEachOf = 2
    if (width > 992) {
      shiftDividerAtEachOf = 3
    }
    else if(width > 768){
      shiftDividerAtEachOf = 3
    }
  
    let dividedData:JSX.Element[] = []
    for(let i=0;i<data.length;i++){
      dividedData.push(data[i])
      if(i < data.length-1){ // only add divider if at least one more element is coming
        if( shiftDividerAtEachOf === 1 ){
          dividedData.push(<div className=" block bg-blueBaby h-[2px] mt-5 mb-5 xxl:mt-8 xxl:mb-8 " key={i+"divider"}></div>)
        }
        else if( (i+1) % shiftDividerAtEachOf === 0) {
          dividedData.push(<div className=" block col-span-2 m:col-span-3 bg-blueBaby h-[2px]  mt-5 mb-5 m:mt-7 xxl:mt-9 xxl:mb-8" key={i+"divider"}></div>)
        }
      }
    }
  
    return dividedData
  }

  if(preparedData.length <= 0){
    return <></>
  }

  return (
    <>
      <div className={`flex flex-col pt-16 pb-0`}>
        <h1>{props.headline}</h1>
        <div className="grid grid-cols-2 m:grid-cols-3 divided text-base m:text-lbase xxl:text-lg xxl:font-thin mt-12 ">
          {preparedData}
        </div>
      </div>
      {props.bottomMargin && <div className="pt-10 m:pt-24 xxl:pt-32"></div>}
    </>
  )
}

export const getInformationsDetailsDataElement = (label: string,value: string,translationType:'devices'|'incidents'|'contracts') => {

  let transformedValue = value
  if(translationType === 'incidents'){
    transformedValue = getElevatorParameterLabelTranslationIncidents(label,value)
  }
  else if(translationType === 'devices'){
    transformedValue = getElevatorParameterLabelTranslationDevices(label,value)
  }
  else if(translationType === 'contracts'){
    transformedValue = getContractParameterLabelTranslation(label,value)
  }

  return <div className="justify-center p-0 block " key={label+'_'+value}>
    <h3 className="w-full font-bold uppercase">{I18n.get(label)}</h3>
    <div className="w-full pt-2 text-[24px] font-light xxl:text-[36px] ">{transformedValue}</div>
  </div>
}

export function getContractParameterLabelTranslation(label: string, value: string) {

    switch (label) {
      case 'Status':
        return I18n.get('ContractStatus_' + value)
      case 'Type':
        return I18n.get('ContractType' + value)
      case 'BillingCycle':
        return I18n.get('BillingCycle_' + value)
      case 'CancellationPeriod':
        return I18n.get('CancellationPeriod_' + value)
      case 'AutoRenewal':
        if(value){
          return I18n.get('Yes')
        }
        else{
          return I18n.get('No')
        }
      default:
        return value
    }
  }

// labelValueTranslater
export function getElevatorParameterLabelTranslationDevices(label: string, value: string) {

  switch (label) {

    case 'AuditInterval':

      return I18n.get(value)

    case 'elevatorType':

      switch (value) {
        case 'Passenger Elevator':
          return I18n.get('passengerElevator')
        case 'Cargo Elevator':
          return I18n.get('cargoElevator')
        case 'Hydraulic Ramp':
          return I18n.get('hydraulicRamp')
        default:
          return value
      }

    case 'liftType':

      switch (value) {
        case 'Rope':
          return I18n.get('rope')
        case 'Direct Hydraulic Systems':
          return I18n.get('directHydraulicSystems')
        case 'Indirect Hydraulic Systems':
          return I18n.get('indirectHydraulicSystems')
        default:
          return value
      }

    default:
      return value
  }
}

export function getElevatorParameterLabelTranslationIncidents(label: string, value: string) {

  switch (label) {

    case 'RecordType':
      return I18n.get(`IncidentType_${value}`)
    case 'Status':
      return I18n.get('IncidentStatus_' + getTransformedIncidentStatus(value))
    case 'description':
      return <><span  dangerouslySetInnerHTML={{__html: value.replace(/(?:\r\n|\r|\n)/g, '<br />')}} ></span></>
    default:
      return value
  }
}