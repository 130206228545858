import { useEffect, useRef, useState, FocusEvent} from 'react';
import { message } from 'antd';
import ContentEditable from 'react-contenteditable'
import { Installation } from '../interfaces/Installation';
import React from 'react';
import { patchAssetTitle } from '../utils/helpersFetch';
import { useAuth0 } from '@auth0/auth0-react';
import {CheckCircleFilled} from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../utils/globalStateProvider';
import CopyToClipboard from './molecules/CopyToClipboard';
import { getAuth0Token } from '../utils/helpers';


interface EditableTitleProps { installation: Installation|null, isEditMode: boolean, setterIsEditMode:Function, loadInstallation:Function, cssIdForTest:string }

const EditableTitle:  React.FC<EditableTitleProps> =  ({
        installation,
        isEditMode,
        setterIsEditMode,
        loadInstallation,
        cssIdForTest
    }) => {
        const inputTitleRef = useRef<HTMLSpanElement>(null)
        const [assetTitle, setAssetTitle] = useState<string>('')
        const [titleCopied, setTitleCopied] = useState<boolean>(false)
        const { getAccessTokenSilently } = useAuth0(); 
        const  showOrgLabelEvenIfNotChanged = true
        const { state, setState } = useGlobalState()

        useEffect(() => {
            if(state.language){
              I18n.setLanguage(state.language)
            }
          }, [state.language]);

        useEffect(() => {
            if(isEditMode){
                let htmlElement = inputTitleRef?.current
                if(htmlElement){
                    htmlElement.focus();
                }
            }
         }, [isEditMode]);    

         useEffect(() => {
            if(installation){
                if(installation.customLabel && installation.customLabel.length > 0){
                    setAssetTitle(installation.customLabel)
                }
                else{
                    setAssetTitle(installation.label)
                }    
            }
          }, [installation]);

          const on_focus = (elem:any) => {
            const selection = window.getSelection();
            const range = document.createRange();
            let offset = elem.target.innerHTML.length
            let startNode = selection?.anchorNode? selection.anchorNode : null
            
            if(startNode && selection){
                selectText(startNode,range,selection,offset)
            }
            else{             
             window.setTimeout(function() {
                let startNode = selection?.anchorNode? selection.anchorNode : null
                selectText(startNode,range,selection,offset)
            }, 1);
            }
          }

          const selectText = (startNode, range, selection,offset) => {
            range.setStart(startNode, offset);
            range.selectNodeContents(startNode)
            selection.removeAllRanges();
            selection.addRange(range);
          }

        const saveTitle = async (title:string) => {
            if(installation){
                
                if(installation.customLabel?.trim() == title.trim() && title !== ''){
                    return
                }

                let accessToken =  await getAuth0Token(getAccessTokenSilently)
                let installationResponse = await patchAssetTitle(title.trim(),installation._id,accessToken)
                if(installationResponse.customLabel.trim() == title.trim()){
                    message.success('Titel erfolgreich geändert!')
                }
                else{
                    message.error('Es gab ein Problem beim speichern des neuen Titels!')
                }
            }
        }

        const handleTitleBLur = (event:FocusEvent<HTMLInputElement>) => {
            if(event.target.innerText.length > 0){
                setAssetTitle(event.target.innerText)
            }
            else{
                if(installation?.label){
                    setAssetTitle( installation.label)
                }
            }    
        
            saveTitle(event.target.innerText)    
            setterIsEditMode(false)
        };
        
        const handleKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        
            // everytime allowed
            let whiteListKeys = ['ArrowRight','ArrowLeft','Backspace']
            if(whiteListKeys.includes(event.key)){
            return true
            }
        
            // no breaks
            if(event.key === 'Enter' ) {
            inputTitleRef?.current?.blur();
            event.preventDefault();
            return false;
            }
        
            // limit of chars
            var element = event.target as HTMLElement
            if(element.innerHTML.length > 30){
            event.preventDefault();
            return false;
            }
        }

        return (
    <span>
        <div className="inline-flex items-center"><h1 className={isEditMode?"mb-0 editableField pl-4 bg-white text-blueDark inline-block":"text-white mb-0 editableField inline-flex group items-center"}>
            <ContentEditable
                html={assetTitle}
                disabled={!isEditMode}
                onChange={()=>{}}
                onBlur={handleTitleBLur}
                onKeyDown={handleKeydown}
                onFocus={e => on_focus(e)}
                tagName='span'
                className="pr-4 pl-0 "
                innerRef={inputTitleRef}
                id={cssIdForTest}
            />
           {!isEditMode && 

            <CopyToClipboard 
            classNames='invisible group-hover:visible text-sm m:text-lg  xxl:text-2xl text-white'
            label={I18n.get('CopyName')}
            textToCopy={assetTitle} />

           }
        </h1>
        {isEditMode?<CheckCircleFilled className="ml-2 mr-2 text-base m:text-lg xxl:text-5xl xxl:ml-4 none" style={{lineHeight:0}}  />:<></>}
        </div>
        <div className="h-4"></div>
    </span>
  );
}
export default React.memo(EditableTitle,areEqual);

function areEqual(prevProps, nextProps) {
    // prevent reinitialisiation of editable title 
    if(!prevProps.installation && nextProps.installation){
        return false
    }
    else if(prevProps.installation?.customLabel !== nextProps.installation?.customLabel){
        return false
    }
    else if(prevProps.isEditMode && nextProps.isEditMode){
        return true
    }
    else if(prevProps.isEditMode && !nextProps.isEditMode){
        return false
    }
    else if(nextProps.isEditMode){
        return false
    }
    return true
}