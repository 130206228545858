import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useRef } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { Category } from '../../../interfaces/Category';
import { DsFile, DsFileResponse } from '../../../interfaces/File';
import { deleteFiles, getAllCategories, getFile, getFiles, getfilesForCategory, getLibraryBySalesforceEntity, iniatMultiDownload } from '../../../utils/helpersFetch';
import { DownloadOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, MenuProps, Spin, Switch } from 'antd';
import TableShared, { TableProps } from '../../../../components/molecules/TableShared';
import FileUploadCategories from '../../../components/salesforce/uploadFilePerCategory';
import { formatBytes, getAuth0IsAuthenticatedStatus, getAuth0Token } from '../../../../utils/helpers';
import { downloadUrlByLinkMethod } from '../../../utils/helpers';
import ViewTileList from '../../../components/detail/viewTileList';
import { DOCUMENTS_DOWNLOAD_SOCKET_URL, SF_DOCUMENTS_PREFERENCES_VIEWTYPES } from '../../../../utils/consts';
import { Library } from '../../../interfaces/Library';
import moment from 'moment';
import ModalDialogRename from '../../../components/salesforce/modalDialogRename';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ModalDialogChangeCategory from '../../../components/salesforce/modalDialogChangeCategory';
import { Button } from '../../../../components/atoms/Button';
import SfFileMenu from '../../../../components/molecules/SfFileMenu';
import CryptoJS from 'crypto-js'
import ModalDialogDownload from '../../../components/salesforce/modalDialogDownload';
import ShowCatagoryListCategorySelect from '../../../components/detail/showCatgegoryListCategoySelect';
import { mergeDeepRight } from "ramda";
import { strings as stringGlobal } from '../../../../utils/globalLocalization';
import { strings as stringLocal } from '../../DocumentsDetailLocalization';
import ModalDialogCopyText from '../../../components/salesforce/modalDialogCopyText';
import UploadFileDropForSubCategory from '../../../components/detail/uploadFileDropForSubCategory';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { strings as stringGlobalDocuments } from '../../../utils/globalLocalization';
import { LinkOutlined} from '@ant-design/icons';

const SalesforceDocumentsAssetShowLibrary = () => {

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal, mergeDeepRight(stringGlobalDocuments, stringGlobal)));
    I18n.setLanguage('de')
    document.body?.classList.add('hideScrollbars')
  }, []);

  interface PreferencesOptions { options: 'List' | 'Tile' | null }

  const setView = (type: 'List' | 'Tile', catId?: string) => {
    if (!catId) return;
    const prefs = localStorage.getItem(SF_DOCUMENTS_PREFERENCES_VIEWTYPES);
    if (prefs) {
      const parsed = JSON.parse(prefs);
      if (parsed) {
        localStorage.setItem(SF_DOCUMENTS_PREFERENCES_VIEWTYPES, JSON.stringify({ ...parsed, [catId]: type }));
        setViewOfFiles(type);
      }
    } else {
      localStorage.setItem(SF_DOCUMENTS_PREFERENCES_VIEWTYPES, JSON.stringify({ [catId]: type }));
      setViewOfFiles(type);
    }
  }

  let { categoryMainId, libraryId, assetId, contractId } = useParams();
  const splitter = <svg className="ml-4 mr-4 h-3 " width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L0.999999 1" stroke="#102A68" strokeWidth="2" /></svg>
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0()
  const [category, setCategory] = useState<Category | undefined>(undefined);
  const [subCategory, setSubCategory] = useState<Category | undefined>(undefined);
  const [library, setLibrary] = useState<Library | undefined>(undefined);
  const toGalleryIcon = <svg onClick={() => { setView('Tile', category?.id) }} className="cursor-pointer h-5 w-5" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_518_5564)"><g clipPath="url(#clip1_518_5564)"><rect width="13.8039" height="13.8039" fill="#36ADF5" /><rect y="18.1973" width="13.8039" height="13.8039" fill="#36ADF5" /><rect x="17.5693" width="13.8039" height="13.8039" fill="#36ADF5" /><rect x="17.5693" y="18.1973" width="13.8039" height="13.8039" fill="#36ADF5" /></g></g><defs><clipPath id="clip0_518_5564"><rect width="31.3725" height="32" fill="white" /></clipPath><clipPath id="clip1_518_5564"><rect width="31.3725" height="32" fill="white" /></clipPath></defs></svg>
  const toListIcon = <svg onClick={() => { setView('List', category?.id) }} className="cursor-pointer h-5 w-5" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_518_8744)"><rect y="6" width="31" height="4" fill="#36ADF5" /><rect y="14" width="31" height="4" fill="#36ADF5" /><rect y="22" width="41.3333" height="4" fill="#36ADF5" /></g><defs><clipPath id="clip0_518_8744"><rect width="31.3725" height="32" fill="white" /></clipPath></defs></svg>
  const [categories, setCategories] = useState<Category[]>([]);
  const [runningDeletions, setRunningDeletions] = useState<String[]>([]);
  const [filesForMainCatgory, setFilesForMainCatgory] = useState<DsFileResponse | null>(null);
  const [files, setFiles] = useState<DsFileResponse | null>(null);
  const [tableProps, setTableProps] = useState<TableProps | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [viewOfFiles, setViewOfFiles] = useState<PreferencesOptions['options']>(null)
  const [itemToRename, setItemToRename] = useState<DsFile | null>(null);
  const [textToCopy, setTextToCopy] = useState<string | null>(null);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState<boolean>(false);
  const [forceTableUpdate, setForceTableUpdate] = useState<number>(0);
  const [itemsToChangeCategory, setItemsToChangeCategory] = useState<DsFile[]>([]);
  const socket = useRef<WebSocket | null>(null);
  const [actualChannelName, setActualChannelName] = useState<string | undefined>(undefined);
  const [showUploadForm, setShowUploadForm] = useState<boolean>(false)
  //  const [activeSubCategoryByUserSelection, setActiveSubCategoryByUserSelection] = useState<Category|null>(null);
  const [uploadWasStarted, setUploadWasStarted] = useState<boolean>(false)
  const refUploadWasStarted = React.useRef<boolean>(false);
  const [sortedInfo, setSortedInfo] = React.useState({ columnKey: '', order: '' })

  const navigate = useNavigate();

  useEffect(() => {
    const loadLibrary = async (id: string, type: 'Asset' | 'Contract') => {
      let accessToken = await getAuth0Token(getAccessTokenSilently)
      let categoriesResult = await getLibraryBySalesforceEntity(id, type, accessToken)
      setLibrary(categoriesResult)
    }

    loadCategory()
    if (assetId) {
      loadLibrary(assetId, 'Asset')
    }
    else if (contractId) {
      loadLibrary(contractId, 'Contract')
    }
  }, [assetId, contractId]);


  useEffect(() => {
    loadFilesForCategory()
  }, [category, subCategory, sortedInfo]);


  const getSortVisualisation = (columnKey: string) => {

    if (columnKey === sortedInfo.columnKey) {
      if (sortedInfo.order === 'asc') {
        return <CaretUpOutlined className="ml-4" style={{ 'color': 'white' }} />
      }
      else {
        return <CaretDownOutlined className="ml-4" style={{ 'color': 'white' }} />
      }
    }
    return <CaretUpOutlined className="ml-4" style={{ 'color': 'grey' }} />
  }

  const sortChange = (sorter: { columnKey: string, order: string }) => {

    if (sorter.order === '') {
      setSortedInfo({ columnKey: '', order: '' })
    }
    else {
      setSortedInfo(sorter)
    }

  };

  const getSortOrder = (columnKey: string): string => {
    if (sortedInfo.columnKey === columnKey) {
      if (sortedInfo.order === 'asc') {
        return 'desc'
      }
      else if (sortedInfo.order === 'desc') {
        return ''
      }
      else {
        return 'asc'
      }
    }
    else {
      return 'asc'
    }
  }

  useEffect(() => {

    let tableP: TableProps = { th: [], tr: [], noDataLabel: 'Dieser Ordner ist leer', height: 'unset', thead: 'sticky-documents-salesforce-asset', tbody:'hideScrollbars', heightPrefs: 'medium', firstRowFullWidth: true }

    if (multiSelectEnabled) {
      tableP.multiselectEnabled = multiSelectEnabled
      tableP.th = [
        { text: <Checkbox onChange={onSelectAll} checked={areAllVisibleSelected()} />, class: 'flex w-1/12 items-center ' },
        {
          text: <>
            <span onClick={() => sortChange({ columnKey: 'categoryGerman', order: getSortOrder('categoryGerman') })} className="flex items-center"><span>Kategorie</span> {getSortVisualisation('categoryGerman')}</span>
            <span className="pl-2 pr-2 hidden s:flex">/</span>
            <span onClick={() => sortChange({ columnKey: 'title', order: getSortOrder('title') })} className="flex items-center"><span className="pr-2 s:hidden">/</span><span>Titel</span> {getSortVisualisation('title')}</span>
          </>,
          class: 'flex w-5/12 flex-col s:flex-row', ellipsis: false
        },
        {
          text: <span onClick={() => sortChange({ columnKey: 'date', order: getSortOrder('date') })} className="flex items-center"><span>Datum</span> {getSortVisualisation('date')}</span>,
          class: 'flex w-3/12 '
        },
        { text: <span onClick={() => sortChange({ columnKey: 'size', order: getSortOrder('size') })} className="flex items-center"><span>Größe</span> {getSortVisualisation('size')}</span>, class: 'flex w-3/12 ' }
      ]
    }
    else {
      tableP.th = [
        {
          text: <>
            <span onClick={() => sortChange({ columnKey: 'categoryGerman', order: getSortOrder('categoryGerman') })} className="flex items-center"><span>Kategorie</span> {getSortVisualisation('categoryGerman')}</span>
            <span className="pl-2 pr-2 hidden m:flex">/</span>
            <span onClick={() => sortChange({ columnKey: 'title', order: getSortOrder('title') })} className="flex items-center"><span className="pr-2 m:hidden">/</span><span>Titel</span> {getSortVisualisation('title')}</span></>,
          class: 'flex w-4/12 flex-col m:flex-row items-left m:items-center pt-2 m:pt-0 ', ellipsis: false
        },
        {
          text: <span onClick={() => sortChange({ columnKey: 'date', order: getSortOrder('date') })} className="flex items-center"> <span>Datum</span> {getSortVisualisation('date')} </span>,
          class: 'flex w-3/12 items-center'
        },
        { text: <span onClick={() => sortChange({ columnKey: 'size', order: getSortOrder('size') })} className="flex items-center" ><span>Größe</span> {getSortVisualisation('size')}</span>, class: 'flex w-3/12 ' },
        { text: <>Aktionen</>, class: 'flex w-2/12 items-center' }
      ]
    }


    if (files) {
      tableP.tr = files.files.map((file) => {
        let f = file
        let category = categories.filter((category) => {
          if (category.id === f.category.id) {
            return category
          }
        })

        var urlToModalDialog: string = ''
        let openCategory = subCategory ? subCategory.id : categoryMainId;

        urlToModalDialog = document.location.origin + '/org_digitalspine' + '/library/' + libraryId + '/f/' + file.id;

        let dataRow: TableProps["tr"][0] = {
          cells: [
            {
              text: <>{f.title}</>,
              class: (multiSelectEnabled ? ' w-11/12 justify-center pl-4' : ' w-12/12 justify-center pl-4'),
              textPlain: f.title
            },
            {
              text: <><div className='font-semibold overflow-hidden text-ellipsis whitespace-nowrap'>{category[0].titleGerman}</div></>,
              class: (multiSelectEnabled ? 'flex flex-col w-5/12 justify-center' : 'flex flex-col w-4/12 justify-center'),
              textPlain: category[0].titleGerman
            },
            {
              text: <>{moment(f.createdAt).format('DD.MM.YYYY')}</>,
              class: 'flex flex-col w-3/12'
            },
            {
              text: <>{formatBytes(f.sizeKb)}</>,
              class: (multiSelectEnabled ? 'flex flex-col w-3/12' : 'flex flex-col w-3/12')
            },
          ],
          trOnClick: (e) => {
            if (multiSelectEnabled) {
              setSelectedFileState(f)
            }
            else {
              let newWindow = window.open(
                urlToModalDialog
                , 'salesforce_preview_' + libraryId + '_' + openCategory)
              if (newWindow) {
                newWindow.focus()
              }
            }
          }
        }

        if (multiSelectEnabled) {
          // unshift select column
          dataRow.cells.unshift({ text: <><Checkbox checked={getSelectedFileState(f)} /></>, class: "flex w-1/12" })
        }
        else {
          // append single actions column
          dataRow.cells.push(
            {
              text: <div className="flex items-center"><DownloadOutlined
                onClick={async (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  let fileResult = await getFile(file.id,await getAuth0Token(getAccessTokenSilently))
                  if(fileResult?.originalUrl){
                    window.location.href = fileResult?.originalUrl
                  }
                }} className="pr-4" />

                {category[0] && <SfFileMenu
                  category={category[0]}
                  file={f}
                  loadFilesForCategory={loadFilesForCategory}
                  runningDeletions={runningDeletions}
                  setItemToRename={setItemToRename}
                  setItemsToChangeCategory={setItemsToChangeCategory}
                  setRunningDeletions={setRunningDeletions}
                  setTextToCopy={setTextToCopy}
                  urlToModalDialog={urlToModalDialog}
                />
                }
              </div>, class: 'flex w-2/12 '
            }
          )
        }

        return dataRow
      })
    }

    setTableProps(tableP)

  }, [files, runningDeletions, multiSelectEnabled, forceTableUpdate]);

  const setSelectedFileState = (file: DsFile) => {
    files?.files?.forEach((item) => {
      if ((file.id === item.id) && item.selected) {
        item.selected = false
      }
      else if ((file.id === item.id) && !item.selected) {
        item.selected = true
      }
    })
    setFiles(files)
    setForceTableUpdate(forceTableUpdate + 1)
  }

  const getSelectedFileState = (file: DsFile) => {
    var itemState = false
    files?.files.forEach((item) => {
      if ((file.id === item.id) && item.selected) {
        itemState = true
      }
    })
    return itemState
  }

  const areAllVisibleSelected = () => {
    let retrunValue = true
    files?.files.forEach((item) => {
      if (!item.selected) {
        retrunValue = false
      }
    })
    return retrunValue
  }

  const onSelectAll = (e: CheckboxChangeEvent) => {

    files?.files?.forEach((item) => {
      if (e.target.checked) {
        item.selected = true
      }
      else {
        item.selected = false
      }
    })
    setFiles(files)
    setForceTableUpdate(forceTableUpdate + 1)
  }

  const loadCategory = async () => {
    console.log(libraryId,categoryMainId)
    if (libraryId && categoryMainId) {
      let accessToken = await getAuth0Token(getAccessTokenSilently)
      setIsLoading(true)
      let categoriesResult: Category[] = []
      if (assetId) {
        categoriesResult = await getAllCategories(accessToken, 'Asset')
      }
      else if (contractId) {
        categoriesResult = await getAllCategories(accessToken, 'Contract')
      }
      setIsLoading(false)
      setCategories(categoriesResult)
      categoriesResult.forEach((category) => {
        if (category.id === categoryMainId) {
          setCategory(category);
          const prefs = localStorage.getItem(SF_DOCUMENTS_PREFERENCES_VIEWTYPES);
          if (prefs) {
            const parsed = JSON.parse(prefs);
            if (parsed && parsed[category.id]) {
              setView(parsed[category.id], category.id);
            }
          } else {
            if (category.titleEnglish === 'Photos') {
              setView('Tile', category.id);
            } else {
              setView('List', category.id);
            }
          }
        }
      })
    }
  }

  const loadFilesForCategory = async () => {
    if (libraryId && categoryMainId && category) {
      let accessToken = await getAuth0Token(getAccessTokenSilently)
      setIsLoading(true)

      // load all files for Category
      let fileResult = await getfilesForCategory(libraryId, category.id, accessToken, null, null, sortedInfo, true)
      if (fileResult) {
        setFilesForMainCatgory(fileResult)
      }

      let fetchCategory: Category = subCategory ? subCategory : category

      let libraryResult = await getfilesForCategory(libraryId, fetchCategory.id, accessToken, null, null, sortedInfo, true)
      setIsLoading(false)
      if (libraryResult) {
        setFiles(libraryResult)
      }
    }
  }

  const goBack = () => {
    if (assetId) {
      navigate('/salesforce/documents/asset/' + assetId + '/' + libraryId);
    }
    else if (contractId) {
      navigate('/salesforce/documents/contract/' + contractId + '/' + libraryId);
    }
  }

  const getCategoryUrl = () => {
    let openCategory = subCategory ? subCategory.id : categoryMainId;

    return document.location.origin + '/org_digitalspine' + '/library/' + libraryId + '/c/' + openCategory;
  }

  const onMultiSelectChange = (checked: boolean) => {
    // deselect all files
    files?.files?.forEach((item) => {
      item.selected = false
    })
    setFiles(files)
    setForceTableUpdate(forceTableUpdate + 1)
    setMultiSelectEnabled(checked)
  };

  const getSelectedFiles = () => {
    return files?.files?.filter(elem => elem.selected ? true : false)
  }

  const getSelectedFilesCount = () => {
    var count = 0
    let sf = getSelectedFiles()
    if (sf) {
      count = sf.length
    }
    return count
  }

  const deleteSelectedFiles = async () => {

    var sf = getSelectedFiles()?.map((items) => { return items.id })

    if (!libraryId || !categoryMainId || !sf) {
      return
    }

    await deleteFiles(libraryId, categoryMainId, sf, await getAuth0Token(getAccessTokenSilently))
    loadFilesForCategory()
  }

  const downloadSelectedFiles = async () => {

    var fileIds = getSelectedFiles()?.map((items) => { return items.id })

    if (!libraryId || !categoryMainId || !fileIds) { return }

    setActualChannelName(CryptoJS.MD5(user?.sub + '_' + libraryId + '_' + new Date().getMilliseconds()).toString())
  }

  useEffect(() => {
    if (actualChannelName) {
      let URL = DOCUMENTS_DOWNLOAD_SOCKET_URL
      socket.current = new WebSocket(URL);
      socket.current.addEventListener('open', () => {
        socket.current?.send(JSON.stringify({ roomId: actualChannelName }));
      });
      socket.current.addEventListener('message', (event) => {
        if (actualChannelName) {
          downloadUrlByLinkMethod(event.data)
        }
        setActualChannelName(undefined)
      });

      const iniatMD = async () => {
        var fileIds = getSelectedFiles()?.map((items) => { return items.id })
        if (!libraryId || !categoryMainId || !fileIds) { return }
        await iniatMultiDownload(fileIds, libraryId, actualChannelName, categoryMainId, await getAuth0Token(getAccessTokenSilently))
      }

      iniatMD()
    }
    else {
      // prevent downloading canceled downloads
      socket.current?.close();
    }
  }, [actualChannelName]);

  useEffect(() => {
    return () => {
      socket.current?.close();
    };
  }, []);

  const moveSelectedFiles = async () => {
    var sf = getSelectedFiles()
    if (!sf) { return }
    setItemsToChangeCategory(sf)
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Spin spinning={false}>
          <span onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (window.confirm(getSelectedFilesCount() + ' Dateien wirklich löschen?')) {
              deleteSelectedFiles()
            }

          }} >Dateien löschen</span></Spin>
      ),
    },
    {
      key: '2',
      label: (
        <span onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          downloadSelectedFiles()
        }} >Dateien&nbsp;herunterladen</span>
      ),
    },
    {
      key: '3',
      label: (
        <span onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          moveSelectedFiles()
        }} >Dateien verschieben</span>
      ),
    }
  ];

  function uploadCallback(subCategoryId: string, componentIsUploading: boolean) {
    if (componentIsUploading) {
      refUploadWasStarted.current = true;
      setUploadWasStarted(true)
    }
  }

  function refreshCallback(activeSubcategory: Category) {
    setUploadWasStarted(false)
    setShowUploadForm(false)
    setSubCategory(activeSubcategory)
    loadFilesForCategory()
  }

  return (
    <>
      {showUploadForm && subCategory && libraryId && <div className="pr-4 pt-10"><UploadFileDropForSubCategory
        uploadCallback={uploadCallback}
        subCategory={subCategory}
        libraryId={libraryId}
        refreshCallback={refreshCallback} /></div>}

      {!showUploadForm && <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? " w-full" : 'hidden'}
        onDragEnterCapture={() => { if (subCategory) { setUploadWasStarted(false), setShowUploadForm(true) } }}
      >
        <Spin tip="Loading..." spinning={isLoading}>
          <div className="text-left sticky top-0 bg-white flex space-x-2 items-center z-20 h-16 px-2">
            <div className="inline-flex items-center text-sm grow ">
              <span className="cursor-pointer" onClick={goBack}>{I18n.get("overview")}</span>
              {splitter}
              <span className="mr-2" >{category ? category.titleGerman : ''}</span>
              <span className="mr-1 flex items-center" title="Link zur Kategorie kopieren" onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                setTextToCopy(getCategoryUrl());
              }} ><LinkOutlined className='cursor-pointer'/></span>
            </div>
            {viewOfFiles === 'List' ? toGalleryIcon : toListIcon}
            <div className='truncate'>
              {category && libraryId && <FileUploadCategories goBack={goBack} refreshCallback={loadFilesForCategory} category={category} libraryId={libraryId} />}
            </div>
          </div>
          <div className="px-2 ">
            <ShowCatagoryListCategorySelect selectedMainCategory={category} allFilesForCategory={filesForMainCatgory} selectedSubCategory={subCategory} setSelectedSubCategory={setSubCategory} subCategories={categories} />
          </div>
          {viewOfFiles !== 'Tile' && <div className="flex items-center justify-end mr-2 mb-4">
            <Switch onChange={onMultiSelectChange} checked={multiSelectEnabled} className='mr-4' />
            {multiSelectEnabled && getSelectedFilesCount() > 0 && <Dropdown className="cursor-pointer" menu={{ items }} placement="bottom">
              <Button label={'Aktionen (' + getSelectedFiles()?.length + ')'} size='extra small' theme='light' />
            </Dropdown>}
            {multiSelectEnabled && getSelectedFilesCount() <= 0 && <Button label='Aktionen' size='extra small' theme='light' disabled={true} />}
            {!multiSelectEnabled && <Button label='Aktionen' size='extra small' theme='light' disabled={true} />}
          </div>}
          {viewOfFiles !== 'Tile' && <TableShared tableContent={tableProps} />}
          {viewOfFiles === 'Tile' && category && files && library && <ViewTileList allFileResponse={files} library={library} category={category} fileResponse={files} paginationPage={null} paginationSize={null} />}
        </Spin>
        {textToCopy && <ModalDialogCopyText copyText={textToCopy} setTextToCopy={setTextToCopy} />}
        {itemToRename && <ModalDialogRename file={itemToRename} setShowModal={setItemToRename} updateTrigger={loadCategory} />}
        {itemsToChangeCategory.length > 0 && <ModalDialogChangeCategory files={itemsToChangeCategory} setShowModal={setItemsToChangeCategory} updateTrigger={loadCategory} />}
        {actualChannelName && <ModalDialogDownload setActualChannelName={setActualChannelName} />}
      </div>}
    </>
  );
};

export default SalesforceDocumentsAssetShowLibrary;
