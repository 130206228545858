import React, { useEffect, useRef, useState } from "react";
import { DsFile } from "../../interfaces/File";
import { I18n } from "@aws-amplify/core";
import { useGlobalState } from "../../../utils/globalStateProvider";
import { useIsVisible } from "../../../components/hooks/useIsVisible";
import { getFileByType } from "../../utils/helpersFetch";
import { useAuth0 } from "@auth0/auth0-react";
import { noThumbnailImg } from "../../utils/helpers";
import { getAuth0Token } from "../../../utils/helpers";


const ViewTile = (props:{
  file:DsFile,
  onCLickFileItem:Function
 }) => {
    const { state, setState } = useGlobalState()
    const { getAccessTokenSilently } = useAuth0(); 
    const ref = useRef<any>();
    const isVisible = useIsVisible(ref);
    const [loadedFile, setLoadedFile] = useState<DsFile|null>(null)
    const [imageUrl,setImageUrl] = useState<string>(noThumbnailImg)
  
    useEffect(() => {
      if(state.language){
        I18n.setLanguage(state.language)
        document.documentElement.lang = state.language
      }
    }, [state.language]);

    useEffect(() => {
      if(isVisible && loadedFile === null){
        if(props.file.thumbnailUrl){
          setLoadedFile(props.file)
          setImageUrl(props.file.thumbnailUrl)
        }
        else{
          loadFile()
        }
      }
    }, [isVisible]);
  
    useEffect(() => {
      setLoadedFile(null)
      setImageUrl(noThumbnailImg)
      if(isVisible){
        loadFile()
      }
    }, [props.file]);    

    const loadFile = async () => {
      if(props.file.hasThumbnail ){
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        let file:DsFile = await  getFileByType(props.file.id,'thumbnail',accessToken)
        setLoadedFile(file)
        if(file.thumbnailUrl){
          setImageUrl(file.thumbnailUrl)
        }  
      }
    }

    return (
      <div ref={ref} className=" l:h-[calc(12rem+70px)] m:h-[calc(9rem+70px)] h-[calc(6rem+70px)] cursor-pointer group" onClick={()=>{props.onCLickFileItem(props.file)}}>
          <div className={"bg-cover bg-center l:h-48 m:h-36 h-24 bg-no-repeat "} style={{backgroundImage:'url("' + imageUrl + '")'}}></div>
          <div className=" bg-blueBaby group-hover:bg-blueDark h-70px flex flex-col  pl-5 pr-5 justify-center">
            <h3 className="text-blueDark group-hover:text-white">{state.language === "en" ? props.file.category.titleEnglish : props.file.category.titleGerman}</h3>
            <h3 className="text-blueDark group-hover:text-white text-s truncate">{props.file.title}</h3>
          </div>
      </div>
      );

}
export default ViewTile