import React, { useEffect, useRef, useState } from 'react';
import { strings as stringLocal} from './WelcomeLocalization';
import { I18n } from '@aws-amplify/core';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { getAuth0IsAuthenticatedStatus, getAuth0Token, langChange, langInit, useClickOutsideListenerRef } from '../../utils/helpers';
import { iNewsOrUpdataElement } from "../interfaces/NewsOrUpdate"
import { getIcon } from '../../utils/icons';
import { iProduct } from '../interfaces/Products';
import NewsUpdateItem from '../components/NewsUpdateItem';
import { Button } from '../../components/atoms/Button';
import { app_url_learn_documents } from '../../utils/consts';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsVisible } from "../../components/hooks/useIsVisible";
import IconFolderOpener from '../../assets/images/icon_folderOpener_dark_blue.svg';
import { fetchNewsAndUpdates, fetchProducts } from '../utils/helpersFetch';
import { Spin } from 'antd';

export const baseUrl = app_url_learn_documents + '/'
export const urlToLatesNews = 'News%20and%20Updates/newsAndUpdates.json'
export const urlToLates5News = 'News%20and%20Updates/newsAndUpdatesLast5.json'
export const urlToAllNews = 'News%20and%20Updates/allNewsAndUpdates.json'
export const urlToNewsFolder = 'News%20and%20Updates/files/'
export const urlToProducts = 'Products/products.json'
export const urlToProductFiles = 'Products/files/'

const Dashboard = () => {
  
  const { state, setState } = useGlobalState()
  const [ latestNews, setLatestNews ] = useState<iNewsOrUpdataElement[]>([])
  const [ products, setProducts ] = useState<iProduct[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const navigate = useNavigate()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const headerRef = useRef<any>()
  const isVisible = useIsVisible(headerRef)
  const [isLoadingNewsAndUpdates, setIsLoadingNewsAndUpdates] = React.useState<boolean>(true)
  const [isLoadingProducts, setIsLoadingProducts] = React.useState<boolean>(true)

  useEffect(() => {
    if(state.language){ langChange({state:state,updateDocumentTitle:true}) } else { langInit( {stringLocal:stringLocal,setStateGlobal:setState} ) }
  }, [state.language]);

 useEffect(() => {
  loadNewsAndUpdates()
  loadProducts()
}, []);

async function loadNewsAndUpdates() {
  try {
    setIsLoadingNewsAndUpdates(true);
    const accessToken = await getAuth0Token(getAccessTokenSilently);
    
    let pagedResult = await fetchNewsAndUpdates(0, 2, accessToken)
    setLatestNews(pagedResult.result)

    setIsLoadingNewsAndUpdates(false);
  } catch (error) {
    console.error('Error fetching News and Updates:', error.message);
    // Handle the error here if needed
  } finally {
    setIsLoadingNewsAndUpdates(false);
  }
}

async function loadProducts() {
  try {
    setIsLoadingProducts(true);
    const accessToken = await getAuth0Token(getAccessTokenSilently);
    
    let pagedResult = await fetchProducts(accessToken)
    setProducts(pagedResult)

    setIsLoadingProducts(false);
  } catch (error) {
    console.error('Error fetching News and Updates:', error.message);
    // Handle the error here if needed
  } finally {
    setIsLoadingProducts(false);
  }
}

  const getTeaserImage = (pathOrSystemIcon:string) => {

    let classNames = ' w-11 m:w-[60px] m:h-[60px] xl:w-20 xl:h-20 xxl:w-22 xxl:h-22  '
    if(pathOrSystemIcon.startsWith('systemIcon')){
      let systemIcon = pathOrSystemIcon.replace('systemIcon','')
      classNames += ' group-hover:fill-blueBaby fill-blueDark '
      switch (systemIcon) {
        case 'Devices':
          return getIcon({type:systemIcon,ownClassName:classNames})
        case 'Contracts':
          return getIcon({type:systemIcon,ownClassName:classNames})
        case 'Documents':
          return getIcon({type:systemIcon,ownClassName:classNames})
        case 'Incidents':
          return getIcon({type:systemIcon,ownClassName:classNames})
        case 'Portal':
          return getIcon({type:systemIcon,ownClassName:classNames})
        case 'Learn':
          return getIcon({type:systemIcon,ownClassName:classNames})
        case 'Api':
          return getIcon({type:systemIcon,ownClassName:classNames})
        case 'Customer':
          return getIcon({type:systemIcon,ownClassName:classNames})
      }
    }
    else{
      return <img src={pathOrSystemIcon} className={classNames} />
    }
  }

  const openDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={ getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      <span ref={headerRef}><Header  /></span>
      <section  className="bg-blueLight welcome">
        <div className=" pt-7 xxl:pt-14 pb-11 xxl:pb-14">
          <div className="flex items-center">
            <div className=" w-[55px] h-[55px] rounded-lg bg-white flex items-center justify-center">
              {getIcon({ type: 'Learn', ownClassName: ' fill-blueLight w-9' })}
            </div>
            <div className="ml-4 m:ml-6 text-base m:text-xl text-white font-bold ">{I18n.get('HelpCenter')}</div>
          </div>
          <h1 className="text-white mb-10 mt-5">{I18n.get('welcomeMessage')}</h1>
        </div>
      </section>
      <section className="Content drop-shadow-md sticky top-20 s:top-24 m:top-24 xl:top-24 xxl:top-28 p-0 bg-white z-10 ">
        <div className="flex h-16 m:h-24 xl:h-[129px] items-center text-sm m:text-base xl:text-lg font-bold">
          <div className="flex items-center" ref={useClickOutsideListenerRef(() => { setIsDropdownOpen(false) })}>
            {!isVisible && (<div className=" w-9 h-9  m:w-14 m:h-14 rounded-lg bg-blueDark flex items-center justify-center">
              {getIcon({ type: 'Learn', ownClassName: 'fill-white w-6 m:w-9' })}
            </div>)}
          <div className={`${!isVisible && 'ml-10 m:ml-16'} cursor-pointer`} onClick={()=>{ navigate(`/products`); openDropdown(); }}>{I18n.get('Topics')}</div>
            <div className="ml-3 flex items-center">
              <img className="m:ml-1 w-4 m:w-6 flex items-center cursor-pointer" src={IconFolderOpener} alt="" onClick={openDropdown} />
              {isDropdownOpen && (
                <div className=" absolute top-[36px] s:top-[36px] m:top-[60px] xl:top-[80px] ml-[-70px] s:ml-[-70px] m:ml-[-78px] xl:ml-[-90px] w-[240px] m:w-[272px]  mt-4 bg-white border border-blueDark">
                    <ul className="">                
                    {products.map((elem, index) => {
                      if (!state.language) { return <></>; }
                      return (
                        <li
                          onClick={() => { navigate(`/products/${elem.pathId}/`) }}
                          key={index}
                          className={`pl-5 pr-5 pt-4 pb-5 cursor-pointer font-normal xl:text-lg m:text-base ${index % 2 === 1 ? 'bg-greyLighter' : ''} hover:bg-blueDark hover:text-white`}>
                          {elem.title[state.language]}
                        </li>
                      );
                    })}
                    </ul>
                </div>
              )}
            </div>
          </div>
          <div className=" ml-10 m:ml-16 cursor-pointer" onClick={() => { navigate(`/news-and-updates`) }}>
            {I18n.get('NewsAndUpdates')}
          </div>
        </div>
      </section>
      <section className="Content">
        <div className=" pt-16 m:pt-22 xl:pt-24">
          <div className=" text-xl m:text-3xl xl:text-[36px] font-bold pb-9">{I18n.get('TopicsByProduct')}</div>
          <Spin spinning={isLoadingProducts}>
            <div className=" grid grid-cols-1 s:grid-cols-2 xl:grid-cols-4 gap-5">
              {products.map((elem,index) => {
                if(!state.language){ return <></> } // not expected, just for the compiler
                return <div  data-testid="test_news_product_tiles_item" className="bg-blueBaby hover:bg-blueDark group h-44 m:h-64 xl:h-[370px] flex flex-col cursor-pointer" key={index} onClick={()=>{navigate(`/products/${elem.pathId}/`)}}>
                  <div className="flex-grow items-center flex justify-center pt-[40px]">{getTeaserImage(elem.image_teaser)}</div>
                  <div className="flex-shrink flex justify-between items-center pl-6 pr-6 h-20 xl:h-32 ">
                    <div className=" text-lg m:text-xl l:text-2xl xl:text-3xl xl:text-[32px] font-bold pr-4 group-hover:text-white">{elem.title[state.language]}</div>
                    <div>{getIcon({type:'ArrowRight',ownClassName:' w-6 m:w-7 xl:w-8 xl:w-8 fill-blueDark group-hover:fill-white'})}</div>
                  </div>
                </div>
              })}
            </div>
          </Spin>
        </div>
      </section>
      <section className="Content pb-20 xl:pb-36">
        <div className="flex w-full  pt-16 m:pt-24 xl:pt-48 flex-col xl:flex-row">
          <div className="xl:w-4/12 text-xl m:text-3xl xl:text-[36px] font-bold xl:leading-5 xl:mt-3">{I18n.get('NewsAndUpdates')}</div>
          <div className="xl:w-8/12 mt-10 xl:mt-0">
            <Spin spinning={isLoadingNewsAndUpdates}>
            {latestNews.map((element,index) => { return <NewsUpdateItem newsUpdateITem={element} key={index}  /> })}
            </Spin>
            <Button
                disabled={false}
                theme='dark'
                size='small'
                ownStyle=' mt-3 xl:mt-6'
                onClick={()=>{navigate(`/news-and-updates`)}}
                label={I18n.get('ShowAll')} />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Dashboard