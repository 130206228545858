import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { strings as stringLocal } from './TableLibrariesLocalization'
import { I18n } from '@aws-amplify/core'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Pagination, Spin } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { GlobalStateInterface, useGlobalState } from "../../../utils/globalStateProvider"
import { useEffect } from 'react'
import { getAuth0Token, getLangByLocalStorage, scrollTop } from '../../../utils/helpers'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../../utils/globalStateProviderApp'
import TableMain, { TableProps } from '../../../components/molecules/TableMain'
import {getLibraries} from './../../utils/helpers'
import { Library } from '../../interfaces/Library'
import { mergeDeepRight } from "ramda";
import SearchHistory from '../../../components/SearchHistory'

I18n.putVocabularies(stringGlobal);
I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
const lang = getLangByLocalStorage()
I18n.setLanguage(lang)

moment.locale(lang ? lang : 'de');

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export interface PrevRef {
  searchTerm: string | undefined
  sortBy: string | undefined
}

const TableInstallations = (props: { }) => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();

  const query = useQuery();

  const [pageUrlParam, setPageUrlParam] = React.useState<number | undefined>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [searchValueUrlParam, setSearchValueUrlParam] = React.useState<string>()
  const [sortByUrlParam, setSortByUrlParam] = React.useState<string | undefined>()
  const [totalNumberLibraries, setTotalNumberLibraries] = React.useState<number>(0)
  const [sortedInfo, setSortedInfo] = React.useState({ columnKey: '', order: '' })
  const prevRef = useRef<PrevRef>({ searchTerm: undefined,sortBy:undefined });
  const topTableElementRef = useRef<null | HTMLTableElement>(null);
  const [tableProps, setTableProps] = useState<TableProps | null >(null)

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [ state.language]);

  const onRowClicked = (library: Library) => {
      scrollTop()
      navigate('/org_' + state.organizationName + '/library/' + library.id)
  }

  const sortChange = (sorter: { columnKey: string, order: string }) => {

    if (sorter.order === '') {
      setSortedInfo({ columnKey: '', order: '' })
    }
    else {
      setSortedInfo(sorter)
    }
    let sortBy = ''
    if (sorter.columnKey && sorter.order) {
      sortBy = sorter.columnKey + ':' + (sorter.order === 'asc' ? 'asc' : 'desc')
    }
    setPageUrlParam(1)
    setSortByUrlParam(sortBy)
  };

  useEffect(() => {
    if (!searchValueUrlParam && !pageUrlParam && !sortByUrlParam) {

      if (stateApp.search) {
        // case hits if components gets loaded > like coming from installation detail page
        // 2 cases can happen / Back button pressd or logo in header 
        if (query.get('back') === 'true') {
          const pageParam = query.get("page") ? query.get("page") : '1'
          if (pageParam !== null) {
            setPageUrlParam(parseInt(pageParam))
          }
          else {
            setPageUrlParam(1)
          }

          const searchParam = query.get("search")
          if (searchParam !== undefined && searchParam !== null) {
            setSearchValueUrlParam(searchParam)
          }
          else {
            setSearchValueUrlParam('')
          }

          const sortByParam = query.get("sortBy")
          if (sortByParam !== undefined && sortByParam !== null) {
            const arrSplit = sortByParam.split(':')
            if (arrSplit.length === 2) {
              setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
              setSortByUrlParam(sortByParam)
            }
          }
          else {
            setSortByUrlParam('')
          }
          return
        }

        // logo clicked case
        setPageUrlParam(1)
        setSearchValueUrlParam('')
        setSortByUrlParam('')

      }
      else {
        // case hits if url gets opened directly by browser
        // init params from saved state
        const pageParam = query.get("page") ? query.get("page") : '1'
        if (pageParam !== null) {
          setPageUrlParam(parseInt(pageParam))
        }
        else {
          setPageUrlParam(1)
        }

        const searchParam = query.get("search")
        if (searchParam !== undefined && searchParam !== null) {
          setSearchValueUrlParam(searchParam)
        }
        else {
          setSearchValueUrlParam('')
        }

        const sortByParam = query.get("sortBy")
        if (sortByParam !== undefined && sortByParam !== null) {
          const arrSplit = sortByParam.split(':')
          if (arrSplit.length === 2) {
            setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
            setSortByUrlParam(sortByParam)
          }
        }
        else {
          setSortByUrlParam('')
        }
      }
      return
    }

    const urlParams = '?'
      + 'page=' + (pageUrlParam ? pageUrlParam : 1)
      + '&search=' + (searchValueUrlParam ? encodeURIComponent(searchValueUrlParam) : '')
      + '&sortBy=' + (sortByUrlParam ? sortByUrlParam : '')

    const setBookableUrlTo = async () => {
      let goTo = '/org_' + state.organizationName + '/welcome' + urlParams
      if (window.location.href !== goTo) {
        navigate(goTo)
      }
    }
    if (state.organizationName) {
      setBookableUrlTo()
    }

    // storing url for having it for the backButton at the installation detail list
    const dataGlobal: Partial<GlobalStateInterfaceApp> = {
      search: {
        page: (pageUrlParam ? pageUrlParam : 1),
        searchterm: (searchValueUrlParam ? searchValueUrlParam : ''),
        sortBy: (sortByUrlParam ? sortByUrlParam : ''),
        parametersUpdatedByUrl: true
      }
    }
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))

  }, [pageUrlParam, searchValueUrlParam, sortByUrlParam, state.organizationName]);

  useEffect(() => {
    BuildItemList()
  }, [stateApp.devices, state.language]);

  useEffect(() => {
    // on reload getInstallations gets called with uninitialised parameters, so looking to parametersUpdatedByUrl
    if (stateApp.search?.parametersUpdatedByUrl) {
      loadLibraries()
    }
    prevRef.current.searchTerm = stateApp.search?.searchterm
    prevRef.current.sortBy = stateApp.search?.sortBy
  }, [stateApp.search?.page, stateApp.search?.searchterm, stateApp.search?.sortBy]);

  async function loadLibraries() {
    setIsLoading(true)
    const accessToken =  await getAuth0Token(getAccessTokenSilently)
    await getLibraries(setStateApp, stateApp.search?.page, 10, stateApp.search?.searchterm, stateApp.search?.sortBy, accessToken)
    setIsLoading(false)
  }

  const getSortOrder = (columnKey: string): string => {
    if (sortedInfo.columnKey === columnKey) {
      if (sortedInfo.order === 'asc') {
        return 'desc'
      }
      else if (sortedInfo.order === 'desc') {
        return ''
      }
      else {
        return 'asc'
      }
    }
    else {
      return 'asc'
    }
  }

  const getLabel = (library:Library) => {
      return library.identifier?library.identifier:I18n.get('Unknown')
  }

  const getType = (library:Library) => {

    let type = I18n.get('Unknown')

    if(library.title === "sf_contract_lib"){
      type = I18n.get('Contract')
    }
    else if(library.title === "sf_asset_lib"){
      type = I18n.get('Installation')
    }
    return type
  }


  const BuildItemList = () => {

    let tableP:TableProps = {th:[],tr:[],noDataLabel:'Dieser Ordner ist leer',height:'unset',thead:'sticky-documents-detail'}

    tableP.th = [
      {
        text:<>{I18n.get('FabricationContractNumberFirst') + ' / ' + I18n.get('FabricationContractNumberSecond')}</>,
        class:' hidden m:flex w-6/12 pb-4',
        ellipsis:true,
      },          
      {
        text:<>{I18n.get('Type')}</>,
        class:' hidden m:flex w-5/12 pb-4 ',
      },
      {text:<></>,class:'hidden m:flex w-1/12 '},

      /* mobile  */

      {
        text:<span className="flex flex-col m:flex-row">
          <span className="flex items-center" >{I18n.get('FabricationContractNumberFirst')}<span className="m:hidden ml-3">/</span></span>
          <span className="hidden m:inline-block mr-3 ml-3">/</span>
          <span className="flex items-center" >{I18n.get('FabricationContractNumberSecond')}</span>
        </span>
        ,
        class:' flex m:hidden w-6/12 items-center pb-0 text-left',
        ellipsis:true,
      },          
      {
        text:<>{I18n.get('Type')}</>,
        class:' flex m:hidden w-5/12 items-center ',
      },
      {text:<></>,class:'flex m:hidden w-1/12 '}

    ]

    if(stateApp.libraries){
      tableP.tr = stateApp.libraries.map((itemLibrary, index) => {
        let dataRow:TableProps["tr"][0] =  {cells:[
          {text:<>{getLabel(itemLibrary)}</>,class:' flex text-left items-center w-6/12 hover:cursor-pointer'},
          {text:<>{getType(itemLibrary)}</>,class:'flex text-left items-center w-5/12  hover:cursor-pointer'},
          {text:<><svg className="m:mr-14 w-2 m:w-[11px]" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></>,class:'flex flex-col w-1/12 justify-center hover:cursor-pointer'}
        ],trOnClick:(e)=>{onRowClicked(itemLibrary)}}
        
        return dataRow
      })
    }
    setTableProps(tableP)

  }

  useEffect(() => {
    BuildItemList()
    setTotalNumberLibraries(stateApp.librariesCount ? stateApp.librariesCount : 0)
  }, [stateApp.librariesCount, stateApp.libraries]);

  const getSortVisualisation = (columnKey: string) => {

    if (columnKey === sortedInfo.columnKey) {
      if (sortedInfo.order === 'asc') {
        return <CaretUpOutlined className="ml-4" style={{ 'color': 'white' }} />
      }
      else {
        return <CaretDownOutlined className="ml-4" style={{ 'color': 'white' }} />
      }
    }
    return <CaretUpOutlined className="ml-4" style={{ 'color': 'grey' }} />
  }

  const handleSearchChange = (searchTerm: string) => {
    if (searchValueUrlParam !== searchTerm) {
      setPageUrlParam(1)
    }
    setSearchValueUrlParam(searchTerm)
  }

  return (
      <div className="idTableInstallations flex flex-col mb-6 m:mb-12 l:mb-18">
        <div className=" mt-12  m:mt-24 flex justify-between flex-col m:flex-row">
          <h1>{I18n.get('overview')}</h1>
        </div>
        <div className=" w-full  m:w-96 pt-10 pb-10">
          <SearchHistory setSearchValue={handleSearchChange} />
        </div>
        <Spin spinning={isLoading}>
          <TableMain tableContent={tableProps} />
          <div className="h-24 bg-blueBaby flex justify-center items-center">
            <Pagination current={stateApp.search?.page ? stateApp.search?.page : 1} pageSize={10} showSizeChanger={false} total={totalNumberLibraries} onChange={(page, pageSize) => {
                setPageUrlParam(page);
                window.scrollTo({
                  top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
                  behavior: 'smooth',
                });
            }} />
          </div>
        </Spin>
      </div>
  );
};

export default TableInstallations;
