export const strings = {
    en: {
        Btn_Search:'Search',
        LastSearched:'Last searched',
        Btn_Apply:'Apply',
    },
    de: {
        Btn_Search:'Suchen',
        LastSearched:'Zuletzt gesucht',
        Btn_Apply:'Übernehmen',
    },
};