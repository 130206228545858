import { I18n } from "@aws-amplify/core";
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { strings as stringLocal } from './helpersLocalization';
import { message } from 'antd';
import { Category, SubCategories } from '../interfaces/Category';
import { DsFile, DsFileResponse, FilesDownloadResponse } from '../interfaces/File';
import { Library, LibraryResponse } from '../interfaces/Library';
import { API_URL, DOCUMENTS_API_URL, USER_CHOICE_LANGUAGE } from './../../utils/consts';
import { mergeDeepRight } from 'ramda';
import { ApiError } from "./api-error";
import { customFetchWithAuthHeader } from "./../../utils/helpersFetch";

// duplicate of /src/utils/helpers.tsx
// cypress tests went fail if function gets included > TODO
const allowedLangValue = ['de','en']
const getLangByLocalStorage = () => {
  let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
  if( !(lang && allowedLangValue.includes(lang)) ){ lang = 'de' }
  return lang
}

I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export const postLibraryForEntity = async (entityID: string, title: string, type: 'Contract' | 'Asset', accessToken: string): Promise<Library | null> => {

  let params = {
    entitySfId: entityID,
    title: title,
    entityType: type
  }

  const url = `${DOCUMENTS_API_URL}/v1/libraries`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'POST',
    body: JSON.stringify(params)
  }, accessToken);
  if (response.status !== 201) {
    message.error('Fehler beim anlegen der Library');
    return null
  }
  else {
    const data = await response.json();
    return data;
  }
};

export const getMainCategories = async (accessToken: string): Promise<Category[]> => {
  const url = `${DOCUMENTS_API_URL}/v1/categories?main=true`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);
  const data = await response.json();
  return data;
};

export const getLibraryBySalesforceEntity = async (sfId: string, type: 'Contract' | 'Asset' | 'Device', accessToken: string): Promise<any> => {
  const url = `${API_URL}/api/v1/entities/map?sfId=${sfId}&entityType=${type}`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);
  const data = await response.json();
  return data;
};

export const getAllCategories = async (accessToken: string, type: 'Contract' | 'Asset' | 'Device'): Promise<Category[]> => {
  const url = `${DOCUMENTS_API_URL}/v1/categories?main=false&entityType=${type}`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);
  const data = await response.json();
  return data;
};

export const getCategoriesForLibrary = async (libraryId: string, accessToken: string): Promise<Category[]> => {
  const url = `${DOCUMENTS_API_URL}/v1/libraries/${libraryId}/categories`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);

  const data = await response.json();

  if (response.status === 200) {
    return data;
  }
  else {
    message.error(data.message);
    return []
  }
};

export const getCategoryForLibrary = async (libraryId: string, categoryId: string, accessToken: string): Promise<any> => {
  const url = `${DOCUMENTS_API_URL}/v1/libraries/${libraryId}/categories/` + categoryId;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);
  const data = await response.json();
  return data;
};

export const getfilesForCategory = async (
  libraryId: string,
  categoryId: string,
  accessToken: string,
  skip: number | null = null,
  take: number | null = null,
  sorter?: { columnKey: string, order: string },
  allFiles?: boolean,
  controllerRef?: React.MutableRefObject<AbortController | null | undefined>
): Promise<DsFileResponse> => {

  let allFilesQuery = allFiles ? 'allFiles=1&' : ''

  let url = `${DOCUMENTS_API_URL}/v1/libraries/${libraryId}/categories/` + categoryId + '/files?' + allFilesQuery;

  if (skip !== null && take !== null) {
    url = url + 'skip=' + skip.toString() + '&take=' + take.toString() + '&';
  }

  if (sorter && sorter?.columnKey !== '' && sorter?.order !== '') {
    url = url += 'sortBy=' + sorter.columnKey + ':' + (sorter.order === 'asc' ? 'asc' : 'desc')
  }

  if (controllerRef) {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const response = await customFetchWithAuthHeader(url, {
      method: 'GET',
      signal: controllerRef.current?.signal
    }, accessToken);

    const data = await response.json();
    controllerRef.current = null;
    return data;
  }
  else {
    const response = await customFetchWithAuthHeader(url, {
      method: 'GET'
    }, accessToken);

    const data = await response.json();
    return data;
  }
};

export const iniatMultiDownload = async (fileIds: string[], libraryId: string, channelName: string, categoryId: string, accessToken: string): Promise<any | null> => {

  const url = DOCUMENTS_API_URL + '/v1/libraries/' + libraryId + '/categories/' + categoryId + '/files?fileIds=' + fileIds.join(',') + '&uniqeTopic=' + channelName;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);

  var data = response.json()

  if (response.status >= 200 && response.status <= 300) {
    return data;
  }
  else {
    message.error(I18n.get('loadErrorFile'));
    return null
  }
};

export const getFile = async (fileId: string, accessToken: string): Promise<DsFile | null> => {
  const url = `${DOCUMENTS_API_URL}/v1/files/${fileId}`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);

  if (!response.ok){

    if (response.status === 403){
      throw new ApiError( I18n.get('noSufficientPermissionWhileFetchingFile'), response.status);
    }
  
    if (response.status === 404){
      throw new ApiError(I18n.get('fileNotFound'), response.status);
    }

    throw new ApiError(I18n.get('genericAPIFailedError'), response.status);
  }

  var data = response.json();
  
  return data;
};

export const getFiles = async (libraryId: string, categoryId: string, fileIds: string[], accessToken: string): Promise<FilesDownloadResponse | null> => {
  const url = `${DOCUMENTS_API_URL}/v1/libraries/${libraryId}/categories/${categoryId}/files?fileIds=` + fileIds.join(',');
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);

  var data = response.json()

  if (response.status >= 200 && response.status <= 300) {
    return data;
  }
  else {
    message.error(I18n.get('loadErrorFile'));
    return null
  }
};

export const patchFileTitle = async (fileId: string, title: string, accessToken: string): Promise<any> => {
  const url = `${DOCUMENTS_API_URL}/v1/files/${fileId}`;

  const response = await customFetchWithAuthHeader(url, {
    method: 'PATCH',
    body: JSON.stringify({ title: title })
  }, accessToken);

  return response
};

export const patchFileCategory = async (fileId: string, cagtegory: Category, accessToken: string): Promise<any> => {

  const url = `${DOCUMENTS_API_URL}/v1/files/${fileId}`
  const response = await customFetchWithAuthHeader(url, {
    method: 'PATCH',
    body: JSON.stringify({ category: cagtegory.id })
  }, accessToken);

  return response
};

export const patchFilesCategory = async (libraryId: string, categoryId: string, cagtegory: Category, fileIds: string[], accessToken: string): Promise<any> => {

  const url = `${DOCUMENTS_API_URL}/v1/libraries/${libraryId}/categories/${categoryId}/files`
  const response = await customFetchWithAuthHeader(url, {
    method: 'PATCH',
    body: JSON.stringify({
      newCategoryId: cagtegory.id,
      fileIds: fileIds.join(',')
    })
  }, accessToken);

  return response
};

export const getFileByType = async (fileId: string, type: 'preview' | 'thumbnail', accessToken: string, throwError=true): Promise<any> => {
  const url = `${DOCUMENTS_API_URL}/v1/files/${fileId}?imageType=${type}`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);

  if (!response.ok && throwError){

    if (response.status === 403){
      throw new ApiError( I18n.get('noSufficientPermissionWhileFetchingLibrary'), response.status);
    }
  
    if (response.status === 404){
      throw new ApiError(I18n.get('libraryNotFound'), response.status);
    }

    throw new ApiError(I18n.get('genericAPIFailedError'), response.status);
  }

  const data = await response.json()
  return data;
};

export const deleteFile = async (fileId: string, accessToken: string): Promise<boolean> => {
  const url = `${DOCUMENTS_API_URL}/v1/files/${fileId}`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'DELETE',
  }, accessToken);
  if (response.status === 204) {
    return true;
  }
  else {
    message.error('Es ist ein Fehler beim löschen aufgetreten.');
    return false
  }
};

export const deleteFiles = async (libraryId: string, category: string, fileIds: string[], accessToken: string): Promise<boolean> => {
  const url = `${DOCUMENTS_API_URL}/v1/libraries/${libraryId}/categories/${category}/files`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'DELETE',
    body: JSON.stringify({ fileIds: fileIds.join(',') })
  }, accessToken);
  if (response.status === 204) {
    return true;
  }
  else {
    message.error('Es ist ein Fehler beim löschen aufgetreten.');
    return false
  }
};


export const getCategoriesForCagtegory = async (categoryId: string, accessToken: string): Promise<SubCategories> => {
  const url = `${DOCUMENTS_API_URL}/v1/categories/` + categoryId;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  }, accessToken);
  const data = await response.json();
  return data;
};

export const getLibraryForEntity = async (entityID: string, type: 'Contract' | 'Asset' | 'Device', accessToken: string): Promise<LibraryResponse|null> => {
  try{
    const url = `${DOCUMENTS_API_URL}/v1/libraries?entityId=${entityID}&entityType=${type}`;
    const response = await customFetchWithAuthHeader(url, {
      method: 'GET',
    }, accessToken);
    const data = await response.json();
    return data;
  }
  catch(error){
    console.log(error)
    return null
  }

};

export const getLibraryById = async (libraryId: string, accessToken: string): Promise<Library | null> => {
  const url = `${DOCUMENTS_API_URL}/v1/libraries/${libraryId}?includeAssets=true`;

  try{
    const response = await customFetchWithAuthHeader(url, {
      method: 'GET',
    }, accessToken);

    if (!response.ok){

      if (response.status === 403){
        throw new ApiError( I18n.get('noSufficientPermissionWhileFetchingLibrary'), response.status);
      }
    
      if (response.status === 404){
        throw new ApiError(I18n.get('libraryNotFound'), response.status);
      }
  
      throw new ApiError(I18n.get('genericAPIFailedError'), response.status);
    }
  
    const data = await response.json();
    return data;
  }
  catch(error){
    throw new ApiError(I18n.get('libraryNotFound'), 404);
  }
};

export const fetchUserLibraries = async (page: number, pageSize: number, searchText: string, sortBy: string, accessToken: string): Promise<LibraryResponse> => {

  try{
    let allInstallations = undefined
    let sortByParam = (!sortBy || sortBy === '') ? '' : '&sortBy=' + encodeURIComponent(sortBy)
    const response = await customFetchWithAuthHeader(DOCUMENTS_API_URL + '/v1/libraries?take=' + pageSize.toString() + '&skip=' + ((page - 1) * pageSize).toString() + '&search=' + encodeURIComponent(searchText) + sortByParam, {
      method: 'GET',
    }, accessToken);
    allInstallations = await response.json()
  
    return allInstallations;
  }
  catch(error){
    //console.log(error)
    return {count:0,libraries:[]}
  }
};



