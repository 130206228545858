import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spin } from 'antd';
import { strings as stringGlobal } from './../../../utils/globalLocalization'
const colorsTheme = require('../../../../src/utils/aufzugheldenTheme')

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import { LineChart } from 'echarts/charts';
// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import { useEffect, useState } from 'react';
import { RunsOverTimeResponse } from '../../../interfaces/RunsOverTime';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { getLangByLocalStorage } from '../../../utils/helpers';
import React from 'react';
import { useGlobalState } from '../../../utils/globalStateProvider';

const TurnsChart = (props: { response: RunsOverTimeResponse|null; isLoading: boolean; visualizePerHours:'day'|'hour' }) => {
  const [noData, setNoData] = useState(false);
  const [data, setData] = useState<(string | number | null)[][]>([]);
  const [timeFormat, setTimeFormat] = useState<string>('ddd DD.MM');
  const [visualize1day, setVisualize1day] = useState<boolean>(false);
  const { state, setState } = useGlobalState()

  useEffect(() => {
    if(visualize1day){
      setTimeFormat('HH:mm') // hour scale
    }
    else{
      setTimeFormat('ddd DD.MM') // daily scale
    }
  }, [visualize1day]);

  useEffect(() => {
    const dataTmp:[string,number|null][] = [];

    if(!props.response){
      setNoData(true);
      return
    }

    if(Array.isArray(props.response)){
      for (const item of props.response) {
        dataTmp.push([item.timestamp.replace('Z',''), item.runcount]);
      }  
    }

    setData(dataTmp);

    if (dataTmp.length > 0) {

      if(moment(dataTmp[dataTmp.length-1][0]).diff(moment(dataTmp[0][0]), 'day') <= 1){
        setVisualize1day(true)
      }
      else{
        setVisualize1day(false)
      }

      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [props.response,state.language]);

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
    LineChart,
    ToolboxComponent,
    DataZoomComponent,
  ]);

  echarts.registerTheme('light', {
    backgroundColor: '#fff',
  });

  const option = {
    grid: {
      left: '1%',
      right: '1%',
      containLabel: true,
      show:false
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor:colorsTheme.chartTooltipBackground,
      textStyle:{
        color:colorsTheme.chartTooltipTextColor
      },
      borderWidth:0,
      formatter: function (params: any[]) {

        if(!params[0].data[1]){
          return null
        }

        const time = (props.visualizePerHours == 'hour') ? I18n.get('Time') + ': ' + moment(params[0].data[0]).format('HH:mm' ) : '' 

        return I18n.get('runs') + ': '+params[0].data[1] + '<br/>' + 
        I18n.get('Date') + ': ' + moment(params[0].data[0]).format('DD.MM.YYYY' ) + '<br/>' +
        time;
      },
    },
    toolbox: {
      feature: {
        /*
        dataZoom: {
          yAxisIndex: 'none'
        },
        saveAsImage: {}
        */
      },
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      axisLine:{
        show:false,
      },
      axisLabel: {
        hideOverlap: true,
        formatter: function (value:string) {
          return moment(value).format(timeFormat )
        }
      },
      splitLine:{
        show:false
      }
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      splitLine:{
        show:true,
        lineStyle:{
          color:colorsTheme.chartBackgroundLineColor,
          opacity: colorsTheme.chartBackgroundLineOpacity
        }
      }
    },
    series: [
      {
        type: 'line',
        symbolSize: 5,
        data: data,
        color: '#05ADF5',
        lineStyle: {
          color: colorsTheme.chartLineColor,
          width: 1,
          type: 'line'
        },
        itemStyle: {
          borderWidth: 3,
          color: colorsTheme.chartLineDotsColor
        }
      },
    ],
  };

  return (
    <>
      {noData && (
        <div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight">{I18n.get('noDataForTimePeriod')}</div>
        </div>
      )}
      {!noData && (
        <Spin size="large" spinning={props.isLoading} key="spin">
          <ReactEChartsCore
            key="chartTurnchart"
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            theme={'light'}
            showLoading={false}
            style={{ height: '350px', width: '100%' }}
          />
        </Spin>
      )}
    </>
  );
};
export default TurnsChart;
