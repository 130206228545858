import React, { createContext, useState, useContext, Dispatch, SetStateAction } from "react";
import { DeviceInfoDictionary } from "../../interfaces/DeviceInfo";
import { Installation, InstallationCoords } from "../../interfaces/Installation";
import { FeedMessageDictionary } from "../../interfaces/Lifefeed";
import { Case } from "src/incidents/interfaces/Case";
import { Insight } from "src/interfaces/Insights";
import { DeviceFilter } from "./../../interfaces/DeviceFilters";

export interface GlobalStateInterfaceApp {
  search:{
    page:number
    searchterm:string
    sortBy:string
    filterBy:DeviceFilter
    parametersUpdatedByUrl:boolean
  }
  devices:DeviceInfoDictionary
  installationsBySearch : InstallationCoords[]
  installations : Installation[]
  installationCount:number  
  liveData:FeedMessageDictionary
  maxInstallationCount:number
  compareSelected:Installation[]
  incidents:{ [key: string] : Case[] }
  insights:Insight[]
  insightsCount:number
}

const globalStateContext = createContext({
  stateApp: {} as Partial<GlobalStateInterfaceApp>,
  setStateApp: {} as Dispatch<SetStateAction<Partial<GlobalStateInterfaceApp>>>,
});

const GlobalStateProviderApp = ({
  children,
  value = {} as GlobalStateInterfaceApp,
}: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterfaceApp>;
}) => {
  const [stateApp, setStateApp] = useState(value);
  return (
    <globalStateContext.Provider value={{ stateApp, setStateApp }}>
      {children}
    </globalStateContext.Provider>
  );
};

const useGlobalStateApp = () => {
  const context = useContext(globalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

export { GlobalStateProviderApp, useGlobalStateApp };