import { I18n } from '@aws-amplify/core';
import React, { useEffect } from 'react'
import { useGlobalState } from '../../utils/globalStateProvider';
import { UserHasPermissionsFor, appLinkFor, getAuth0Token, langChange, langInit } from '../../utils/helpers';
import { strings as stringLocal} from './../utils/globalLocalization';
import { useAuth0 } from '@auth0/auth0-react';
import { getIcon } from '../../utils/icons';
import { Apps } from '../../interfaces/Apps'

const AppTiles = () => {

  const { state } = useGlobalState()
  const [applications, setApplications] = React.useState<Apps[]>([])
  const { getAccessTokenSilently } = useAuth0()
  
  useEffect(() => {
    const GetPermissions = async () => {
      let accessToken = await getAuth0Token(getAccessTokenSilently)

      const applicationsTmp:Apps[] = []
      UserHasPermissionsFor('Devices',accessToken)?applicationsTmp.push('Devices'):null
      UserHasPermissionsFor('Incidents',accessToken)?applicationsTmp.push('Incidents'):null
      UserHasPermissionsFor('Documents',accessToken)?applicationsTmp.push('Documents'):null
      UserHasPermissionsFor('Contracts',accessToken)?applicationsTmp.push('Contracts'):null
      applicationsTmp.push('Learn')
      setApplications(applicationsTmp)
    }
    GetPermissions()
  }, []);

  useEffect(() => {
    if (state.language) { langChange({ state: state }) } else { langInit({ stringLocal }) }
  }, [state.language]);

  return (
    <>
        <div className="flex justify-between cp_app_tiles" data-testid="test_app_tiles_container">
            <h1 className="mb-5 mt-10">{I18n.get('Digital Spine Cloud')}</h1>
        </div>
        <div className=" grid grid-cols-1 s:grid-cols-2 xl:grid-cols-3 gap-5 ">
            {applications.map((elem,index) => {
            return <div data-testid="test_app_tiles_item" className="cp_app_tiles_item bg-blueBaby hover:bg-blueDark group h-44 m:h-64 xl:h-[370px] flex flex-col cursor-pointer" key={index} onClick={() => { document.location.href = appLinkFor(elem, state) }}>
                <div className="flex-grow items-center flex justify-center pt-[40px]">{getIcon({type:elem,ownClassName:' w-14 h-20 m:w-20 fill-blueDark group-hover:fill-white'})}</div>
                <div className="flex-shrink flex justify-between items-center pl-6 pr-6 h-20 xl:h-32 ">
                <div className=" text-lg m:text-xl l:text-2xl xl:text-3xl xl:text-[32px] font-bold pr-4 group-hover:text-white">{I18n.get(elem)}</div>
                <div>{getIcon({type:'ArrowRight',ownClassName:' w-6 m:w-7 xl:w-8 xl:w-8 fill-blueDark group-hover:fill-white'})}</div>
                </div>
            </div>
            })}
        </div>
    </>
  )
}

export default AppTiles;