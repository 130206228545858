import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { DOCUMENTS_API_URL } from '../../../utils/consts';
import { Category } from '../../interfaces/Category';
import { delay, fileChecksum, getAuth0Token } from '../../../utils/helpers';
import { useGlobalState } from '../../../utils/globalStateProvider';
import { I18n } from '@aws-amplify/core';
import { strings as stringLocal} from './../../../documents/utils/globalLocalization';
import { strings as stringGlobal } from '../../../utils/globalLocalization';
import { mergeDeepRight } from 'ramda';
import { Button } from '../../../components/atoms/Button';
import { itsWindowsWithAHeicImage } from '../../utils/helpers';

interface UploadFileListItem {file:File,uploadIsRunning:boolean,id:number,uploadFinished:boolean}

function UploadFileDropForSubCategory(props: { libraryId: string; subCategory: Category; refreshCallback:Function; uploadCallback:Function }) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const [fileList, setFileList] = useState<UploadFileListItem[]>([]);
  const [fileUploaded, setFileUploaded] = useState<UploadFileListItem|null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { user,getAccessTokenSilently } = useAuth0()
  const { state, setState } = useGlobalState()
  const [showFolderErrorMessage, setShowFolderErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    setFileList([])
  }, []);  

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  const handleDrop = function(e) {
    setShowFolderErrorMessage(false)
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if(e.dataTransfer.files[0].type === '' && !itsWindowsWithAHeicImage(e.dataTransfer.files[0])){
        setShowFolderErrorMessage(true)
      }else{
        handleFileChange(e.dataTransfer.files)
      }
    }
  };
  
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFileChange(e.target.files)
      e.target.value = null
    }
  };

  const handleFileChange = (files: FileList) => {
    props.uploadCallback(props.subCategory.id ,true)
    // keep last uploaded files
      let tmpFileList = JSON.parse(JSON.stringify(fileList))
      let arrFolders = Array.from(files).filter((item) => {
        if(item.type === '' && !itsWindowsWithAHeicImage(item)){return true}
      })

      if(arrFolders.length>0){
        setShowFolderErrorMessage(true)  
      }

      let arrFiles = Array.from(files).filter((item) => {
        if(item.type !== ''){return true}
      }).map((item,index)=> {
          return {file:item,uploadIsRunning:false,id:item.size+index,uploadFinished:false}
      })
      setFileList(tmpFileList.concat(arrFiles))
  };

  const uploadFiles = (filelist:UploadFileListItem[]) => {
    filelist.forEach( (item) => {

      if(!item.uploadIsRunning && !item.uploadFinished){
        
        // set status is uploading
        let tmpFilelist = JSON.parse(JSON.stringify(filelist)).map((elem:UploadFileListItem) => {
          if(item.id === elem.id ){
            return {file:elem.file,uploadIsRunning:true,id:elem.id}
          }
          else{
            return elem
          }
        })
        setFileList(tmpFilelist)

        var reader = new FileReader();
        reader.onload = async function(event) {
          let accessToken =  await getAuth0Token(getAccessTokenSilently)
          let md5Value = await fileChecksum(item.file)

          // receive signedurl
          let params = {
            'author': user?.name? user?.name : 'unknown',
            'libraryId': props.libraryId,
            'categoryId': props.subCategory.id,
            'title': item.file.name,
            'MD5Hash': md5Value
            }
              
          fetch(`${DOCUMENTS_API_URL}/v1/files`, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
          })
          .then((res) => {
            return res.json()})
          .then((data) => {
            // start upload
            fetch(data.signedUrl, {
              method: 'PUT',
              body: event.target?.result,
              headers: {
                'Content-Type': item.file.type,
                'Content-MD5': md5Value
              },
            })
              .then((data) => {
                setFileUploaded(item)
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err))
          .finally(() => {
          });
        };
        
        if(item.file instanceof Blob){
          reader.readAsArrayBuffer(item.file);
        }
      }
    })
  }

  useEffect(() => {
    if(!fileUploaded){
      return
    }

    let tmpFilelist = JSON.parse(JSON.stringify(fileList)).map((elem:UploadFileListItem) => {
      if(fileUploaded.id == elem.id ){
        elem.uploadFinished = true
        return elem
      }
      else{
        return elem
      }
    })

    setFileList(tmpFilelist)

    let uploadDone = true
    tmpFilelist.forEach((item:UploadFileListItem)=>{
      if(!item.uploadFinished){
        uploadDone = false
      }
    })

    if(uploadDone){
      setIsUploading(false)
      const  hideAndRefresh = async () =>{
        await delay(1000)
        props.refreshCallback(props.subCategory)
      }
      hideAndRefresh()
    }
  }, [fileUploaded]);
  

  useEffect(() => {
    let uploadDone = true
    fileList.forEach((item:UploadFileListItem)=>{
      if(!item.uploadFinished){
        uploadDone = false
      }
    })

    if (uploadDone) {
      setIsUploading(false)
      return;
    }

    // as long as items exists uploadstatus is true
    setIsUploading(true)
    uploadFiles(fileList)

  }, [fileList]);
  
  useEffect(() => {
    props.uploadCallback(props.subCategory.id ,isUploading)
  }, [isUploading]);  

  const getUploadDetails = () => {
    let elementsFinished = fileList.filter((elem)=> elem.uploadFinished?true:false)
    return <>{elementsFinished.length} von {fileList.length}<br/>hochgeladen</>
  }

  return (
    <Spin spinning={isUploading} className=" cursor-not-allowed" >
    {showFolderErrorMessage ? <div className=" text-center text-warnRed pb-12">{I18n.get('FolderErrorMessage')}</div>:<></>}

    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} className=" w-full h-36 mr-2 ml-2 mb-4 max-w-full text-center relative">
      <input ref={inputRef} type="file" id={"input-file-upload" + props.subCategory.id} multiple={true} onChange={handleChange} className="hidden" />
      <label id="label-file-upload" htmlFor={"input-file-upload" + props.subCategory.id} className={(dragActive ? " bg-blueBaby " : "") + " h-full flex items-center justify-center border-2 rounded-2xl border-dashed border-blueBaby" }>
        <div>
          <p className="w-full whitespace-normal [hyphens:auto]" lang="de">{I18n.get('DropHereToUpload')}<br/><strong>{state.language === 'en'? props.subCategory.titleEnglish : props.subCategory.titleGerman}</strong></p>
          <div>{fileList.length > 0 && getUploadDetails()}</div>
        </div> 
      </label>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} className=" absolute w-full h-full rounded-2xl top-0 right-0 bottom-0 left-0" ></div> }
    </form>
    <div className=" flex justify-center">
      {isUploading && <Button label={I18n.get('Cancel')} size='extra small' theme='light' onClick={()=>props.refreshCallback()} />}
      {!isUploading && <Button label={I18n.get('Close')} size='extra small' theme='light' onClick={()=>props.refreshCallback()} />}
    </div>
    </Spin>

  );
};

export default UploadFileDropForSubCategory;

