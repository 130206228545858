export const strings = {
  en: {
    welcomeMessage: 'Welcome back',
    welcomeMessageFollow : 'Here you will find an overview of ###number### contracts.',
    nameApp: 'Contracts',
    ContractActive: 'Active',
    ContractExpired: 'Expired',
    ContractSignNeeded: 'Signature required',
    All: 'All',
    filterBy: 'Filter by:',
  },
  de: {
    welcomeMessage: 'Willkommen zurück',
    welcomeMessageFollow : 'Hier finden Sie eine Übersicht zu ###number### Verträgen.',
    nameApp: 'Verträge',
    ContractActive: 'Aktiv',
    ContractExpired: 'Ausgelaufen',
    ContractSignNeeded: 'Unterschrift nötig',
    All: 'Alle',
    filterBy: 'Filtern nach:',
  },
};