import { strings as stringGlobal } from '../utils/globalLocalization';
import { strings as stringCookie } from './CookieBannerLocalization';
import { I18n } from '@aws-amplify/core';
import { PUBLIC_POSTHOG_HOST, PUBLIC_POSTHOG_KEY, ga4_id, gtm_id } from '../utils/consts';
import { mergeDeepRight } from 'ramda';
import ReactGA from "react-ga4";
import { useEffect, useState } from 'react'
import { WindowWithDataLayer } from '../interfaces/WindowWithDataLayer';
import TagManager from 'react-gtm-module'
import React from 'react';
import { getLangByLocalStorage } from '../utils/helpers';
import posthog from 'posthog-js';

declare const window: WindowWithDataLayer;

export default function CookieBanner() {
  const [showBanner, setShowBanner] = useState<boolean>(false)

  useEffect(() => {
 
    I18n.putVocabularies(mergeDeepRight(stringCookie,stringGlobal));
    I18n.setLanguage(getLangByLocalStorage())
    
    var cookieString = document.cookie;
    
    if(cookieString.includes("cookieconsent=True")){
      // nothing
    }
    else if(cookieString.includes("cookieconsent=False")){
      // nothing
    }
    else {
      setShowBanner(true)
    }
  }, []);  

  const acceptCookies = () => {
    document.cookie = "cookieconsent=True;max-age=31536000;path=/";
    if(gtm_id){
      let tagmanagerParams = {
        gtmId:gtm_id,
        events:{event:'cookieconsent'},
        }
      TagManager.initialize(tagmanagerParams)
    }

    if(ga4_id){
      ReactGA.initialize(ga4_id);
    }

    posthog.init( 
      PUBLIC_POSTHOG_KEY, 
      { 
        api_host: PUBLIC_POSTHOG_HOST,
        person_profiles:'identified_only'
      }
    )

    setShowBanner(false)    
  }

  const denyCookies = () => {
    document.cookie = "cookieconsent=False;max-age=31536000;path=/";
    setShowBanner(false)
  }  

  return (<>
    {showBanner && <div className=" p-4 fixed z-50 bottom-0 bg-white border-t w-full flex flex-row justify-between flex-nowrap items-center">
      <div className="text">
          {I18n.get('firstLine')}<br/>{I18n.get('secondLine')} <a href="https://www.aufzughelden.com/de/datenschutz">{I18n.get('moreAbout')}</a>.
      </div>
      <div className=" flex-shrink-0">
        <button data-testid="test_denie_ccokies" onClick={()=>{denyCookies() }} className="p-4 bg-blueDark text-white border-0 hover:cursor-pointer mr-4 cp_do_not_accept_cookies">
          {I18n.get('denieCookies')}
        </button>
        <button data-testid="test_accept_ccokies" onClick={()=>{acceptCookies() }} className="p-4 bg-blueDark text-white border-0 hover:cursor-pointer cp_accept_cookies">
          {I18n.get('acceptCookies')}
        </button>
      </div>
    </div>}
    </>
  )
}
