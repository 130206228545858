import React, { useEffect, useState } from "react";
import { Category } from "../../interfaces/Category";
import { I18n } from "@aws-amplify/core";
import { CaretUpOutlined } from '@ant-design/icons';
import { useClickOutsideListenerRef } from '../../../utils/helpers';
import { useGlobalState } from "../../../utils/globalStateProvider";
import { DsFileResponse } from "../../interfaces/File";
import { isSalesforceView } from "../../utils/helpers";

const ShowCatagoryListCategorySelect = (props:{subCategories:Category[],setSelectedSubCategory:Function,selectedSubCategory:Category|undefined,selectedMainCategory:Category|undefined,allFilesForCategory:DsFileResponse | null}) => {
  const [showCategorySelect,setShowCategorySelect] = useState(false)
  var [subCategories,setSubCategories] = useState<Category[]>([])
  const { state, setState } = useGlobalState()

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      document.documentElement.lang = state.language
    }
  }, [state.language]);
  
  useEffect(() => {
    var tmpSubCategories = props.subCategories
    let subCategoriesWithFiles:string[] = []
    props.allFilesForCategory?.files.forEach((fileItem) => {
      if(subCategoriesWithFiles.indexOf(fileItem.category.id) <= -1){
        subCategoriesWithFiles.push(fileItem.category.id)
      }
    })
    tmpSubCategories = tmpSubCategories.filter((categortiItem) => {
      //return true // show all subcategories
        if( categortiItem.parent === props.selectedMainCategory?.id ) { return true }
      })
    if(state.language !== 'de'){
      tmpSubCategories.sort((a,b) => (a.titleEnglish > b.titleEnglish) ? 1 : ((b.titleEnglish > a.titleEnglish) ? -1 : 0))
    }
    else{
      tmpSubCategories.sort((a,b) => (a.titleGerman > b.titleGerman) ? 1 : ((b.titleGerman > a.titleGerman) ? -1 : 0))
    }
    setSubCategories(tmpSubCategories)
  }, [props.subCategories,state.language,props.allFilesForCategory]); 

  const getCategorySelectOpener = () => {
    return <div className="p-1 pl-2  cursor-pointer flex justify-between items-center bg-white" onClick={()=>{setShowCategorySelect(true)}}>
      <div>{props.selectedSubCategory? (state.language==='en'?props.selectedSubCategory.titleEnglish:props.selectedSubCategory.titleGerman):(I18n.get('AllSubCatagories')  )}</div>
      <CaretUpOutlined className="ml-4 mr-1" style={{ 'color': 'grey' }} rotate={180}  onClick={()=>{setShowCategorySelect(true)}} />
    </div>
  }

  return (
    <div 
      ref={ useClickOutsideListenerRef(()=>{setShowCategorySelect(false) }) }
      className="border absolute text-sm m:text-lbase l:text-l xxl:text-lg z-[15]"
      style={{minWidth:isSalesforceView()?'auto':'272px'}}
      >
        {getCategorySelectOpener()}
        <div className={showCategorySelect?" flex flex-col cursor-pointer":"hidden"}>

          <div key={'-1'} onClick={()=>{
                setShowCategorySelect(false),
                props.setSelectedSubCategory(undefined)
              }} className={" bg-greyLighter p-4 hover:bg-blueDark hover:text-white cursor-pointer"}>{I18n.get('AllSubCatagories')} ({props.allFilesForCategory?.count})</div>

          {subCategories.map((category,index) => {
            let rowClassName= index % 2 === 0 ? ' bg-white ' : ' bg-greyLighter '
            return <div key={index} onClick={()=>{
              setShowCategorySelect(false),
              props.setSelectedSubCategory(category)
            }} className={rowClassName + " p-4 hover:bg-blueDark hover:text-white cursor-pointer"}>{state.language === 'en'? category.titleEnglish : category.titleGerman} ({props.allFilesForCategory?.files.filter((file) => { if(category.id === file.category.id ) { return true } }).length})</div>
          })}
        </div>
      </div>
  );

}
export default ShowCatagoryListCategorySelect