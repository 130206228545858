import { I18n } from "@aws-amplify/core";
import { message, Spin } from "antd";
import { fetchInstallation } from "../../utils/helpersFetch";
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { DOCUMENTS_LAST_VIEWED, USER_CHOICE_LANGUAGE } from '../../utils/consts'
import React from "react";
import { TableProps } from "../../components/molecules/TableMain";
import { DsFile, DsFileRecentlyViewed, DsFileResponse, DsFileStored } from "../interfaces/File";
import moment from "moment";
import { fetchUserLibraries, getFile } from "./helpersFetch";
import { User } from "@auth0/auth0-react";
import { GlobalStateInterfaceApp } from ".//globalStateProviderApp";
import { Library } from "../interfaces/Library";
import { Contract } from "../interfaces/Contract";
import { NavigateFunction } from "react-router-dom";
import { getAuth0Token } from "./../../utils/helpers";

export const downloadIcon = <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="17.5" cy="18" r="17.5" fill="#36ADF5"/><rect x="11.9316" y="25.5566" width="11.9318" height="2.38636" fill="#F2F4F7"/><path d="M23.8633 18.3978L22.1809 16.7154L19.0906 19.7938L19.0906 7.65918L16.7042 7.65918L16.7042 19.7938L13.6258 16.7154L11.9315 18.3978L17.8974 24.3637L23.8633 18.3978Z" fill="#F2F4F7"/></svg>
export const downloadIconWhite = <svg className=" text-blueDark w-7 h-7 m:w-9 m:h-9 " width="35" height="36" viewBox="0 0 35 36"  xmlns="http://www.w3.org/2000/svg"><circle cx="17.5" cy="18" r="17.5" fill="white"/><rect x="11.9316" y="25.5566" width="11.9318" height="2.38636" /><path d="M23.8633 18.3978L22.1809 16.7154L19.0906 19.7938L19.0906 7.65918L16.7042 7.65918L16.7042 19.7938L13.6258 16.7154L11.9315 18.3978L17.8974 24.3637L23.8633 18.3978Z" /></svg>

// duplicate of /src/utils/helpers.tsx
// cypress tests went fail if function gets included > TODO
const allowedLangValue = ['de','en']
const getLangByLocalStorage = () => {
  let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
  if( !(lang && allowedLangValue.includes(lang)) ){ lang = 'de' }
  return lang
}

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export const noThumbnailImg = '/assets/images/no-thumbnail.png'

export const loadInstallation = async (id:string,setIsLoading:Function,setCurrentInstallation:Function,accessToken:string,ifNotFoundNavigateTo?:string|undefined,navigate?:NavigateFunction|undefined) => {
  setIsLoading(true)
  
  let installationResponse = await fetchInstallation(id,accessToken)
  if(installationResponse.status === 200){
    const installation = await installationResponse.json()
    setCurrentInstallation(installation);
  }
  else{
    const resonseMessage = await installationResponse.json()
    if(ifNotFoundNavigateTo && navigate){
      navigate(ifNotFoundNavigateTo)
    }
    else{
      message.error(I18n.get('loadErrorInstallation'));
    }
  }
  setIsLoading(false)
}

export const getDsFileDownloadLink = async (file:DsFile,getAccessTokenSilently:Function) => {
  let accessToken =  await getAuth0Token(getAccessTokenSilently)
  let fileResult = await getFile(file.id,accessToken)
  if(!fileResult || !fileResult.originalUrl){
    return
  }
  else{
    return fileResult.originalUrl
  }
}

export const downloadDsFile = async (file:DsFile,getAccessTokenSilently:Function,windowReference?:any|null) => {
  let accessToken =  await getAuth0Token(getAccessTokenSilently)
  let fileResult = await getFile(file.id,accessToken)
  if(!fileResult || !fileResult.originalUrl){
    return
  }

  let urlByRedirect = '/redirect?to=' + encodeURIComponent(fileResult.originalUrl)

  if(windowReference){
    windowReference.location = urlByRedirect
    windowReference?.focus()  
  }  
  else if(fileResult.originalUrl){
    downloadUrlByLinkMethod(urlByRedirect,fileResult.title)
  }
}

export const downloadFilebyUrl = async (url:string,title?:string,windowReference?:any|null) => {

  let urlByRedirect = '/redirect?to=' + encodeURIComponent(url)
  if(windowReference){
    windowReference.location = urlByRedirect
    windowReference?.focus()  
  }  
  else if(url){
    downloadUrlByLinkMethod(url,title)
  }
}

export const downloadUrlByLinkMethod = async (url:string,title?:string) => {
    const link = document.createElement('a');
    link.href = url
    if(title){ link.download = title }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const isSalesforceView = () => {
  if(document.location.pathname.indexOf('/salesforce/') > -1){
    return true
  }
  else{
    return false
  }
}

export const CookieConsent = ():boolean => {
  var cookieString = document.cookie;
  if(cookieString.includes("cookieconsent=True")){
    return true
  }
  else{
    return false
  }
}

export const updateViewdDocuments = (file:DsFileRecentlyViewed,user:User | undefined,organizationIdentifier:string, infoLibrary:Library, infoContract?:Contract) => {
  if(CookieConsent() && user?.sub ){
    let storedLastDocumentsViewed:string|null = localStorage.getItem(DOCUMENTS_LAST_VIEWED)
    let lastDocumentsViewed:DsFileStored = {}

    if(storedLastDocumentsViewed !== null){
      lastDocumentsViewed = JSON.parse(storedLastDocumentsViewed)
    }

    let organozationUserIdentifier = user.sub + '-' + organizationIdentifier

    if(lastDocumentsViewed[organozationUserIdentifier] ){

      if(lastDocumentsViewed[organozationUserIdentifier].length > 1){
        // if more than 30 files... remove files viewed more than 30 days before
        lastDocumentsViewed[organozationUserIdentifier] = lastDocumentsViewed[organozationUserIdentifier].filter(item=>{ 
          return moment().diff(item.lastViewTime, 'days') > 30 ? false : true
        })
      }

      let fileAllreadIncluded:number | boolean = false
      lastDocumentsViewed[organozationUserIdentifier].forEach((item,index)=>{
        if(file.id === item.id){
          fileAllreadIncluded = index
        }
      })

      if(fileAllreadIncluded !== false){
        lastDocumentsViewed[organozationUserIdentifier].splice(fileAllreadIncluded, 1);
      }

      file.lastViewTime = moment().toISOString()
      file.infoLibrary = infoLibrary
      file.infoContract = infoContract

      if(lastDocumentsViewed[organozationUserIdentifier].length <= 0){
        lastDocumentsViewed[organozationUserIdentifier] = [file]
      }
      else{
        lastDocumentsViewed[organozationUserIdentifier].unshift(file)
      }
    }
    else{
      lastDocumentsViewed[organozationUserIdentifier] = [file]
    }

    localStorage.setItem(DOCUMENTS_LAST_VIEWED, JSON.stringify(lastDocumentsViewed));
  }
}

export const getLibraries = async (setState: React.Dispatch<React.SetStateAction<any | null>>, page = 1, pageSize = 10, searchText = '', sortBy = '', accessToken: string) => {
  
  const librariesResponse = await fetchUserLibraries(page, pageSize, searchText, sortBy, accessToken);
  let dataGlobal: Partial<GlobalStateInterfaceApp> = {
    libraries: librariesResponse.libraries,
    librariesCount: librariesResponse.count,
  }
  setState((prev: any) => ({ ...prev, ...dataGlobal }))

};

export const setMaxLibraryCount = async (setState: React.Dispatch<React.SetStateAction<any | null>>, accessToken: string)  => {
  const librariesResponse = await fetchUserLibraries(1, 1, '', '', accessToken);
  let dataGlobal: Partial<GlobalStateInterfaceApp> = {
    maxLibrariesCount: librariesResponse.count
  }
  setState((prev: any) => ({ ...prev, ...dataGlobal }))
};

export const setPathForSelectedFile = (selectedFile:DsFile|null,libraryId:string|undefined,organizationName:string|undefined,categoryMainOrSubId:string|undefined,navigate:NavigateFunction) => {
  if(selectedFile){
    // the modal component is setting its deeplink
    // if the modal component set selectedFile to undefined the parent component takes control about the deeplink
    return
  }
  // create deeplink url
  let targetPath = '/org_' + organizationName + '/unknown'
  if(libraryId){
    if(categoryMainOrSubId){
      targetPath = '/org_' + organizationName + '/library/' + libraryId  + '/c/' + categoryMainOrSubId   
    }
    else{
      targetPath = '/org_' + organizationName + '/library/' + libraryId        
    }
    navigate(targetPath)
  }
}

export const itsWindowsWithAHeicImage = (file:File) => {
  let itsWindowsWithAHeicImage = false
  if(file.type === '' && file.name && file.name.toLowerCase().includes('.heic')){
    itsWindowsWithAHeicImage = true
  }
  return itsWindowsWithAHeicImage
}