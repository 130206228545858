export const strings = {
  en: {
    Previous: 'Previous',
    Next: 'Next',
    liftAnalize: 'Lift analyse',
    backLabel: 'Back',
    constructionYear: 'Construction year',
    elevatorType: 'Elevator type',
    liftType: 'Lift type',
    floors: 'Floors',
    doors: 'Doors',
    loadKG: 'Load (kg)',
    loadPersons: 'Load (Persons)',
    mainEntrance: 'Main entrance',
    speedMS: 'Speed (m/s)',
    passengerElevator: 'Passenger elevator',
    cargoElevator: 'Cargo elevator',
    hydraulicRamp: 'Hydraulic ramp',
    rope: 'Rope',
    directHydraulicSystems: 'Direct hydraulicsystems',
    indirectHydraulicSystems: 'indirect hydraulicsystems',
    doorState: 'State',
    doorOpend: 'Open',
    doorClosed: 'Shut',
    elevatorAnalysis: 'Elevator analysis',
    doorCylces: 'Door cycles',
    messageDoorCylces: 'The door cycles (doors open and closed) in the selected period are shown here.',
    messageRunsOverTime: 'Visualizes the number of trips of the respective elevator per day within the specified period.',
    messageBlockages: 'The graphic shows the duration and number of door blockages on the different floors.',
    messageRunsBetweenStops: 'Visualizes the frequency of trips between the departure and destination floors of the elevator.',
    totalOpenings: 'TOTAL OPENINGS',
    totalClosures: 'TOTAL CLOSURES',
    runsOverTime: 'Runs over time',
    runsTotal: 'Runs total',
    doorBlockages: 'Door blockages',
    totalBlockages: 'Total blockages',
    totalCycles : 'Total cycles',
    runsBetweenLevels: 'Runs between stops',
    liveActivities: 'Live activity',
    doorCyclesTotalDetailHead: 'Today total doorcycles',
    runsTotalDetailHead: 'Today total runs',
    messageLiveActivities: 'Shows the last 100 live activity of the given time period',
    messageInsights: 'Shows the last insights',  
    Insights:'Insights',
    EnergyConsumption:'Power consumption',
    EnergyConsumptionKw:'Power consumption',
    EnergyConsumptionKwPerH:'Power consumption',
    EnergyConsumptionTotal:'Absolute consumption',
    MessageEnergyConsumptionOverTime:'Shows the energy consumption in the selected period.'
  },
  de: {
    Previous: 'Vorhergehende',
    Next: 'Nächste',
    liftAnalize: 'Aufzuganalyse',
    backLabel: 'Zurück',
    constructionYear: 'Baujahr',
    elevatorType: 'Aufzugstyp',
    liftType: 'Aufzugart',
    floors: 'Etagen',
    doors: 'Türen',
    loadKG: 'Last (kg)',
    loadPersons: 'Last (Personen)',
    mainEntrance: 'Hauptzugang',
    speedMS: 'Geschw. (m/s)',
    passengerElevator: 'Personenaufzug',
    cargoElevator: 'Lastenaufzug',
    hydraulicRamp: 'Hydraulische Rampe',
    rope: 'Seil',
    directHydraulicSystems: 'Direct Hydraulic Systems',
    indirectHydraulicSystems: 'Indirektes Hydrauliksystem',
    doorState: 'Status',
    doorOpend: 'Auf',
    doorClosed: 'Zu',
    elevatorAnalysis: 'Aufzug Analyse',
    doorCylces: 'Türzyklen',
    messageDoorCylces: 'Hier werden die Türzyklen (Türen auf und zu) in dem ausgewählten Zeitraum dargestellt.',
    messageRunsOverTime: 'Visualisiert die Anzahl der Fahrten des jeweiligen Aufzuges pro Tag innerhalb des festgelegten Zeitraums.',
    messageBlockages: 'Die Grafik zeigt Dauer und Anzahl der Türblockierungen in den verschiedenen Stockwerken.',
    messageRunsBetweenStops: 'Visualisiert die Häufigkeit der Fahrten zwischen den Abfahrts- und Zieletagen des Aufzuges.',
    totalOpenings: 'ÖFFNUNGEN GESAMT',
    totalClosures: 'SCHLIESSUNGEN GESAMT',
    totalCycles : 'ZYKLEN TOTAL',
    runsOverTime: 'Fahrten über Zeitraum',
    runsTotal: 'FAHRTEN GESAMT',
    doorBlockages: 'TÜRBLOCKIERUNGEN',
    totalBlockages: 'Blockierungen Gesamt',
    runsBetweenLevels: 'Fahrten zwischen Haltestellen',
    liveActivities: 'Live aktivitäten',
    doorCyclesTotalDetailHead: 'Türzyklen Gesamt heute',
    runsTotalDetailHead: 'Fahrten gesamt heute',
    messageLiveActivities: 'Zeigt die letzten 100 Aktivitäten des ausgewählten Zeitraumes',
    messageInsights: 'Zeigt die letzten Vorfälle',
    Insights:'Vorfälle',
    EnergyConsumption:'Energieverbrauch',
    EnergyConsumptionKw:'Energieverbrauch',
    EnergyConsumptionKwPerH:'Energieverbrauch',
    EnergyConsumptionTotal:'Absoluter Verbrauch',
    MessageEnergyConsumptionOverTime:'Zeigt den Energieverbrauch im ausgewählten Zeitraum.'
  },
};

