export const strings = {
  en: {
    UploadFor: 'Upload to category',
    UploadFiles: 'Upload files',
    DontCloseBecauseUploadsAreRunning: 'Please wait for the files to be uploaded.',
    RunningUploads:'Running uploads'
  },
  de: {
    UploadFor: 'Upload in Kategorie',
    UploadFiles: 'Dateien uploaden',
    DontCloseBecauseUploadsAreRunning: 'Bitte warten bis die Dateien hochgeladen wurden.',
    RunningUploads:'Laufend Vorgänge'
  },
};