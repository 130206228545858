
import { strings as stringLocal} from './CompareLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import React, { useEffect, useState } from 'react';
const colorsTheme = require('../../../src/utils/aufzugheldenTheme')
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../utils/globalStateProviderApp';
import { useNavigate } from 'react-router-dom';
import arrowBack from '../../assets/images/icons/arrow_back.svg'
import { Installation } from '../../interfaces/Installation';
import { Select, Tag } from 'antd';
import './CompareAssets.css';
import { DateRange } from '../../interfaces/DateRange';
import moment from 'moment';
import SelectMoreInstallation from '../components/compare/SelectMoreInstallations';
import { useAuth0 } from '@auth0/auth0-react';
import { analyticsEvent, getAuth0IsAuthenticatedStatus, getLangByLocalStorage, setDocumentTitle } from '../../utils/helpers';
import useWindowDimensions from '../../utils/UseWindowDimensions';
import { getElevatorParameterLabelTranslationDevices } from '../../components/InformationDetails';
import TurnsChartWrapper from '../components/compare/TurnsChartChartWrapper';
import TurnsChartSumWrapper from '../components/compare/TurnsChartSumWrapper';
import DoorcyclesOverTimeWrapper from '../components/compare/DoorcyclesOverTimeWrapper';
import DoorBlockagesOverTimeWrapper from '../components/compare/DoorBlockagesWrapper';


const CompareAssets = () => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();
  
  const [compareFeature1, setCompareFeature1] = useState<boolean>(true);
  const [compareFeature2, setCompareFeature2] = useState<boolean>(false);
  const [compareFeature3, setCompareFeature3] = useState<boolean>(false);
  const [compareFeature4, setCompareFeature4] = useState<boolean>(false);
  const [ maxNumberOfCompare, setMaxNumberOfCompare ] = useState<number>(10);

  const [selectMoreMode, setSelectMoreMode] = useState<boolean>(false);
  const [countSelected, setCountSelected] = useState<string>('0');
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: moment().subtract(7, 'days').startOf('day').toISOString(),
    endDate: moment().toISOString(),
  });
  const { isAuthenticated } = useAuth0();
  const { height, width } = useWindowDimensions();
  
  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let lang = getLangByLocalStorage()
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }
  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
      setDocumentTitle()
    }
  }, [state.language]);

  useEffect(() => {
    if(width < 768){
      setMaxNumberOfCompare(4)
    }
    else{
      setMaxNumberOfCompare(10)
    }
  }, [width]);

  useEffect(() => {
    if(stateApp.compareSelected && stateApp.compareSelected.length > 0 && stateApp.compareSelected.length <= 9 ){
      setCountSelected('0'+stateApp.compareSelected.length)
    }
    else if(stateApp.compareSelected && stateApp.compareSelected.length > 9){
      setCountSelected(stateApp.compareSelected.length.toString())
    }
    else{
      setCountSelected('0')
    }

    analyticsEvent({action:'ComparePage',category:'Feature compared range',label:'ComparedDays'+(moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days')+1)})
    if(compareFeature1){
      analyticsEvent({action:'ComparePage',category:'Feature compared feature',label:'RunsPerElevator' })
    }
    if(compareFeature2){
      analyticsEvent({action:'ComparePage',category:'Feature compared feature',label:'TotalRuns' })
    }
    if(compareFeature3){
      analyticsEvent({action:'ComparePage',category:'Feature compared feature',label:'DoorCycles' })
    }
    if(compareFeature4){
      analyticsEvent({action:'ComparePage',category:'Feature compared feature',label:'DoorBlockages' })
    }

  }, [stateApp.compareSelected,dateRange, compareFeature1,compareFeature2,compareFeature3,compareFeature4]);
  
  const goBack = () => {
    let urlParams = '?'
    +'page='+(stateApp.search?.page ? stateApp.search?.page : 1)
    +'&search='+(stateApp.search?.searchterm ? encodeURIComponent(stateApp.search.searchterm) : '')
    +'&sortBy='+(stateApp.search?.sortBy ? stateApp.search.sortBy : '')
    +'&back=true'

    navigate('/org_'+state.organizationName+'/welcome'+urlParams)
  }

  const getLabel = (installation:Installation) => {
    if(installation.customLabel && installation.customLabel.trim().length > 0 ){
      return installation.customLabel
    }
    else{
      return installation.label
    }
  }

  const getSelectedTiles = () => {
    let collectionTiles:any[] = []
    stateApp.compareSelected?.forEach((installation,index)=>{

      let infoLabel = ''
      if(installation.elevatorConstruction){
        infoLabel = getElevatorParameterLabelTranslationDevices('elevatorType',installation.elevatorConstruction)
      }

      collectionTiles.push(
        <div key={'inst'+installation._id}>
            <div className="float-right mr-1 mt-1" onClick={()=>{removeInstallationFromSelected(installation)}}><svg width="35" height="46" viewBox="0 0 35 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.4237 15.4239L19.8476 23L27.4237 30.5761L24.8983 33.1015L17.3222 25.5254L9.74606 33.1015L7.22068 30.5761L14.7968 23L7.22068 15.4239L9.74606 12.8985L17.3222 20.4746L24.8983 12.8985L27.4237 15.4239Z" fill="white"/></svg></div>
            <div className="bg-blueDark p-4 pb-10 m:pb-0 s:h-64 m:h-80 mh-64 min-h-full ">
              <div className="text-blueLight mt-10 text-xl" style={{color:colorsTheme.chartColors[index]}}>{getLabel(installation)}</div>
              <div className="text-white mt-4 text-lg">{infoLabel}<br/>{installation.building?.city}, {installation.building?.street} {installation.building?.houseNumber}</div>
            </div>
        </div>
      )
    })
    if(collectionTiles.length < maxNumberOfCompare){
      collectionTiles.push(
        <div key={'instPlus'}>
            <div onClick={()=>setSelectMoreMode(true)} className=" bg-greyLighter p-4 pt-16 pb-16 m:pt-4 m:pb-4 s:h-64 m:h-80 flex justify-center items-center cursor-pointer">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30" cy="30" r="30" fill={colorsTheme.blueLight}/>
                  <path d="M43 31.2857H32.2857V42H28.7143V31.2857H18V27.7143H28.7143V17H32.2857V27.7143H43V31.2857Z" fill="white"/>
                </svg>
            </div>
        </div>
      )
    }
    return collectionTiles
  }

  const removeInstallationFromSelected = (installation:Installation) => {
    
    let localCompareSelected = JSON.parse(JSON.stringify(stateApp.compareSelected))

    var i;
    for (i = 0; i < localCompareSelected.length; i++) {
        if (localCompareSelected[i]._id === installation._id) {
          localCompareSelected.splice(i, 1); 
        }
    }
    let dataGlobal:Partial<GlobalStateInterfaceApp> = {
      compareSelected : localCompareSelected
    } 
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))
  }

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
    <Header />
    <section className="bg-blueLight Content">
      <div className=" pt-0 m:pt-9 cursor-pointer">
        <div className="text-white flex text-lg" onClick={()=>{goBack()}  }>
          <img className=" mr-6" src={arrowBack} alt="" /> {I18n.get('backLabel')}
        </div>
      </div>
      <div className=" pt-16 m:pt-20 pb-6 m:pb-20 flex flex-col m:flex-row">
        <h1 className=" text-white m:w-2/5">{I18n.get('conpareElevators')}</h1>
        <p className="text-white pt-5 m:pt-0 text-lg m:w-3/5">{I18n.get('compareIntroducingPart1')}<br/>{I18n.get('compareIntroducingPart2')}</p>
      </div>
      <div className="flex pb-5 m:pb-16">
        <div className=" bg-blueDark  pl-3 pt-6">
          <h2 className="mb-0 pr-3 text-white" >{countSelected} </h2>
          <div className=" h-1 bg-white"></div>
          <div className=" h-5"></div>
        </div>
        <div className=" pt-6">
          <h2 className="mb-0 pl-3" ><span className=" text-blueDark "> {I18n.get('of')} {maxNumberOfCompare}</span></h2>
          <div className=" h-1 bg-blueDark"></div>
        </div>
      </div>
    </section>
    {selectMoreMode && <SelectMoreInstallation onClose={()=>{setSelectMoreMode(false)}}  />}
      <section className={selectMoreMode ? "hidden" : "Content pt-7 m:pt-24 pb-16  m:pb-36" }>
        <div className=" grid  s:grid-cols-2 m:grid-cols-3  l:grid-cols-4 xl:grid-cols-5 gap-4">
          {getSelectedTiles()}
        </div>
      </section>
      <section className={selectMoreMode ? "hidden" : ""}>
        <div className="Content Compare bg-blueBaby text-blueDark text-lg ">
          <div className="flex pt-12 pb-12">
            <div className="pr-24">
              <h1 className="  uppercase">{I18n.get('compare')}</h1>
              <div className="">
                <Tag color={compareFeature1? colorsTheme.blueDark : '' } className="mt-8 cursor-pointer select-none" onClick={()=>{setCompareFeature1(!compareFeature1)}}>{I18n.get('compareFeature1')}</Tag>
                <Tag color={compareFeature2? colorsTheme.blueDark : '' } className="mt-8 cursor-pointer select-none" onClick={()=>{setCompareFeature2(!compareFeature2)}}>{I18n.get('compareFeature2')}</Tag>
                <Tag color={compareFeature3? colorsTheme.blueDark : '' } className="mt-8 cursor-pointer select-none" onClick={()=>{setCompareFeature3(!compareFeature3)}}>{I18n.get('compareFeature3')}</Tag>
                <Tag color={compareFeature4? colorsTheme.blueDark : '' } className="mt-8 cursor-pointer select-none" onClick={()=>{setCompareFeature4(!compareFeature4)}}>{I18n.get('compareFeature4')}</Tag>
              </div>
              <div className="pt-8">{I18n.get('pleaseChooseCriterias')}</div>
            </div>
          </div>
        </div>
      </section>
      <section className={selectMoreMode ? "hidden" : " sticky top-16 s:top-16 m:top-24 xl:top-24 xxl:top-28 pl-0 p-6 pr-0  bg-white"} style={{zIndex:'5'}}>
        <div className="Content ">
          <div className="  ">
            <Select 
              defaultValue="last7days"
              style={{ width: 200 }}
              dropdownStyle={{
                zIndex:5
              }}
              onChange={(value: string)=>{
                let startDate = '';
                let endDate = '';
                let startFomat = 'YYYY-MM-DDT00:00:00.000'
                let endFomat = 'YYYY-MM-DDT23:59:59.999'

                switch(value){
                  case 'today':
                    startDate = moment().startOf('day').format(startFomat);
                    endDate = moment().endOf('day').format(endFomat);
                    break;
                  case 'thisMonth':
                    startDate = moment().startOf('month').format(startFomat);
                    endDate = moment().endOf('month').format(endFomat);
                    break;
                  case 'lastMonth':
                    startDate = moment().subtract(1, 'month').startOf('month').format(startFomat);
                    endDate = moment().subtract(1, 'month').endOf('month').format(endFomat);
                    break;
                  case 'last7days':
                    startDate = moment().subtract(7, 'days').startOf('day').format(startFomat);
                    endDate = moment().subtract(1, 'day').endOf('day').format(endFomat);
                    break;
                  case 'last14days':
                    startDate = moment().subtract(14, 'days').startOf('day').format(startFomat);
                    endDate = moment().subtract(1, 'day').endOf('day').format(endFomat);
                    break;
                  case 'last30days':
                    startDate = moment().subtract(30, 'days').startOf('day').format(startFomat);
                    endDate = moment().subtract(1, 'day').endOf('day').format(endFomat);
                    break;
                  case 'last60days':
                    startDate = moment().subtract(60, 'days').startOf('day').format(startFomat);
                    endDate = moment().subtract(1, 'day').endOf('day').format(endFomat);
                    break;
                }

                if (startDate && endDate) {
                  setDateRange({ startDate, endDate });
                }          
              }}
              loading={false}
              options={[
                {
                  value: 'today',
                  label: I18n.get('today'),
                },
                {
                  value: 'thisMonth',
                  label: I18n.get('thisMonth'),
                },
                {
                  value: 'lastMonth',
                  label: I18n.get('lastMonth'),
                },
                {
                  value: 'last7days',
                  label: I18n.get('last7days'),
                },
                {
                  value: 'last14days',
                  label: I18n.get('last14days'),
                },
                {
                  value: 'last30days',
                  label: I18n.get('last30days'),
                },
                {
                  value: 'last60days',
                  label: I18n.get('last60days'),
                },
              ]}
            />
          </div>
        </div>
      </section>
      <section className={selectMoreMode ? "hidden" : "Content -mt-14 mb-14"}>
        {compareFeature1 &&
          <TurnsChartWrapper compareSelected={stateApp.compareSelected} dateRange={dateRange}/>
        }
        {compareFeature2 &&
          <TurnsChartSumWrapper compareSelected={stateApp.compareSelected} dateRange={dateRange} />
        }
        {compareFeature3 &&
          <DoorcyclesOverTimeWrapper compareSelected={stateApp.compareSelected} dateRange={dateRange} />
        }
        {compareFeature4 &&
          <DoorBlockagesOverTimeWrapper compareSelected={stateApp.compareSelected} dateRange={dateRange} />
        }
        {!compareFeature1 && !compareFeature2 && !compareFeature3 && !compareFeature4 && <div className="h-9"></div>}
      </section>
    <Footer />
    </div>
  );
};

export default CompareAssets;
