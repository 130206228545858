export enum ContractFilterEnum {
    All = "All",
    ContractActive = "ContractActive",
    ContractExpired = "ContractExpired",
    ContractSignNeeded = "ContractSignNeeded"
}

export type ContractFilter = ContractFilterEnum | ''

export const contractFilterData: ContractFilterEnum[] = [
    ContractFilterEnum.All,
    ContractFilterEnum.ContractActive,
    ContractFilterEnum.ContractExpired
];