import { strings as stringGlobal } from '../utils/globalLocalization'
import { useSearchParams } from 'react-router-dom';
import { I18n } from '@aws-amplify/core'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useEffect } from 'react';
import moment from 'moment';
import { GlobalStateInterface, useGlobalState } from '../utils/globalStateProvider';
import React from 'react';
import { getLangByLocalStorage } from '../utils/helpers';

export const LogoutPage = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { state, setState } = useGlobalState()

  useEffect(() => {
    I18n.putVocabularies(stringGlobal);
    if(!state.language){
      let lang = getLangByLocalStorage()
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }
  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  const login = () => {
    const organization = searchParams.get("organization")
    if(organization){
      window.location.href='/org_'+organization+'/welcome'
    }
      else{
        window.location.href='/welcome'
    }
  }

  return (
    <>
      <Header isLoggedout={true} />
      <div className="flex w-full  items-center justify-center flex-col pt-20 pb-20 text-center pl-4 pr-4 h-s min-h-[50vh]">
          <h2>{I18n.get('messageLogout')}</h2>
          <h3 className="pt-5">{I18n.get('messageGoTo')}<span onClick={()=>login() } id="btn_login" className=" font-bold cursor-pointer">{I18n.get('login')}</span>.</h3>
      </div>
      <Footer />
    </>
  );
};
