
export const strings = {
  en: {
    welcomeMessage: 'Welcome back',
    nameApp: 'Portal',
    Hint_Report_a_problem:'Click here to report any problems or malfunctions you may experience.',
    NoOpenIncidents:'There are no open incidents.',
    NoClosedIncidents:'There are no recently closed incidents.',
  },
  de: {
    welcomeMessage: 'Willkommen zurück',
    nameApp: 'Portal',
    Hint_Report_a_problem:'Klicken Sie hier, um etwaige Probleme oder Störungen zu melden.',
    NoOpenIncidents:'Es sind keine offenen Vorfälle vorhanden.',
    NoClosedIncidents:'Es sind keine kürzlich geschlossenen Vorfälle vorhanden.',
  },
};