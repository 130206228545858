import React from "react"
import { Apps } from '../interfaces/Apps'

type Options =
    {
        type: Apps | 'Messages' | 'Accounts' | 'Logout' | 'ArrowRight' | 'ArrowLink' | 'Download' | 'Download-Circle' | 'Lamp' | 'Edit' | 'Api' | 'Customer'| 'Closer'| 'Search'| 'Filter'| 'Select-Circle'| 'Checkmark'
        ownClassName: string
    }
export function getIcon(props: Options) {
    // generate icons with these setup > https://digitalspine.atlassian.net/browse/AP-1975

    if (props.type === 'Documents') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M140-160q-23 0-41.5-18.5T80-220v-520q0-23 18.5-41.5T140-800h281l60 60h339q23 0 41.5 18.5T880-680H140v460l102-400h698L833-206q-6 24-22 35t-41 11H140Z" />
        </svg>
    }
    else if (props.type === 'Portal') {
        return <svg 
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M160-120v-480l320-240 320 240v480H560v-280H400v280H160Z"/>
        </svg>
    }
    else if (props.type === 'Contracts') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M319-250h322v-60H319v60Zm0-170h322v-60H319v60ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554h189L551-820v186Z" />
        </svg>

    }
    else if (props.type === 'Devices') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path fill="current" d="M280-230h120v-170h40v-110q0-33-23.5-56.5T360-590h-40q-33 0-56.5 23.5T240-510v110h40v170Zm60-394q23 0 39.5-16.5T396-680q0-23-16.5-39.5T340-736q-23 0-39.5 16.5T284-680q0 23 16.5 39.5T340-624Zm175 94h200L615-690 515-530Zm100 260 100-160H515l100 160ZM180-120q-24 0-42-18t-18-42v-600q0-23 18-41.5t42-18.5h600q23 0 41.5 18.5T840-780v600q0 24-18.5 42T780-120H180Z" />
        </svg>
    }
    else if (props.type === 'Accounts') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M480-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-160v-94q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5T731-360q31 14 50 41t19 65v94H160Z" />
        </svg>
    }
    else if (props.type === 'Logout') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h299v60H180v600h299v60H180Zm486-185-43-43 102-102H360v-60h363L621-612l43-43 176 176-174 174Z" />
        </svg>
    }
    else if (props.type === 'Messages') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm340-302 340-223v-55L480-522 140-740v55l340 223Z" />
        </svg>
    }
    else if (props.type === 'ArrowRight') {
        return <svg
            className={props.ownClassName}
            width="35"
            height="35"
            viewBox="0 0 35 35"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8753 7.29175L19.8191 9.348L26.4982 16.0417H2.91699V18.9584H26.4982L19.8045 25.6522L21.8753 27.7084L32.0837 17.5001L21.8753 7.29175Z" />
        </svg>
    }
    else if(props.type === 'ArrowLink'){
        return <svg
            className={props.ownClassName}
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8 20.5L16 12.5L8 4.5" stroke="#283769" strokeWidth="2"/>
        </svg>
    }
    else if (props.type === 'Download') {
        return <svg
            className={props.ownClassName}
            width="35"
            height="35"
            viewBox="0 0 35 35"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
        </svg>
    }
    else if (props.type === 'Download-Circle') {
        return <svg
            className={props.ownClassName}
            width="35"
            height="35"
            viewBox="0 0 35 35"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="18" r="17.5" fill="#36ADF5" /><rect x="11.9316" y="25.5566" width="11.9318" height="2.38636" fill="#F2F4F7" /><path d="M23.8633 18.3978L22.1809 16.7154L19.0906 19.7938L19.0906 7.65918L16.7042 7.65918L16.7042 19.7938L13.6258 16.7154L11.9315 18.3978L17.8974 24.3637L23.8633 18.3978Z" fill="#F2F4F7" />
        </svg>
    }
    else if (props.type === 'Incidents') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M250-290h180v-60H250v60Zm400 0h60v-60h-60v60ZM250-450h180v-60H250v60Zm400 0h60v-220h-60v220ZM250-610h180v-60H250v60ZM132-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h696q24 0 42 18t18 42v600q0 24-18 42t-42 18H132Z"/>
        </svg>
    }
    else if (props.type === 'Learn') {
        return <svg 
            className={props.ownClassName}
            width="33"
            height="27"
            viewBox="0 0 33 27"
            xmlns="http://www.w3.org/2000/svg">
                <path d="M29.6237 20.7907V10.7282L16.4987 17.874L0.457031 9.12402L16.4987 0.374023L32.5404 9.12402V20.7907H29.6237ZM16.4987 26.624L6.29036 21.0824V13.7907L16.4987 19.3324L26.707 13.7907V21.0824L16.4987 26.624Z" />
        </svg>
    }
    else if(props.type === 'Lamp'){
        return <svg
            className={props.ownClassName}
            width="24"
            height="25"
            viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1527_4917" style={{'maskType':'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25"><rect y="0.476562" width="24" height="24" fill="#D9D9D9"/></mask>
            <g mask="url(#mask0_1527_4917)"><path d="M12 22.4766C11.5667 22.4766 11.175 22.3724 10.825 22.1641C10.475 21.9557 10.2 21.6766 10 21.3266C9.45 21.3266 8.97917 21.1307 8.5875 20.7391C8.19583 20.3474 8 19.8766 8 19.3266V15.7766C7.01667 15.1266 6.22917 14.2682 5.6375 13.2016C5.04583 12.1349 4.75 10.9766 4.75 9.72656C4.75 7.7099 5.45417 5.9974 6.8625 4.58906C8.27083 3.18073 9.98333 2.47656 12 2.47656C14.0167 2.47656 15.7292 3.18073 17.1375 4.58906C18.5458 5.9974 19.25 7.7099 19.25 9.72656C19.25 11.0099 18.9542 12.1766 18.3625 13.2266C17.7708 14.2766 16.9833 15.1266 16 15.7766V19.3266C16 19.8766 15.8042 20.3474 15.4125 20.7391C15.0208 21.1307 14.55 21.3266 14 21.3266C13.8 21.6766 13.525 21.9557 13.175 22.1641C12.825 22.3724 12.4333 22.4766 12 22.4766ZM10 19.3266H14V18.4266H10V19.3266ZM10 17.4266H14V16.4766H10V17.4266ZM9.8 14.4766H11.25V11.7766L9.05 9.57656L10.1 8.52656L12 10.4266L13.9 8.52656L14.95 9.57656L12.75 11.7766V14.4766H14.2C15.1 14.0432 15.8333 13.4057 16.4 12.5641C16.9667 11.7224 17.25 10.7766 17.25 9.72656C17.25 8.2599 16.7417 7.01823 15.725 6.00156C14.7083 4.9849 13.4667 4.47656 12 4.47656C10.5333 4.47656 9.29167 4.9849 8.275 6.00156C7.25833 7.01823 6.75 8.2599 6.75 9.72656C6.75 10.7766 7.03333 11.7224 7.6 12.5641C8.16667 13.4057 8.9 14.0432 9.8 14.4766Z" fill="#283769"/></g>
        </svg>
    }
    else if(props.type === 'Edit'){
        return <svg
            className={props.ownClassName}
            width="48"
            height="48"
            viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M180-180h44l472-471-44-44-472 471v44Zm-60 60v-128l575-574q8-8 19-12.5t23-4.5q11 0 22 4.5t20 12.5l44 44q9 9 13 20t4 22q0 11-4.5 22.5T823-694L248-120H120Zm659-617-41-41 41 41Zm-105 64-22-22 44 44-22-22Z"/>
        </svg>
    }
    else if(props.type === 'Api'){
        return <svg
            className={props.ownClassName}
            width="48"
            height="48"
            viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M320-242 80-482l242-242 43 43-199 199 197 197-43 43Zm318 2-43-43 199-199-197-197 43-43 240 240-242 242Z"/>
        </svg>
    }
    else if(props.type === 'Customer'){
        return <svg
            className={props.ownClassName}
            width="48"
            height="48"
            viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M440-120v-60h340v-304q0-123.686-87.321-209.843Q605.357-780 480-780t-212.679 86.157Q180-607.686 180-484v244h-20q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v69q0 24.75-17.625 42.375T780-120H440Zm-80.175-290Q347-410 338.5-418.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5Zm240 0Q587-410 578.5-418.675q-8.5-8.676-8.5-21.5 0-12.825 8.675-21.325 8.676-8.5 21.5-8.5 12.825 0 21.325 8.675 8.5 8.676 8.5 21.5 0 12.825-8.675 21.325-8.676 8.5-21.5 8.5ZM241-462q-7-106 64-182t177-76q87 0 151 57.5T711-519q-89-1-162.5-50T434.719-698Q419-618 367.5-555.5 316-493 241-462Z"/>
        </svg>
    }
    else if(props.type === 'Closer'){
        return <svg
            className={props.ownClassName}
            width="24"
            height="24"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.35352 18.2166L18.2165 5.35357" stroke="#283769" strokeWidth="2"/>
                <path d="M18.2168 18.2166L5.35381 5.35357" stroke="#283769" strokeWidth="2"/>
            </svg>
    }
    else if(props.type === 'Search'){
        return <svg
            className={props.ownClassName}
            width="24"
            height="24"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.14583 15.3708 4.8875 14.1125C3.62917 12.8542 3 11.3167 3 9.5C3 7.68333 3.62917 6.14583 4.8875 4.8875C6.14583 3.62917 7.68333 3 9.5 3C11.3167 3 12.8542 3.62917 14.1125 4.8875C15.3708 6.14583 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8125 13.5625 12.6875 12.6875C13.5625 11.8125 14 10.75 14 9.5C14 8.25 13.5625 7.1875 12.6875 6.3125C11.8125 5.4375 10.75 5 9.5 5C8.25 5 7.1875 5.4375 6.3125 6.3125C5.4375 7.1875 5 8.25 5 9.5C5 10.75 5.4375 11.8125 6.3125 12.6875C7.1875 13.5625 8.25 14 9.5 14Z"/>
            </svg>
    }
    else if(props.type === 'Filter'){
        return <svg
            className={props.ownClassName}
            width="24"
            height="24"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 21V15H13V17H21V19H13V21H11ZM3 19V17H9V19H3ZM7 15V13H3V11H7V9H9V15H7ZM11 13V11H21V13H11ZM15 9V3H17V5H21V7H17V9H15ZM3 7V5H13V7H3Z" />
            </svg>
    }
    else if(props.type === 'Select-Circle'){
        return <svg
            className={props.ownClassName}
            width="24"
            height="24"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="9.5" fill="white" stroke="#283769"/>
            </svg>
    }
    else if(props.type === 'Checkmark'){
        return <svg
            className={props.ownClassName}
            width="24"
            height="24"
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.7 18.025L4 12.325L5.425 10.9L9.7 15.175L18.875 6L20.3 7.425L9.7 18.025Z" />
            </svg>
    }
}
