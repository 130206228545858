import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Category } from '../../interfaces/Category';
import { getCategoriesForCagtegory } from '../../utils/helpersFetch';
import DragDropFile from './uploadFileDrop'
import { Button } from './../../../components/atoms/Button';
import { I18n } from '@aws-amplify/core';
import { getAuth0Token } from '../../../utils/helpers';

const FileUploadCategories = (props: { category: Category; libraryId: string; refreshCallback:Function,goBack:Function }) => {

  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [isLodingCategories, setIsLodingCategories] = useState<boolean>(false);
  const [runningUploads, setRunningUploads] = useState<string[]>([]);
  const splitter = <svg className="ml-4 mr-4 h-3 " width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L0.999999 1" stroke="#102A68" strokeWidth="2"/></svg>
  const [showFolderErrorMessage, setShowFolderErrorMessage] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    setRunningUploads([])
  }, []);

  useEffect(() => {
    if(showUploadDialog){
      document.body.classList.add('overflow-hidden')
    }
    else{
      document.body.classList.remove('overflow-hidden')
    }
  }, [showUploadDialog]);

  useEffect(() => {
    const loadCategories = async () => {
      if(props.category.id){
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        let categoriesResult = await getCategoriesForCagtegory(props.category.id,accessToken)
        setSubCategories(categoriesResult.subCategories)
        setIsLodingCategories(false)
      }
    }
    setIsLodingCategories(true)
    loadCategories()
  }, [props.category.id]);

  const uploadCaller = (categoryId:string,isLoading:boolean) =>{
    if(isLoading){
        // add entry 
        let localRunningUploads = JSON.parse(JSON.stringify(runningUploads))
        localRunningUploads.push(categoryId)
        setRunningUploads(localRunningUploads)
    }
    else{
        //remove entry
      let localRunningUploads = JSON.parse(JSON.stringify(runningUploads))
      var index = localRunningUploads.indexOf(categoryId);
      if (index !== -1) {
        localRunningUploads.splice(index, 1);
        props.refreshCallback() // refresh only if a upload was finishes
      }
      setRunningUploads(localRunningUploads)   
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
        window.removeEventListener('beforeunload', alertUser)
        window.removeEventListener('unload', handleTabClosing)
    }
  })

  const handleTabClosing = () => {
      console.log('handleTabClosing')
  }

  const alertUser = (event:any) => {
    if(runningUploads.length > 0){
      event.preventDefault()
      event.returnValue = ''
    } 
  }

  return (
    <>
    {showUploadDialog && 
    <div className="fixed overflow-scroll top-0 left-0 right-0 bottom-0 z-50 w-full h-full bg-white ">
        <div className=" text-left sticky top-0 bg-white z-20 h-16">
          <div className=" ">
            <div className=" inline-flex  items-center text-sm grow">
              <span className={( (runningUploads.length > 0)? " cursor-not-allowed " : ' cursor-pointer ') } onClick={()=>{
                if(runningUploads.length <= 0){
                  setShowUploadDialog(false);
                  props.goBack()
              }}} >{I18n.get("overview")}</span>
              {splitter}
              <span className={( (runningUploads.length > 0)? " cursor-not-allowed " : ' cursor-pointer ') } onClick={()=>{
                if(runningUploads.length <= 0){
                  setShowUploadDialog(false);
              }
            }} >{props.category? props.category.titleGerman :''}</span>            
            </div>
            <div className=" inline-block float-right ">
              <Button label='Upload schließen' size='extra small' disabled={runningUploads.length > 0 ? true : false} onClick={()=>{
                if(runningUploads.length <= 0){
                  setShowUploadDialog(false);
              }
            }} theme='light' />
            </div>
          </div>
          <br/>
        </div>
      {runningUploads.length > 0 ? <div className=" text-center">Laufend Vorgänge:{runningUploads.length}</div>:<></>}
      {showFolderErrorMessage ? <div className=" text-center text-warnRed ">{I18n.get('FolderErrorMessage')}</div>:<></>}

      <Spin spinning={isLodingCategories} className="" >
        <div className=" p-0 pt-16 flex flex-wrap overflow-scroll justify-center">
          {subCategories.map((subCategory,index) => {return <DragDropFile key={index} libraryId={props.libraryId} subCategory={subCategory} refreshCallback={props.refreshCallback} uploadCallback={uploadCaller} setShowFolderErrorMessage={setShowFolderErrorMessage} />})}
        </div>
      </Spin>
    </div>
    }
    {!showUploadDialog && <Button label='Dateien uploaden' size='extra small' onClick={()=>{setShowUploadDialog(true)}} theme='light' />}
    </>
  );
}

export default FileUploadCategories;

