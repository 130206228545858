import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import { useGlobalState } from "../../../utils/globalStateProvider";
import React, { useEffect, useRef, useState } from 'react';
import { EnvironmentTwoTone } from '@ant-design/icons';
import mapStyles from "./../welcome/MapStyles"
import "./../welcome/Map.css"
import { Link } from 'react-router-dom';
import * as uuid from 'uuid';
import { Spin } from 'antd';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Installation, InstallationCoords } from '../../../interfaces/Installation';
import { google_maps_api_code } from '../../../utils/consts';

function TileMapDetail(props:{installations:Installation[] | undefined , installationCoords?:InstallationCoords[]}) {
  const { state } = useGlobalState();
  var mapsApiCode = google_maps_api_code

  Geocode.setApiKey(mapsApiCode);
  Geocode.setLanguage("en")

  type objMarker = { lat:Number,lng:Number,name:string ,key:string, show:boolean, needToLoadLatLng:boolean,label:string};
  const [markersArr, setMarkers] = React.useState<objMarker[]>([])
  const [geoCoordiantesLoaded, setGeoCoordiantesLoaded] = React.useState<boolean>(false)
  const [map, setMap] = React.useState<any>(null)
  const [maps, setMaps] = React.useState<any>(null)
  const [currentInstallations, setCurrentInstallations] = useState<Installation[]>([]);
  const [currentCoords, setCurrentCoords] = useState<InstallationCoords[]>([]);
  const [cluster, SetCluster] = React.useState<MarkerClusterer>()

  useEffect(()=>{
    if(!props.installations){
      return 
    }
    setCurrentInstallations(props.installations)
  },[props.installations])

  useEffect(()=>{
    if(!props.installationCoords || props.installationCoords.length <= 0){
      return 
    }
    setCurrentCoords(props.installationCoords)
  },[props.installationCoords])

  useEffect(() => {
    
    let allLoaded = true
    if(markersArr.length <= 0){
      setGeoCoordiantesLoaded(true)
      return 
    }

    markersArr.forEach((place) => {
      if(place.needToLoadLatLng){
        allLoaded = false 
      }
    });
    
    if(allLoaded){
      setGeoCoordiantesLoaded(true)
    }
    if(map && maps){
      centerMap(map, maps, markersArr)
    }

   let renderer = undefined
   const markers: any[] = [];


   if(map && maps){

      markersArr?.forEach((location) => {

      let myMarker = new maps.Marker({
        title:location.name,
        icon:pinSymbol("#00265E"),
        position: {
          lat: location.lat,
          lng: location.lng,
        },
        map,
      })

      myMarker.addListener("click", () => {
        //infoWindow.setContent('<div><a href="/org_'+state.organizationName+'/installations/'+location.key+'">'+location.name+'</a></div>');
        //infoWindow.open(map, myMarker);
        });

        markers.push(
          myMarker
        );
      });
    
      renderer = {
        render({ count, position }:any) {
          return new maps.Marker({
            label: { text: String(count), color: "white", fontSize: "30px" },
            position,
            icon: "/assets/images/map_cluster.png",
            // adjust zIndex to be above other markers
            zIndex: Number(maps.Marker.MAX_ZINDEX) + count,
          })
        }
      }

      cluster?.clearMarkers()
      SetCluster(new MarkerClusterer({ markers, map,renderer}))
    }
  }, [ markersArr,maps ]);

  function pinSymbol(color:string) {
    return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: color,
        strokeWeight: 0,
        scale: 1,
   };
}

  useEffect(() => {
    if(!currentInstallations){
      return
    }
    let places = [{} as objMarker]
    places = []
    let placesToLoadLatLng = [{} as objMarker]
    placesToLoadLatLng = []
    currentInstallations.forEach((currentInstallation) => {
      // activate if contract contains the full asset
      
      if(currentInstallation &&
        currentInstallation.building?.coordinates && 
        currentInstallation.building?.coordinates.length > 0){
        let tmpObj = {} as objMarker
        tmpObj.key = currentInstallation._id
        tmpObj.lat = currentInstallation.building.coordinates[1]
        tmpObj.lng = currentInstallation.building.coordinates[0]
        tmpObj.name = currentInstallation?.label || ''
        tmpObj.show = false
        tmpObj.needToLoadLatLng = false
        places.push(tmpObj)
      }
      
    })
    setMarkers(places)
     
  }, [ currentInstallations ]);

  useEffect(() => {
    if(!currentCoords){
      return
    }
    let places = [{} as objMarker]
    places = []
    let placesToLoadLatLng = [{} as objMarker]
    placesToLoadLatLng = []
    currentCoords.forEach((currentInstallation) => {
      // activate if contract contains the full asset
      if(currentInstallation &&
        currentInstallation.coords && 
        currentInstallation.coords.lat&& 
        currentInstallation.coords.lng){
        let tmpObj = {} as objMarker
        tmpObj.key = currentInstallation.id
        tmpObj.lat = parseFloat(currentInstallation.coords.lat)
        tmpObj.lng = parseFloat(currentInstallation.coords.lng)
        tmpObj.name = currentInstallation?.label || ''
        tmpObj.show = false
        tmpObj.needToLoadLatLng = false
        places.push(tmpObj)
      }
      
    })
    setMarkers(places)
     
  }, [ currentCoords ]);


  const showInfowindow = (id:any) =>{
    const index = markersArr.findIndex((e) => e.key === id);
    let myMarkers =  JSON.parse(JSON.stringify( markersArr ))
    myMarkers.forEach((element: { show: boolean; }) => {
      element.show = false
    });
    myMarkers[index].show = !markersArr[index].show
    setMarkers(myMarkers)
  }


  const MapMarker = ({ text,show,id }:any ) => {
    const markerRef = useRef(null);
    return (
      <div className="mapMarker"  ref={markerRef}>
        <EnvironmentTwoTone onClick={()=>{ showInfowindow(id)}} alt={text} twoToneColor="#34495E" style={{ fontSize: '26px' }}/>
        {show && <div className="googleMapsInfoWindow"><Link to={'/installations/'+id}>{text}</Link></div>}
      </div>
      )
    };

  // Return map bounds based on list of places
  const getMapBounds = (map: any, maps: { Marker?: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; LatLngBounds?: any; LatLng?: any; }) => {
    const bounds = new maps.LatLngBounds();
    markersArr.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

  const centerMap = (map: any, maps: { Marker: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; },markers: { lat: Number; lng: Number; name: string; key: string; }[]) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps);
    map.fitBounds(bounds);
    map.panToBounds(bounds);

    if(markers.length <= 1){
      map.setZoom(15);
    }
   return [];
  };

  const handleApiLoaded = (map: any, maps: { Marker: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; },markers: { lat: Number; lng: Number; name: string; key: string; }[]) => {
    setMap(map)
    setMaps(maps)
    if(markers.length > 0){
      centerMap(map, maps, markers)
    }
  };


  function _onClick(obj:any){ console.log(obj.x, obj.y, obj.lat, obj.lng, obj.event, obj.target);}

  const getMap = () => {

    return <GoogleMapReact
      bootstrapURLKeys={{ key: mapsApiCode}}
      defaultCenter={{lat:52.519325,lng:13.392709}}
      defaultZoom={4}
      yesIWantToUseGoogleMapApiInternals
      options={{styles:mapStyles}}
      //resetBoundsOnResize={true}

      onGoogleApiLoaded={({map, maps}) => { handleApiLoaded(map, maps, markersArr)} }
      >
      { false && markersArr.map((place) => (
          <MapMarker
            key={place.key + '-' + uuid.v4()}
            id={place.key}
            text={place.name}
            lat={place.lat}
            lng={place.lng}
            show={place.show}
          />
        ))}
      </GoogleMapReact>
    }
    return (
        <div className={""} style={{ height: '500px', width: '100%' }}>{ geoCoordiantesLoaded ? getMap() : <Spin size="large" />}</div>
        );
  }
  
export default TileMapDetail;
  