export const strings = {
  en: {
    noSufficientPermissionWhileFetchingLibrary:'You do not have sufficient permission to view this library information.',
    libraryNotFound: 'Library not found.',
    loadErrorFile:'The file is not available.',
    genericAPIFailedError:'API request failed',
    noSufficientPermissionWhileFetchingFile:'You do not have sufficient permission to view this file information.',
    fileNotFound: 'File not found.'
  },
  de: {
    noSufficientPermissionWhileFetchingLibrary:'Sie verfügen nicht über ausreichende Berechtigungen, um diese Bibliotheksinformationen anzuzeigen.',
    libraryNotFound: 'Bibliothek nicht gefunden.',
    loadErrorFile:'Die Datei ist nicht verfügbar.',
    genericAPIFailedError: 'API request failed',
    noSufficientPermissionWhileFetchingFile:'Sie verfügen nicht über ausreichende Berechtigungen, um diese Dateiinformationen anzuzeigen.',
    fileNotFound: 'Datei nicht gefunden'
  },
};