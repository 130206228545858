
export const strings = {
    en: {
      navDashboard: 'Dashboard',
      navInstallations: 'Installations',
      navDocuments: 'Documents',
      sprachwahl: 'Language selection',
      Cost: 'Cost',
      ContractLength : 'Contract length'
    },
    de: {
      navDashboard: 'Dashboard',
      navInstallations: 'Anlagen',
      navDocuments: 'Dokumente',
      sprachwahl: 'Sprachwahl',
      Cost: 'Kosten',
      ContractLength : 'Vertragslaufzeit'
    },
  };