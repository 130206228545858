export const appPermissions = {
    'Devices': [
        'app:devices'
    ],
    'Documents': [
        'app:documents'
    ],
    'Contracts': [
        'app:contracts'
    ],
    'Incidents': [
        'app:incidents'
    ],
    'Portal': [
    ],
    'Learn': [
    ],
};