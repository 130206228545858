import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { strings as stringLocal} from './WelcomeLocalization';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../utils/globalStateProvider';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { getAuth0IsAuthenticatedStatus, langChange, langInit, setDocumentTitle } from '../../utils/helpers';
import WelcomeWidgets from '../components/Widgets';
import { getIcon } from '../../utils/icons';
import MainWrapper from '../components/MainWrapper';

const Dashboard = () => {
  
  const { isAuthenticated, user } = useAuth0();
  const { state, setState } = useGlobalState()
  const navigate = useNavigate();

  useEffect(() => {
    if(state.language){ langChange({state:state,updateDocumentTitle:true}) } else { langInit( {stringLocal:stringLocal,setStateGlobal:setState} ) }
  }, [state.language]);

  useEffect(() => {
    return () => {
      setDocumentTitle()
    };
  }, []);

  useEffect(() => {
    if(state.organizationName && document.location.pathname === '/welcome'){
        navigate('/org_'+state.organizationName+'/welcome')
    }
  }, [state.organizationName]);

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? 'h-screen flex flex-col' : 'hidden'}>
      <Header />
      <section className="bg-blueLight welcome">
        <div className=" pt-7 xxl:pt-14 pb-11 xxl:pb-14">
          <div className="flex items-center">
            <div className="  w-[55px] h-[55px] rounded-lg bg-white flex items-center justify-center">
              {getIcon({ type: 'Portal', ownClassName: ' fill-blueLight w-9' })}
            </div>
            <div className=" ml-4 m:ml-6 text-base m:text-xl text-white font-bold ">{I18n.get('nameApp')}</div>
          </div>
          <h1 className="text-white mb-10 mt-5">{I18n.get('welcomeMessage')} {(getAuth0IsAuthenticatedStatus(isAuthenticated) && user && user.email && <>{user.name}</>)}</h1>
          <WelcomeWidgets />
        </div>
      </section>
      <section className="Content">
        <div className="flex flex-col pt-10 pb-10"><MainWrapper /></div>
      </section>      
      <Footer />
    </div>

  );
};

export default Dashboard