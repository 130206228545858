import React from 'react'
import { } from 'react-router-dom'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { strings as stringHeader } from './AppLauncherLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { mergeDeepRight } from 'ramda';
import { I18n } from '@aws-amplify/core';
import { UserHasPermissionsFor, appLinkFor, getAuth0Token, getLangByLocalStorage, isTestEnv } from '../../utils/helpers';
import { useGlobalState } from '../../utils/globalStateProvider';
import './AppLauncher.css';
import { getIcon } from '../../utils/icons';

const AppLauncher = (props: { logoutAuth0: Function }) => {

  const { state, setState } = useGlobalState()
  const { user, getAccessTokenSilently } = useAuth0()

  const [userHasPermissionDevices, setUserHasPermissionDevices] = React.useState<boolean>(false)
  const [userHasPermissionDocuments, setUserHasPermissionDocuments] = React.useState<boolean>(false)
  const [userHasPermissionContracts, setUserHasPermissionContracts] = React.useState<boolean>(false)
  const [userHasPermissionAccount, setUserHasPermissionAccount] = React.useState<boolean>(false)
  const [userHasPermissionIncidents, setUserHasPermissionIncidents] = React.useState<boolean>(false)

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringHeader, stringGlobal));
    I18n.setLanguage(getLangByLocalStorage())

    const GetPermissions = async () => {
      let accessToken = await getAuth0Token(getAccessTokenSilently)
      UserHasPermissionsFor('Devices',accessToken)?setUserHasPermissionDevices(true):setUserHasPermissionDevices(false)
      UserHasPermissionsFor('Documents',accessToken)?setUserHasPermissionDocuments(true):setUserHasPermissionDocuments(false)
      UserHasPermissionsFor('Contracts',accessToken)?setUserHasPermissionContracts(true):setUserHasPermissionContracts(false)
      UserHasPermissionsFor('Incidents',accessToken)?setUserHasPermissionIncidents(true):setUserHasPermissionIncidents(false)
      }
    GetPermissions()
  }, []);

  return (
    <div className="flex flex-col bg-transparent text-base appLauncher">
      <div className="hidden m:flex bg-blueDark text-white h-12 rounded-t justify-center items-center ellipsis"><span className="ml-4 mr-4">Digital Spine Cloud {I18n.get('for')} {state.organizationDisplayName}</span></div>
      <div className=" bg-white rounded-b m:shadow-2xl h-[280px] overflow-y-auto overflow-x-hidden">

        <div className="grid grid-cols-3 gap-4 p-8 pl-0 m:pl-6 pr-0 m:pr-6">

          <div onClick={() => { document.location.href = appLinkFor('Portal', state) }} className="flex flex-col cursor-pointer items-center mb-4 group/item">
            <div className=" w-[55px] h-[55px] rounded-lg bg-blueBaby group-hover/item:bg-blueDark flex items-center justify-center">
              {getIcon({ type: 'Portal', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-9' })}
            </div>
            <div className="pt-1">{I18n.get('Portal')}</div>
          </div>
        
          {userHasPermissionDevices &&
            <div onClick={() => { document.location.href = appLinkFor('Devices', state) }} className="flex flex-col cursor-pointer items-center mb-4 group/item">
              <div className=" w-[55px] h-[55px] rounded-lg bg-blueBaby group-hover/item:bg-blueDark flex items-center justify-center">
                {getIcon({ type: 'Devices', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-9' })}
              </div>
              <div className="pt-1">{I18n.get('Devices')}</div>
            </div>
          }

          {userHasPermissionDocuments &&
            <div onClick={() => { document.location.href = appLinkFor('Documents', state) }} className="flex flex-col cursor-pointer items-center mb-4 group/item">
              <div className=" w-[55px] h-[55px] rounded-lg bg-blueBaby group-hover/item:bg-blueDark flex items-center justify-center">
                {getIcon({ type: 'Documents', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-9' })}
              </div>
              <div className="pt-1">{I18n.get('Documents')}</div>
            </div>
          }

          {userHasPermissionContracts &&
            <div onClick={() => { document.location.href = appLinkFor('Contracts', state) }} className="flex flex-col cursor-pointer items-center mb-4 group/item">
              <div className=" w-[55px] h-[55px] rounded-lg bg-blueBaby group-hover/item:bg-blueDark flex items-center justify-center">
                {getIcon({ type: 'Contracts', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-9' })}
              </div>
              <div className="pt-1">{I18n.get('Contracts')}</div>
            </div>
          }

          {userHasPermissionAccount &&
            <div className="flex flex-col cursor-pointer items-center mb-4 group/item">
              <div className=" w-[55px] h-[55px] rounded-lg bg-blueBaby group-hover/item:bg-blueDark flex items-center justify-center">
                {getIcon({ type: 'Accounts', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-9' })}
              </div>
              <div className="pt-1">{I18n.get('Account')}</div>
            </div>
          }

          {userHasPermissionIncidents &&
            <div onClick={() => { document.location.href = appLinkFor('Incidents', state) }} className="flex flex-col cursor-pointer items-center mb-4 group/item">
              <div className=" w-[55px] h-[55px] rounded-lg bg-blueBaby group-hover/item:bg-blueDark flex items-center justify-center">
                {getIcon({ type: 'Incidents', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-9' })}
              </div>
              <div className="pt-1">{I18n.get('Incidents')}</div>
            </div>
          }

          { <div onClick={() => { document.location.href = appLinkFor('Learn', state) }} className="flex flex-col cursor-pointer items-center mb-4 group/item">
            <div className=" w-[55px] h-[55px] rounded-lg bg-blueBaby group-hover/item:bg-blueDark flex items-center justify-center">
              {getIcon({ type: 'Learn', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-9' })}
            </div>
            <div className="pt-1">{I18n.get('Learn')}</div>
          </div>}

          {/* logout */}
          <div className="hidden m:flex flex-col cursor-pointer items-center mb-4 group/item cp_btn_logout" onClick={() => props.logoutAuth0()}>
            <div className=" w-[55px] h-[55px] rounded-lg bg-blueBaby group-hover/item:bg-blueDark flex items-center justify-center">
              {getIcon({ type: 'Logout', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-9' })}
            </div>
            <div className="pt-1">Logout</div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AppLauncher;
