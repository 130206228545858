import { I18n } from '@aws-amplify/core';
import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { strings as stringGlobal } from './../utils/globalLocalization'
import { getLangByLocalStorage } from '../utils/helpers';

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export const RedirectPage = () => {
  const { search } = useLocation();

  let query =  new URLSearchParams(search);
  useEffect(() => {
    let newLocation = query.get("to")
    if(newLocation){
      document.location.href=newLocation
    }
  },[] );

  return (
    <div className="flex w-full h-screen items-center justify-center flex-col">
        {I18n.get('gettingRedirected')}
    </div>
  );
};
