import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import arrowIotDown from '../../../assets/images/icons/arrow_down_iot.svg'
import arrowIotUp from '../../../assets/images/icons/arrow_up_iot.svg'
import { DeviceInfo, DeviceShadowDictionary } from '../../../interfaces/DeviceInfo';
import { Installation } from '../../../interfaces/Installation';
import { FeedMessage } from '../../../interfaces/Lifefeed';
import { GlobalStateInterface } from '../../../utils/globalStateProvider';
import { GlobalStateInterfaceApp } from '../../utils/globalStateProviderApp';

export enum DirectionStatus {
    UP = "up",
    DOWN = "down",
    UNSET = "unset",
  }

export const LevelVisualizationSmall = ({
    deviceName,
    state,
    liveData,
    devicesShadow,
    //isLoadingDeviceState
  }: {
    deviceName: string;
    state: Partial<GlobalStateInterfaceApp>;
    liveData: FeedMessage[] | undefined;
    devicesShadow: DeviceShadowDictionary|null;
    //isLoadingDeviceState: boolean
  }) => {

    const [installation, setInstallation] = useState<Installation|undefined>(undefined)
    const [device, setDevice] = useState<DeviceInfo|undefined>(undefined)
    
    const [currentFloor, setCurrentFloor] = useState<string>('')
    const [direction , setDirection] = useState<DirectionStatus>(DirectionStatus.UNSET)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [hideLiveInformations,setHideLiveInformations] = useState(false)

    useEffect(
        () => {
            
            if(liveData && liveData.length > 0){
                setIsLoading(false)
            }
            else if(deviceName){
                setIsLoading(false)
            }
            else if(!deviceName && state.devices !== undefined){
                setIsLoading(false)    
            }
            
            if(devicesShadow && devicesShadow[deviceName] ){
                setIsLoading(false)
                let deviceShadowStateReported = devicesShadow[deviceName]?.state.reported

                if(deviceShadowStateReported?.deviceStatus.direction === "directionUp"){
                    setDirection(DirectionStatus.UP)
                }
                else if(deviceShadowStateReported?.deviceStatus.direction === "directionDown"){
                    setDirection(DirectionStatus.DOWN)
                }
                else{
                    setDirection(DirectionStatus.UNSET)
                }
                // detecting arrived floor
                if(deviceShadowStateReported?.deviceStatus.mappedFloor !== undefined){
                    setCurrentFloor(deviceShadowStateReported.deviceStatus.mappedFloor)
                }
            }
        },[devicesShadow,deviceName]
      )

    useEffect(
        () => {
            if(liveData && liveData.length > 0 ){

                if(liveData[0].body?.name === "deviceDirectionChanged" ){
                    if(liveData[0].body.attributes?.direction === "directionUp"){
                        setDirection(DirectionStatus.UP)
                    }
                    else if(liveData[0].body.attributes?.direction === "directionDown"){
                        setDirection(DirectionStatus.DOWN)
                    }
                    else{
                        setDirection(DirectionStatus.UNSET)
                    }
                }

                if(liveData[0].body?.name === "deviceDoorStateChanged"){
                    // sometimes the deviceDirectionChanged > directionStop isnt appearing
                    // so hide arrow if doors are moving
                    setDirection(DirectionStatus.UNSET)
                }                

                // detecting arrived floor
                if(liveData[0].body?.name === "deviceMoved" && liveData[0].body.attributes?.mappedFloor ){
                    setCurrentFloor(liveData[0].body.attributes?.mappedFloor)
                }
            }
        },[liveData,device,installation]
      )

    useEffect(
        () => {
            if(installation){
                return
            }

            state.installations?.forEach((installation:Installation)=>{
                if(installation.iotBoxes.includes(deviceName)){
                setInstallation(installation)
                }
            })

        },[deviceName,state.installations]
      )

      useEffect(
        () => {
            let deviceInfo = state.devices ? state.devices[deviceName] : undefined
            let infoDevices = state.devices 
            let deviceFound = false
            for (const property in infoDevices) {
                if(infoDevices[property]?.deviceId === deviceName){
                    deviceFound = true
                    if(infoDevices[property]?.ragMqtt === "1" || infoDevices[property]?.ragGlobal === "1"){
                        //setHideLiveInformations(true)
                    }
                }
            }            

            if( deviceFound ){
                // nothing    
            }
            else if(installation && installation.mainAccess){
                setHideLiveInformations(true)
            }

        },[state.devices,installation]
      )

    return (<Spin size="small" spinning={isLoading} className=" ml-3">
        {hideLiveInformations && <></>}
        {!hideLiveInformations && <div className="flex">
            <div className=" text-sm m:text-xl  m:w-11 h-10 flex justify-center items-center"><div>{ currentFloor }</div></div>
            {direction === DirectionStatus.UP && <img className=" scale-50 m:scale-100  ml-0" src={arrowIotUp.toString()} alt="" /> }
            {direction === DirectionStatus.DOWN && <img className=" scale-50 m:scale-100  ml-0" src={arrowIotDown.toString()} alt="" /> }
            {direction === DirectionStatus.UNSET && <div className="  ml-0" /> }
        </div>}
        </Spin>
    );
}