import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ShowLibrary from '../../../components/salesforce/showLibrary';
import { Category } from '../../../interfaces/Category';
import { getCategoriesForLibrary } from '../../../utils/helpersFetch';
import { getAuth0IsAuthenticatedStatus, getAuth0Token } from '../../../../utils/helpers';

const SalesforceDocumentsAssetShowLibraries = () => {
  
  const { isAuthenticated,getAccessTokenSilently } = useAuth0()
  let { libraryId } = useParams();
  const [library, setLibrary] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {

    const loadLibrary = async () => {

      if(libraryId){
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        setIsLoading(true)
        let libraryResult = await getCategoriesForLibrary(libraryId,accessToken)
        setIsLoading(false)
        if(libraryResult){
          setLibrary(libraryResult)
        }
      }
    }

    loadLibrary()
  }, []); 

  return (
    
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated)? "h-full flex flex-col items-center justify-center": 'hidden' }>
      <Spin tip="Loading..." spinning={isLoading}><ShowLibrary assetLibrary={library} /></Spin>
    </div>
  );
};

export default SalesforceDocumentsAssetShowLibraries;