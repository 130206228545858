import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { DsFile } from '../../documents/interfaces/File';
import { Dropdown, MenuProps, Spin, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { deleteFile } from '../../documents/utils/helpersFetch';
import { Category } from '../../documents/interfaces/Category';
import { getDsFileDownloadLink } from '../../documents/utils/helpers';
import { getAuth0Token } from '../../utils/helpers';

const SfFileMenu = (
  props: { 
    file:DsFile,
    runningDeletions:String[],
    setRunningDeletions:React.Dispatch<React.SetStateAction<String[]>>,
    category:Category,
    loadFilesForCategory:() => Promise<void>,
    setItemToRename:React.Dispatch<React.SetStateAction<DsFile | null>>,
    setItemsToChangeCategory:React.Dispatch<React.SetStateAction<DsFile[]>>
    setTextToCopy:React.Dispatch<React.SetStateAction<string|null>>
    urlToModalDialog:string
  }) => {
  
  const { user,getAccessTokenSilently} = useAuth0()
  const ref = useRef<any>();
  
  const deleteDsFile = async (file:DsFile) => {
    let localRunningDeletions = JSON.parse(JSON.stringify(props.runningDeletions))
    localRunningDeletions.push(file.id)
    props.setRunningDeletions(localRunningDeletions)
    let accessToken =  await getAuth0Token(getAccessTokenSilently)
    let isFileDeleted = await deleteFile(file.id,accessToken)
    if(isFileDeleted){
      props.loadFilesForCategory()
    }
  }

  useEffect(() => {
    }, []);  

    const items: MenuProps['items'] = [
      {
        key: '1',
        label: (
        <Spin spinning={props.runningDeletions.includes(props.file.id)}>
          <span onClick={(e)=>{
            e.preventDefault()
            e.stopPropagation()
            ref.current.click()
            if(window.confirm(props.category.titleGerman + ' / ' + props.file.title + ' wirklich löschen?')){
            deleteDsFile(props.file)
            }
          }} >Löschen</span></Spin>
        ),
      },
      {
        key: '2',
        label: (
          <span onClick={(e)=>{
            e.preventDefault()
            e.stopPropagation()
            ref.current.click()
            props.setItemToRename(props.file)
          }} >Umbenennen</span>
        ),
      },
      {
        key: '3',
        label: (
          <span onClick={(e)=>{
            e.preventDefault()
            e.stopPropagation()
            ref.current.click()
            props.setItemsToChangeCategory([props.file])
          }} >Verschieben</span>
        ),
      },
      {
        key: '4',
        label: (
          <span onClick={async (e)=>{
            e.preventDefault()            
            e.stopPropagation()
            ref.current.click()
            if(props.urlToModalDialog){
              props.setTextToCopy(props.urlToModalDialog)
            }
            else{
              message.error('Link wurde nicht kopiert!')
            }
          }} >Link kopieren</span>
        ),
      }
    ];

  return (
    <Dropdown className="cursor-pointer" menu={{ items }} trigger={['click']} placement="bottom">
      <MoreOutlined ref={ref}  onClick={ (e)=>{
      e.preventDefault()
      e.stopPropagation()
    }} />
    </Dropdown>
  );
};

export default SfFileMenu;
