import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { IsDsMember, UserHasPermissionsForDocuments, getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, setDocumentTitle, setMaxAssetCount } from '../../utils/helpers';
import { USER_CHOICE_LANGUAGE, app_url_devices, auth0_audience } from './../../utils/consts';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useGlobalStateApp } from '../utils/globalStateProviderApp';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { strings as stringLocal} from './WelcomeLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import moment from 'moment';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TableLibraries from '../components/welcome/TableLibraries';
import { setMaxLibraryCount, setPathForSelectedFile } from '../utils/helpers';
import RecentlyViewedFiles from '../components/detail/recentlyViewedFiles';
import { DsFile } from '../interfaces/File';
import { useNavigate } from 'react-router-dom';
import DocumentDialog from '../components/detail/documentDialog';
import { getIcon } from '../../utils/icons';

const Dashboard = () => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const { user, isAuthenticated ,logout} = useAuth0()
  const { getAccessTokenSilently } = useAuth0();
  const [ userHasPermissionsForDocuments, setUserHasPermissionsForDocuments] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = React.useState<DsFile | null>(null)
  const navigate = useNavigate();

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
      setDocumentTitle()
    }
  }, [state.language]);

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let lang = getLangByLocalStorage()
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }

    const asyncInit = async () => {
      setMaxLibraryCount(setStateApp,await getAuth0Token(getAccessTokenSilently))
    }
    
    const checkPermissions = async () => {
      if(await UserHasPermissionsForDocuments(await getAuth0Token(getAccessTokenSilently))){
        setUserHasPermissionsForDocuments(true)
      }
      else{
        location.href = app_url_devices
      }
    }
    checkPermissions()
    asyncInit()
  }, []);

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
    <Header />
    <section className="bg-blueLight welcome">
      <div className=" pt-7 xxl:pt-14 pb-11 xxl:pb-14">
          <div className="flex items-center">
            <div className="  w-[55px] h-[55px] rounded-lg bg-white flex items-center justify-center">
              {getIcon({ type: 'Documents', ownClassName: ' fill-blueLight w-8' })}
            </div>
            <div className=" ml-4 m:ml-6 text-base m:text-xl text-white font-bold ">{I18n.get('nameApp')}</div>
          </div>
        <h1 className="text-white mt-5">{I18n.get('welcomeMessage')} {(getAuth0IsAuthenticatedStatus(isAuthenticated) && user && user.email && <>{user.name}</>)}</h1>
        <p className=" mt-1 xxl:mt-0 text-white text-lbase m:text-l xl:text-xl xxl:text-4xl">{I18n.get('welcomeMessageFollow').replace('###number###',stateApp.maxLibrariesCount ? stateApp.maxLibrariesCount: 0)}</p>
      </div>
    </section>
    <section className="Content">
      <div className="flex flex-col pb-14 ">
        <TableLibraries />
        <RecentlyViewedFiles 
          libraryIdToFilterFor={ undefined }
          setSelectedFile={setSelectedFile}
          isOverView={true}
        />
        {selectedFile && 
        <DocumentDialog 
          isLastSeenMode={true}
          file={{file:selectedFile,imageSrc:null}}
          setSelectedFile={setSelectedFile}
          mode={'Library'}
          />}    
      </div>
    </section>
    <Footer />
    </div>
  );
};

export default Dashboard