import { I18n } from "@aws-amplify/core";
import { strings as stringGlobal } from '../../utils/globalLocalization'
import * as allConsts from "../../utils/consts";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import theme from './../utils/mdCodeHighlightTheme'

import React from "react";
import { getIcon } from "../../utils/icons";
import { getLangByLocalStorage, scrollToLocation } from "../../utils/helpers";
import { languagStrings } from "../interfaces/LanguagStrings";
import CopyToClipboard from "../../components/molecules/CopyToClipboard";

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export const BreadCrumbSeperator = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 20.0007L16 12.0007L8 4.00073" stroke="white" strokeWidth="2"/></svg>

const iconCopy = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" fill="#406CF6"/><mask id="mask0_1527_4935" style={{'maskType':'alpha'}}  maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24"><rect x="8" y="8" width="24" height="24" fill="#D9D9D9"/></mask><g mask="url(#mask0_1527_4935)"><path d="M17 26C16.45 26 15.9792 25.8042 15.5875 25.4125C15.1958 25.0208 15 24.55 15 24V12C15 11.45 15.1958 10.9792 15.5875 10.5875C15.9792 10.1958 16.45 10 17 10H26C26.55 10 27.0208 10.1958 27.4125 10.5875C27.8042 10.9792 28 11.45 28 12V24C28 24.55 27.8042 25.0208 27.4125 25.4125C27.0208 25.8042 26.55 26 26 26H17ZM17 24H26V12H17V24ZM13 30C12.45 30 11.9792 29.8042 11.5875 29.4125C11.1958 29.0208 11 28.55 11 28V14H13V28H24V30H13Z" fill="white"/></g></svg>

function flatten(text, child) {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function HeadingRenderer(props:any) {
  var children = React.Children.toArray(props.children)
  var text = children.reduce(flatten, '')
  var slug = text.toLowerCase().replace(/\W/g, '-')
  
  let copyLink = <CopyToClipboard 
                  classNames='w-3 mr-0 xl:w-4 xl:mr-2 cursor-pointer invisible group-hover:visible'
                  label={I18n.get('CopyLink')}
                  textToCopy={location.origin + location.pathname + '#' + slug} />

  return React.createElement(props.node.tagName, {id: slug, className:"scrollMarginTakeCareAboutHeader group -ml-3 xl:-ml-6"}, 
  <React.Fragment >
      {copyLink}
      {props.children}
  </React.Fragment>)
}

function HeadingSidebarRenderer(props:any) {
  var children = React.Children.toArray(props.children)
  var text = children.reduce(flatten, '')
  var slug = text.toLowerCase().replace(/\W/g, '-')
  return React.createElement('a', { onClick: ()=>{scrollToLocation(slug)} }, props.children)
}

function replaceLinkVariables(doc:string){
  let regExp = /{{(.*?)}}/g

  const constVariables = {}
  Object.entries(allConsts).forEach(([key, value]) => {
    constVariables[key.toLowerCase()] = value
  });

  let content = doc.replace(regExp, function(match, linkParam) {
    if(constVariables[linkParam.toLowerCase()]){
      return constVariables[linkParam.toLowerCase()]
    }
    else{
      return ''
    }
  });

  return content
}

export const getMD = (doc:string,usersSelectedLanguage:string) => {
  doc = replaceLinkVariables(doc)
  return <Markdown 
  rehypePlugins={[rehypeRaw,remarkGfm]}
    components={{
      p: ({children}) => {
        // Check for GitHub-flavored admonitions syntax
        if(typeof (children) === 'string'){
          let childrenLines = children.split('\n')

          if(childrenLines.length > 1 && childrenLines[0] === '[!TIP]'){
            childrenLines.shift()
            return <span className={"block pt-4 pb-4 pl-5 pr-5 xl:pt-8 xl:pb-8 xl:pl-11 xl:pr-11 bg-blueBaby text-sm m:text-base xl:text-[22px] font-normal mt-8 xl:mt-12"}><span className=" pb-1 xl:pb-3 block">{getIcon({type:'Lamp',ownClassName:' w-5 xl:w-6'})}</span>
              {(childrenLines).map((line, index) => (
                  <React.Fragment key={index}>
                      { !!index && <br/> }
                      { line }
                  </React.Fragment>
              ))}
            </span>
          }
          else if(childrenLines.length > 1 && childrenLines[0].trim().startsWith('[showIf=')){
            let language = childrenLines[0].split('=')[1]?.replace(']','').trim()
            if(usersSelectedLanguage === language){
              childrenLines.shift()
              return <p>
                {(childrenLines).map((line, index) => (
                    <React.Fragment key={index}>
                        {language}
                        { !!index && <br/> }
                        { line }
                    </React.Fragment>
                ))}
              </p>  
            }
            else{
              return null
            }
          }
        }
        return <p>{children}</p>;
      },
      h2:HeadingRenderer,
      h3:HeadingRenderer,
      code(props) {
        const {children, className, node, ...rest} = props
        const match = /language-(\w+)/.exec(className || '')
        return match ? (
          <div className="bg-blueDark group mt-8 xl:mt-12 text-s m:text-sm xl:text-base xxl:text-[22px]">
            <div className="group-hover:visible invisible float-right -ml-10 mt-6 mr-6 cursor-pointer " onClick={()=>{navigator.clipboard.writeText(String(children).replace(/\n$/, ''))}} >{iconCopy}</div>
            <SyntaxHighlighter
              {...rest}
              PreTag="div"
              children={String(children).replace(/\n$/, '')}
              language={match[1]}
              style={theme}
            />
          </div>
        ) : (
          <code {...rest} className={className}>
            {children}
          </code>
        )
      }
    }}
    >{doc}</Markdown>
}

export const getSidebar = (doc:string) => {

  return <div><div className="block text-[24px] font-bold mb-16">{I18n.get('TableOfContents')}</div><Markdown 
  allowedElements={['h2']}
  components={{
      h2:HeadingSidebarRenderer,
    }}
    >{doc}</Markdown></div>
}

export const Breadcrumb = ({  children }) => {
  return (
    <div className=" pt-7 xxl:pt-14 pb-11 xxl:pb-14 text-white flex items-center text-s xl:text-[22px] font-normal">
    {(children).map((crumb, index) => (
        <React.Fragment key={index}>
            { !!index && <div className=" w-3 xl:w-5 xl:h-4 ml-2 mr-2 xl:ml-5 xl:mr-5">{BreadCrumbSeperator}</div> }
            { crumb }
        </React.Fragment>
    ))}
  </div>
  )
};

export const removeMdMetaData = (mdContent:string) => {
  const splittedContent = mdContent.split('---')
  return splittedContent[splittedContent.length-1]
}

export const getDocsLanguageString = (language:string|undefined,texts:languagStrings) => {
  if(!language){ 
    return texts.en
  }
  else{
    return texts[language]
  }
}