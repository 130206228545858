import React, { useState } from 'react'
import { useEffect } from 'react'
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { getLangByLocalStorage } from '../../utils/helpers'
import { Tooltip } from 'antd'
import { LinkOutlined,CheckCircleFilled } from '@ant-design/icons'

const CopyToClipboard = (props: { classNames:string, textToCopy:string, label:string }) => {

  const [titleCopied, setTitleCopied] = useState<boolean>(false)

  useEffect(() => {
    I18n.putVocabularies(stringGlobal);
    I18n.setLanguage(getLangByLocalStorage())
  }, []);

  return (
    <Tooltip placement="top" color={'white'} title={titleCopied?I18n.get('Copied'):props.label} onOpenChange={()=>{setTitleCopied(false)}} >
        { titleCopied   ? 
            <CheckCircleFilled className={props.classNames} style={{lineHeight:0}}  />
            :
            <LinkOutlined className={props.classNames} onClick={() => {navigator.clipboard.writeText(props.textToCopy);setTitleCopied(true)}} />
            }
    </Tooltip>
  );
};

export default CopyToClipboard;
