
import { Modal, Spin } from 'antd';
import React, { useState } from 'react';

const ModalDialogRename = (props:{setActualChannelName:Function}) => {

    const [isSaving,setIsSaving] = useState<boolean>(true)

    const handleCancelOk = () => {
        props.setActualChannelName(undefined);
    };

    return (
        <Modal title="Das herunterladen wird automatisch gestartet." destroyOnClose={true} open={true} onOk={handleCancelOk} onCancel={handleCancelOk} footer={[]} maskClosable={false}>
            <Spin size="large" spinning={isSaving} >
                <div className="pb-4">Zip Datei wird vorbereitet!</div>
            </Spin>
        </Modal>
    );
};

export default ModalDialogRename;
