import { I18n } from '@aws-amplify/core';
import { useEffect, useState } from 'react';
import React from 'react';
import { useGlobalState } from '../../utils/globalStateProvider';
import { appLinkFor, getAuth0Token, isErrResponseType, langChange, langInit } from '../../utils/helpers';
import { strings as stringLocal} from './../utils/globalLocalization';
import { getHeadLine, widgetTypeOptions } from './Widgets';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';
const colorsTheme = require('../../../src/utils/aufzugheldenTheme');
import { ContractStatistics } from "../../interfaces/ContractStatistics";
import { useAuth0 } from '@auth0/auth0-react';
import { fetchContractStatistics } from '../utils/helpersFetch';
import { Spin, Tooltip } from 'antd';
import { ContractFilterEnum } from '../../interfaces/ContractFilters.ts';

export default function WelcomeWidgetContracts(props: widgetTypeOptions) {
  const { state } = useGlobalState();
  const { getAccessTokenSilently } = useAuth0();
  const [options, setOptions] = useState<any>({});
  const [smallerFontSizesByLevel, setSmallerFontSizesByLevel] = useState<number>(0)

  const [contractStatistics, setContractStatistics] = useState<null | ContractStatistics>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);

    const token = await getAuth0Token(getAccessTokenSilently)
    const contractStatisticsResult = await fetchContractStatistics(token);

    if (typeof contractStatisticsResult === 'undefined' || isErrResponseType(contractStatisticsResult)) {
      setContractStatistics(null);
    } else {
      setContractStatistics(contractStatisticsResult);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (state.language) { langChange({ state: state }); } else { langInit({ stringLocal }); }
  }, [state.language]);

  useEffect(() => {

    let data:{ value: number; name: string; }[] = []

    if(contractStatistics){ 
      data = [
        { value: contractStatistics.active_contract_count, name: '' },
        { value: contractStatistics.contract_need_signature_count, name: '' },
        { value: contractStatistics.expired_contract_count, name: '' },
      ]  
    }

    setOptions({
      color: [colorsTheme.blueLight, colorsTheme.blueLight2, colorsTheme.blueBaby],
      colorBy: 'series',
      series: [
        {
          name: 'Contracts',
          type: 'pie',
          cursor:'auto',
          radius: ['77%', '95%'],
          avoidLabelOverlap: false,
          hoverAnimation:false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          data: data
        }
      ],
    });

  }, [contractStatistics]);

  useEffect(() => {
    fetchData();
    //setContractStatistics({active_contract_count:9999,contract_count:9999,contract_need_signature_count:9999,expired_contract_count:9999});
  }, []);

  const redirectToContractsWithFilter = (contractFilter : ContractFilterEnum) => {
    document.location.href = appLinkFor('Contracts', state, 'welcome', {filterBy: contractFilter})
  }

  useEffect(() => {
    let tmpLevelNumber:number = 0

    if(contractStatistics){
      if(contractStatistics.active_contract_count > 9){
        tmpLevelNumber = 1
      }
      else if(contractStatistics.contract_need_signature_count > 9){
        tmpLevelNumber = 1
      }
      else if(contractStatistics.expired_contract_count > 9){
        tmpLevelNumber = 1
      }

      if(contractStatistics.active_contract_count > 999){
        tmpLevelNumber = 2
      }
      else if(contractStatistics.contract_need_signature_count > 999){
        tmpLevelNumber = 2
      }
      else if(contractStatistics.expired_contract_count > 999){
        tmpLevelNumber = 2
      }

      if(contractStatistics.active_contract_count > 9999){
        tmpLevelNumber = 3
      }
      else if(contractStatistics.contract_need_signature_count > 9999){
        tmpLevelNumber = 3
      }
      else if(contractStatistics.expired_contract_count > 9999){
        tmpLevelNumber = 3
      }

    }
    setSmallerFontSizesByLevel(tmpLevelNumber)
  }, [contractStatistics])

  const getFormatedValue = (dynamicClassNamesList:string[], fixClassNames:string,value:number|undefined|null) => {
    let dynamicClassNames = ''
    if(dynamicClassNamesList[smallerFontSizesByLevel]){
      dynamicClassNames = dynamicClassNamesList[smallerFontSizesByLevel]
    }
    else{
      dynamicClassNames = dynamicClassNamesList.slice(-1)[0]
    }
    if(value === undefined || value === null){
      return <div className={dynamicClassNames + ' ' + fixClassNames}>-</div>
    }else{
      return <div className={dynamicClassNames + ' ' + fixClassNames}>{(value >= 0) ? value : '-'}</div>
    }
}

  return (
    <>
      {/* mobile to :l  */}
      <div data-testid="test_contract_widget" className={"cp_contract_widget flex xl:hidden  " + (props.presentAsWidget !== false ? props.colSpan : '')}>
        <Spin size="default" spinning={isLoading} key="spin">
          <div className="bg-white flex-col justify-end flex h-full">
            <div>{getHeadLine({ headline: I18n.get('HeadlineContracts'), type: 'Documents', link:'Contracts', state:state })}</div>
            <div className=" flex-grow flex flex-col items-center justify-center ">
              <div className="w-full h-full pl-8 pr-8 grid">
                <div className=" w-full h-full flex justify-center items-center" style={{ gridColumn: 1, gridRow: 1 }}>
                  <div className=" w-[45%] rounded-full bg-greyLighter after:content-[''] after:block after:pb-[100%]"></div>
                </div>
                <div className=" w-full h-full flex justify-center items-center" style={{ gridColumn: 1, gridRow: 1 }}>
                {getFormatedValue(
                  [
                    "text-[70px] leading-[70px]",
                    "text-[70px] leading-[70px]",
                    "text-[70px] leading-[70px]",
                    "text-[50px] leading-[50px]",
                  ]
                  , "w-1/2 font-medium  after:content-[''] after:block after:pb-[100%] flex justify-center items-center -mt-4" ,contractStatistics?.contract_count)}
                </div>
                <div className=" w-[99%] h-full min-h-[120px] mt-12 mb-12" style={{ gridColumn: 1, gridRow: 1 }}>
                  <ReactEChartsCore
                    key="chartTurnchart"
                    echarts={echarts}
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={'light'}
                    showLoading={false}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              </div>
            </div>
            <div className="flex min-h-[115px] p-[23px] pt-0">
              <div className=" grid grid-cols-3 gap-8 w-full">
                <div className="flex flex-col justify-between cursor-pointer"
                onClick={() => redirectToContractsWithFilter(ContractFilterEnum.ContractActive)}>
                  <div className="bg-blueLight h-2 mb-4"></div>
                  <div className="flex-grow break-words">{I18n.get('ContractActive')}</div>
                  {getFormatedValue(
                  [
                    "text-[50px] leading-[50px]",
                    "text-[45px] leading-[45px]",
                    "text-[35px] leading-[35px]",
                    "text-[27px] leading-[27px]",
                  ]
                  , "" ,contractStatistics?.active_contract_count)}
                </div>
                <div className="flex flex-col justify-between cursor-pointer"
                onClick={() => redirectToContractsWithFilter(ContractFilterEnum.ContractExpired)}>
                  <div className="bg-blueLight2 h-2 mb-4"></div>
                  <div className="flex-grow break-words">{I18n.get('ContractExpired')}</div>
                  {getFormatedValue(
                  [
                    "text-[50px] leading-[50px]",
                    "text-[45px] leading-[45px]",
                    "text-[35px] leading-[35px]",
                    "text-[27px] leading-[27px]",
                  ]
                  , "" ,contractStatistics?.expired_contract_count)}
                </div>
                <div className="flex flex-col justify-between cursor-pointer hidden"
                onClick={() => redirectToContractsWithFilter(ContractFilterEnum.ContractSignNeeded)}>
                  <div className="bg-blueBaby h-2 mb-4"></div>
                  <div className="flex-grow break-words">{I18n.get('ContractSignNeeded')}</div>
                  {getFormatedValue(
                  [
                    "text-[50px] leading-[50px]",
                    "text-[45px] leading-[45px]",
                    "text-[35px] leading-[35px]",
                    "text-[27px] leading-[27px] mt-4",
                  ]
                  , "" ,contractStatistics?.contract_need_signature_count)}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
      {/* dektop from :l  */}
      <div data-testid="test_contract_widget" className={"cp_contract_widget hidden xl:block " + (props.presentAsWidget !== false ? props.colSpan : '')}>
        <Spin size="default" spinning={isLoading} key="spin">
          <div className="flex bg-white flex-row justify-end h-full">
            <div className="flex w-1/2 flex-col justify-between">
              <div>{getHeadLine({ headline: I18n.get('HeadlineContracts'), type: 'Documents', link:'Contracts', state:state })}</div>
              <div className="flex min-h-[115px] p-[23px] pt-0">
                <div className=" grid grid-cols-3 gap-8 w-full">
                  <div className="flex flex-col justify-between cursor-pointer"
                  onClick={() => redirectToContractsWithFilter(ContractFilterEnum.ContractActive)}>
                    <div className="bg-blueLight h-2 mb-4"></div>
                    <div className="flex-grow break-words">{I18n.get('ContractActive')}</div>
                    {getFormatedValue(
                    [
                      " text-[62px] leading-[67px]",
                      " text-[36px] leading-[36px] mt-4",
                      " text-[28px] leading-[28px] mt-4",
                      " text-[22px] leading-[22px] mt-6"
                    ]
                    , "" ,contractStatistics?.active_contract_count)}
                  </div>
                  <div className="flex flex-col justify-between cursor-pointer"
                  onClick={() => redirectToContractsWithFilter(ContractFilterEnum.ContractExpired)}>
                    <div className="bg-blueLight2 h-2 mb-4"></div>
                    <div className="flex-grow break-words">{I18n.get('ContractExpired')}</div>
                    {getFormatedValue(
                    [
                      " text-[62px] leading-[67px]",
                      " text-[36px] leading-[36px] mt-4",
                      " text-[28px] leading-[28px] mt-4",
                      " text-[22px] leading-[22px] mt-6"
                    ]
                    , "" ,contractStatistics?.expired_contract_count)}
                  </div>
                  <div className="flex flex-col justify-between cursor-pointer hidden"
                  onClick={() => redirectToContractsWithFilter(ContractFilterEnum.ContractSignNeeded)}>
                    <div className="bg-blueBaby h-2 mb-4"></div>
                    <div className="flex-grow break-words">{I18n.get('ContractSignNeeded')}</div>
                    {getFormatedValue(
                    [
                      " text-[62px] leading-[67px]",
                      " text-[36px] leading-[36px] mt-4",
                      " text-[28px] leading-[28px] mt-4",
                      " text-[22px] leading-[22px] mt-6"
                    ]
                    , "" ,contractStatistics?.contract_need_signature_count)}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex-grow flex flex-col items-center justify-center ">
              <div className="w-full h-full pl-8 pr-8 grid">
                <div className=" w-full h-full flex justify-center items-center" style={{ gridColumn: 1, gridRow: 1 }}>
                  <div className=" w-[45%] rounded-full bg-greyLighter after:content-[''] after:block after:pb-[100%]"></div>
                </div>
                <div className=" w-full h-full flex justify-center items-center" style={{ gridColumn: 1, gridRow: 1 }}>
                {getFormatedValue(
                  [
                    "text-[70px] leading-[70px]",
                    "text-[70px] leading-[70px]",
                    "text-[70px] leading-[70px]",
                    "text-[50px] leading-[50px]",
                  ]
                  , "w-1/2 font-medium after:content-[''] after:block after:pb-[100%] flex justify-center items-center -mt-4" ,contractStatistics?.contract_count)}
                </div>
                <div className=" w-[99%] h-full min-h-[120px] mt-12 mb-12" style={{ gridColumn: 1, gridRow: 1 }}>
                  <ReactEChartsCore
                    key="chartTurnchart"
                    echarts={echarts}
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={'light'}
                    showLoading={false}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </>);
}