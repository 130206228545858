module.exports = {
    black : '#000',
    white : '#ffffff',
    blueLight: '#406CF6',
    blueLight2: '#8BA5F8',
    blueBaby: '#D8E0FC',
    blueDark: '#283769',
    blueActive: '#2F4791',
    greyLight: '#969696',
    greyLighter: '#F2F4F7',
    greyFooterBottom: '#9BA8C0',
    greenInfo: '#E1F9EB',
    warnRed: '#FE4365',
    warnOrange: '#FC913A',
    warnYellow: '#FCC03A',
    green: '#52C07A',
    disabledButton: '#cccccc',
    inactiveButton: '#F1F3F4',
    chartColors: ['#FF0066','#FFFF05','#0099CC','#99CC00','#CC6699','#05FFC9','#996633','#663399','#339966','#9305FF'],
    chartMainColor: '#4a6aec',
    chartLineColor: '#4a6aec',
    chartLineDotsColor: '#00265E',
    chartTooltipBackground: '#FE4365',
    chartTooltipTextColor: '#fff',
    chartBackgroundLineColor: '#C3E6FC',
    chartBackgroundLineOpacity: 0.3,
    doorColors: ['#4a6aec', '#91a5f3','#dae1fb'],
    doorBlurColors: ['grey', 'grey','grey'],
    doorBlurOpacity: [0.6, 0.4, 0.2],
    logo: '../assets/images/icon_folderOpener_white.svg'
}
