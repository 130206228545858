export enum IncidentFilterEnum {
    Open = "open",
    RecentlyClosed = "recentlyClosed",
  }
  
  export type IncidentFilter = IncidentFilterEnum | ''
  
  export const IncidentFilterData: IncidentFilterEnum[] = [
    IncidentFilterEnum.Open,
    IncidentFilterEnum.RecentlyClosed,
  ];
  