import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spin } from 'antd';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import { BarChart } from 'echarts/charts';

import { useEffect, useState } from 'react';
import { RunsOverTimeResponse } from '../../../interfaces/RunsOverTime';
const colorsTheme = require('../../../../src/utils/aufzugheldenTheme')
import { Installation } from '../../../interfaces/Installation';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../../utils/globalStateProvider';
import { strings as stringGlobal } from '../../../utils/globalLocalization';
import React from 'react';

const TurnsChartSum = (props: { response: RunsOverTimeResponse[]; installations: Installation[] | undefined ; isLoading: boolean }) => {
  const [noData, setNoData] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const { state, setState } = useGlobalState()

  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language]);  

  useEffect(() => {
    I18n.putVocabularies(stringGlobal);
  }, []);

  useEffect(() => {
    const dataTmp:any[] = [];
    if(!props.response || props.response.length === 0 || props.installations?.length === 0){
      setNoData(true);
      return
    }

    if(Array.isArray(props.response)){

      props.response.map((singleResponse,index)=>{
        let localDataTmpl = 0;
          if(Array.isArray(singleResponse)){
            for (const item of singleResponse) {
              localDataTmpl += item.runcount
            }
        }
        if(props.installations && props.installations[index] && props.installations[index].label){
          dataTmp.push({name:props.installations[index].label,value:localDataTmpl,color:colorsTheme.chartColors[index]})  
        }
        
      })
    }

    dataTmp.sort((a, b) => a.value < b.value ? 1 : -1)
    setData(dataTmp);

    if (dataTmp.length > 0) {
      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [props.response]);

  echarts.use([
    BarChart,
  ]);

  echarts.registerTheme('light', {
    backgroundColor: '#fff',
  });

  const option = {
    grid: {
      left: '1%',
      right: '1%',
      bottom: '15%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'item',
      backgroundColor:colorsTheme.chartTooltipBackground,
      borderWidth:0,
      textStyle:{
        color:colorsTheme.chartTooltipTextColor
      },
      formatter: function (params: any) {
        return I18n.get('runs') + ': ' + params.value + '<br/>'+params.seriesName;
      }
    },
    legend: {
      bottom: '0%',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine:{
        show:false,
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '0%'],
      splitLine:{
        show:true,
        lineStyle:{
          color:colorsTheme.chartBackgroundLineColor,
          opacity: colorsTheme.chartBackgroundLineOpacity
        }
      },
      axisLabel: {
        formatter: (value:any)=>{ return value.toLocaleString('de-DE')} 
    },
    },
    series: data.map((element) => {
      return {data : [element.value], 
      name:element.name,
      type: 'bar',
      itemStyle: {
        color: element.color
      }
    }}),
  };

  return (
    <Spin size="large" spinning={props.isLoading} key="spin">
      {noData && (
        <div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight">{I18n.get('noDataForTimePeriod')}</div>
        </div>
      )}
      {!noData && (
        
          <ReactEChartsCore
            key="chartTurnchart"
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            theme={'light'}
            showLoading={false}
            style={{ height: '300px', width: '100%' }}
          />
        
      )}
    </Spin>
  );
};
export default TurnsChartSum;
