import React, { useEffect, useRef, useState, } from 'react'
import { strings as stringLocal} from './NewsUpdateItemLocalization'
import { I18n } from '@aws-amplify/core'
import {  useGlobalState } from '../../utils/globalStateProvider'
import { useNavigate } from 'react-router-dom'
import { langChange, langInit, useContainerDimensions } from '../../utils/helpers'
import { iNewsOrUpdataElement } from "../interfaces/NewsOrUpdate"
import moment from 'moment';
import { getDocsLanguageString } from '../utils/helpers';
import { app_url_learn } from '../../utils/consts'

const NewsUpdateItem = (props:{newsUpdateITem:iNewsOrUpdataElement}) => {
  
  const { state, setState } = useGlobalState()
  const navigate = useNavigate()
  const componentRef = useRef<HTMLHeadingElement>(null)
  const { width, height } = useContainerDimensions(componentRef)
  const [ putTagOnTop, setPutTagOnTop ] = useState<boolean>(false)
  const [ container, setContainer ] = useState<string>('')
  const [ containerRow1, setContainerRow1 ] = useState<string>('')
  const [ containerRow2, setContainerRow2 ] = useState<string>('')
  const [ putDateOnTop, setPutDateOnTop ] = useState<boolean>(false)
  
  moment.locale(state.language)

  useEffect(() => {
    if(state.language){ langChange({state:state,updateDocumentTitle:true}) } else { langInit( {stringLocal:stringLocal,setStateGlobal:setState} )}
  }, [state.language]);

  const getType = (updateItem:iNewsOrUpdataElement) => {
    let classNames = " w-24 m:w-28 w-32 h-8 m:h-9 xl:h-10 rounded-[43px] border-2 inline-flex text-s m:text-sm xl:text-base items-center justify-center"
    if(updateItem.type === 'news'){
      return <div className={classNames + " border-blueLight "}>{I18n.get('News')}</div>
    }
    else if(updateItem.type === 'update'){
      return <div className={classNames + " border-green "}>{I18n.get('Update')}</div>
    }
  }

  useEffect(() => {
    if(width < 600){
      setPutTagOnTop(true)
    }else{
      setPutTagOnTop(false)
    }
  }, [width]);

  useEffect(() => {
    if(putTagOnTop){
      setContainer("flex flex-col mb-10 pb-10 border-b-2 border-blueLight2 cursor-pointer")
      setContainerRow1("mb-3 flex justify-between")
      setContainerRow2("")
      setPutDateOnTop(true)
    }
    else{
      setContainer("flex flex-row mb-10 pb-10 border-b-2 border-blueLight2 cursor-pointer")
      setContainerRow1("w-3/12 mb-3 xl:mb-0 mt-2")
      setContainerRow2("w-9/12")
      setPutDateOnTop(false)
    }
  }, [putTagOnTop]);

  const goToDetailpage = () => {
    if(location.origin === app_url_learn){
      navigate(`/news-and-updates/${props.newsUpdateITem.id}`)
    }
    else{
      window.location.href = app_url_learn+`/news-and-updates/${props.newsUpdateITem.id}?lang=${state.language}`
    }
  }

  const getDateFormatByTime = (timestring:string) => {
    if(moment().diff(timestring, "days") > 28 ){
      return moment(props.newsUpdateITem.date).format('DD.MM.YYYY')
    }
    else{
      return moment.duration( moment(  props.newsUpdateITem.date ).diff( moment.utc()  ) ).humanize(true)
    }
  }

  return (
    <div data-testid="test_news_item" ref={componentRef} className={container} onClick={ () => goToDetailpage() }>
      <div className={containerRow1}>
        <div>{getType(props.newsUpdateITem)}</div>
        {putDateOnTop && <div className=" -mt-1 text-sm m:text-l content-center ">{getDateFormatByTime(props.newsUpdateITem.date)}</div>}
      </div>
      <div className={containerRow2}>
        <div className=" text-base m:text-lg xl:text-[24px] font-bold">{ getDocsLanguageString( state.language , props.newsUpdateITem.title)}</div>
        <div className="flex text-sm m:text-l xl:text-[22px] font-normal">
          {!putDateOnTop && <div>{getDateFormatByTime(props.newsUpdateITem.date)}</div>}
          {props.newsUpdateITem.tag && <div className=" ml-4 hidden">{props.newsUpdateITem.tag}</div>}
        </div>
      </div>
    </div>
  );
};

export default NewsUpdateItem