export const strings = {
    en: {
      conpareElevators : 'Compare Elevators',
      compareIntroducingPart1 : 'Choose at least two elevators to start a comparison.',
      compareIntroducingPart2: 'You can display up to ten elevators at a time.',
      of: 'of',
      compare : 'What is to be compared?',
      compareFeature1 : 'Runs per elevator',
      compareFeature2 : 'Total runs',
      compareFeature3 : 'Door cycles',
      compareFeature4 : 'Door blockages',
      pleaseChooseCriterias : 'Please select one to four comparison criteria',
      runsOverTime : 'Runs per elevator',
      sublineRunsOverTime : 'Time-based comparison of trips',
      runsPerElevator : 'Total runs',
      sublineRunsPerElevator : 'Total comparison of runs',
      sublineDoorcycledOverTime : 'Time-based comparison of door cycles',
      doorcycledOverTime : 'Door cycles',
      doorBlockages : 'Door blockages',
      sublineDoorBlockages : 'Time-based comparison of door blockages',
    },
    de: {
      conpareElevators : 'Aufzüge vergleichen',
      compareIntroducingPart1 : 'Wähle mindestens zwei Aufzüge, um einen Vergleich starten zu können.',
      compareIntroducingPart2: 'Du kannst bis zu zehn Aufzüge gleichzeitig gegenüberstellen.',
      of: 'von',
      compare : 'Was soll verglichen werden?',
      compareFeature1 : 'Fahrten pro Aufzug',
      compareFeature2 : 'Fahrten',
      compareFeature3 : 'Türzyklen',
      compareFeature4 : 'Türblockierungen',
      pleaseChooseCriterias : 'Bitte wählen Sie eine bis vier Vergleichskriterien',
      runsOverTime : 'Fahrten pro Aufzug',
      sublineRunsOverTime : 'Zeitbasierter Vergleich der Fahrten',
      runsPerElevator : 'Fahrten',
      sublineRunsPerElevator : 'Abslouter Vergleich der Fahrten',
      sublineDoorcycledOverTime : 'Zeitbasierter Vergleich der Türzyklen',
      doorcycledOverTime : 'Türzyklen',
      doorBlockages : 'Türblockierungen',
      sublineDoorBlockages : 'Zeitbasierter Vergleich der Türblockierungen',
    },
  };