import React, { useEffect } from 'react'
const colorsTheme = require('../../src/utils/aufzugheldenTheme')
import { getIcon } from '../utils/icons'

export default function OverlayWrapper(props:{closerFunction:Function,title:string,children}) {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
    document.body?.classList.add('modalOpen')

    return () => {
      document.body?.classList.remove('modalOpen')
    };

  }, []);
  
  return (
    <div>
      <div className=" absolute top-0 left-0 m:hidden  w-full bg-white z-40 ">
        <div className="  w-full overflow-hidden m-0 p-0 h-16  flex items-center fixed top-0 z-10 bg-white ">
          <h1 className="grow text-center">{props.title}</h1>
          <div className="cursor-pointer" onClick={()=> {props.closerFunction()}} >{getIcon({type:'Closer',ownClassName:'fill-blueDark h-6 mr-4'})}</div>
        </div>
        <div className="p-4 overflow-scroll pt-20 h-[100vh]  ">
          {props.children}
        </div>
      </div>
    </div>
  )
}
