import 'antd/dist/antd.min.css';
import './index.css';
import ReactDOM from 'react-dom/client';
import AppDevices from './devices/App';
import AppDocuments from './documents/App';
import AppContracts from './contracts/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import React from 'react';
import { APPLICATION,auth0_domain,auth0_clientId,auth0_audience,gtm_id,ga4_id, SENTRY_DSN, PUBLIC_POSTHOG_KEY, PUBLIC_POSTHOG_HOST } from './utils/consts';
import { fetchOrganizationIdentifier } from './utils/helpersFetch';
import TagManager from 'react-gtm-module'
import ReactGA from "react-ga4";
import { isSalesforceView } from './documents/utils/helpers';
import AppPortal from './portal/App';
import AppLearn from './learn/App';
import AppIncidents from './incidents/App';
import * as Sentry from "@sentry/react";
import { isTestEnv } from './utils/helpers';
import posthog from 'posthog-js';

if(SENTRY_DSN){
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: isTestEnv()?"Test":"Prod",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [`https://${APPLICATION}.aufzughelden.com`, `https://${APPLICATION}.test.aufzughelden.com`],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

localStorage.setItem('onload.window.location.href',window.location.href)
localStorage.setItem('onload.window.location.pathname',window.location.pathname)

var cookieString = document.cookie;
if(gtm_id){
  let tagmanagerParams = {
    gtmId:gtm_id,
    events:{},
    }

    if(cookieString.includes("cookieconsent=True")){
      tagmanagerParams.events={
        event:'cookieconsent'
      }
    }
  TagManager.initialize(tagmanagerParams)
}

if(cookieString.includes("cookieconsent=True") && ga4_id){
  ReactGA.initialize(ga4_id);
}

if(cookieString.includes("cookieconsent=True")){
  posthog.init( 
    PUBLIC_POSTHOG_KEY, 
    { 
      api_host: PUBLIC_POSTHOG_HOST,
      person_profiles:'identified_only'
    }
  ) 
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const onRedirectCallback = (appState: any) => {
  let navTO = appState && appState.returnTo ? appState.returnTo : window.location.pathname
  window.location.href=navTO
};

function RenderApp(organizationIdentifier:string|null){
  root.render(
    /*<React.StrictMode>*/
      <BrowserRouter>
        <Auth0Provider 
        domain={auth0_domain}
        clientId={auth0_clientId}
        useRefreshTokens={false}
        cacheLocation= 'localstorage'
        onRedirectCallback={onRedirectCallback}
          authorizationParams={{
            ...(organizationIdentifier? {organization:organizationIdentifier} : null),
            redirect_uri: window.location.origin+'/callback',
            //prompt:'none',
            ...(auth0_audience? { audience: auth0_audience } : null ),
          }}
          >
          {(APPLICATION === 'devices') && <AppDevices />}
          {(APPLICATION === 'documents') && <AppDocuments />}
          {(APPLICATION === 'contracts') && <AppContracts />}
          {(APPLICATION === 'portal') && <AppPortal />}
          {(APPLICATION === 'learn') && <AppLearn />}
          {(APPLICATION === 'incidents') && <AppIncidents />}   
        </Auth0Provider>
      </BrowserRouter>
    /*</React.StrictMode>*/
  );
}

const  LoadOrganizationIdBeforeReactRenders = async() =>{

  let partsUrl = window.location.pathname.split('/')
  let organizationIdentifier:string|null = null

  if(isSalesforceView()){
  
    try{
      organizationIdentifier = await fetchOrganizationIdentifier('digitalspine')
    }
    catch(error){
      console.log(error)
    }
    RenderApp(organizationIdentifier)
  }
  else if(partsUrl[1] && partsUrl[1].indexOf('org_') === 0 ){
    let organizationName = partsUrl[1].substring(4)
    try{
      organizationIdentifier = await fetchOrganizationIdentifier(organizationName)
    }
    catch(error){
      console.log(error)
    }
    RenderApp(organizationIdentifier)
  }
  else{
    RenderApp(null)
  }
}

LoadOrganizationIdBeforeReactRenders()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
