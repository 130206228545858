export enum DeviceFilterEnum {
    All = "All",
    Operational = "Operational",
    IssueDetected = "IssueDetected",
    AttentionNeeded  = "AttentionNeeded"
}

export type DeviceFilter = DeviceFilterEnum | ''

export const deviceFilterData: DeviceFilterEnum[] = [
    DeviceFilterEnum.All,
    DeviceFilterEnum.Operational,
    DeviceFilterEnum.IssueDetected,
    DeviceFilterEnum.AttentionNeeded
];