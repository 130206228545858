import { I18n } from "@aws-amplify/core";
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { getLangByLocalStorage } from "../../utils/helpers";

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export const getTransformedIncidentStatus = (status: string) => {
    if(status === 'Closed' || status.toLocaleLowerCase().indexOf('done') > -1){
        return 'Done'
    }
    else{
        return 'In Progress'
    }
}