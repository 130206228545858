import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { strings as stringLocal } from '../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Pagination, Spin, Tooltip } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { GlobalStateInterface, useGlobalState } from "../../../utils/globalStateProvider"
import { useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../../utils/globalStateProviderApp'
import TableMain, { TableProps } from '../../../components/molecules/TableMain'
import { mergeDeepRight } from 'ramda';
import { fetchCases } from '../../../utils/helpersFetch'
import { Insight } from "../../../interfaces/Insights";
import { getIconInsights } from '../../../utils/iconsInsights'
import { DeviceInfo } from '../../../interfaces/DeviceInfo'
import { getAuth0Token, getLangByLocalStorage } from '../../../utils/helpers'


I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
const lang = getLangByLocalStorage()
I18n.setLanguage(lang)

moment.locale(lang ? lang : 'de');

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface PrevRef {
  searchTerm: string | undefined
  sortBy: string | undefined
}

const TableInsights = (props: {device:DeviceInfo}) => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();

  const query = useQuery();

  const [totalNumberOfIncidents, setTotalNumberOfIncidents] = React.useState<number>(0)
  const topTableElementRef = useRef<null | HTMLTableElement>(null);
  const [tableProps, setTableProps] = useState<TableProps | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [activePage,setActivePage] = React.useState<number>(1)

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [state.language]);

  useEffect(() => {
    BuildItemList()
    setTotalNumberOfIncidents(stateApp.insightsCount ? stateApp.insightsCount : 0)

  }, [state.language, stateApp.insights,stateApp.insightsCount]);

  useEffect(() => {
      loadSignals();
  }, [activePage]);

  async function loadSignals() {
    try {
      setIsLoading(true);
      const accessToken = await getAuth0Token(getAccessTokenSilently);
      let pagedIncidents = await fetchCases(activePage, 5, '', '', props.device.deviceId , accessToken)

      let dataGlobal: Partial<GlobalStateInterfaceApp> = {
        insights: pagedIncidents.insights,
        insightsCount: pagedIncidents.totalCount,
       }

      setStateApp((prev: any) => ({ ...prev, ...dataGlobal }));
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching incidents:', error.message);
      // Handle the error here if needed
    } finally {
      setIsLoading(false);
    }
  }

  const BuildItemList = () => {
    let tableP: TableProps = { th: [], tr: [], noDataLabel: I18n.get('NoIncidentsFound'), height: 'unset', thead: 'sticky-documents-detail' }

    tableP.th = [
      {
        text: <>{I18n.get('Date')}</>,
        class: 'flex w-4/12 m:w-2/12 items-center l:items-end',
        ellipsis: false
      },
      {
        text: <>{I18n.get('SignalType')}</>,
        class: 'flex w-1/12 m:w-2/12 items-center l:items-end',
        twoLinesEllipses: true
      },
      {
        text: <>{I18n.get('Description')}</>,
        class: 'flex w-7/12 m:w-8/12 items-center l:items-end',
        twoLinesEllipses: true
      },
      // desktop & mobile
      //{ text: <></>, class: 'w-1/12 l:flex ' }
    ]

    if (stateApp.insights) {
      tableP.tr = stateApp.insights.map((itemSignal, index) => {
        let dataRow: TableProps["tr"][0] = {
          cells:
            [
              { text: <>{ (itemSignal.updatedAt?moment(itemSignal.updatedAt).format('DD.MM.YYYY'):'' ) }</>, textPlain: (itemSignal.updatedAt?moment(itemSignal.updatedAt).format('DD.MM.YYYY'):'' ), class: 'flex text-left items-center w-4/12 m:w-2/12 hover:cursor-default' },
              { text: <>
               <div className=" m:hidden"><Tooltip title={I18n.get('InsightTypes_Message_'+itemSignal.classification_type)} color={'white'}>
                  <div className="flex items-center">
                    {getIconInsights( { type:itemSignal.classification_type,ownClassName:'fill-blueDark w-9 min-w-[36px] pt-1 pr-3'} )}
                  </div>
                </Tooltip></div>
                <div className="hidden m:block"><Tooltip title={I18n.get('InsightTypes_Message_'+itemSignal.classification_type)} color={'white'}>
                  <div className="flex items-center">
                    {getIconInsights( { type:itemSignal.classification_type,ownClassName:'fill-blueDark w-9 min-w-[36px] pt-1 pr-3'} )}
                    { I18n.get('InsightTypes_'+itemSignal.classification_type) }
                  </div>
                </Tooltip></div>
                </>
                 , textPlain: I18n.get('InsightTypes_'+itemSignal.classification_type), class: 'flex text-left items-center w-1/12 m:w-2/12 hover:cursor-default' },
              { text: <>{itemSignal.description}</>, textPlain: itemSignal.description, class: 'flex text-left items-center w-7/12 m:w-8/12 hover:cursor-default break-all s:break-normal' },
              //{ text: <><svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></>, class: 'w-1/12 justify-center hover:cursor-pointer' }
            ],
          trOnClick: (e) => { onRowClicked(itemSignal) }
        }

        return dataRow
      })
    }

    setTableProps(tableP);
  }

  const onRowClicked = (itemSignal: Insight) => {
    return false
  }

  if(stateApp && stateApp.insightsCount && stateApp.insightsCount > 0){
    return (
      <>
        <section className="Content pt-[102px] xxl:pt-48 ">
          <div className="pb-14 ">
            <h1>{I18n.get('Insights')}</h1>
            <div className="idTableContracts flex flex-col xxl:mb-24 pt-8 m:pt-16" ref={topTableElementRef}>
            <Spin spinning={isLoading}>
              <TableMain tableContent={tableProps} />
              <div className="h-24 bg-blueBaby flex justify-center items-center">
                <Pagination current={activePage} pageSize={5} showSizeChanger={false} total={totalNumberOfIncidents} onChange={(page, pageSize) => {
                  setActivePage(page)
                  window.scrollTo({
                    top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 90) : 0,
                    behavior: 'smooth',
                  });
                }} />
              </div>
            </Spin>
          </div>
          </div>
        </section>
      </>
    );  

  }
  else{
    return <div className="mt-16 m:mt-28 xxl:mt-44"></div>
  }

};

export default TableInsights;
