import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spin } from 'antd';
import { strings as stringGlobal } from './../../../utils/globalLocalization'
const colorsTheme = require('../../../../src/utils/aufzugheldenTheme')

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import { LineChart } from 'echarts/charts';
// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { getLangByLocalStorage, getSiPrefixedNumber } from '../../../utils/helpers';
import React from 'react';
import { EnergyConsumption, EnergyConsumptionResponse } from '../../../interfaces/EnergyConsumption';
import { useGlobalState } from '../../../utils/globalStateProvider';

const EnergyChart = (props: { response: EnergyConsumptionResponse|null; isLoading: boolean; visualizePerHours: boolean }) => {
  const [noData, setNoData] = useState(false)
  const [noDataAtBeginningOfSelectedTimePeriod, setNoDataAtBeginningOfSelectedTimePeriod] = useState(false)
  const [data, setData] = useState< (number |any)[]>([])
  const [dataX, setDataX] = useState<(string)[]>([])
  const [timeFormat, setTimeFormat] = useState<string>('ddd DD.MM')
  const eChartsRef = React.useRef(null as any)
  const [chartIsReady, setChartIsReady] = useState(false)
  const { state, setState } = useGlobalState()

  useEffect(() => {
    if(props.visualizePerHours){
      setTimeFormat('HH:mm') // hour scale
    }
    else{
      setTimeFormat('ddd DD.MM') // daily scale
    }
  }, [props.visualizePerHours]);

  useEffect(() => {
    if (eChartsRef && eChartsRef.current){
      eChartsRef.current?.getEchartsInstance().setOption(getOption());
    }

  }, [data,dataX,chartIsReady,eChartsRef,timeFormat,props.isLoading,state.language]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (eChartsRef && eChartsRef.current){
        // stop interval and init chart
        setChartIsReady(true)
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const dataTmp:(number | any)[] = []
    const dataTmpX:string[] = []

    if(!props.response || props.response?.data?.length <= 0 || !props.response?.startDate || !props.response?.endDate){
      setNoData(true);
      return
    }

    let lowestKey:null|number = null
    let highestKey:null|number = null
    let lowest:null|number = null
    let highest:null|number = null

    let sourceData:EnergyConsumption[] = []

    let startDate = moment(props.response.startDate)
    let endeDate = moment(props.response.endDate)

    let sourceObject:any = {}
    props.response.data.forEach( (item:EnergyConsumption) => {
      sourceObject[item.timestamp]=item.power_consumption
    })

    let units: moment.unitOfTime.DurationConstructor = 'day';
    if(props.visualizePerHours){
      units = 'hour';
    }
    else{
      units = 'day';
    }

    let nexDatePoint = startDate
    let tempNoDataAtBeginningOfSelectedTimePeriod = false
    let didFoundEnergyData = false

    while(nexDatePoint <= endeDate){
      
      let dayFormat = nexDatePoint.format("YYYY-MM-DDT00:00:00.000")+'Z'
      if(units === 'hour'){
        dayFormat = nexDatePoint.format("YYYY-MM-DDTHH:00:00.000")+'Z'
      }

      if(sourceObject[dayFormat]){
        didFoundEnergyData = true
        sourceData.push({timestamp:dayFormat,power_consumption:sourceObject[dayFormat]})
      }
      else{
        if(!didFoundEnergyData){
          tempNoDataAtBeginningOfSelectedTimePeriod = true
        }
        sourceData.push({timestamp:dayFormat,power_consumption:null})
      }
      nexDatePoint = nexDatePoint.add(1,units)
    }

    setNoDataAtBeginningOfSelectedTimePeriod(tempNoDataAtBeginningOfSelectedTimePeriod)

    // scan for highest & lowest value
    sourceData.forEach((item:EnergyConsumption,index:number) => {
      if(highest === null|| (item.power_consumption && item.power_consumption > highest)){
        highest = item.power_consumption
        highestKey = index
      }
      if(lowest === null || (item.power_consumption && item.power_consumption < lowest)){
        lowest = item.power_consumption
        lowestKey = index
      }
    })    
    
    sourceData.forEach((item:EnergyConsumption,index:number) => {

      let color = colorsTheme.chartMainColor
      if(index === highestKey){
        color = colorsTheme.warnRed
      }
      else if(index === lowestKey){
        color = colorsTheme.green
      }

      dataTmp.push({ 
        value:item.power_consumption,
        itemStyle: {
          color: color
        }
      });
      dataTmpX.push(item.timestamp);
    }) 

    setData(dataTmp);
    setDataX(dataTmpX);

    if (dataTmp.length > 0) {
      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [props.response,props.response?.data,props.response?.startDate,props.response?.endDate,chartIsReady,eChartsRef,timeFormat,props.isLoading,state.language]);

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
    LineChart,
    ToolboxComponent,
    DataZoomComponent,
  ]);

  echarts.registerTheme('light', {
    backgroundColor: '#fff',
  });

  const getOption = () => {return {
    grid: {
      left: '1%',
      right: '1%',
      containLabel: true,
      show:false
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor:colorsTheme.chartTooltipBackground,
      textStyle:{
        color:colorsTheme.chartTooltipTextColor
      },
      borderWidth:0,
      formatter: function (params: any[]) {
        if(props.visualizePerHours){
          return I18n.get('EnergyConsumptionKw') + ': '+
          (params[0].data.value ? getSiPrefixedNumber(params[0].data.value,!props.visualizePerHours) : '-') + '<br/>' + 
          I18n.get('Date') + ': ' + moment(params[0].axisValue).format('DD.MM.YYYY' ) + '<br/>' +
          I18n.get('Time') + ': ' + moment(params[0].axisValue).format('HH:mm' )
        }
        else{
          return I18n.get('EnergyConsumptionKwPerH') + ': '+
          (params[0].data.value ? getSiPrefixedNumber(params[0].data.value,!props.visualizePerHours) : '-') + '<br/>' + 
          I18n.get('Date') + ': ' + moment(params[0].axisValue).format('DD.MM.YYYY' ) + '<br/>'  
        }
      },
    },
    xAxis: {
      type: 'category',
      data: dataX,
      splitLine: { show: false },
      axisLine:{ show:false },
      axisTick:{ show:false },
      axisLabel: {
        hideOverlap: true,
        formatter: function (value:string) {
          return moment(value).format(timeFormat )
        }
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '10%'],
      splitLine:{
        show:true,
        lineStyle:{
          color:colorsTheme.chartBackgroundLineColor,
          opacity: colorsTheme.chartBackgroundLineOpacity
        }
      },
      axisLabel: {
        showMaxLabel: true,
        inside :false,
        hideOverlap: true,
        formatter: function (value:number) {
          let val = parseFloat((value / 1000).toFixed(2)).toString()+' KW';
          if(!props.visualizePerHours){ val += '/h' }
          return val
        }
      },
    },
    series: [
      {
        type: 'bar',
        symbolSize: 5,
        data: data,
        itemStyle: {
          borderWidth: 3,
        },
      },
    ],
  };
}

  return (
    <>
      {noData && (
        <div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight">{I18n.get('noDataForTimePeriod')}</div>
        </div>
      )}
      {!noData && (
        <>
          {noDataAtBeginningOfSelectedTimePeriod && <div className=" flex justify-center">{I18n.get('WeStillCollectData')}</div>}
          <Spin size="large" spinning={props.isLoading} key="spin">
            <ReactEChartsCore
              key="chartTurnchart"
              echarts={echarts}
              option={{}}
              notMerge={true}
              lazyUpdate={true}
              theme={'light'}
              showLoading={false}
              ref={eChartsRef}
              style={{ height: '350px', width: '100%' }}
            />
          </Spin>
        </>
      )}
    </>
  );
};
export default EnergyChart;
