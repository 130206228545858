import React, { createContext, useState, useContext, Dispatch, SetStateAction } from "react";
import { DeviceInfoDictionary } from "../../interfaces/DeviceInfo";
import { Installation, InstallationCoords } from "../../interfaces/Installation";
import { Library } from "./../interfaces/Library";


export interface GlobalStateInterfaceApp {
  search:{
    page:number
    searchterm:string
    sortBy:string
    parametersUpdatedByUrl:boolean
  }
  devices:DeviceInfoDictionary
//  installationsBySearch : InstallationCoords[]
  libraries : Library[]
  librariesCount:number  
  maxLibrariesCount:number
}

const globalStateContext = createContext({
  stateApp: {} as Partial<GlobalStateInterfaceApp>,
  setStateApp: {} as Dispatch<SetStateAction<Partial<GlobalStateInterfaceApp>>>,
});

const GlobalStateProviderApp = ({
  children,
  value = {} as GlobalStateInterfaceApp,
}: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterfaceApp>;
}) => {
  const [stateApp, setStateApp] = useState(value);
  return (
    <globalStateContext.Provider value={{ stateApp, setStateApp }}>
      {children}
    </globalStateContext.Provider>
  );
};

const useGlobalStateApp = () => {
  const context = useContext(globalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

export { GlobalStateProviderApp, useGlobalStateApp };