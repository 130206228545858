import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spin } from 'antd';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import { LineChart } from 'echarts/charts';
// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import { useEffect, useState } from 'react';
import { RunsOverTimeResponse } from '../../../interfaces/RunsOverTime';
const colorsTheme = require('../../../../src/utils/aufzugheldenTheme')
import { Installation } from '../../../interfaces/Installation';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../../utils/globalStateProvider';
import React from 'react';

const TurnsChart = (props: { response: RunsOverTimeResponse[]; installations: Installation[] | undefined ; isLoading: boolean }) => {
  const [noData, setNoData] = useState(false);
  const [data, setData] = useState<(string | number)[][][]>([]);
  const { state, setState } = useGlobalState()
  const [timeFormat, setTimeFormat] = useState<string>('ddd DD.MM');

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [ state.language]);

  useEffect(() => {
    const dataTmp:any[] = [];
    if(!props.response || props.response.length === 0){
      setNoData(true);
      return
    }

    if(Array.isArray(props.response)){

      props.response.map((singleResponse)=>{
        const localDataTmpl:any[] = [];

        if( Array.isArray(singleResponse)){
          for (const item of singleResponse) {
            localDataTmpl.push([item.timestamp, item.runcount]);
          }
          dataTmp.push(localDataTmpl)  
        }
        else{
          dataTmp.push([])  
        }
  
      })

    }

    setData(dataTmp);

    // detect time distance between 2 values
    setTimeFormat('ddd DD.MM') // daily scale
    dataTmp.forEach((dataSeries)=>{
      if(dataSeries[0] && dataSeries[1]){
        if(moment(dataSeries[1][0]).diff(moment(dataSeries[0][0]), 'hours') <= 1){
          setTimeFormat('HH:mm') // hour scale
        }
        return
      }      
    })

    if (dataTmp.length > 0) {
      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [props.response]);

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
    LineChart,
    ToolboxComponent,
    DataZoomComponent,
  ]);

  echarts.registerTheme('light', {
    backgroundColor: '#fff',
  });

  const option = {
    grid: {
      left: '1%',
      right: '1%',
      bottom: '30%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor:colorsTheme.chartTooltipBackground,
      textStyle:{
        color:colorsTheme.chartTooltipTextColor
      },
      borderWidth:0,
      formatter: function (params: any) {
        let returnValue = moment(params[0].data[0]).format(timeFormat)
        params.forEach((series:any)=>{
          let value = series.data[1]? series.data[1] : '-'
          returnValue += '<div class="flex justify-between"><div class="flex"><div class="w-3 h-3 mt-1 mr-2 rounded-full" style="background-color:'+series.color+'"></div><div>' + series.seriesName + '</div></div><div class="ml-3">' + value + '</div></div>'
        })
        return returnValue
      }
    },
    legend: {
      data: props.installations?.map((installation) => {return installation.label}),
      bottom:'0',
    },
    toolbox: {
      feature: {
      },
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      axisLine:{
        show:false,
      },
      axisLabel: {
        hideOverlap: true,
        formatter: function (value:string) {
          return moment(value).format(timeFormat)
        }
      },
      splitLine:{
        show:false
      }
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '0%'],
      splitLine:{
        show:true,
        lineStyle:{
          color:colorsTheme.chartBackgroundLineColor,
          opacity: colorsTheme.chartBackgroundLineOpacity
        }
      }
    },
    series: data.map((dataSeries,index)=>{
      return {
        name: props.installations? props.installations[index]?.label : '',
        type: 'line',
        smooth: false,
        symbol: 'none',
        color: colorsTheme.chartColors[index],
        //areaStyle: {},
        data: dataSeries,
      }
    })
  };

  return (
    <Spin size="large" spinning={props.isLoading} key="spin">
      {noData && (
        <div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight">{I18n.get('noDataForTimePeriod')}</div>
        </div>
      )}
      {!noData && (
      
          <ReactEChartsCore
            key="chartTurnchart"
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            theme={'light'}
            showLoading={false}
            style={{ height: '400px', width: '100%' }}
          />
      
      )}
      </Spin>
  );
};
export default TurnsChart;
