import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const IFramAuthDone = () => {
  const location = useLocation();
  const locationSplitted = location.pathname.split('/')

  useEffect(() => {
    window.opener?.location.reload()
    window.close()
  }, []);


  return (
    <div className="outer">
      <div className="flex items-center flex-col justify-between w-full">
        you can close this window
      </div>
    </div>
  );
};
