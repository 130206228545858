export const strings = {
    en: {
      current: 'Current',
      next: 'Next',
      stop: 'stop',
      level: 'level',
    },
    de: {
      current: 'Aktuelle',
      next: 'Nächster',
      stop: 'Halt',
      level: 'Etage',
    },
  };