export const strings = {
    en: {
      resetFilter: 'Reset filer',
      address: 'Address',
      chooseToCompare :'Which elevators do you want to compare',
    },
    de: {
      resetFilter: 'Filter zurücksetzen',
      address: 'Adresse',
      chooseToCompare :'Welche Aufzüge wollen Sie vergleichen',
    },
  };