import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Welcome from './pages/Welcome'
import { LoginPage } from './../pages/Login'
import { LogoutPage } from './../pages/Logout'
import Callback from './../pages/Callback'
import { RequireAuth } from './../utils/RequireAuth'
import { GlobalStateProvider, useGlobalState } from "./../utils/globalStateProvider"
import { useAuth0 } from '@auth0/auth0-react'
import { Spin } from 'antd';
import { I18n } from '@aws-amplify/core'
import { USER_CHOICE_LANGUAGE} from './../utils/consts'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { strings as stringGlobal } from './../utils/globalLocalization'
import React from 'react'
import { RedirectPage } from '../pages/Redirect'
import { GlobalStateProviderApp } from './utils/globalStateProviderApp'
import ContractDetail from './pages/ContractDetail'
import { IsDsMember, checkUrlOrganizationAgainSessionOrganizationAndLogoutIfRequired, getAuth0Token, getLangByLocalStorage } from '../utils/helpers'
import { Status404 } from '../pages/Status_404'
import posthog from 'posthog-js'

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export default function AppContracts() {

  const { isLoading, logout,user,getAccessTokenSilently } = useAuth0();
  const { state, setState } = useGlobalState()
  const { search } = useLocation();

  useEffect(() => {
    if(user){
      checkUrlOrganizationAgainSessionOrganizationAndLogoutIfRequired(user,logout)
      posthog.identify(user.sub)
      posthog.setPersonProperties({visitedContracts:"yes"})

      const setPosthogDSMemberProperty = async () => {
        if(await IsDsMember(await getAuth0Token(getAccessTokenSilently))){
          posthog.setPersonProperties({isDsMember:"yes"})
        }
        else{
          posthog.setPersonProperties({isDsMember:"no"})
        }
      }
  
      setPosthogDSMemberProperty()
    }
  }, [user]);

  useEffect(() => {
    if(state.organizationName && state.organizationIdentifier){
      posthog.group(state.organizationName, state.organizationIdentifier, {
        name: state.organizationName,        
    });
  }
}, [state.organizationName,state.organizationIdentifier]);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
    else{
      let query =  new URLSearchParams(search)
      let langDefaulvalue = query.get("lang")
      let lang:string='de'
      if(langDefaulvalue){
        lang = langDefaulvalue
        localStorage.setItem(USER_CHOICE_LANGUAGE, lang);
      }
      I18n.setLanguage(lang)
      moment.locale(lang);
      document.documentElement.lang = lang        
    }
  }, [ state.language]);

  if (isLoading) {
    return <div className="flex h-screen"><div className="m-auto"><Spin size="large" /></div></div>;
  }

  return (
    <>
      <GlobalStateProvider>
        <GlobalStateProviderApp>
          <Routes>
            <Route path="/" element={<Navigate to={'/welcome'} />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/redirect" element={<RedirectPage />} />
            <Route path="/404" element={<Status404 entity='contract' />} />
            <Route element={<RequireAuth />}>
              <Route path='/callback' element={<Callback />} />
              <Route path='/welcome' element={<Welcome />} />
              <Route path='/:organizationName/welcome' element={<Welcome />} />
              <Route path="/:organizationName/contract/:contractId" element={<ContractDetail />} />
            </Route>
            <Route path="*" element={<Navigate to={'/'} />} />
          </Routes>
        </GlobalStateProviderApp>
      </GlobalStateProvider>
    </>
  );
}
