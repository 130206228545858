export const strings = {
    en: {
      doorChartOpenings: 'Openings',
      doorChartOpens: 'Open',
      doorChartClosings: 'Closings',
      doorChartCloses: 'Close',
    },
    de: {
      doorChartOpenings: 'Öffnungen',
      doorChartOpens: 'Geöffnet',
      doorChartClosings: 'Schließungen',
      doorChartCloses: 'Geschloßen',
    },
  };