import React, { useEffect, useState } from 'react';
import { strings as stringLocal} from './WelcomeLocalization';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { IsStorybookAndDsMember, getAuth0IsAuthenticatedStatus, getAuth0Token, langChange, langInit, scrollToLocation } from '../../utils/helpers';
import { Breadcrumb, getMD, getSidebar, removeMdMetaData } from '../utils/helpers';
import { iProduct, iProductsPage } from '../interfaces/Products';
import { baseUrl, urlToProductFiles } from './Welcome';
import { useAuth0 } from '@auth0/auth0-react';
import { getIcon } from '../../utils/icons';
import { I18n } from '@aws-amplify/core';
import CopyToClipboard from '../../components/molecules/CopyToClipboard';
import { Spin, Tooltip } from 'antd';
import { fetchProducts } from '../utils/helpersFetch';

const ProductPage = () => {

  const { state, setState } = useGlobalState()
  const [ products, setProducts ] = useState<iProduct[]>([])
  const [ product, setProduct ] = useState<iProduct|undefined>(undefined)
  const [ page, setPage ] = useState<iProductsPage|undefined>(undefined)
  const [ currentMdContent, setCurrentMdContent ] = useState<string>('')
  const [ showEditButton, setShowEditButton ] = useState<boolean>(false)
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [ isLoadingMdFile, setIsLoadingMdFile] = React.useState<boolean>(true)
  
  const navigate = useNavigate();
  let { productId,fileId } = useParams();
  const { user } = useAuth0();
  const { hash } = useLocation();

  useEffect(() => {
    if(state.language){ langChange({state:state,updateDocumentTitle:true}) } else { langInit( {stringLocal:stringLocal,setStateGlobal:setState} ) }
  }, [state.language]);

  useEffect(() => {

    const setEditPermission = async () => {

      if( await IsStorybookAndDsMember(await getAuth0Token(getAccessTokenSilently))){
        setShowEditButton(true)
      }
      else if(state.organizationName === 'digitalspine'){
        setShowEditButton(true)
      }
      else{
        setShowEditButton(false)
      }
    }

    setEditPermission()

  }, [state.organizationName]);

  useEffect(() => {
    if(currentMdContent){
      // in case the url was pasted directly the anchor does not reach the target
      // we need to jum to the anchor after the md files was rendered
      const timer = setTimeout(() => {
        scrollToLocation(hash)
      }, 1000);
    }
  }, [currentMdContent]);

  useEffect(() => {
    if(products.length <= 0){
      return
    }

    let foundProduct:iProduct|undefined = undefined
    foundProduct = products.filter((element)=>{if(element.pathId===productId){return true}})[0]
    if(foundProduct){
      setProduct(foundProduct)
      let foundPageByPath:iProductsPage|undefined = undefined
      foundProduct.sections.every(section => {
        return section.pages.every(page => {
          if(page.id === fileId){
            foundPageByPath = page
            return false
          }
          else{
            return true
          }
        })
      })
      if(foundPageByPath){
        setPage(foundPageByPath)
      }
      else {
        navigate('/welcome')
      }
    }
    else{
      navigate('/welcome')
    }
  }, [products]);


  useEffect(() => {

    setIsLoadingMdFile(true)

    loadProducts()

    let languageFolder = ''
    if(state.language && state.language !== 'en'){
      languageFolder = state.language + '/'
    }

    fetch(baseUrl+urlToProductFiles+languageFolder+fileId+'.md')
    .then(r => r.text())
    .then(text => {setCurrentMdContent(removeMdMetaData(text))})
    .catch(function() {
        console.log("error");
    })
    .finally(function (){
      setIsLoadingMdFile(false)
    })

  }, [state.language]);

  async function loadProducts() {
    try {
      const accessToken = await getAuth0Token(getAccessTokenSilently);
      
      let pagedResult = await fetchProducts(accessToken)
      setProducts(pagedResult)
  
    } catch (error) {
      console.error('Error fetching News and Updates:', error.message);
      // Handle the error here if needed
    } finally {
    }
  }

  const EditPage = () => {
    if(page){
      window.open('https://github.com/digitalspine/learn-docs-products/edit/main/'+page.gitPath,'github')
    }
  }

  return (
    <div className={ getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      <Header />
      <section className="bg-blueLight welcome">
        <Breadcrumb>
          <div className=" cursor-pointer" onClick={()=>{ navigate('/')}}>{I18n.get('HelpCenter')}</div>
          <div className=" cursor-pointer" onClick={()=>{ navigate(`/products/${productId}`)}}>{state.language && product?.title[state.language]}</div>
          <div className="" >{state.language && page?.title[state.language]}</div>
        </Breadcrumb>
      </section>
      {page && <section className="Content"><div data-testid="icon_container" className=" flex justify-end pt-9">

          <CopyToClipboard 
          classNames='text-[22px] m:text-[35px] text-blueLight w-5 m:w-9 cursor-pointer mr-3 m:mr-3 '
          label={I18n.get('CopyLink')}
          textToCopy={location.origin + location.pathname} />

          {showEditButton && 
          <Tooltip placement="top" color={'white'} title={I18n.get('Edit')} >
            <span onClick={()=>{EditPage()}} title={I18n.get('Edit')}>{getIcon({type:'Edit',ownClassName:'w-[25px] h-[25px] m:w-[40px] m:h-[40px] fill-blueLight cursor-pointer'})}</span>
          </Tooltip>}
        </div>
      </section>}
      <section className="Content">
        <Spin spinning={isLoadingMdFile}>
          <div className="w-full markdocContent flex">
              <div className="w-3/12 sidebar hidden xl:block">{ getSidebar(currentMdContent)}</div>
              <div className="w-1/12 hidden xl:block"></div>
              <div className="w-full xl:w-8/12">{state.language && getMD(currentMdContent,state.language)}</div>
          </div>
        </Spin>
      </section>
      <Footer />
    </div>
  );
};

export default ProductPage