import { I18n } from "@aws-amplify/core";
import { useEffect, useRef, useState } from "react";
import { useGlobalState } from "../../../utils/globalStateProvider";
import React from "react";
import { Spin } from "antd";
import { DateRange } from "../../../interfaces/DateRange";
import {  fetchEnergyConsumption, setOrReportError } from "../../../utils/helpersFetch";
import { useAuth0 } from "@auth0/auth0-react";
import EnergyChart from "./EnergyChart";
import { DeviceInfo } from "../../../interfaces/DeviceInfo";
import { EnergyConsumption, EnergyConsumptionResponse } from "../../../interfaces/EnergyConsumption";
import moment from "moment";
import { distanceLowerOrEqualThan, getAuth0Token, getSiPrefixedNumber } from "../../../utils/helpers";

const EnergyChartMemo = React.memo(EnergyChart)

const EnergyChartWrapper = (props: { device: DeviceInfo | null; dateRange:DateRange; speakingTimePeriod:string }) => {

  const { state, setState } = useGlobalState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [energyConsumption, setEnergyConsumption] = useState<null | EnergyConsumptionResponse>(null);
  const [countEnergy, setCountEnergy] = useState<number | null>(null);
  const [visualizeEnergyPerHours,setVisualizeEnergyPerHours] = React.useState<boolean>(false)
  const { getAccessTokenSilently } = useAuth0()
  const controllerRef1 = useRef<AbortController | null>()

  const fetchAllData = async (deviceId: string,accessToken:string, dateRange?: DateRange) => {

    let intervalEnergy = 'daily' //interval = hour, day, week, month
    if(Math.abs(moment(dateRange?.endDate).diff(moment(dateRange?.startDate), 'days')) <= 7){
      intervalEnergy = 'hourly'
    }

    const queryStringWithIntervalEnergy = dateRange ? `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&interval=${intervalEnergy}` : '';
  
    return Promise.all([
      fetchEnergyConsumption(deviceId, queryStringWithIntervalEnergy,accessToken,controllerRef1),
    ]);
  };

  const fetchData = async () => {
    setIsLoading(true)

    if (props.device?._id) {
      const [energyConsumptionInfo] = await fetchAllData(props.device._id, await getAuth0Token(getAccessTokenSilently), props.dateRange);

      energyConsumptionInfo.data?.sort((a, b) => a.time > b.time ? 1 : -1)
      
      setEnergyConsumption(null)
      setOrReportError(setEnergyConsumption, energyConsumptionInfo);
        
      if (energyConsumptionInfo.data?.length) {
        let count = 0
        energyConsumptionInfo.data.forEach(function (item:EnergyConsumption) {
          if(item.power_consumption){
            count += item.power_consumption
          }
        });
        if(distanceLowerOrEqualThan(props.dateRange.endDate,props.dateRange.startDate,'days',7)){
          setCountEnergy(count)
          setVisualizeEnergyPerHours(true)
        }
        else{
          setCountEnergy(count/energyConsumptionInfo.data.length)
          setVisualizeEnergyPerHours(false)
        }
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language]);

  useEffect(() => {
    fetchData()
  }, [ props.dateRange,props.device?.deviceId]);


  return (
    <>
     {props.device?.energyMeter === 1 &&
      <>
        <section>
          <div className="Content bg-blueBaby text-blueDark text-base xxl:text-lg">
            <div className="flex flex-col m:flex-row pt-6 xxl:pt-12 pb-6 xxl:pb-12">
              <div className=" w-full m:w-1/2 m:pr-24">
                <h2 className="uppercase xl:text-3xl">{I18n.get('EnergyConsumption')}</h2>
                <div className=" pt-2 xxl:pt-4 xl:text-lg font-bold">{I18n.get(props.speakingTimePeriod)}</div>
                <div className="text-sm  pt-2 xl:text-lg  xxl:pt-4">{I18n.get('MessageEnergyConsumptionOverTime')}</div>
              </div>
              <div className=" w-full m:w-1/2 flex pt-4 m:pt-0">
                <div className="">
                  <h3 className="pr-8 m:pr-0 pb-2 m:pb-4 uppercase">{I18n.get('EnergyConsumptionTotal')}</h3>
                  <div><h1 className=" normal inline-block"><Spin size="default" spinning={isLoading} key="spin">{countEnergy?getSiPrefixedNumber(countEnergy,!visualizeEnergyPerHours):'-'}</Spin></h1></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Content">
          <div className=" pb-14 pt-6">
            <EnergyChartMemo response={energyConsumption} isLoading={isLoading} visualizePerHours={visualizeEnergyPerHours} />
          </div>
        </section>
      </>
     }
     </>
  );
};
export default EnergyChartWrapper;
