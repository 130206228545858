import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Welcome from './pages/Welcome'
import { LoginPage } from './../pages/Login'
import { LogoutPage } from './../pages/Logout'
import Callback from './../pages/Callback'
import { RequireAuth } from './../utils/RequireAuth'
import { GlobalStateProvider, useGlobalState } from "./../utils/globalStateProvider"
import { GlobalStateProviderApp, useGlobalStateApp } from "./utils/globalStateProviderApp"
import { useAuth0 } from '@auth0/auth0-react'
import { Spin } from 'antd';
import { I18n } from '@aws-amplify/core'
import { USER_CHOICE_LANGUAGE} from './../utils/consts'
import { fetchOrganizationName } from './../utils/helpersFetch'
import { useEffect, useState } from 'react'
import Header from './../components/Header'
import Footer from './../components/Footer'
import moment from 'moment'
import { strings as stringGlobal } from './../utils/globalLocalization'
import React from 'react'
import DocumentsDetail from './pages/DocumentsDetail'
import { SalesforceAuthCheck } from './pages/salesforce/SalesforceAuthCheck'
import { InitSalesforceIframeAuth } from './pages/salesforce/InitSalesforceIframeAuth'
import {IFramAuthDone} from './pages/salesforce/iFramAuthDone'
import SalesforceDocumentsAssetShowLibrary from './pages/salesforce/documents/SalesforceDocumentsAssetShowLibrary'
import SalesforceDocumentsAssetShowLibraries from './pages/salesforce/documents/SalesforceDocumentsAssetShowLibraries'
import SalesforceDocumentsAssetCreateLibray from './pages/salesforce/documents/SalesforceDocumentsAssetCreateLibrary'
import SalesforceDocumentsAssetModalPreview from './pages/salesforce/documents/SalesforceDocumentsAssetModalPreview'
import { RedirectPage } from '../pages/Redirect'
import LibraryDetail from './pages/LibraryDetail'
import { IsDsMember, checkUrlOrganizationAgainSessionOrganizationAndLogoutIfRequired, getAuth0Token, getLangByLocalStorage } from '../utils/helpers'
import { Status404 } from '../pages/Status_404'
import posthog from 'posthog-js'

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export default function AppDocuments() {

  const { isLoading,user, error, logout, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [speakingOrgName, setSpeakingOrgName] = useState<string>('');
  const { state, setState } = useGlobalState()
  const { search } = useLocation();

  useEffect(() => {
    if(user){
      checkUrlOrganizationAgainSessionOrganizationAndLogoutIfRequired(user,logout)
      posthog.identify(user.sub)
      posthog.setPersonProperties({visitedDocuments:"yes"})

      const setPosthogDSMemberProperty = async () => {
        if(await IsDsMember(await getAuth0Token(getAccessTokenSilently))){
          posthog.setPersonProperties({isDsMember:"yes"})
        }
        else{
          posthog.setPersonProperties({isDsMember:"no"})
        }
      }
  
      setPosthogDSMemberProperty()
    }
  }, [user]);

  useEffect(() => {
      if(state.organizationName && state.organizationIdentifier){
        posthog.group(state.organizationName, state.organizationIdentifier, {
          name: state.organizationName,        
      });
    }
  }, [state.organizationName,state.organizationIdentifier]);

  const loginWithoutOrg = () => {
    loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    });
  }
    
    useEffect(() => {
      if(state.language){
        I18n.setLanguage(state.language)
        moment.locale(state.language);
        document.documentElement.lang = state.language
      }
      else{
        let query =  new URLSearchParams(search)
        let langDefaulvalue = query.get("lang")
        let lang:string='de'
        if(langDefaulvalue){
          lang = langDefaulvalue
          localStorage.setItem(USER_CHOICE_LANGUAGE, lang);
        }
        I18n.setLanguage(lang)
        moment.locale(lang);
        document.documentElement.lang = lang        
      }
    }, [ state.language]);      
   
    if (error) {
      let errorMessage = error.message

    if (errorMessage.includes('is not part of the') && errorMessage.includes('organization')) {
      // case: user google-apps|robert@digitalspine.io is not part of the org_BdGtxj3VINHioKjk organization
      let messageParts = errorMessage.split(' ')
      let organizationName = messageParts[7]

      const getOrgName = async (organizationName: string) => {
        let organization = await fetchOrganizationName(organizationName)
        if (organization) {
          setSpeakingOrgName(organization.displayName)
        }
      }
      getOrgName(organizationName)
      errorMessage = I18n.get('auth0ErrorMessageNotPartOfOrg').replace('###orgName###', speakingOrgName)
    }
    else {
      errorMessage = I18n.get(error.message)
    }

    return <>
      <Header isLoggedout={true} />
      <div className="flex w-full  items-center justify-center flex-col pt-20 pb-20 text-center pl-4 pr-4 min-h-[50vh]">
        <h2>{I18n.get('errorOccurred')}</h2>
        <h3 className="pt-5">{errorMessage}</h3>
        <h3>{I18n.get('messageGoTo')}<span onClick={() => loginWithoutOrg()} className=" font-bold cursor-pointer">Login</span>.</h3>
      </div>
      <Footer />
    </>
  }

  if (isLoading) {
    return <div className="flex h-screen"><div className="m-auto"><Spin size="large" /></div></div>;
  }

  return (
    <>
      <GlobalStateProvider>
        <GlobalStateProviderApp>
          <Routes>
            <Route path="/" element={<Navigate to={'/welcome'} />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/redirect" element={<RedirectPage />} />
            <Route path="/404" element={<Status404 entity='document' />} />
            <Route path="/salesforce/redirect/asset/:assetId" element={<SalesforceAuthCheck />} />
            <Route path="/salesforce/redirect/contract/:contractId" element={<SalesforceAuthCheck />} />
            <Route path="/salesforce/init-salesforce-iframe-auth" element={<InitSalesforceIframeAuth />} />
            <Route path="/salesforce/iFramAuthDone" element={<IFramAuthDone />} />          
            <Route element={<RequireAuth />}>
              <Route path='/callback' element={<Callback />} />
              <Route path='/welcome' element={<Welcome />} />
              <Route path='/:organizationName/welcome' element={<Welcome />} />
              
              <Route path="/:organizationName/installations/" element={<Navigate to={'/welcome'} />} />
              <Route path="/:organizationName/installations/:installationId" element={<DocumentsDetail />} />
              <Route path="/:organizationName/installations/:installationId/c/:categoryMainOrSubId" element={<DocumentsDetail />} />
              <Route path="/:organizationName/installations/:installationId/f/:fileId" element={<DocumentsDetail />} />

              <Route path="/:organizationName/library/:libraryId" element={<LibraryDetail />} />
              <Route path="/:organizationName/library/:libraryId/c/:categoryMainOrSubId" element={<LibraryDetail />} />
              <Route path="/:organizationName/library/:libraryId/f/:fileId" element={<LibraryDetail />} />

              <Route path="/salesforce/documents/asset/:assetId" element={<SalesforceDocumentsAssetCreateLibray />} />
              <Route path="/salesforce/documents/asset/:assetId/:libraryId" element={<SalesforceDocumentsAssetShowLibraries />} /> 
              <Route path="/salesforce/documents/asset/:assetId/:libraryId/:categoryMainId" element={<SalesforceDocumentsAssetShowLibrary />} />
              <Route path="/salesforce/documents/asset/:assetId/:libraryId/:categoryMainId/:fileId" element={<SalesforceDocumentsAssetModalPreview />} />            

              <Route path="/salesforce/documents/contract/:contractId" element={<SalesforceDocumentsAssetCreateLibray />} />
              <Route path="/salesforce/documents/contract/:contractId/:libraryId" element={<SalesforceDocumentsAssetShowLibraries />} /> 
              <Route path="/salesforce/documents/contract/:contractId/:libraryId/:categoryMainId" element={<SalesforceDocumentsAssetShowLibrary />} />
              <Route path="/salesforce/documents/contract/:contractId/:libraryId/:categoryMainId/:fileId" element={<SalesforceDocumentsAssetModalPreview />} />            

            </Route>
            <Route path="*" element={<Navigate to={'/'} />} />
          </Routes>
        </GlobalStateProviderApp>
      </GlobalStateProvider>
    </>
  );
}
