import { I18n } from "@aws-amplify/core";
import { useEffect, useState } from "react";
import { useGlobalState } from "../../../utils/globalStateProvider";
import React from "react";
import { DeviceInfo } from "../../../interfaces/DeviceInfo";
import TableEvents from "./TableEvents";
import { FeedMessage } from "../../../interfaces/Lifefeed";


const TableEventsWrapper = (props: { device: DeviceInfo | null; speakingTimePeriod:string; pastEvents:[],storedFeedMessages: FeedMessage[] }) => {

  const { state, setState } = useGlobalState()
/*
  useEffect(() => {
    console.log(props.storedFeedMessages)
  }, [ props.storedFeedMessages]);
*/
  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language]);

  return (
    <>
      <section>
        <div className="Content bg-blueBaby text-blueDark text-base">
          <div className="flex  pt-10 xxl:pt-12 pb-10 xxl:pb-12">
            <div className="m:pr-24">
              <h2 className="uppercase">{I18n.get('liveActivities')}</h2>
              <div className=" pt-2 xxl:pt-4  xl:text-lg font-bold hidden">{I18n.get(props.speakingTimePeriod)}</div>
              <div className="text-sm  pt-2 xl:text-lg  xxl:pt-4">{I18n.get('messageLiveActivities')}</div>
            </div>
          </div>
        </div>
      </section>

      <section className="Content">
        <div className="pb-14 pt-6 ">
          <TableEvents pastEvents={props.pastEvents} device={props.device} showMessagesCount={100} storedFeedMessages={props.storedFeedMessages} />
        </div>
      </section>
     </>
  );
};
export default TableEventsWrapper;
