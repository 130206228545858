import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { I18n } from "@aws-amplify/core";
import { useGlobalState } from "../../../utils/globalStateProvider";
import moment from "moment";
import { DsFile, DsFilePreloaded, DsFileRecentlyViewed, DsFileResponse } from "../../interfaces/File";
import { downloadDsFile, downloadIconWhite, downloadUrlByLinkMethod, isSalesforceView, noThumbnailImg, updateViewdDocuments } from "../../utils/helpers";
import { formatBytes, getAuth0Token } from "../../../utils/helpers";
import { Button } from "../../../components/atoms/Button";
import { getFile, getFileByType, getfilesForCategory } from "../../utils/helpersFetch";
import { Library } from "../../interfaces/Library";
import { Category } from "../../interfaces/Category";
import PdfViewer from "./PdfViewer";
import DocumentDialogImage from "./documentDialogImage";
import { useNavigate, useParams } from "react-router-dom";
import { Contract } from "../../interfaces/Contract";
import { DsFileStored } from "../../interfaces/File";
import { handleApiError } from "../../../utils/helpersFetch";

type Options =
  | { 
    isLastSeenMode: true, 
    file:DsFilePreloaded,
    setSelectedFile:Function,
    mode:'Installation'|'Library'
    }
  | { isLastSeenMode: false,
      file:DsFilePreloaded,
      setSelectedFile:Function,
      paginationPage:number|null,
      paginationSize:number|null,
      allFileResponse:DsFileResponse|null,
      library:Library,
      category:Category,
      contract?:Contract,
      mode:'Installation'|'Library'
      sorter?:{ columnKey: string, order: string }
    };

const DocumentDialog = (props:Options) => {

    const { getAccessTokenSilently,user } = useAuth0();
    const { state, setState } = useGlobalState()
    const leftArrow = <svg onClick={()=>props.setSelectedFile(prevFile)}  className="h-6 l:h-10 cursor-pointer fill-blueLight" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" /><path d="M25 26.16L18.84 20L25 13.84L22.18 11L13.18 20L22.18 29L25 26.16Z" fill="white"/></svg>
    const rightArrow = <svg onClick={()=>props.setSelectedFile(nextFile)} className="h-6 l:h-10 cursor-pointer fill-blueLight" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 40 0)" /><path d="M15 26.16L21.16 20L15 13.84L17.82 11L26.82 20L17.82 29L15 26.16Z" fill="white"/></svg>
    const leftArrowDisabled = <svg className="h-6 l:h-10  fill-greyLight cursor-not-allowed" width="40" height="40" viewBox="0 0 40 40"  xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" /><path d="M25 26.16L18.84 20L25 13.84L22.18 11L13.18 20L22.18 29L25 26.16Z" fill="white"/></svg>
    const rightArrowDisabled = <svg className="h-6 l:h-10 fill-greyLight cursor-not-allowed" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 40 0)" /><path d="M15 26.16L21.16 20L15 13.84L17.82 11L26.82 20L17.82 29L15 26.16Z" fill="white"/></svg>
    const [previewUrl,setPreviewUrl] = useState<string|undefined>(undefined)
    const [nextFile,setNextFile] = useState<DsFilePreloaded|undefined>(undefined)
    const [prevFile,setPrevFile] = useState<DsFilePreloaded|undefined>(undefined)
    const [fileToShow,setFileToShow] = useState<DsFile|undefined>(undefined)
    const [isLoading,setIsLoading] = useState<boolean>(false)
    const [allFileResponse,setAllFileResponse] = useState<DsFileResponse | null >(null)
    const [isPdfFile,setIsPdfFile] = useState<boolean>(false)
    const [notViewable,setNotViewable] = useState<boolean>(false)
    let { installationId, libraryId, organizationName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if(state.language){
        I18n.setLanguage(state.language)
        moment.locale(state.language)
        document.documentElement.lang = state.language
      }
    }, [state.language]);

    useEffect(() => {
      // create deeplink url
      if(!isSalesforceView()){
        if(fileToShow){ 
          let targetPath = ''
          if(props.mode === 'Installation'){
            targetPath = '/' + organizationName + '/installations/' + installationId + '/f/' + fileToShow.id 
          }
          else if(props.mode === 'Library'){
            if(libraryId){// libraryId gets set in url
              targetPath = '/' + organizationName + '/library/' + libraryId + '/f/' + fileToShow.id 
            }
            else{// in case of recently viewed files it comes from the stored object
              targetPath = '/' + organizationName + '/library/' + fileToShow.library + '/f/' + fileToShow.id 
            }
          }
          navigate(targetPath)
        }
      }
    }, [fileToShow]);

    useEffect(() => {
      const loadFiles = async() => {
        if(props.isLastSeenMode === false){
          setNextFile(undefined)
          setPrevFile(undefined)
          setPreviewUrl(undefined)

          if(props.allFileResponse === null || props.allFileResponse === undefined ){
            setIsLoading(true)
            let accessToken =  await getAuth0Token(getAccessTokenSilently)
            const files = await getfilesForCategory(props.library.id,props.category.id,accessToken,null,null,props.sorter)
            setAllFileResponse(files)
            setIsLoading(false)
          }
          else{
            setAllFileResponse(props.allFileResponse)
          }
        }
      }
      loadFiles()
    }, []);

    useEffect(() => {
      
      if(props.file.file.title.split(".").pop()?.toLocaleLowerCase() === "pdf" ){
        setIsPdfFile(true)
        const loadPdf = async (file:DsFile) => {
          try {
            let loadedFile = await getFile(file.id,await getAuth0Token(getAccessTokenSilently))
            if(loadedFile === null || !loadedFile.originalUrl){
              setPreviewUrl(noThumbnailImg)
            }
            else{
              setPreviewUrl(loadedFile.originalUrl)
            }
          }
          catch (error) {
            handleApiError(error,navigate);
          }
        }
        loadPdf(props.file.file)
      }
      else{
        setIsPdfFile(false)

        if(props.file?.imageSrc == null){
          const loadThumbnail = async (file:DsFile) => {

            try {
              let preview = await getFileByType(file.id,'preview',await getAuth0Token(getAccessTokenSilently),false)
              if(preview && preview.previewUrl){
                setPreviewUrl(preview.previewUrl)
              }
              else{
                setNotViewable(true)
                setPreviewUrl(noThumbnailImg)
              }
              
            }
            catch (error) {
              handleApiError(error,navigate);
            }

          }
          if(props.file.file.hasPreview){
            setNotViewable(false)
            loadThumbnail(props.file.file)
          }
          else{
            setNotViewable(true)
            setPreviewUrl(noThumbnailImg)
          }
        }
        else{// preloaded file
          setPreviewUrl(props.file.imageSrc)
          setNotViewable(false)
        }
      }

      setFileToShow(props.file.file)
      if(!props.isLastSeenMode && state.organizationIdentifier){
        updateViewdDocuments(props.file.file,user,state.organizationIdentifier,props.library,props.contract)
      }
    }, [props.file]);

    useEffect(() => {
      if(props.file && allFileResponse !== null){
        setNextPrevFiles(props.file.file)
      }
      }, [props.file,allFileResponse]);

    const setNextPrevFiles = (file:DsFile) => {
      let locFileResponse = allFileResponse

      if(locFileResponse === null){
        return
      }
      if(!file){
        return
      }
      
      locFileResponse?.files.forEach(async (fileItem,index)=>{
        if(fileItem.id === file.id){
          let prevElement:DsFile|null = null
          let nextElement:DsFile|null = null

          if(locFileResponse){
            prevElement = locFileResponse.files[index-1]
            nextElement = locFileResponse.files[index+1]  
          }

          if(prevElement){

            let preloadedItem:DsFilePreloaded = {file:prevElement,imageSrc: null}
            let accessToken =  await getAuth0Token(getAccessTokenSilently)

            if(prevElement.title.indexOf('.pdf')===-1){
              if(prevElement.hasPreview){
                let loadedFile = await getFileByType(prevElement.id,'preview',accessToken)
                const imagePrev:HTMLImageElement = new Image().src =  loadedFile.previewUrl // loads image to browsercache  
                preloadedItem.imageSrc = loadedFile.previewUrl  
              }
            }
            else{
              // in case of pdf no action is required
            }
            setPrevFile(preloadedItem)
          }
          else{
            setPrevFile(undefined)
          }

          if(nextElement){

            let preloadedItem:DsFilePreloaded = {file:nextElement,imageSrc: null}
            let accessToken =  await getAuth0Token(getAccessTokenSilently)

            if(nextElement.title.indexOf('.pdf')===-1){
              if(nextElement.hasPreview){
                let loadedFile = await getFileByType(nextElement.id,'preview',accessToken)
                const imagePrev:HTMLImageElement = new Image().src =  loadedFile.previewUrl // loads image to browsercache  
                preloadedItem.imageSrc = loadedFile.previewUrl  
              }
              else{
                preloadedItem.imageSrc = noThumbnailImg
              }
            }
            else{
              // in case of pdf no action is required
            }

            setNextFile(preloadedItem)
          }
          else{
            setNextFile(undefined)
          }
        }
      })
    }

    const downlaodFile = async (file:DsFile) => {
      let fileResult = await getFile(file.id,await getAuth0Token(getAccessTokenSilently))
      if(fileResult?.originalUrl){
        window.location.href = fileResult?.originalUrl
      }
    }

    const getDataRow = (labe:string,value:string) => {
      return <div className=" table-row">
              <div className=" table-cell text-base m:text-lbase l:text-l xl:text-lg xxl:text-xl font-bold pr-6 xl:pr-16 pt-6 2">{labe}</div>
              <div className=" table-cell text-base m:text-lbase l:text-l xl:text-lg xxl:text-xl break-all ">{value}</div>
            </div>
    }

    const keyFunction = useCallback((event: { key: string; }) => {
      if (event.key === "Escape") {
        if(!isSalesforceView()){
          props.setSelectedFile(null)
        }
      }
      else if(event.key=== 'ArrowLeft'){
        if(prevFile?.file.id !== props.file.file.id){ // heavyclicking
          if(prevFile){props.setSelectedFile(prevFile)}
        }
      }
      else if(event.key=== 'ArrowRight'){
        if(nextFile?.file.id !== props.file.file.id){ // heavyclicking
          if(nextFile){props.setSelectedFile(nextFile)}
        }
      }
    }, [nextFile,prevFile]);
  
    useEffect(() => {
      document.addEventListener("keydown", keyFunction, false);
  
      return () => {
        document.removeEventListener("keydown", keyFunction, false);
      };
    }, [keyFunction]);

    return (
      <>
        <div className={ " hidden m:flex   justify-center items-center text-white fixed z-40 top-0 left-0 w-full h-screen bg-opacity-50 bg-blueLight "}>
          <div className=' w-full h-full p-4'>
            <div className=' pl-9 pr-9 h-20 flex justify-between items-center bg-blueDark rounded-t '>
              <h3 className="text-white">{fileToShow && fileToShow.title}</h3>
              <div className="flex items-center">
                <svg className={( isSalesforceView()?' hidden ': '' ) + " w-8 l:w-10  cursor-pointer"} onClick={()=> {props.setSelectedFile(null)}} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="22" fill="white"/>
                  <path d="M27.922 29.0411L22.3662 23.4852L16.8104 29.0411L14.9584 27.1891L20.5143 21.6333L14.9584 16.0775L16.8104 14.2255L22.3662 19.7814L27.922 14.2255L29.774 16.0775L24.2182 21.6333L29.774 27.1891L27.922 29.0411Z" fill="#102A68"/>
                </svg>
              </div>
            </div>
            <div className= "  bg-white max-[900px]:h-10 h-20 "></div>
            <div className='flex items-center h-[calc(100%-15rem)] max-[900px]:h-[calc(100%-10rem)] overflow-hidden  text-blueDark bg-white '>
              <div className={(props.isLastSeenMode?"  invisible ":" visible ") + " flex w-1/12  justify-center"}>{(prevFile && !isLoading)?leftArrow:leftArrowDisabled}</div>
              <div className=" w-6/12 flex justify-center h-full">
                {isPdfFile && <PdfViewer url={previewUrl} />}
                {!isPdfFile && !notViewable && previewUrl && fileToShow  && <span className="cursor-move  w-full h-full"><DocumentDialogImage previewUrl={previewUrl} file={fileToShow} /></span>}
                {!isPdfFile && notViewable  && <span className="cursor-move  w-full h-full flex justify-center items-center flex-col">
                  <img src={previewUrl} />
                  <div className="mt-5">{I18n.get('Document_preview_not_supported_for_this_file_type')}</div>
                </span>}
              </div>
              <div className=" w-4/12 pl-12">
                <div className="table">
                  {fileToShow && getDataRow(I18n.get('Filename'),fileToShow.title)}
                  {fileToShow && getDataRow(I18n.get('SubCategory'),state.language === 'de' ? fileToShow.category.titleGerman : fileToShow.category.titleEnglish)}
                  {fileToShow && fileToShow.mimeType  &&  getDataRow(I18n.get('Type'),fileToShow.mimeType)}
                  {fileToShow && getDataRow(I18n.get('Size'),formatBytes(fileToShow.sizeKb))}
                  {fileToShow && getDataRow(I18n.get('UploadedDate'),moment(fileToShow.createdAt).format('DD.MM.YYYY' ))}
                </div>
                <div className=" pt-12">
                  <Button label="Download" size="medium" theme="dark" onClick={()=>downlaodFile(props.file.file)} />
                </div>
              </div>
              <div className={(props.isLastSeenMode?"  invisible ":" visible ") + " w-1/12 flex justify-center"}>{(nextFile && !isLoading)?rightArrow:rightArrowDisabled}</div>
            </div>
            <div className=" flex justify-center bg-white text-blueDark pb-7 rounded-b  max-[900px]:h-10 h-20 ">{/*12|<span className="font-bold">30</span>*/}</div>
          </div>
        </div>
        <div className={ "  m:hidden justify-center items-center text-white fixed z-30 top-0 left-0 w-full h-screen overflow-scroll bg-white select-none"}>
          <div className=' '>
            <div className=' pl-4 pr-4 h-20 flex justify-between items-center  bg-blueDark fixed top-0 w-full z-20'>
              <h3 className="text-white text-ellipsis overflow-hidden">{fileToShow && fileToShow.title}</h3>
              <div className="flex items-center">
                <svg className={( isSalesforceView()?' hidden ': '' ) + " w-8  cursor-pointer"} onClick={()=> {props.setSelectedFile(null)}} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="22" fill="#102A68"/>
                  <path d="M27.922 29.0411L22.3662 23.4852L16.8104 29.0411L14.9584 27.1891L20.5143 21.6333L14.9584 16.0775L16.8104 14.2255L22.3662 19.7814L27.922 14.2255L29.774 16.0775L24.2182 21.6333L29.774 27.1891L27.922 29.0411Z" fill="white"/>
                </svg>
              </div>
            </div>
            <div className='flex flex-col items-center pt-28 pb-11 text-blueDark pl-4 pr-4 bg-white  h-[calc(100vh-1rem)] '>
              <div className=" flex justify-center  w-full h-full">
                {isPdfFile && <PdfViewer url={previewUrl} />}
                {!isPdfFile && !notViewable && previewUrl && fileToShow && <span className="cursor-move w-full h-full"><DocumentDialogImage previewUrl={previewUrl} file={fileToShow} /></span>}
                {!isPdfFile && notViewable  && <span className="cursor-move  w-full h-full flex justify-center items-center flex-col">
                  <img src={previewUrl} />
                  <div className="mt-5">{I18n.get('Document_preview_not_supported_for_this_file_type')}</div>
                </span>}
              </div>
              <div className=" w-full bg-white">
                <div className="table mb-20 ">
                  { fileToShow && getDataRow(I18n.get('Filename'),fileToShow.title)}
                  { fileToShow && getDataRow(I18n.get('SubCategory'),state.language === 'de' ? props.file.file.category.titleGerman : props.file.file.category.titleEnglish)}
                  { fileToShow && getDataRow(I18n.get('Type'),fileToShow.mimeType)}
                  { fileToShow && getDataRow(I18n.get('Size'),formatBytes(fileToShow.sizeKb))}
                  { fileToShow && getDataRow(I18n.get('UploadedDate'),moment(fileToShow.createdAt).format('DD.MM.YYYY' ))}
                </div>
              </div>
            </div>
            <div className=" flex fixed w-full bottom-0 h-16 bg-blueDark text-white items-center justify-center" >
              <div className="flex items-center cursor-pointer" onClick={()=>downlaodFile(props.file.file)}><span className=" text-sm pr-7">Download</span> {downloadIconWhite}</div>
            </div>
          </div>
        </div>
        </>
      );

}
export default DocumentDialog