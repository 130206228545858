import React from "react"

type Options =
    {
        type: 'Information' | 'Anomaly' | 'Insight' | 'Performance_Insight' | 'Maintenance_Alert' | 'Safety_Alert' | 'Connectivity_Status' | 'Environmental_Conditions' | 'Usage_Patterns' | 'Energy_Efficiency'
        ownClassName: string
    }

export function getIconInsights(props: Options) {
    // generate icons with these setup > https://digitalspine.atlassian.net/browse/AP-1975
    if (props.type === 'Performance_Insight') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M407-325q23 23 66.5 21.5T537-336l216-339-335 219q-30 20-32 64t21 67Zm71-474q57 0 119 18.5T716-717l-52 37q-45-30-96.5-44.5T477.978-739q-140.472 0-239.225 100.215Q140-538.569 140-396.017 140-351 152.5-305q12.5 46 35.5 85h579q22-36 35-84t13-94q0-42-12.5-90.5T758-578l39-52q38 56 57 112.5T875-404q2 60-12 113t-41 98q-12 23-25.5 28t-33.5 5H192q-17 0-33.5-8.5T134-193q-26-48-40-97.5T80-396q0-83 31.5-156.5t85.5-128Q251-735 323.68-767T478-799Zm-9 331Z"/>
            
            </svg>
    }
    else if (props.type === 'Maintenance_Alert') {
        return <svg 
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="m643-273 44-41q7-7 7-15.5t-7-15.5L535-497q5-14 8-27t3-27q0-58-41-99t-99-41q-17 0-34 5t-33 14l88 87-54 51-86-85q-9 16-13.5 33t-4.5 35q0 57 40 96.5t97 39.5q14 0 27.5-2.5T461-425l151 152q6 6 15.5 6t15.5-6ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/></svg>
    }
    else if (props.type === 'Safety_Alert') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M450-284h60v-257h-60v257Zm30-323q14.45 0 24.225-9.775Q514-626.55 514-641q0-14.45-9.775-24.225Q494.45-675 480-675q-14.45 0-24.225 9.775Q446-655.45 446-641q0 14.45 9.775 24.225Q465.55-607 480-607Zm0 526q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Zm0-62q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z"/></svg>
    }
    else if (props.type === 'Connectivity_Status') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M480-127q-36 0-63-27t-27-63q0-36 27-63t63-27q36 0 63 27t27 63q0 36-27 63t-63 27ZM232-357l-63-63q70-70 146.5-105T480-560q88 0 164.5 35T791-420l-63 63q-61-61-123-87t-125-26q-63 0-125 26t-123 87ZM63-526 0-589q93-95 216.5-153T480-800q140 0 263.5 58T960-589l-63 63q-88-84-192.5-134T480-710q-120 0-224.5 50T63-526Z"/></svg>
    }
    else if (props.type === 'Environmental_Conditions') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M213-175q-43.594-45-68.297-104Q120-338 120-400q0-73 25.5-133.5T222-645q35-35 87-59t122.5-37.5Q502-755 591-758.5t198 3.5q8 108 5.5 197.5t-16 160.75q-13.5 71.25-38 124.563T680-183q-51 51-110 77T444-80q-69 0-126.5-23.5T213-175Zm103 0q25 17 58 26t69.923 9Q497-140 547-162t91-64q27-27 46-70.5t31-103Q727-459 731-534t0-165q-94-2-168.5 2.5T431-680q-57 12-98 30.5T266-604q-42 43-64 91t-22 98q0 48 20.5 100.5T251-230q53-98 127-176t157-123q-87 75-141 162.5T316-175Zm0 0Zm0 0Z"/></svg>
    }
    else if (props.type === 'Usage_Patterns') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="m126-220-46-46 300-300 160 161 298-335 42 41-340 384-160-159-254 254Z"/></svg>
    }
    else if (props.type === 'Energy_Efficiency') {
        return <svg
            className={props.ownClassName}
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48">
            <path d="M480-180q125 0 212.5-87.5T780-480v-300H480q-125 0-212.5 87.5T180-480q0 125 87.5 212.5T480-180Zm-53-105 207-185q10-9 6-21.5T622-506l-162-16 97-133q4-5 3.5-10.5T556-676q-5-5-11.5-5t-11.5 5L327-491q-10 9-6 21.5t18 14.5l162 16-98 133q-4 5-3.5 10.5T405-285q5 5 11 5t11-5Zm53 165q-67 0-126-22.5T247-205l-76 76q-5 5-10 7t-11 2q-12 0-21-9t-9-21q0-6 2-11t7-10l76-76q-40-48-62.5-107T120-480q0-150 105-255t255-105h360v360q0 150-105 255T480-120Zm0-360Z"/></svg>
    }
    else if (props.type === 'Information') {
        return <svg
            className={props.ownClassName}
            height="48"
            viewBox="0 -960 960 960"
            width="48"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M479.982-694q14.018 0 23.518-9.482 9.5-9.483 9.5-23.5 0-14.018-9.482-23.518-9.483-9.5-23.5-9.5-14.018 0-23.518 9.482-9.5 9.483-9.5 23.5 0 14.018 9.482 23.518 9.483 9.5 23.5 9.5ZM450-367h60v-251h-60v251ZM80-80v-740q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240L80-80Zm134-220h606v-520H140v600l74-80Zm-74 0v-520 520Z"/></svg>
    }
    else if(props.type === 'Anomaly'){
        return <svg
            className={props.ownClassName}
            height="48"
            viewBox="0 -960 960 960"
            width="48"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M478-80q-83 0-156-31.5T195-197q-54-54-85.5-127T78-480q0-83 31.5-156T195-763q54-54 127-85.5T478-880q88 0 166.5 36T780-742L478-440v-380q-142 0-241 98.812Q138-622.375 138-480t98.812 241.188Q335.625-140 478-140q81 0 153.5-36.5T757-276v83q-57 54-129 83.5T478-80Zm339-147v-327h60v327h-60Zm33.018 140Q836-87 826.5-96.483q-9.5-9.482-9.5-23.499 0-14.018 9.482-23.518 9.483-9.5 23.5-9.5 14.018 0 23.518 9.482 9.5 9.483 9.5 23.5Q883-106 873.518-96.5q-9.483 9.5-23.5 9.5Z"/></svg>
    }
    else if (props.type === 'Insight') {
        return <svg
            className={props.ownClassName}
            height="48"
            viewBox="0 -960 960 960"
            width="48"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M300-370h40v-140h-40v50h-60v40h60v50Zm100-50h320v-40H400v40Zm220-110h40v-50h60v-40h-60v-50h-40v140Zm-380-50h320v-40H240v40Zm90 460v-80H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H630v80H330ZM140-260h680v-520H140v520Zm0 0v-520 520Z"/></svg>
    }
}