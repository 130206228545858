import { Installation, InstalltionsCoordsResponse, InstalltionsResponse } from 'src/interfaces/Installation';
import { Case, PagedCases, CaseParams, CheckOpenCasesResponse, IncidentByAsset } from '../interfaces/Case';
import { API_URL, INCIDENTS_API_URL } from './../../utils/consts';
import { GlobalStateInterfaceApp } from "./globalStateProviderApp";
import { customFetchWithAuthHeader } from './../../utils/helpersFetch';

const buildCasesUrl = (page: number, pageSize: number, searchText: string, sortBy: string, filterBy: string) => {
  const sortByParam = sortBy ? `&sortBy=${encodeURIComponent(sortBy)}` : '';
  const filterByParam = filterBy ? `&filterBy=${encodeURIComponent(filterBy)}` : '';
  return `${INCIDENTS_API_URL}/v1/cases?take=${pageSize}&skip=${(page - 1) * pageSize}&search=${encodeURIComponent(searchText)}${sortByParam}${filterByParam}`;
};

export const getCaseById = async (caseId: string ,accessToken:string): Promise<Case|null> => {
  const url = `${INCIDENTS_API_URL}/v1/cases/${caseId}`;
  const response = await customFetchWithAuthHeader(url, {
    method: 'GET',
  },accessToken);

  if(response.status == 404 || response.status == 500){
    return null
  }
  const data = await response.json();
  if(data){
    return data;
  }
  else{
    return null
  }
};

export const fetchIncidentsIntallationsCoords = async (page:number, pageSize:number, sortBy:string,assetIds:any[],accessToken:string): Promise<InstalltionsCoordsResponse> => {

  if(assetIds.length <= 0){
    throw new Error("An error occure: NO ASSETS FOR INCIDENT")
  }

  try {
    let allInstallations = undefined
    let sortByParam = (!sortBy || sortBy === '')? '' : '&sortBy='+encodeURIComponent(sortBy)
    const response = await customFetchWithAuthHeader(API_URL + '/api/v1/assets?coords=true&assetIds='+assetIds.join(',')+'&take='+pageSize.toString()+'&skip='+((page-1)*pageSize).toString()+''+sortByParam, {
      method: 'GET',
    },accessToken);
    allInstallations = await response.json()
    
    return allInstallations;
  }
  catch(error){
    throw new Error("An error occured while fetching assets for the incident")
  }
};

export const fetchIncidentsIntallations = async (page:number, pageSize:number, sortBy:string,assetIds:any[],accessToken:string): Promise<InstalltionsResponse> => {

  if(assetIds.length <= 0){
    throw new Error("An error occure: NO ASSETS FOR INCIDENT")
  }

  try {
    let allInstallations = undefined
    let sortByParam = (!sortBy || sortBy === '')? '' : '&sortBy='+encodeURIComponent(sortBy)
    const response = await customFetchWithAuthHeader(API_URL + '/api/v1/assets?assetIds='+assetIds.join(',')+'&take='+pageSize.toString()+'&skip='+((page-1)*pageSize).toString()+''+sortByParam, {
      method: 'GET',
    },accessToken);
    allInstallations = await response.json()
    
    return allInstallations;
  }
  catch(error){
    throw new Error("An error occured while fetching assets for the incident")
  }
};

export const fetchCases = async <T>(
  page: number = 1,
  pageSize: number,
  searchText: string = '',
  sortBy: string = '',
  filterBy: string = '',
  accessToken: string): Promise<PagedCases> => {

  try {
    const url = buildCasesUrl(page, pageSize, searchText, sortBy, filterBy);

    const response = await customFetchWithAuthHeader(url, {
      method: 'GET',
    }, accessToken);

    if (!response.ok) {
      throw new Error('Error while fetching incidents')
    }

    const incidents: PagedCases = await response.json();

    return incidents;
    
  } catch (error) {
    throw new Error("An error occured while fetching incidents..")
  }
};

export const fetchCasesByAssetFabrNumber = async <T>(
  installation:Installation,
  accessToken: string): Promise<PagedCases> => {

  try {
    const url = `${INCIDENTS_API_URL}/v1/cases?take=&skip=0&&search=` + installation.label;

    const response = await customFetchWithAuthHeader(url, {
      method: 'GET',
    }, accessToken);

    if (!response.ok) {
      throw new Error('Error while fetching incidents')
    }

    const incidents: PagedCases = await response.json();

    return incidents;
    
  } catch (error) {
    throw new Error("An error occured while fetching incidents..")
  }
};

export const setMaxIncidentsCount = async (setState: React.Dispatch<React.SetStateAction<any | null>>, accessToken: string)  => {
  try {
    const pagedIncidents = await fetchCases<PagedCases>(1, 1, '', '', '', accessToken);
  
    let dataGlobal: Partial<GlobalStateInterfaceApp> = {
      maxCasesCount: pagedIncidents.totalCount
    };
  
    setState((prev: GlobalStateInterfaceApp) => ({ ...prev, ...dataGlobal }));
  } catch (error) {
    console.error('Error fetching incidents total:', error);
  }
};

export const fetchIncidentByAsset = async (
  assetId: string,
  accessToken: string): Promise<IncidentByAsset> => {

  try {
    const url = `${INCIDENTS_API_URL}/v1/cases/notificationByAsset/${assetId}`

    const response = await customFetchWithAuthHeader(url, {
      method: 'GET',
    }, accessToken);

    return await response.json();;
    
  } catch (error) {
    throw new Error(`An error occured while fetching incident by Asset..${assetId}`)
  }
};

export const fetchHasOpenCases = async <T>(
  params: CaseParams[],
  accessToken: string): Promise<CheckOpenCasesResponse> => {

  try {
    const paramsStr = params.reduce((tempStr, fieldObj) => tempStr +  fieldObj.fieldName + '=' + fieldObj.fieldValue , '');
    const url = `${INCIDENTS_API_URL}/v1/cases/hasOpenCases?${paramsStr}`
    const response = await customFetchWithAuthHeader(url, {
      method: 'GET',
    }, accessToken);

    if (!response.ok) {
      throw new Error(`Error while fetching incidents ${JSON.stringify(params)}`)
    }

    const hasOpenCasesResp: CheckOpenCasesResponse = await response.json();

    return hasOpenCasesResp;
    
  } catch (error) {
    throw new Error(`An error occured while fetching incidents..${JSON.stringify(params)}`)
  }
};