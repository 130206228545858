import React, { ReactNode } from 'react'
import {  } from 'react-router-dom'
import { useEffect } from 'react'
import './TableShared.css';
import useWindowDimensions from '../../utils/UseWindowDimensions'

interface TableCell {class:string,text:ReactNode,textPlain?:string}

export interface TableProps {
  th: ({ellipsis?:boolean} & TableCell)[]
  tr: {
    cells:TableCell[],
    trOnClick?: Function
  }[]
  firstRowFullWidth?:boolean
  multiselectEnabled?:boolean
  noDataLabel: string
  thead?: "sticky-documents-salesforce-asset"
  tbody?: "hideScrollbars"
  heightPrefs: "small" | "medium" | "large" 
  height: "max-h-[50vh]" | "unset";
}

const TableShared = (props: {  tableContent:TableProps|null }) => {
      
  const [tableContent,setTableContent] = React.useState<TableProps | null>(null)
  const [dsTable,setDsTable] = React.useState<JSX.Element | null>(null)
  const [fullRowWidth,setFullRowWidth] = React.useState<string >('')
  let thTdFixStyle = ' font-medium bg-blueDark text-white pl-4 '
  let trTdFixStyle = ' self-center overflow-hidden p-4 '
  const { height, width } = useWindowDimensions();
  
  useEffect(() => {
    setTableContent(props.tableContent)
  }, [props.tableContent]);

  useEffect(() => {
    if(props.tableContent?.firstRowFullWidth){
      if(props.tableContent?.multiselectEnabled){
        setFullRowWidth((width-50) + 'px')
      }
      else{
        setFullRowWidth((width-20) + 'px')
      }
   }
    BuildTable()
  }, [tableContent,width,props.tableContent]);

  const BuildTable = () => {
    
    if(!tableContent || tableContent.tr.length === 0 ){
      setDsTable(<div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight ">{tableContent?.noDataLabel}</div>
        </div>)
    }
    else{

    let thead = <></>

    if(tableContent.firstRowFullWidth && tableContent.multiselectEnabled){
      // the widhts of the columns in the tbody are not just 1/12
      // in the 11/12 part gets a new 12/12 row created so its needs to be done here in the same way :/

        thead = <tr className={(props.tableContent?.heightPrefs === 'small' ?  "h-8 m:h-10 ": " h-14 m:h-24 ") + " align-bottom flex w-full bg-blueDark "}>
          <td colSpan={tableContent.th.length} className={(props.tableContent?.heightPrefs === 'small' ?  "h-8 m:h-10 ": " h-14 m:h-24 ") + " align-bottom flex w-full bg-blueDark "}>
            <div className="flex flex-row w-full">
              <div className={ thTdFixStyle + tableContent.th[0].class}>{tableContent.th[0]?tableContent.th[0].text:''}</div>
              <div className="flex flex-col min-w-full">
                <div className={(props.tableContent?.heightPrefs === 'small' ?  "h-8 m:h-10 ": " h-14 m:h-24 ") + "flex flex-row items-center  overflow-y-scroll w-full"}>
                  {tableContent.th.map((tdElement,index)=>{
                    if(index !== 0 ){
                      if( tableContent.th[index-1].ellipsis){
                        return <div className={ thTdFixStyle + ' ellipsis ml-5 mr-5 ' + tdElement.class} key={index}><span>{tdElement.text}</span></div>
                      }
                      else{
                        return <div className={thTdFixStyle + tdElement.class} key={index}>{tdElement.text}</div>
                      }
                    }
                  })}
                </div>
              </div>
            </div>
          </td>
        </tr>
      }
      else{
        thead = <tr className={(props.tableContent?.heightPrefs === 'small' ?  "h-8 m:h-10 ": " h-14 m:h-24 ") + " align-bottom flex w-full bg-blueDark "}>
        {tableContent.th.map((thElement,index)=>{
          if(thElement.ellipsis){
            return <td className={ thTdFixStyle + ' ellipsis ml-5 mr-5 ' + thElement.class} key={index}><span>{thElement.text}</span></td>
          }
          else{
            return <th className={ thTdFixStyle + thElement.class} key={index}>{thElement.text}</th>
          }
        })}
      </tr>
      }

      setDsTable(<><table className={(props.tableContent?.heightPrefs === 'small' ?  " text-s ": "text-sm m:text-lg") } align="left" >
      <thead className={props.tableContent?.thead}>
        {thead}
      </thead>
      <tbody className={tableContent.height + " " + props.tableContent?.tbody + " " + "bg-grey-light flex flex-col items-center justify-between overflow-y-scroll w-full"} >
        {tableContent.tr.map((trElement,index)=>{
          let height = (props.tableContent?.heightPrefs === 'small' ? " h-8 m:h-10 ": props.tableContent?.heightPrefs === 'medium' ? " h-16 m:h-24 " : " h-24 ")
          let rowClassName= (index % 2 === 0 ? ' flex w-full' : 'bg-greyLighter flex w-full'  ) + height

          // firstRowFullWidth
          if(tableContent.firstRowFullWidth){
            if(tableContent.multiselectEnabled){
              return <tr key={index + '_0'} onClick={()=>{(trElement.trOnClick? trElement.trOnClick(): false)}} className={rowClassName + "hover:bg-blueBaby"}>
                <td colSpan={trElement.cells.length} className=" w-full">
                  <div className="flex flex-row w-full">
                    <div className={trElement.cells[0].class  + ' self-center overflow-hidden p-4'  } title={trElement.cells[0].textPlain?trElement.cells[0].textPlain:''}>{trElement.cells[0]?trElement.cells[0].text:''}</div>
                    <div className="flex flex-col min-w-full">
                    <div className={trElement.cells[1].class + ' pt-3 m:pt-4 overflow-hidden text-ellipsis'} style={{'width':fullRowWidth}} title={trElement.cells[1].textPlain?trElement.cells[1].textPlain:''}>{trElement.cells[1]?trElement.cells[1].text:''}</div>
                      <div className="flex flex-row items-center  overflow-y-scroll w-full">
                        {trElement.cells.map((tdElement,index)=>{
                          if(index !== 0 && index !== 1){
                            if( tableContent.th[index-1].ellipsis){
                              return <div className={tdElement.class + ' self-center overflow-hidden p-4 pt-0 ' + ' ellipsis ml-5 mr-5 '} title={tdElement.textPlain?tdElement.textPlain:''} key={index}><span>{tdElement.text}</span></div>
                            }
                            else{
                              return <div className={tdElement.class + ' self-center overflow-hidden p-4 pt-0'} title={tdElement.textPlain?tdElement.textPlain:''} key={index}>{tdElement.text}</div>
                            }
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>              
            }
            else{
              return <tr key={index + '_0'} onClick={()=>{(trElement.trOnClick? trElement.trOnClick(): false)}} className={rowClassName + "hover:bg-blueBaby"}>
                <td colSpan={trElement.cells.length} className=" w-full">
                  <div className="flex flex-col w-full">
                    <div className={trElement.cells[0].class + ' pt-3 m:pt-4 overflow-hidden text-ellipsis '} style={{'width':fullRowWidth}} title={trElement.cells[0].textPlain?trElement.cells[0].textPlain:''}>{trElement.cells[0]?trElement.cells[0].text:''}</div>
                    <div className="flex flex-row items-center justify-between overflow-y-scroll w-full">
                      {trElement.cells.map((tdElement,index)=>{
                        if(index !== 0){
                          if( tableContent.th[index-1].ellipsis){
                            return <div className={tdElement.class + ' self-center overflow-hidden p-4 pt-0 ' + ' ellipsis ml-5 mr-5 '} title={tdElement.textPlain?tdElement.textPlain:''} key={index}><span>{tdElement.text}</span></div>
                          }
                          else{
                            return <div className={tdElement.class + ' self-center overflow-hidden p-4 pt-0 '} title={tdElement.textPlain?tdElement.textPlain:''} key={index}>{tdElement.text}</div>
                          }
                        }
                      })}
                    </div>
                  </div>
                </td>
              </tr>
            }
          }
          else{ // standard case
            return <tr onClick={()=>{(trElement.trOnClick? trElement.trOnClick(): false)}} className={rowClassName + "hover:bg-blueBaby"} key={index}>
              {trElement.cells.map((tdElement,index)=>{
                if( tableContent.th[index].ellipsis){
                  return <td className={tdElement.class + trTdFixStyle + ' ellipsis ml-5 mr-5 '} key={index}><span>{tdElement.text}</span></td>
                }
                else{
                  return <td className={tdElement.class + trTdFixStyle} key={index}>{tdElement.text}</td>
                }
              })
            }
            </tr>
          }

        })}
      </tbody>
    </table>
    </>
    )
    }
  }

  return (
    <div className="tableShared flex flex-col">
      {dsTable}
    </div>
  );
};

export default TableShared;
