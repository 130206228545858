import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateLibrary from '../../../components/salesforce/createLibrary';
import { getLibraryBySalesforceEntity, getLibraryForEntity } from '../../../utils/helpersFetch';
import { LibraryResponse } from '../../../interfaces/Library';
import { getAuth0IsAuthenticatedStatus, getAuth0Token } from '../../../../utils/helpers';

const SalesforceDocumentsAssetCreateLibray = () => {
  
  const [forceCreateLibrary, setForceCreateLibrary] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAuthenticated,getAccessTokenSilently } = useAuth0()
  let { assetId,contractId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    const loadLibrary = async () => {
      if(assetId || contractId){
        setIsLoading(true)
        
        let accessToken =  await getAuth0Token(getAccessTokenSilently)

        let entityId:any|undefined = undefined

        if(contractId){
          entityId = await getLibraryBySalesforceEntity(contractId,'Contract',accessToken)
        }
        else if(assetId){
          entityId = await getLibraryBySalesforceEntity(assetId,'Asset',accessToken)
        }
        if(entityId && entityId.id){

          let library:LibraryResponse|null = null

          if(contractId){
            library = await getLibraryForEntity(entityId.id,'Contract',accessToken)
          }
          else if(assetId){
            library = await getLibraryForEntity(entityId.id,'Asset',accessToken)
          }

          if((library && library.message && library.message == 'Entity not found') 
            || (library && library.count <= 0)
            || !(library?.libraries[0])
            ){
            setForceCreateLibrary(true)
            setIsLoading(false)
          }
          else{
            if(assetId){
              navigate('/salesforce/documents/asset/' + assetId + '/' + library?.libraries[0].id);
            }
            else if(contractId){
              navigate('/salesforce/documents/contract/' + contractId + '/' + library?.libraries[0].id);
            }
          }
        }
        else{
          setForceCreateLibrary(true)
          setIsLoading(false)
        }
      }
    }

    loadLibrary()
  }, []);

  return (
    <div className={isAuthenticated? " h-screen flex items-center justify-center": 'hidden' }>
      <Spin tip="Loading..." spinning={isLoading}>
        {forceCreateLibrary && <div><CreateLibrary /></div>}
        {!forceCreateLibrary && <div className=" w-10 h-10"></div>}      
      </Spin>
    </div>
  );
};

export default SalesforceDocumentsAssetCreateLibray;