import { I18n } from '@aws-amplify/core';
import { useEffect, useState } from 'react';
import React from 'react';
import { useGlobalState } from '../../utils/globalStateProvider';
import { appLinkFor, getAuth0Token, isErrResponseType, langChange, langInit } from '../../utils/helpers';
import { strings as stringLocal } from './../utils/globalLocalization';
import { getHeadLine, widgetTypeOptions } from './Widgets';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchAssetStatistics } from '../../portal/utils/helpersFetch.ts';
import { AssetStatistics } from "../../interfaces/AssetStatistics.ts";
import { Spin } from 'antd';
import './WidgetAssets.css';
import { DeviceFilterEnum } from '../../interfaces/DeviceFilters.ts';
const colorsTheme = require('../../../src/utils/aufzugheldenTheme')

export default function WelcomeWidgetAssets(props: widgetTypeOptions) {
  const { state } = useGlobalState();
  const { getAccessTokenSilently } = useAuth0();

  const [assetStatistics, setAssetStatistics] = useState<null | AssetStatistics>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [smallerFontSizesByLevel, setSmallerFontSizesByLevel] = useState<number>(0)

  const fetchData = async () => {
    setIsLoading(true);
    const token = await getAuth0Token(getAccessTokenSilently)
    const assetStatisticsResult = await fetchAssetStatistics(token);

    if (typeof assetStatisticsResult === 'undefined' || isErrResponseType(assetStatisticsResult)) {
      setAssetStatistics(null);
    } else {
      setAssetStatistics(assetStatisticsResult);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let tmpLevelNumber:number = 0 
    if(assetStatistics){
      if(assetStatistics.operational_count > 99){
        tmpLevelNumber = 1
      }
      else if(assetStatistics.issue_detected_count > 999){
        tmpLevelNumber = 1
      }
      else if(assetStatistics.attention_needed_count > 999){
        tmpLevelNumber = 1
      }
      else if(assetStatistics.total_count > 999){
        tmpLevelNumber = 1
      }

      if(assetStatistics.operational_count > 9999){
        tmpLevelNumber = 2
      }
      else if(assetStatistics.issue_detected_count > 9999){
        tmpLevelNumber = 2
      }
      else if(assetStatistics.attention_needed_count > 9999){
        tmpLevelNumber = 2
      }
      else if(assetStatistics.total_count > 9999){
        tmpLevelNumber = 2
      }

      if(assetStatistics.operational_count > 99999){
        tmpLevelNumber = 3
      }
      else if(assetStatistics.issue_detected_count > 99999){
        tmpLevelNumber = 3
      }
      else if(assetStatistics.attention_needed_count > 99999){
        tmpLevelNumber = 3
      }
      else if(assetStatistics.total_count > 99999){
        tmpLevelNumber = 3
      }

      if(assetStatistics.operational_count > 999999){
        tmpLevelNumber = 4
      }
      else if(assetStatistics.issue_detected_count > 999999){
        tmpLevelNumber = 4
      }
      else if(assetStatistics.attention_needed_count > 999999){
        tmpLevelNumber = 4
      }
      else if(assetStatistics.total_count > 999999){
        tmpLevelNumber = 4
      }

    }
    setSmallerFontSizesByLevel(tmpLevelNumber)
  }, [assetStatistics])

  useEffect(() => {
    if (state.language) { langChange({ state: state }); } else { langInit({ stringLocal }); }
  }, [state.language]);

  useEffect(() => {
    fetchData();
    //setAssetStatistics({attention_needed_count:1340,issue_detected_count:25,operational_count:1,total_count:1456})
  }, []);

  const redirectToDevicesWithFilter = (deviceFilter : DeviceFilterEnum) => {
    document.location.href = appLinkFor('Devices', state, 'welcome', {filterBy: deviceFilter})
  }

  const getFormatedValue = (dynamicClassNamesList:string[], fixClassNames:string,value:number|undefined|null) => {
      let dynamicClassNames = ''
      if(dynamicClassNamesList[smallerFontSizesByLevel]){
        dynamicClassNames = dynamicClassNamesList[smallerFontSizesByLevel]
      }
      else{
        dynamicClassNames = dynamicClassNamesList.slice(-1)[0]
      }
      if(value === undefined || value === null){
        return <div className={dynamicClassNames + ' ' + fixClassNames}>-</div>
      }else{
        return <div className={dynamicClassNames + ' ' + fixClassNames}>{(value && value >= 0) ? value : '-'}</div>
      }
  }
  
  return (
    <>
      {/* mobile to :l  */}
      <div data-testid="test_asset_widget" className={"cp_asset_widget xxl:hidden flex-grow " + (props.presentAsWidget !== false ? props.colSpan : '')}>
        <Spin size="default" spinning={isLoading} key="spin" >
          <div className={"flex bg-white flex-col justify-end h-full"}>
            <div>{getHeadLine({ headline: I18n.get('HeadlineDevices'), type: 'Devices', link: 'Devices', state: state })}</div>
            {getFormatedValue(
                [
                  "text-[100px] leading-[100px]",
                  "text-[100px] leading-[100px]",
                  "text-[84px] leading-[84px]",
                  "text-[72px] leading-[72px]"
                ]
                , `pl-[23px] pr-[23px] pb-[35px] flex-grow flex flex-col justify-end ${assetStatistics?.operational_count === 0 ? 'h-[306px]' : 'h-[160px]'}`
             ,assetStatistics?.total_count)}
            <div
              className={`bg-green pl-[23px] pr-[23px] pb-[20px]  pt-5 flex flex-col justify-between cursor-pointer 
              ${assetStatistics?.operational_count === 0 ? 'hidden' : ''}
              ${(assetStatistics?.attention_needed_count === 0 && assetStatistics?.issue_detected_count === 0) ? 'h-[283px]' : 'h-[146px]'}`}
              onClick={() => redirectToDevicesWithFilter(DeviceFilterEnum.Operational)}>
              <div>{I18n.get('Operational')}</div>
              {getFormatedValue(
                [
                  "text-[70px] leading-[70px]",
                  "text-[70px] leading-[70px]",
                  "text-[60px] leading-[60px]",
                  "text-[60px] leading-[60px]"
                ]
                , "" ,assetStatistics?.operational_count)}
            </div>
            <div
              className={`flex h-[137px]
            ${(assetStatistics?.attention_needed_count === 0 && assetStatistics?.issue_detected_count === 0) ? 'hidden' : ''}
            `}>
              <div
                className={`w-1/2 flex-grow bg-warnOrange pl-[23px] pr-[23px] pb-[23px] pt-5 flex flex-col justify-between cursor-pointer
              ${assetStatistics?.issue_detected_count === 0 ? 'hidden' : ''}`}
              onClick={() => redirectToDevicesWithFilter(DeviceFilterEnum.IssueDetected)}>
                <div>{I18n.get('IssueDetected')}</div>
                {getFormatedValue(
                [
                  "text-[50px] leading-[50px]",
                  "text-[45px] leading-[50px]",
                  "text-[36px] leading-[36px]",
                  "text-[31px] leading-[31px]"
                ]
                , "" ,assetStatistics?.issue_detected_count)}
              </div>
              <div
                className={`w-1/2 flex-grow bg-warnRed pl-[23px] pr-[23px] pb-[23px] pt-5 flex flex-col justify-between cursor-pointer
                ${assetStatistics?.attention_needed_count === 0 ? 'hidden' : ''}`}
                onClick={() => redirectToDevicesWithFilter(DeviceFilterEnum.AttentionNeeded)}>
                <div>{I18n.get('AttentionNeeded')}</div>
                {getFormatedValue(
                [
                  "text-[50px] leading-[50px]",
                  "text-[45px] leading-[50px]",
                  "text-[36px] leading-[36px]",
                  "text-[31px] leading-[31px]"
                ]
                , "" ,assetStatistics?.attention_needed_count)}
              </div>
            </div>
          </div>
        </Spin>
      </div>
      {/* dektop from :xxl  */}
      <div data-testid="test_asset_widget" className={"cp_asset_widget hidden xxl:flex + flex-grow " + (props.presentAsWidget !== false ? props.colSpan : '')}>
        <Spin size="default" spinning={isLoading} key="spin" >
          <div className={" bg-white flex-row w-full h-full flex"}>
            <div className="w-1/2 flex flex-col flex-grow">
              <div>{getHeadLine({ headline: I18n.get('HeadlineDevices'), type: 'Devices', link: 'Devices', state: state })}</div>
              {getFormatedValue(
                [
                  "text-[150px] leading-[150px]",
                  "text-[140px] leading-[140px]",
                  "text-[120px] leading-[120px]",
                  "text-[100px] leading-[100px]",
                  "text-[87px] leading-[87px]"
                ]
                , "pl-[23px] pr-[23px] pb-[19px] flex-grow h-[190px] flex flex-col justify-end" ,assetStatistics?.total_count)}
            </div>
            <div
              className={`flex ml-auto
            ${assetStatistics?.operational_count === 0 ? 'w-1/4' : 'w-1/2'}
            `}>
              <div
                className={`bg-green pl-[23px] pr-[23px] pb-[21px]  pt-5 flex flex-col justify-between h-full w-1/2 flex-grow cursor-pointer
              ${assetStatistics?.operational_count === 0 ? 'hidden' : ''}`}
              onClick={() => redirectToDevicesWithFilter(DeviceFilterEnum.Operational)}>
                <div>{I18n.get('Operational')}</div>
                {getFormatedValue( 
                  [
                    "text-[90px] leading-[95px]",
                    "text-[62px] leading-[69px]",
                    "text-[52px] leading-[58px]",
                    "text-[44px] leading-[46px]",
                    "text-[38px] leading-[41px]"
                  ]
                  , "", assetStatistics?.operational_count)}
              </div>
              <div
                className={`flex h-full flex-col w-1/2 ml-auto flex-grow
              ${(assetStatistics?.attention_needed_count === 0 && assetStatistics?.issue_detected_count === 0) ? 'hidden' : ''}
              `}>
                <div
                  className={`flex-grow w-full h-1/2 bg-warnOrange pl-[23px] pr-[23px] pb-[23px] pt-5 flex flex-col justify-between cursor-pointer
                ${assetStatistics?.issue_detected_count === 0 ? 'hidden' : ''}`}
                onClick={() => redirectToDevicesWithFilter(DeviceFilterEnum.IssueDetected)}>
                  <div>{I18n.get('IssueDetected')}</div>
                  {getFormatedValue(
                    [
                      "text-[62px] leading-[67px]",
                      "text-[62px] leading-[65px]",
                      "text-[52px] leading-[54px]",
                      "text-[44px] leading-[42px]",
                      "text-[38px] leading-[37px]"
                    ]
                    , "",assetStatistics?.issue_detected_count)}
                </div>
                <div
                  className={`flex-grow w-full h-1/2 bg-warnRed pl-[23px] pr-[23px] pb-[23px] pt-5 flex flex-col justify-between cursor-pointer
                ${assetStatistics?.attention_needed_count === 0 ? 'hidden' : ''}`}
                onClick={() => redirectToDevicesWithFilter(DeviceFilterEnum.AttentionNeeded)}>
                  <div>{I18n.get('AttentionNeeded')}</div>
                  {getFormatedValue(
                    [
                      "text-[62px] leading-[67px]",
                      "text-[62px] leading-[65px]",
                      "text-[52px] leading-[54px]",
                      "text-[44px] leading-[42px]",
                      "text-[38px] leading-[37px]"
                    ]
                    , "",assetStatistics?.attention_needed_count)}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
}
