import React, { useEffect, useRef, useState } from "react";
import { I18n } from "@aws-amplify/core";
import { useGlobalState } from "../../utils/globalStateProvider";
import TableMain, { TableProps } from "../../components/molecules/TableMain";
import { Case, CaseAsset, PagedCases } from "../../incidents/interfaces/Case";
import moment from "moment";
import { Pagination, Spin, Tooltip } from "antd";
import { getAuth0Token, langChange, langInit } from "../../utils/helpers";
import { strings as stringLocal} from './../utils/globalLocalization';
import { useAuth0 } from "@auth0/auth0-react";
import { fetchCases } from "../../incidents/utils/helpersFetch";
import { app_url_incidents } from "../../utils/consts";
import StatusTabs from "./StatusTabs";
import { IncidentFilter, IncidentFilterData, IncidentFilterEnum } from "../../interfaces/IncidentFilters";

const colorsTheme = require('../../../src/utils/aufzugheldenTheme')

const IncidentsTable = () => {

  const tablePropsDefault: TableProps = {
    height: "unset",
    noDataLabel: I18n.get('Loading...'),
    th: [],
    tr: []
  };

  const { state, setState } = useGlobalState()
  const [tableProps, setTableProps] = useState<TableProps>({...tablePropsDefault});
  const { organizationName } = state;
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isInitialLoading, setInitialIsLoading] = React.useState<boolean>(true)
  const topTableElementRef = useRef<null | HTMLTableElement>(null)
  const [pageParam, setPageParam] = React.useState<number | undefined>(1)
  const [pagedIncidents,setPagedIncidents] = useState<PagedCases>({incidents:[],totalCount:0})
  const [incidentFilterBy, setIncidentFilterBy] = React.useState<IncidentFilter>(IncidentFilterEnum.Open)
  const { getAccessTokenSilently } = useAuth0()

  const getAffectedAssets = (caseAssets: CaseAsset[]) => {
    if(caseAssets.length === 0){
      return '-';
    }
  
    const firstAsset = caseAssets[0];
    const firstLabel = firstAsset.label || firstAsset.assetId;
    const remainingAssetCount = caseAssets.length - 1;
    return remainingAssetCount === 0 ? `${firstLabel}` : <Tooltip  placement="bottom" color={colorsTheme.blueDark}  title={<div className=" text-white">{caseAssets.map((item,index)=>{return <div key={index}>{item.label}</div>})}</div>} >{firstLabel} <span className='text-greyLight'>+{remainingAssetCount}</span></Tooltip>;
  }

  async function loadIncidents() {
    try {
      setIsLoading(true);
      const accessToken = await getAuth0Token(getAccessTokenSilently)

      let pagedIncidents = await fetchCases<PagedCases>(pageParam, 7, '', '', incidentFilterBy, accessToken)
      setPagedIncidents(pagedIncidents)

      setIsLoading(false);
      setInitialIsLoading(false);
    } catch (error) {
      console.error('Error fetching incidents:', error.message)
      // Handle the error here if needed
    } finally {
      setIsLoading(false);
      setInitialIsLoading(false);
    }
  }

  useEffect(() => {
    loadIncidents()
  }, [pageParam, incidentFilterBy]);

  useEffect(() => {
    if (state.language) { langChange({ state: state }) } else { langInit({ stringLocal }) }
  }, [state.language]);

  useEffect(() => {

    const incidentsData: Case[]= pagedIncidents.incidents;
    const tableProps: TableProps = tablePropsDefault;

    tableProps.th = [

      // mobile
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" >{I18n.get('CaseType')}</span>
          <span className="flex items-center" ><span className=" mr-3">/</span>{I18n.get('CaseNumber')}</span>
        </span>,
        class: 'flex l:hidden w-5/12 items-center pb-0',
        ellipsis: false,
      },
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" >{I18n.get('CaseSubject')}</span>
        </span>,
        class: 'flex l:hidden w-7/12 items-center pb-0',
        ellipsis: false,
        twoLinesEllipses: true,
      },

      // desktop
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" >{I18n.get('CaseType')}</span>
          <span className="flex items-center" ><span className=" mr-3">/</span>{I18n.get('CaseNumber')}</span>
        </span>,
        class: 'hidden l:flex w-3/12 ',
        ellipsis: false,
      },
      {
        text: <>{I18n.get('DateUpdated')}</>,
        class: 'hidden l:flex w-2/12  break-all',
        ellipsis: false
      },
      {
        text: <>{I18n.get('CaseSubject')}</>,
        class: 'hidden l:flex w-3/12 ',
        twoLinesEllipses: true,
      },
      {
        text: <>{I18n.get('AffectedAssets')}</>,
        class: 'hidden l:flex w-3/12 ',
        ellipsis: false,
      },

      // desktop & mobile
      { text: <></>, class: 'hidden l:w-1/12 l:flex ' }

    ]

    tableProps.tr = incidentsData.map((itemIncidents) => {
      return {
        trOnClick:()=> {
          let deviceDetailsUrl = app_url_incidents + '/org_' + organizationName + '/incidents/' + itemIncidents._id;
          window.location.href = deviceDetailsUrl;
        },
        cells: 
        [
          // mobile
          { text: <>{I18n.get(`IncidentType_${itemIncidents.recordType}`)}<br />{parseInt(itemIncidents.caseNumber).toString()}</>, class: 'flex l:hidden w-5/12 hover:cursor-pointer' },
          { text: <>{itemIncidents.subject}</>, class: 'flex l:hidden text-left items-center w-5/12 hover:cursor-pointer ' },

          // desktop
          { text: <>{I18n.get(`IncidentType_${itemIncidents.recordType}`)}<br />{parseInt(itemIncidents.caseNumber).toString()}</>, class: 'hidden l:flex w-3/12 hover:cursor-pointer' },
          { text: <>{ (itemIncidents.updatedAt?moment(itemIncidents.updatedAt).format('DD.MM.YYYY'):'' ) }</>, textPlain: (itemIncidents.updatedAt?moment(itemIncidents.updatedAt).format('DD.MM.YYYY'):'' ), class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer' },
          { text: <>{itemIncidents.subject}</>, textPlain: itemIncidents.subject, class: 'hidden l:flex text-left items-center w-3/12 hover:cursor-pointer' },
          { text: <>{getAffectedAssets(itemIncidents.assets)}</>, class: 'hidden l:flex text-left items-center w-3/12 hover:cursor-pointer whitespace-nowrap' },
          { text: <><svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></>, class: ' w-2/12 l:w-1/12 justify-center hover:cursor-pointer' }
        ],
      }
    })

    if(incidentsData.length <= 0 && !isInitialLoading){
      if(incidentFilterBy === IncidentFilterEnum.Open){
        tableProps.noDataLabel = I18n.get('NoOpenIncidents')
      }
      else if(incidentFilterBy === IncidentFilterEnum.RecentlyClosed){
        tableProps.noDataLabel = I18n.get('NoClosedIncidents')
      }
      else{
        tableProps.noDataLabel = I18n.get('noData')
      }
    }

    setTableProps(tableProps)
  }, [state.language,pagedIncidents]);

  return (
  <>
    <div className="flex justify-between items-center mb-5 mt-10 cp_incidents_table" data-testid="test_incidents_table">
      <h1>{I18n.get('Incidents')}</h1>
      <StatusTabs selectedTab={incidentFilterBy} setFilter={setIncidentFilterBy} valuesArray={IncidentFilterData} defaultTab={IncidentFilterEnum.Open} />
    </div>
    <Spin spinning={isLoading}>
      <div ref={topTableElementRef} className="idTableInstallations">
        <TableMain tableContent={tableProps} />
        {!isInitialLoading && pagedIncidents.incidents.length > 0 && <div className="h-24 bg-blueBaby flex justify-center items-center">
          <Pagination current={pageParam} pageSize={7} showSizeChanger={false} total={pagedIncidents.totalCount} onChange={(page, pageSize) => {
            setPageParam(page);
            window.scrollTo({
            top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
            behavior: 'smooth',
            });
            }} />
        </div>}
      </div>
    </Spin>
  </>)

  
}
export default IncidentsTable;