import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spin } from 'antd';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from './../../../utils/globalLocalization'
const colorsTheme = require('../../../../src/utils/aufzugheldenTheme')

I18n.putVocabularies(stringGlobal);

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import { BarChart } from 'echarts/charts';

import { useEffect, useState } from 'react';
import { DoorBlockagesResponse ,DoorBlockages} from '../../../interfaces/DoorBlockages';
import { useGlobalState } from '../../../utils/globalStateProvider';
import React from 'react';
import { DeviceInfo } from '../../../interfaces/DeviceInfo';

const DoorBlockagesChart = (props: { response: DoorBlockagesResponse|null, isLoading: boolean, device: DeviceInfo | null  }) => {
  const [noData, setNoData] = useState(false)
  const [dataLevel, setDataLevel] = useState<(number[])>([])
  const [dataAttempts, setDataAttempts] = useState<(number[])>([])
  const [maxFloorsByData, setMaxFloorsByData] = useState<(number)>(0)
  const [dataAttemptsFakeHeight, setDataAttemptsFakeHeight] = useState<(number[])>([])
  const [dataTime, setDataTime] = useState<(string[])>([])
  const [dataBlockTime, setDataDoorTime] = useState<(string[])>([])
  const [dataDuration, setDataDuration] = useState<(number[])>([])
  const { state, setState } = useGlobalState()
  const [mapping, setMapping] = useState<({[index: string]: string}|undefined)>(undefined)
  const [mappingWithOrgKeys, setMappingWithOrgKeys] = useState<({[index: string]: string}|undefined)>(undefined)
  const marginForBringingChartValueInPositovRange = 1
  const eChartsRef = React.useRef(null as any)
  const [chartIsReady, setChartIsReady] = useState(false)

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] == value);
  }

  moment.relativeTimeThreshold('ss',-1);

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [ state.language]);  

  useEffect(() => {
    const interval = setInterval(() => {
      if (eChartsRef && eChartsRef.current){
        // stop interval and init chart
        setChartIsReady(true)
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if(props.device?.mappingWithOrgKeys){
      setMappingWithOrgKeys(props.device.mappingWithOrgKeys)   
    }
    if(props.device?.mapping){
      setMapping(props.device.mapping)
    }
    
  }, [ props.device]);  

  useEffect(() => {
    let data:DoorBlockages[] = []

    if(! Array.isArray(props.response) || props.response.length <= 0){
      setNoData(true);
      return
    }
    
    if(!mappingWithOrgKeys){
      return
    }

    if(!mapping){
      return
    }

    data = props.response

    data.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1)
    
    const dataTmpLevel:number[] = [];
    const dataTmpAttempts:number[] = [];
    const dataTmpAttemptsFakeHeight:number[] = [];
    const dataTmpTime:string[] = [];
    const dataTmpDuration:number[] = [];
    const dataDoorBlockTime:string[] = []

    for (const item of data) {
      let yAxisValue = getKeyByValue(mapping,mappingWithOrgKeys[item.floor])

      if(yAxisValue!==undefined){
        dataTmpLevel.push(parseInt(yAxisValue)-0.5+marginForBringingChartValueInPositovRange);
        dataTmpAttempts.push(item.attempts);
        dataTmpAttemptsFakeHeight.push(1)
        
        dataTmpDuration.push(item.doorBlockDurationMs)

        dataTmpTime.push(moment.utc(item.timestamp).format('ddd DD.MM' ));
        dataDoorBlockTime.push(moment.utc(item.timestamp).format('HH:mm:ss'))
        if(item.floor > maxFloorsByData){
          setMaxFloorsByData(item.floor)
        }
      }
    }

    setDataLevel(dataTmpLevel);
    setDataAttempts(dataTmpAttempts);
    setDataAttemptsFakeHeight(dataTmpAttemptsFakeHeight);
    setDataTime(dataTmpTime);
    setDataDuration(dataTmpDuration);
    setDataDoorTime(dataDoorBlockTime)

    if (setDataLevel.length > 0) {
      setNoData(false);
    } else {
      setNoData(true);
    }

  }, [props.response,state.language,mappingWithOrgKeys,mapping,chartIsReady,eChartsRef]);

  useEffect(() => {
    if (eChartsRef && eChartsRef.current){
      eChartsRef.current?.getEchartsInstance().setOption(getOption());
    }

  }, [dataLevel,chartIsReady,eChartsRef,props.isLoading,state.language]);

  echarts.use([
    BarChart,
  ]);

  echarts.registerTheme('light', {
    backgroundColor: '#fff',
  });

  const getOption = () => {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          backgroundColor:colorsTheme.chartTooltipBackground,
          textStyle:{
            color:colorsTheme.chartTooltipTextColor
          },
          borderWidth:0,
          formatter: function (params: any[]) {
            if(!mapping){return }
            var tar = params[1];
            var index = params[0].dataIndex
            var result = `${tar.name}
                          <br/>${I18n.get('Level')}: ${(mapping[params[0].data+0.5-marginForBringingChartValueInPositovRange])}
                          <br/>${I18n.get('tries')}:  ${dataAttempts[index]}
                          <br/>${I18n.get('duration')}: ${moment.duration(dataDuration[index]/1000, "seconds").humanize()}
                          <br/>${I18n.get('time')}: ${dataBlockTime[index]} `
            
            return result
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          splitLine: { show: false },
          data: dataTime,
          axisLine:{
            show:false,
          },
        axisTick:{
          show:false
        }
        },
        yAxis: {
          type: 'value',
          splitLine:{
            show:true,
            lineStyle:{
              color:colorsTheme.chartBackgroundLineColor,
              opacity: colorsTheme.chartBackgroundLineOpacity
            }
          },
          axisLabel: {
            formatter: function (value:any) {
              if(mapping){
                return mapping[value-marginForBringingChartValueInPositovRange]
              }
            }
        }
        },
        series: [
          {
            name: 'Placeholder',
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            },
            emphasis: {
              itemStyle: {
                borderColor: 'transparent',
                color: 'transparent'
              }
            },
            data: dataLevel
          },
          {
            name: 'Versuche',
            type: 'bar',
            stack: 'Total',
            color:colorsTheme.chartMainColor,
            label: {
              show: false,
              position: 'inside',
              formatter: function (params:any) {
                var index = params.dataIndex
                return dataAttempts[index];
              }
            },
            data: dataAttemptsFakeHeight
          }
        ]
      }
  }

  return (
    <>
      {noData && (
        <div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight">{I18n.get('noDataForTimePeriod')}</div>
        </div>
      )}
      {!noData && (
        <Spin size="large" spinning={props.isLoading} key="spin">
          <ReactEChartsCore
            key="chartTurnchart"
            echarts={echarts}
            option={{}}
            notMerge={true}
            lazyUpdate={true}
            theme={'light'}
            showLoading={false}
            ref={eChartsRef}
            style={{ height: '300px', width: '100%' }}
          />
        </Spin>
      )}
    </>
  );
};
export default DoorBlockagesChart;
