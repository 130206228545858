import { strings as stringLocal} from './TableEventsLocalization'
import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { mergeDeepRight } from 'ramda'
import moment from 'moment'
import React, { useState } from 'react'
import {  } from 'react-router-dom'
import { useEffect } from 'react'
import './TableEvents.css';
import { FeedMessage } from '../../../interfaces/Lifefeed'
import arrowIotDown from '../../../assets/images/icons/arrow_down_iot.svg'
import arrowIotUp from '../../../assets/images/icons/arrow_up_iot.svg'
import { DeviceInfo } from '../../../interfaces/DeviceInfo'
import { useGlobalState } from '../../../utils/globalStateProvider'
import TableShared, { TableProps } from '../../../components/molecules/TableShared';
import { getLangByLocalStorage } from '../../../utils/helpers'

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)
moment.locale(lang? lang: 'de') ;

const TableEvents = (props: { device:DeviceInfo|null, storedFeedMessages: FeedMessage[], showMessagesCount?:number, pastEvents:any[] }) => {

    const { state } = useGlobalState();
    const [tableProps, setTableProps] = useState<TableProps | null >(null)

    // past events arent allready filtered... only livemessages are expected with that types
    const messagesToSkip = ['deviceSystemState','mqttPresence','deviceLowCanMessagesAlert','deviceNotMovingAlert']

      useEffect(() => {
        BuildItemList() 
      }, [props.storedFeedMessages,state.language,props.pastEvents]);

      const eventHumanReadableDsTable = (message:FeedMessage,debugEvent?:any):TableProps["tr"][0]|null => {

        if(!message.body?.name){
          return null
        }

        if(message.body?.name && messagesToSkip.includes(message.body.name)){
          return null
        }
        
        let messageLabel = <></>

        switch(message.body?.name) {
          case 'deviceDirectionChanged':
            //console.log(message,debugEvent)
            let directionChangedLabel = <></>
            switch(message.body?.attributes?.direction){
              case 'directionUp':
                directionChangedLabel = <img className="  ml-0" src={arrowIotUp} alt="" />
                break;
              case 'directionDown':
                directionChangedLabel = <img className="  ml-0" src={arrowIotDown} alt="" />
                break;
              case 'directionStop':
                return null
                break;
              default:
                directionChangedLabel = <>{message.body?.attributes?.direction}</>
            }
            messageLabel = <div className=" mt-2">{directionChangedLabel}</div>
            break;         
          case 'deviceMoved':
            messageLabel = <>{I18n.get('Level')} {message.body?.attributes?.mappedFloor}: {I18n.get('moving')}</>
            break;
          case 'deviceArrived':
            messageLabel = <>{I18n.get('Level')}  {message.body?.attributes?.mappedFloor}: {I18n.get('stop')}</>
            break;
          case 'deviceRun':
            let strLevelRun = ''
            if(message.body.attributes?.leveling){
              strLevelRun = '| ' + I18n.get('leveling')
            }
            messageLabel = <>{I18n.get('Level')}  {message.body?.attributes?.runEndFloor}: {I18n.get('endOfRun')} {strLevelRun} | {message.body.attributes?.runDurationMs} ms</>
            break;
          case 'deviceRun':
            let directionLabel = <></>
            switch(message.body?.attributes?.runDirection){
              case 'directionUp':
                directionLabel = <img className="  ml-0" src={arrowIotUp} alt="" />
                break;
              case 'directionDown':
                directionLabel = <img className="  ml-0" src={arrowIotDown} alt="" />
                break;
              default:
                directionLabel = <>{message.body?.attributes?.runDirection}</>
            }
            messageLabel = <div className=" mt-2">{directionLabel}</div>
            break;
          case 'deviceDoorCycle':
            messageLabel = <>{I18n.get('Level')} { message.body?.attributes?.mappedFloor }:  {I18n.get('endOfDoorMovement')} ({message.body?.attributes?.durationMs} ms)</>
            break;
          case 'deviceGoalFloorsChanged':
            messageLabel = <>{I18n.get('deviceGoalFloorsChanged')}</>
            break;      
          case 'deviceFloorInnerButtonPress':
            messageLabel = <>{I18n.get('deviceFloorInnerButtonPress')}, {I18n.get('goalFloor')}: {message.body.attributes?.mappedGoalFloors? message.body.attributes?.mappedGoalFloors[0] : ''}</>
            break;   
          case 'deviceFloorOuterButtonPress':
            messageLabel = <>{I18n.get('deviceFloorOuterButtonPress')}, {I18n.get('goalFloor')}: {message.body.attributes?.mappedGoalFloors? message.body.attributes?.mappedGoalFloors[0] : ''}</>
            break;  
          case 'deviceDoorStateChanged':
            let tmpMessageLabel = ''
            message.body?.attributes?.doors?.forEach((element,index) => {
              let doorStateLabel = ''
              if(element.doorState === undefined){
                return false
              }
              switch(element.doorState){
                case 'doorClosed':
                  doorStateLabel = I18n.get('closed')
                  tmpMessageLabel += I18n.get('door') +' ' + index + ': ' + doorStateLabel + ', '
                  break;
                case 'doorClosing':
                  doorStateLabel = I18n.get('closing')
                  tmpMessageLabel += I18n.get('door') +' ' + index + ': ' + doorStateLabel + ', '
                  break;
                case 'doorOpening':
                  doorStateLabel = I18n.get('opens')
                  tmpMessageLabel += I18n.get('door') +' ' + index + ': ' + doorStateLabel + ', '
                  break;
                case 'doorOpened':
                  doorStateLabel = I18n.get('open')
                  tmpMessageLabel += I18n.get('door') +' ' + index + ': ' + doorStateLabel + ', '
                  break;
                case 'doorMoving':
                  doorStateLabel = I18n.get('doorIsMoving')
                  tmpMessageLabel += I18n.get('door') +' ' + index + ': ' + doorStateLabel + ', '
                  break;
                default:
                  doorStateLabel = element.doorState
                  tmpMessageLabel += I18n.get('door') +' ' + index + ': ' + doorStateLabel + ', '
                }
            })
            messageLabel = <>{tmpMessageLabel.substr(0, tmpMessageLabel.length - 2)}</>
            break;            
          default:
            messageLabel = <>{I18n.get(message.body?.name)}</>
        }

        return {cells:[
          {text:<>{moment(message.timestamp).format('DD.MM.YYYY ' )}</>,class:'hidden m:flex w-1/4'},
          {text:<>{moment(message.timestamp).format('HH:mm:ss ' )}</>,class:'hidden m:flex w-1/4'},
          {text:<>{moment(message.timestamp).format('DD.MM.YYYY ' )}<br/>{moment(message.timestamp).format('HH:mm:ss' )}</>,class:'flex m:hidden w-1/4'},
          {text:<>{messageLabel}</>,class:'flex w-3/4 m:w-2/4'},
          ]}

      }

      const BuildItemList = () => {
        
        let tableP:TableProps = {th:[],tr:[],noDataLabel:I18n.get('noDataForTimePeriod'),height:'max-h-[50vh]', heightPrefs: 'large'}

        let tableRowItems:any = []
        let countMessagesToShow = props.showMessagesCount? props.showMessagesCount : 10
        props.storedFeedMessages?.slice(0,countMessagesToShow).forEach( (message) => {

          let tableItem = eventHumanReadableDsTable(message)
          if(tableItem){
            tableP.tr.push(tableItem)
          }
        })
        let rowItemsCount = tableRowItems.lenght
        let counterPastEventes = 0
        if(!rowItemsCount){rowItemsCount = 0}
        for (rowItemsCount; rowItemsCount < 100 ; rowItemsCount++) {
          if(props.pastEvents && props.pastEvents && props.pastEvents[counterPastEventes]){
            let event = props.pastEvents[counterPastEventes]

            let doorStates:any[] = [] 
            if(event.attributes?.doors && Array.isArray(event.attributes.doors)){
              event.attributes.doors.forEach(dState => {
                if(dState.doorState){
                  doorStates.push({doorState:dState.doorState})
                }
              })
            }

            let feedMessageObject:FeedMessage = {
              body:{
                name:event.event,
                attributes:{
                  runEndFloor:event.attributes.runEndFloor,
                  runDurationMs:event.attributes.runDurationMs,
                  durationMs:event.attributes.durationMs,
                  mappedFloor:event.attributes.mappedFloor,
                  direction:event.attributes.direction,
                  mappedGoalFloors:event.attributes.mappedGoalFloors,
                  goalFloors:[event.attributes.goalFloor],
                  leveling:event.attributes.leveling,
                  doors:doorStates
                }
              },
              deviceId:props.device?.edgeboxName?props.device.edgeboxName:'',
              timestamp:event.timestamp.replace('Z','')           
              }
            let tableItem = eventHumanReadableDsTable(feedMessageObject)
            if(tableItem){
              tableP.tr.push(tableItem)
            }

            rowItemsCount++;
            counterPastEventes++;
          }
          else{
            rowItemsCount++;
          }
        }

        tableP.th = [
          {text:<>{I18n.get('date')}</>,class:'hidden m:flex w-1/4 items-center'},
          {text:<>{I18n.get('time')}</>,class:'hidden m:flex w-1/4 items-center'},
          {text:<>{I18n.get('time')}</>,class:'flex m:hidden w-1/4 items-center'},
          {text:<>{I18n.get('event')}</>,class:'w-3/4 m:w-2/4 flex items-center',ellipsis:false},
        ]
    
        setTableProps(tableP)
      }

  return (
    <div className="idTableEvents flex flex-col">
      <TableShared tableContent={tableProps} />
    </div>
  );
};

export default TableEvents;
