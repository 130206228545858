import React, { useEffect } from "react";
import { Category } from "../../interfaces/Category";
import { DsFile, DsFilePreloaded, DsFileResponse } from "../../interfaces/File";
import { I18n } from "@aws-amplify/core";
import { useGlobalState } from "../../../utils/globalStateProvider";
import moment from "moment";
import DocumentDialog from "./documentDialog";
import { Library } from "../../interfaces/Library";
import { isSalesforceView } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import ViewTile from "./viewTile";

const ViewTileList = (props:{
  fileResponse:DsFileResponse,
  allFileResponse:DsFileResponse|null,
  paginationPage:number|null,
  paginationSize:number|null,
  library:Library,
  category:Category,
  sorter?:{ columnKey: string, order: string }
}) => {

    const { state, setState } = useGlobalState()
    const [selectedFile, setSelectedFile] = React.useState<DsFilePreloaded | null>(null)
    let { assetId, contractId,libraryId,categoryMainId,installationId } = useParams();

    useEffect(() => {
      if(state.language){
        I18n.setLanguage(state.language)
        moment.locale(state.language)
        document.documentElement.lang = state.language
      }
    }, [state.language]);

    useEffect(() => {

    }, []);

    const onCLickFileItem = (file:DsFile) => {
      if(isSalesforceView()){

        let url
        let windowName

        if(contractId){
          url = document.location.origin + '/salesforce/documents/contract/' + contractId + '/' + libraryId + '/' + categoryMainId + '/' + file.id
          windowName = 'salesforce_preview_' + contractId + '_' + libraryId + '_' + categoryMainId
        }
        else{//assetId
          url = document.location.origin + '/salesforce/documents/asset/' + assetId + '/' + libraryId + '/' + categoryMainId + '/' + file.id
          windowName = 'salesforce_preview_' + assetId + '_' + libraryId + '_' + categoryMainId
        }

        let newWindow = window.open( url , windowName )
        if(newWindow){
          newWindow.focus()
        }      
      }
      else{
        setSelectedFile({file:file,imageSrc:null})
      }
    }

    return (
      <>
        <div className=" mt-10 grid grid-cols-2  s:grid-cols-3 m:grid-cols-3 l:grid-cols-4 xl:grid-cols-4 gap-7">
          {props.fileResponse?.files.map((file,index)=>{
          return <ViewTile key={index} file={file} onCLickFileItem={onCLickFileItem} />
        })}
        </div>
        {props.fileResponse?.files === undefined || props.fileResponse?.files.length <= 0 && <div className="flex h-28 " ><div className="m-auto text-sm m:text-lg text-greyLight ">{I18n.get('noFiles')}</div></div>}
        {selectedFile && !isSalesforceView() && <DocumentDialog 
        isLastSeenMode={false}
        allFileResponse={props.allFileResponse} 
        file={selectedFile} 
        setSelectedFile={setSelectedFile} 
        paginationPage={props.paginationPage} 
        paginationSize={props.paginationSize}
        category={props.category}
        library={props.library}
        sorter={props.sorter}
        mode={(installationId)?'Installation':'Library'}
        />}
        </>
      );

}
export default ViewTileList