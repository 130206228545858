import React, { useEffect, } from 'react';
import { strings as stringLocal} from './NewsUpdateItemLocalization';
import {  useGlobalState } from '../../utils/globalStateProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { langChange, langInit } from '../../utils/helpers';
import { iProductSection } from '../interfaces/Products';
import { getIcon } from '../../utils/icons';

const ProductsSection = (props:{productSection:iProductSection}) => {
  
  const { state, setState } = useGlobalState()
  const navigate = useNavigate();
  let { productId } = useParams();

  useEffect(() => {
    if(state.language){ langChange({state:state,updateDocumentTitle:true}) } else { langInit( {stringLocal:stringLocal,setStateGlobal:setState} ) }
  }, [state.language]);

  return (
    <div className="flex flex-col xl:flex-row mb-20 xl:mb-[150px] xl:mt-[150px] ">
      <div className=" xl:w-3/12 text-xl m:text-3xl xl:text-[36px] font-bold mb-10 xl:mb-0">{state.language? props.productSection.title[state.language] : ''}</div>
      <div className=" xl:w-9/12 text-base m:text-lg xl:text-[24px] font-bold">
        {state.language? props.productSection.pages.map((page,index) => {
          return <div className="mb-2 pb-4 xl:mb-10 xl:pb-10 border-b xl:border-b-2 border-blueLight2 cursor-pointer flex items-center last:border-0 last:pb-0 last:mb-0" key={index} onClick={()=>{navigate(`/products/${productId}/${page.id}`)}}>
            <div className="flex-grow ">{state.language ? page.title[state.language]:''}</div>
            <div className="flex-shrink mr-1 ml-4 xl:ml-16">{getIcon( {type:'ArrowLink',ownClassName:' w-6 m:w-7  xl:w-8'} )}</div>
          </div>
        }):<></>}
      </div>
    </div>
  );
};

export default ProductsSection