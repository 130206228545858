
export const strings = {
    en: {
      firstLine: 'Use of cookies:',
      secondLine: 'In order to optimally design our website for you and to be able to continuously improve it, we use cookies.',
      moreAbout: 'Learn more',
      acceptCookies: 'Allow cookies',
      denieCookies: 'Reject cookies',
    },
    de: {
      firstLine: 'Verwendung von Cookies:',
      secondLine: 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, nutzen wir Cookies.',
      moreAbout: 'Mehr erfahren',
      acceptCookies: 'Cookies zulassen',
      denieCookies: 'Cookies ablehnen',
    },
  };