export const strings = {
    en: {
      welcomeMessage: 'Welcome back',
      welcomeMessageFollow : 'Here you will find an overview of ###number### libraries.',
      nameApp: 'Documents'
    },
    de: {
      welcomeMessage: 'Willkommen zurück',
      welcomeMessageFollow : 'Hier finden Sie eine Übersicht zu ###number### Bibliotheken.',
      nameApp: 'Dokumente'
    },
  };