import { useEffect, useState } from 'react'
import React from 'react';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { UserHasPermissionsFor, appLinkFor, getAuth0Token, langChange, langInit } from '../../utils/helpers';
import { getIcon } from '../../utils/icons';
import { strings as stringLocal} from './../utils/globalLocalization';
import WelcomeWidgetAssets from './WidgetAssets';
import WelcomeWidgetContracts from './WidgetContracts';
import WelcomeWidgetMessages from './WidgetMessages';
import { useAuth0 } from '@auth0/auth0-react';

export type widgetTypeOptions =
{ 
  presentAsWidget?:true,
  colSpan:'col-span-1'|'col-span-1 l:col-span-2'|'col-span-3'|'col-span-4'| 'col-span-1 m:col-span-2 l:row-span-2' | 'hidden l:col-span-2' | 'col-span-2'
  
}|{
  presentAsWidget:false
}

export const getHeadLine = (props:{headline:string,type:'Devices'|'Documents'|'Messages',link?:'Devices'|'Documents'|'Contracts',state?:Partial<GlobalStateInterface>})=>{
  
  const handleClick = () => {
    if (props.link && props.state) {
      document.location.href = appLinkFor(props.link, props.state);
    }
  };

  return <div className="flex items-center p-[23px] pb-0">
    <div className=" w-[42px] h-[42px] rounded-lg bg-blueDark flex items-center justify-center mr-3">
        {getIcon({type:props.type,ownClassName:'fill-white w-7'})}
    </div>
    <div className="uppercase text-base font-bold">{props.headline}</div>
    <div onClick={handleClick} className={`${props.link && props.state ? 'cursor-pointer' : ''} flex-grow justify-end flex`}>{getIcon({type:'ArrowRight',ownClassName:'fill-blueLight w-7 h-7'})}</div>
  </div>
}

export default function WelcomeWidgets() {

  const { state, setState } = useGlobalState()
  const { user, getAccessTokenSilently } = useAuth0()
  const [userHasPermissionDevices, setUserHasPermissionDevices] = React.useState<boolean>(false)
  const [userHasPermissionContracts, setUserHasPermissionContracts] = React.useState<boolean>(false)
  
  useEffect(() => {
    if(state.language){ langChange({state:state}) } else { langInit({ stringLocal} ) }
  }, [state.language]);

  useEffect(() => {
    const GetPermissions = async () => {
      const accessToken = await getAuth0Token(getAccessTokenSilently)
      UserHasPermissionsFor('Devices',accessToken)?setUserHasPermissionDevices(true):setUserHasPermissionDevices(false)
      UserHasPermissionsFor('Contracts',accessToken)?setUserHasPermissionContracts(true):setUserHasPermissionContracts(false)

      // test
      // setUserHasPermissionDevices(false)
      // setUserHasPermissionContracts(false)
    }
    GetPermissions()
  
  }, []);

  return (
    <>    
    { (userHasPermissionDevices && userHasPermissionContracts) &&  
      <div className=" grid grid-cols-1 m:grid-cols-2 gap-y-4 m:gap-4 bg-blueLight widget">
        { userHasPermissionDevices && <WelcomeWidgetAssets colSpan={ 'col-span-1' } />}
        { userHasPermissionContracts && <WelcomeWidgetContracts colSpan={ 'col-span-1' } />}
      </div>
    }
    { (userHasPermissionDevices && !userHasPermissionContracts) &&  
      <div className=" grid grid-cols-1 bg-blueLight widget">
        <WelcomeWidgetAssets colSpan={ 'col-span-1' } />
      </div>
    }
    { (!userHasPermissionDevices && userHasPermissionContracts) &&  
      <div className=" grid grid-cols-1 bg-blueLight widget">
        <WelcomeWidgetContracts colSpan={ 'col-span-1' } />
      </div>
    }
    </>
  )
}
