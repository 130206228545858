import { SpecialZoomLevel, Worker } from '@react-pdf-viewer/core';
import React, { useEffect, useState } from 'react';
import { Viewer,ViewMode } from '@react-pdf-viewer/core';
import { toolbarPlugin,TransformToolbarSlot } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { ToolbarSlot } from '@react-pdf-viewer/default-layout';

const PdfViewer = (props:{url?:string}) => {
    
    const [pdfUrl,setPdfUrl] = useState<string|undefined>(undefined)

    useEffect(() => {
        if( props.url && (pdfUrl != props.url))
        {
            setPdfUrl(props.url)
        }
      }, [props.url]);


      const toolbarPluginInstance = toolbarPlugin();
      const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  
      const transform: TransformToolbarSlot = (slot: ToolbarSlot) => {
          const { NumberOfPages } = slot;
          return Object.assign({}, slot, {
            Download:()=>{return <></>},
            Open:()=>{return <></>},
            SwitchTheme:()=>{return <></>},
            Print:()=>{return <></>},
            EnterFullScreen:()=>{return <></>},
            NumberOfPages: () => (
                <>
                    of <NumberOfPages />
                </>
            ),
          });
      };

    return (
    <Worker workerUrl="/assets/scripts/pdf.worker.min.js">
        <div className=" w-[calc(100vw-2em)] m:w-full h-96 m:h-[68vh] m:mb-[8%] l:mb-[7%] ml-auto mr-auto overflow-hidden cp_modaldialog_file">
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '4px',
                }}
                >
                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
            </div>

            {pdfUrl && <Viewer 
            fileUrl={pdfUrl}
            initialPage={0}
            viewMode={ViewMode.SinglePage}
            plugins={[toolbarPluginInstance]}
            defaultScale={SpecialZoomLevel.PageWidth}
            /> }
        </div>
    </Worker>);

}
export default PdfViewer