import { strings as stringLocal } from './TableInstallationsLocalization'
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { mergeDeepRight } from 'ramda'
import { app_url_devices } from '../../utils/consts'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Pagination } from 'antd'
import { GlobalStateInterface, useGlobalState } from "../../utils/globalStateProvider"
import { useEffect } from 'react'
import { Installation } from '../../interfaces/Installation'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import TableMain, { TableProps } from '../../components/molecules/TableMain'
import { Case } from '../interfaces/Case'
import { fetchIncidentsIntallations } from '../utils/helpersFetch'
import { getAuth0Token, getLangByLocalStorage } from '../../utils/helpers'
import './TableInstallations.css'

I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
const lang = getLangByLocalStorage()
I18n.setLanguage(lang)

const TableInstallations = (props: { incident:Case }) => {
  const { state, setState } = useGlobalState()
  const [pageUrlParam, setPageUrlParam] = React.useState<number>(1)
  const [sortByUrlParam, setSortByUrlParam] = React.useState<string | undefined>()
  const [sortedInfo, setSortedInfo] = React.useState({ columnKey: '', order: '' })
  const topTableElementRef = useRef<null | HTMLTableElement>(null);
  const [tableProps, setTableProps] = useState<TableProps | null >(null)
  const [currentIncident,setCurrentIncident] = useState<Case | undefined>(undefined)
  const [totalNumberIncidentInstalations, setTotalNumberIncidentInstalations] = React.useState<number>(0)
  const [incidentInstallations,setIncidentInstallations] = useState<Installation[]>([])

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [ state.language]);

  useEffect(() => {
    setCurrentIncident(props.incident)
  }, [ props.incident ]);

  useEffect(() => {
    // load installations
    const loadAssets = async () => {
      if(!currentIncident){
        return 
      }

      try {
        let assetIDs = currentIncident.assets.map(item => { return item.assetId })
        //let assetIDs = ["63ea025d99bf4a22da239754","63ea025d99bf4a22da239766","63ea025d99bf4a22da239760","63ea025d99bf4a22da239794","63ea025e99bf4a22da2397fc","63ea025e99bf4a22da239808","63ea025e99bf4a22da23980e","63ea025e99bf4a22da239814","63ea025e99bf4a22da23981a","63ea025e99bf4a22da239820","63ea025e99bf4a22da239826","63ea025e99bf4a22da239832","63ea025e99bf4a22da239838","63ea025e99bf4a22da23983e","63ea025e99bf4a22da239844","63ea025e99bf4a22da23995d","63ea025f99bf4a22da239a27","63ea026099bf4a22da239c0d","63ea026099bf4a22da239c3a","63ea026199bf4a22da239ca8","63ea025d99bf4a22da239772","63ea026799bf4a22da23a6e2","63c01f9fb633ea71bbea5b6c","63c01f9fb633ea71bbea5b78","63ea025d99bf4a22da23973f","63ea025d99bf4a22da23976c","63ea025d99bf4a22da23979a","63ea025e99bf4a22da239802","63ea025e99bf4a22da23982c","63ea025e99bf4a22da23996f","63ea026099bf4a22da239c1c","63ea026099bf4a22da239c2e","63ea026099bf4a22da239c75","64513059ef71389a2914fa9f","63ea026799bf4a22da23a6e8","63c01fa1b633ea71bbea601e","63c01fa2b633ea71bbea61b9","63c01fa0b633ea71bbea5e2f","63ea026799bf4a22da23a59f","63ea025d99bf4a22da239739","64994df0ed920ed815d9e6b5","64994e73ed920ed815d9e6cc","63c01f9fb633ea71bbea5b72","63ea026099bf4a22da239c22","64995af9ed920ed815da7d4a","6499744ced920ed815db38b0","6499744ced920ed815db38bc","63c01f9fb633ea71bbea5b66","63ea026099bf4a22da239c6f","6499744ced920ed815db38b6","63c01fa1b633ea71bbea60fc","63c01fa1b633ea71bbea6102","63ea026099bf4a22da239bfb","63ea026099bf4a22da239c01","63ea026099bf4a22da239c07","63ea026099bf4a22da239c16","63ea026099bf4a22da239c28","63ea026099bf4a22da239c34","649970b6ed920ed815dacbe4","6499744ced920ed815db38aa","63ea025d99bf4a22da23975a","63ea025d99bf4a22da239745"]
        let assets = await fetchIncidentsIntallations(pageUrlParam,10,(sortByUrlParam?sortByUrlParam:''),assetIDs, await getAuth0Token(getAccessTokenSilently))
        if(assets){
          setTotalNumberIncidentInstalations(assets.totalCount)
          setIncidentInstallations(assets.assets)
        }
      }
      catch (error) {
        console.error('Error fetching incidents:', error.message);
      } 
      finally { 
      }
    }
    loadAssets()

  }, [ currentIncident, pageUrlParam, sortedInfo ]);

  useEffect(() => {
    BuildItemList()
  }, [totalNumberIncidentInstalations, incidentInstallations, state.language]);

  const onRowClicked = (installation: Installation) => {
    document.location.href = (app_url_devices + '/org_' + state.organizationName + '/installations/' + installation._id)
  }

  const sortChange = (sorter: { columnKey: string, order: string }) => {

    if (sorter.order === '') {
      setSortedInfo({ columnKey: '', order: '' })
    }
    else {
      setSortedInfo(sorter)
    }

    let sortBy = ''
    if (sorter.columnKey && sorter.order) {
      sortBy = sorter.columnKey + ':' + (sorter.order === 'asc' ? 'asc' : 'desc')
    }
    setPageUrlParam(1)
    setSortByUrlParam(sortBy)
  };

  const getSortOrder = (columnKey: string): string => {
    if (sortedInfo.columnKey === columnKey) {
      if (sortedInfo.order === 'asc') {
        return 'desc'
      }
      else if (sortedInfo.order === 'desc') {
        return ''
      }
      else {
        return 'asc'
      }
    }
    else {
      return 'asc'
    }
  }

  const getLabel = (installation:Installation) => {
    if(installation.customLabel && installation.customLabel.trim().length > 0 
      && installation.customLabel?.trim() !== installation.label){
      return installation.customLabel + '/' + installation.label
    }
    else{
      return installation.label
    }
  }

  const getSortVisualisation = (columnKey: string) => {

    if (columnKey === sortedInfo.columnKey) {
      if (sortedInfo.order === 'asc') {
        return <CaretUpOutlined style={{ 'color': 'white' }} />
      }
      else {
        return <CaretDownOutlined style={{ 'color': 'white' }} />
      }
    }
    return <CaretUpOutlined style={{ 'color': 'grey' }} />
  }

  const BuildItemList = () => {

    let tableP:TableProps = {th:[],tr:[],noDataLabel:I18n.get('NoAffectedAssets'),height:'unset',thead:'sticky-documents-detail'}

    tableP.th = [
      
      // mobile
      {
        text:<><span className="mr-4">{I18n.get('elevator')}</span>{getSortVisualisation('label')}</>,
        class:'flex l:hidden w-2/12 items-center pb-0',
        ellipsis:true,
        onCellClick:() => sortChange({ columnKey: 'label', order: getSortOrder('label') })
      },          
      {
        text:<><span className="mr-4">{I18n.get('address')}</span>{getSortVisualisation('address')}</>,
        class:'flex l:hidden w-5/12 items-center pb-0',
        twoLinesEllipses:true,
        onCellClick:() => sortChange({ columnKey: 'address', order: getSortOrder('address') })
      },
      {
        text:<span className="flex flex-col pb-0 text-left">
          <span className="" >{I18n.get('Last')} / {I18n.get('Next')}</span>
          <span className="" >{I18n.get('Maintenance')}</span>
        </span>,        
        class:'flex l:hidden w-5/12 items-center pb-0 text-left',
        ellipsis:false,
      },

      // desktop
      {
        text:<><span className="mr-4">{I18n.get('elevator')}</span>{getSortVisualisation('label')}</>,
        class:'hidden l:flex w-2/12 ',
        twoLinesEllipses:true,
        onCellClick:() => sortChange({ columnKey: 'label', order: getSortOrder('label') })
      },          
      {
        text:<><span className="mr-4">{I18n.get('address')}</span>{getSortVisualisation('address')}</>,
        class:'hidden l:flex w-3/12 ',
        twoLinesEllipses:true,
        onCellClick:() => sortChange({ columnKey: 'address', order: getSortOrder('address') })
      },
      {
        text:<>{I18n.get('MaintenanceInterval')}</>,
        class:'hidden l:flex w-2/12 text-left',
        ellipsis:false,
      },
      {
        text:<>{I18n.get('Last')} / {I18n.get('Next')} <br/>{I18n.get('Maintenance')} </>,
        class:'hidden l:flex w-2/12 text-left ',
        ellipsis:false,
      },
      {
        text:<span className="text-left">{I18n.get('AuditInterval')} / <br/> {I18n.get('LastDateOfMainAudit')}</span>,
        class:'hidden l:flex w-2/12 ',
        ellipsis:false,
      },
      {text:<></>,class:'hidden l:flex w-1/12 '}
    ]

    if(incidentInstallations.length > 0){
      tableP.tr = incidentInstallations.map((itemInstallation, index) => {
        let dataRow:TableProps["tr"][0] =  {cells:[
          //itemInstallation.
          // mobile
          {text:<>{getLabel(itemInstallation)}</>,textPlain:getLabel(itemInstallation) ,class:'flex l:hidden text-left items-center w-2/12 hover:cursor-pointer '},
          {text:<>{itemInstallation.building?.label}</>,textPlain:itemInstallation.building?.label,class:'flex l:hidden w-5/12 hover:cursor-pointer'},
          {text:<>{(itemInstallation.lastDateOfMaintenance?moment(itemInstallation.lastDateOfMaintenance).format('DD.MM.YYYY'):'' )}
            {((!itemInstallation.lastDateOfMaintenance && !itemInstallation.lastDateOfMaintenance)?'':' / ')}<br/>
            {(itemInstallation.nextDateOfMaintenance?moment(itemInstallation.nextDateOfMaintenance).format('DD.MM.YYYY'):'')}</>,class:'flex l:hidden w-5/12 hover:cursor-pointer'},

          // desktop
          {text:<>{getLabel(itemInstallation)}</>,textPlain:getLabel(itemInstallation) ,class:'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer '},
          {text:<>{itemInstallation.building?.label}</>,textPlain:itemInstallation.building?.label,class:'hidden l:flex w-3/12 hover:cursor-pointer'},
          {text:<>{itemInstallation.maintenanceInterval?(itemInstallation.maintenanceInterval + ' ' + I18n.get('Months')):''}</>,class:'hidden l:flex w-2/12 hover:cursor-pointer'},
          {text:<>{(itemInstallation.lastDateOfMaintenance?moment(itemInstallation.lastDateOfMaintenance).format('DD.MM.YYYY'):'' )}
            {((!itemInstallation.lastDateOfMaintenance && !itemInstallation.lastDateOfMaintenance)?'':' / ')}<br/>
            {(itemInstallation.nextDateOfMaintenance?moment(itemInstallation.nextDateOfMaintenance).format('DD.MM.YYYY'):'')}</>,class:'hidden l:flex w-2/12 hover:cursor-pointer'},
          {text:<>{
            (itemInstallation.auditInterval? itemInstallation.auditInterval : '') + ' ' }
             / <br/> 
            {(itemInstallation.lastDateOfMainAudit?moment(itemInstallation.lastDateOfMainAudit).format('DD.MM.YYYY'):'')}</>,class:'hidden l:flex w-2/12 hover:cursor-pointer'},
          {text:<><svg className="mr-14" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></>,class:'hidden l:flex flex-col w-1/12 justify-center hover:cursor-pointer'}
        ],trOnClick:(e)=>{onRowClicked(itemInstallation)}}
        return dataRow
      })
    }

    setTableProps(tableP)
  }

  return (
    <div className="idTableInstallationsForContract flex flex-col" ref={topTableElementRef}>
      <TableMain tableContent={tableProps} />
      <div className=" h-24 bg-blueBaby flex justify-center items-center">
        <Pagination current={pageUrlParam} pageSize={10} showSizeChanger={false} total={totalNumberIncidentInstalations} onChange={(page, pageSize) => {
            setPageUrlParam(page);
            window.scrollTo({
              top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
              behavior: 'smooth',
            });
        }} />
      </div>
    </div>
  );
};

export default TableInstallations;
