export const strings = {
  en: {
    doorCyclesTotalDetailHead: 'Today total doorcycles',
    runsTotalDetailHead: 'Today total runs',
    Type:'Type',
    Uploaded:'Uploaded',
    UploadedBy:'Uploaded by',
    UploadedDate:'Upload date',
    Description:'Description',
    UserTableHead:'User',
    Size:'Size',
    noFiles:'This folder is empty',
    Filename:'Filename',
    AllSubCatagories:'All subcategories',
    MatchDeepLinkCategoryError:'The category is not available.',
    Document_preview_not_supported_for_this_file_type:'Document preview not supported for this file type'
  },
  de: {
    doorCyclesTotalDetailHead: 'Türzyklen Gesamt heute',
    runsTotalDetailHead: 'Fahrten gesamt heute',
    Type:'Typ',
    UploadedBy:'Hochgeladen von',
    UploadedDate:'Hochgeladen am',
    Description:'Beschreibung',
    Uploaded:'Hochgeladen',
    UserTableHead:'Nutzer',
    Size:'Größe',
    noFiles:'Dieser Ordner ist leer',
    Filename:'Dateiname',
    AllSubCatagories:'Alle Unterkatergorien',
    MatchDeepLinkCategoryError:'Die Kategorie ist nicht verfügbar',
    Document_preview_not_supported_for_this_file_type:'Für diesen Dateityp wird die Vorschau nicht unterstützt.'
  },
};