import React, { ReactNode } from 'react';

export interface ButtonProps {
  disabled?: boolean,
  isInactive?: boolean
  label: string
  size: 'extra small' | 'small' | 'medium'
  theme: 'dark' | 'light',
  icon?: ReactNode,
  ownStyle?: string
  onClick?: () => void;
}

export const Button = ({
  disabled = false,
  isInactive = false,
  label,
  theme = 'light',
  size = 'small',
  icon = '',
  ownStyle = '',
  onClick,
  ...props
}: ButtonProps) => {

  let baseStyles = 'rounded-sm pl-20 pr-20 items-center inline-flex'
  let themeStyles = (theme === 'dark') ? ' bg-blueDark ' : ' bg-blueLight '
  let sizeStyles = (size === 'small') ? ' pl-9 pr-9 pt-2 pb-2 text-base xxl:text-[18px] tracking-[1px] font-normal ' : ' pt-2.5 pb-2.5 pr-4 pl-4 m:pr-20 m:pl-20 text-lbase m:text-lg '
  sizeStyles = (size === 'extra small') ? ' pt-1 pb-1 pl-3 pr-3 text-sm xxl:text-lbase ' : sizeStyles

  let disabledStyles = (disabled ? ' bg-disabledButton cursor-not-allowed ' : ' cursor-pointer ');
  let isInactiveStyles = (isInactive ? ' bg-inActiveButton' : ' cursor-pointer ');

  let textColor = ' text-blueDark ' 
  if(theme === 'dark'){
    if(disabled || isInactive){
      textColor = ' text-blueDark '
    }
    else{
      textColor = ' text-white '
    }
  }
  else if(theme === 'light'){
    if(disabled || isInactive){
      textColor = ' text-blueDark '
    }
    else{
      textColor = ' text-white '
    }
  }
  
  let styles = baseStyles + themeStyles + sizeStyles + isInactiveStyles + disabledStyles + textColor + ownStyle;

  return (
    <div {...props}
    className={styles} 
    onClick={ onClick }>
      {icon && <span>{icon}</span>}
      <span>{label}</span>
    </div>
  );
};
