import { I18n } from "@aws-amplify/core";
import { useEffect, useRef, useState } from "react";
import { useGlobalState } from "../../../utils/globalStateProvider";
import React from "react";
import DoorOpenClosings from "./DoorOpenClosings";
import { Spin } from "antd";
import { DoorCyclesOverTimeResponse } from "../../../interfaces/DoorCyclesOverTime";
import { DateRange } from "../../../interfaces/DateRange";
import { fetchDoorCyclesOverTime, setOrReportError } from "../../../utils/helpersFetch";
import { useAuth0 } from "@auth0/auth0-react";
import { DoorCyclesCount } from "../../../interfaces/DoorCyclesCount";
import { getAuth0Token } from "../../../utils/helpers";

const DoorOpenClosingsChartMema = React.memo(DoorOpenClosings)

const DoorOpenClosingsWrapper = (props: { deviceId: string | undefined; dateRange:DateRange; speakingTimePeriod:string }) => {

  const { state, setState } = useGlobalState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [doorOpenClosingsOverTime, setDoorOpenClosingsOverTime] = useState<null | DoorCyclesOverTimeResponse>(null)
  const [doorCyclesCount, setDoorCyclesCount] = useState<null | DoorCyclesCount>(null)
  const { getAccessTokenSilently } = useAuth0()
  const controllerRef1 = useRef<AbortController | null>()

  const fetchAllData = async (deviceId: string,accessToken:string, dateRange: DateRange) => {

    const queryStringWithIntervalFixToDay = `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&interval=day`

    return Promise.all([
      fetchDoorCyclesOverTime(deviceId, queryStringWithIntervalFixToDay,accessToken,controllerRef1),
    ]);
  };

  const fetchData = async () => {
    setIsLoading(true)

    if (props.deviceId) {
      const [doorOpenClosingsOverTimeResult] = await fetchAllData(props.deviceId, await getAuth0Token(getAccessTokenSilently), props.dateRange);

      if(!doorOpenClosingsOverTimeResult ||  !Array.isArray(doorOpenClosingsOverTimeResult) || doorOpenClosingsOverTimeResult.length <= 0){
        setDoorCyclesCount(null)
      }
      else{

        var doorCyclesCountResult:DoorCyclesCount = {
          closingCount:0,
          doorCycleCount:0,
          openingCount:0
        }
        doorOpenClosingsOverTimeResult.forEach(item => {
          if(item.closingCount && item.closingCount > 0){
            doorCyclesCountResult.closingCount += item.closingCount
          }
          if(item.doorCycleCount && item.doorCycleCount > 0){
            doorCyclesCountResult.doorCycleCount += item.doorCycleCount
          }
          if(item.openedCount && item.openedCount > 0){
            doorCyclesCountResult.openingCount += item.openedCount
          }
          else if(item.openingCount && item.openingCount > 0){
            doorCyclesCountResult.openingCount += item.openingCount
          }
        })
        setDoorCyclesCount(doorCyclesCountResult)
        setOrReportError(setDoorCyclesCount, doorCyclesCountResult)
      }

      setDoorOpenClosingsOverTime(null)
      setOrReportError(setDoorOpenClosingsOverTime, doorOpenClosingsOverTimeResult)
    }

    setIsLoading(false);
  }

  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language]);

  useEffect(() => {
    fetchData()
  }, [ props.dateRange,props.deviceId]);


  return (
    <>
      <section>
        <div className="Content bg-blueBaby text-blueDark text-base xxl:text-lg">
          <div className="flex flex-col m:flex-row pt-6 xxl:pt-12 pb-6 xxl:pb-12">
            <div className="w-full m:w-1/2 m:pr-24">
              <h2 className="uppercase xl:text-3xl">{I18n.get('doorCylces')}</h2>
              <div className=" pt-2 xxl:pt-4 xl:text-lg font-bold">{I18n.get(props.speakingTimePeriod)}</div>
              <div className="text-sm  pt-2 xxl:pt-4 xl:text-lg">{I18n.get('messageDoorCylces')}</div>
            </div>
            <div className="m:w-1/2 flex pt-4 m:pt-0">

              {doorCyclesCount?.openingCount === 0 && doorCyclesCount?.openingCount === 0 && (
 
                <div className="w-1/2">
                  <h3 className=" uppercase pr-8 m:pr-0 pb-2 m:pb-4">{I18n.get('totalCycles')}</h3>
                  <div><h1 className=" normal inline-block"><Spin size="default" spinning={isLoading} key="spin">{(doorCyclesCount && doorCyclesCount.doorCycleCount)?doorCyclesCount.doorCycleCount:'-'}</Spin></h1></div>
                </div>

                )}

              {!(doorCyclesCount?.closingCount === 0 && doorCyclesCount?.closingCount === 0) && (
                <>
                  <div className="w-1/2">
                    <h3 className=" uppercase pr-8 m:pr-0 pb-2 m:pb-4">{I18n.get('totalOpenings')}</h3>
                    <div><h1 className=" normal inline-block"><Spin size="default" spinning={isLoading} key="spin">{(doorCyclesCount && doorCyclesCount.openingCount)? doorCyclesCount.openingCount : '-'}</Spin></h1></div>
                  </div>
                  <div className="w-1/2">
                    <h3 className=" uppercase pr-8 m:pr-0 pb-2 m:pb-4">{I18n.get('totalClosures')}</h3>
                    <div><h1 className=" normal inline-block"><Spin size="default" spinning={isLoading} key="spin">{(doorCyclesCount && doorCyclesCount.closingCount)?doorCyclesCount.closingCount:'-'}</Spin></h1></div>
                  </div>
                </>
                )}

            </div>
          </div>
        </div>
      </section>
      <section className="Content">
        <div className=" pb-14 pt-6">
          <DoorOpenClosingsChartMema response={doorOpenClosingsOverTime} isLoading={isLoading} dateRange={props.dateRange} />
        </div>
      </section>
    </>
  );
};
export default DoorOpenClosingsWrapper;
