
import { useAuth0 } from '@auth0/auth0-react';
import { Modal, Select, Spin } from 'antd';
const { Option } = Select;
import React, { useEffect, useState } from 'react';
import { DsFile } from '../../interfaces/File';
import { getAllCategories, patchFileCategory, patchFilesCategory } from '../../utils/helpersFetch';
import { Button } from './../../../components/atoms/Button';
import { Category, CategoryTree } from '../../interfaces/Category';
import { useGlobalState } from '../../../utils/globalStateProvider';
import { useParams } from 'react-router-dom';
import { getAuth0Token } from '../../../utils/helpers';

const ModalDialogChangeCategory = (props:{files:DsFile[],setShowModal:Function,updateTrigger:Function}) => {

    const { getAccessTokenSilently } = useAuth0()
    const [categoryTree,setCategoryTree] = useState<CategoryTree[]>([])
    const [selectedMainCategory,setSelectedMainCategory] = useState<Category|'choose'>('choose')
    const [selectedSubCategory,setSelectedSubCategory] = useState<Category|'choose'>('choose')
    const [mainCategoriesForDropdown,setMainCategoriesForDropdown] = useState<Category[]>([])
    const [subCategoriesForDropdown,setSubCategoriesForDropdown] = useState<Category[]>([])
    const [orgFileMainCat,setOrgFileMainCat] = useState<Category|''>('')
    const [orgFileSubCat,setOrgFileSubCat] = useState<Category|''>('')
    const [inputStatus,setInputStatus] = useState<''|'error'|'warning'>('')
    const [apiMessage,setApiMessage] = useState<string|undefined>(undefined)
    const [isSaving,setIsSaving] = useState<boolean>(false)
    const { state, setState } = useGlobalState()
    let { assetId,contractId,libraryId,categoryMainId } = useParams();
    
    useEffect(() => {
        
        const loadCategories = async () => {
            let accessToken =  await getAuth0Token(getAccessTokenSilently)

            let categoriesResult:Category[] = []
            if(assetId){
                categoriesResult = await getAllCategories(accessToken,'Asset')
            }
            else if(contractId){
                categoriesResult = await getAllCategories(accessToken,'Contract')
            }
            
            let mainCategories = categoriesResult.filter((elem) => {
                if(!elem.parent){return true}
            })
            let tree:CategoryTree[] = mainCategories.map((elemMain) => {
                let sub = categoriesResult.filter(elem => {
                    if(elem.id === props.files[0].category.id){
                        setOrgFileSubCat(elem)
                        if(elem.parent){
                            let mainCat = categoriesResult.filter(elemCategoriesResult => {
                                if(elemCategoriesResult.id === elem.parent){return true}
                            })
                            if(mainCat[0]){
                                setOrgFileMainCat(mainCat[0])
                            }
                        }
                    }
                    if(elem.parent === elemMain.id){return true}
                })
                return {main:elemMain,sub:sub}
            })
            setCategoryTree(tree)
          }
          loadCategories()

      }, [props.files]);
      
      useEffect(() => {
    
        var tmpCategoryTree = categoryTree
        if(state.language !== 'en'){
            tmpCategoryTree.sort((a,b) => (a.main.titleGerman > b.main.titleGerman) ? 1 : ((b.main.titleGerman > a.main.titleGerman) ? -1 : 0))
        }
        else{
            tmpCategoryTree.sort((a,b) => (a.main.titleEnglish > b.main.titleEnglish) ? 1 : ((b.main.titleEnglish > a.main.titleEnglish) ? -1 : 0))
        }
        setCategoryTree(categoryTree)

        setMainCategoriesForDropdown(categoryTree.map(elem => {return elem.main }))
        setSubCategoriesForDropdown([])
      }, [categoryTree,state.language]); 

      useEffect(() => {
    
        if(selectedMainCategory === 'choose'){
            setSubCategoriesForDropdown([])
            return 
        }

        var subCategories = categoryTree.filter(elem => {
            if(elem.main.id === selectedMainCategory?.id){return true}
        })[0]?.sub

        if(state.language !== 'en'){
            subCategories.sort((a,b) => (a.titleGerman > b.titleGerman) ? 1 : ((b.titleGerman > a.titleGerman) ? -1 : 0))
        }
        else{
            subCategories.sort((a,b) => (a.titleEnglish > b.titleEnglish) ? 1 : ((b.titleEnglish > a.titleEnglish) ? -1 : 0))
        }

        setSubCategoriesForDropdown(subCategories)
        setSelectedSubCategory('choose')

        // check if visibility of maincat is changing
        if(orgFileMainCat !== '' && (selectedMainCategory.dsOnly != orgFileMainCat.dsOnly)){
            setInputStatus('warning')
        }
        else{
            setInputStatus('')
        }
      }, [selectedMainCategory]); 

    const handleCancelOk = () => {
        props.setShowModal([]);
    };

    const handleMove = async () => {

        if(selectedSubCategory==='choose'){
            return 
        }

        if(props.files[0].category.id === selectedSubCategory.id){
            // no change
            props.setShowModal([]);
        }
        else{
            setIsSaving(true)
            let accessToken =  await getAuth0Token(getAccessTokenSilently)
            let response

            if(props.files.length > 1){
                if(!libraryId || !categoryMainId){
                    return
                }
                response = await patchFilesCategory(libraryId,categoryMainId,selectedSubCategory ,props.files.map(item=>item.id),accessToken)

            }
            else{
                response = await patchFileCategory(props.files[0].id,selectedSubCategory,accessToken)
            }
            
            if(response.status === 200){
                props.updateTrigger()
                props.setShowModal([]);
            }
            else{
                const data = await response.json()
                setApiMessage(data.message);
            }
            setIsSaving(false)
        }
        
    };

    const handleChangeMain = (value:any) => {
        if(value === 'choose'){
            setSelectedMainCategory('choose')
        }
        else{
            let tmpSelectedMainCategory = mainCategoriesForDropdown.filter(elem=>{if(elem.id===value){return true}})[0]
            if(tmpSelectedMainCategory){setSelectedMainCategory(tmpSelectedMainCategory)}
        }
    };

    const handleChangeSub = (value:any) => {
        let tmpSelectedSubCategory = subCategoriesForDropdown.filter(elem=>{if(elem.id===value){return true}})[0]
        if(tmpSelectedSubCategory){
            setSelectedSubCategory(tmpSelectedSubCategory)
        }
    };

    const getFileCategoryPath = () => {
        let fileName = <></>
        if(props.files[0].title !== props.files[0].originalTitle){
            fileName = <div>{props.files[0].title}<br/>({props.files[0].originalTitle})</div>
        }
        else{
            fileName = <div>{props.files[0].title}</div>
        }

        if(orgFileMainCat === '' || orgFileSubCat === ''){
            return fileName
        }

        return <div>{ (props.files.length > 1)? <div>{props.files.length} Dateien</div> : fileName}
            <strong>{orgFileMainCat.titleGerman}</strong> - <strong>{orgFileSubCat.titleGerman}</strong><br/>
            Verschieben nach:
            </div>
    }

    return (
        <Modal title="Kategorie ändern" open={true} onOk={handleCancelOk} onCancel={handleCancelOk} footer={[]}>
            <Spin size="large" spinning={isSaving} >
                <div className="pb-4 text-center">{getFileCategoryPath()}</div>
                <div className=" items-center ">
                    <div className="flex flex-col items-center mb-5 ">
                        <Select
                            style={{ width: 220 }}
                            onChange={handleChangeMain}
                            value={selectedMainCategory==='choose'? 'choose': selectedMainCategory.id}
                            >
                            <Option key={'choose'}>Hauptkategorie wählen</Option>
                            {mainCategoriesForDropdown.map(elem => (
                                <Option key={elem.id}>{(state.language !== 'en')?  elem.titleGerman  : elem.titleEnglish}</Option>
                            ))}
                        </Select>
                    </div>
                { subCategoriesForDropdown && subCategoriesForDropdown.length > 0 && 
                    <div className="flex flex-col items-center  mb-5 ">
                        <Select
                        style={{ width: 220 }}
                        onChange={handleChangeSub}
                        value={selectedSubCategory==='choose'? 'choose': selectedSubCategory.id}
                        >
                        <Option key={'choose'}>Unterkategorie wählen</Option>
                        {subCategoriesForDropdown.map(elem => (
                            <Option key={elem.id}>{(state.language !== 'en')?  elem.titleGerman  : elem.titleEnglish}</Option>
                        ))}
                        </Select>
                    </div>}
                {selectedSubCategory!=='choose' && 
                    <div className="flex justify-center"><Button key="1" onClick={ handleMove } label="Verschieben" size='extra small' theme="light"  /></div>
                }
                </div>
                {(inputStatus === 'warning')&&(selectedMainCategory !== 'choose' && selectedMainCategory.dsOnly)?<div className=" text-warnOrange pt-4 text-center pl-10 pr-10">Achtung die Datei wird nach dem verschieben für den Kunden <strong>nicht sichtbar</strong> sein.</div>:<></>
                }
                {(inputStatus === 'warning')&&(selectedMainCategory !== 'choose' && !selectedMainCategory.dsOnly)?<div className=" text-warnOrange pt-4 text-center pl-10 pr-10">Achtung die Datei wird nach dem verschieben für den Kunden <strong>sichtbar</strong> sein.</div>:<></>
                }
                
                {apiMessage ? <div className="text-warnRed mt-4 flex justify-center">{apiMessage}</div> : <></>}
            </Spin>
        </Modal>
    );
};

export default ModalDialogChangeCategory;
