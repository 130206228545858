export const strings = {
    en: {
      welcomeMessage: 'Welcome back',
      welcomeMessageFollow : 'Here you will find an overview of your ###number### elevators.',
      overview: 'Elevator overview',
      compareElevators : 'Compare elevators',
      compare : 'Compare',
      placesOnMap : 'Map view',
      nameApp : 'Devices',
      Operational: 'Operational',
      AttentionNeeded: 'Attention needed',
      IssueDetected: 'Issue detected',
      All: 'All',
      filterBy: 'Filter by:',
    },
    de: {
      welcomeMessage: 'Willkommen zurück',
      welcomeMessageFollow : 'Hier finden Sie eine Übersicht zu Ihren ###number### Aufzügen.',
      overview: 'Aufzugübersicht',
      compareElevators : 'Aufzüge vergleichen',
      compare : 'Vergleichen',
      placesOnMap : 'Kartenansicht',
      nameApp : 'Geräte',
      Operational: 'Funktionsfähig',
      AttentionNeeded: 'Aufmerksamkeit benötigt',
      IssueDetected: 'Problem erkannt',
      All: 'Alle',
      filterBy: 'Filtern nach:',
    },
  };