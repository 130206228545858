import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../../components/atoms/Button';
import { fetchOrganizationIdentifier, fetchOrganizationName } from '../../../utils/helpersFetch';


export const SalesforceAuthCheck = () => {
  const [authenticatedByWrongOrganization, setAuthenticatedByWrongOrganization] = useState<boolean>(false);
  const [authenticatedByWrongOrganizationWithThatName, setAuthenticatedByWrongOrganizationWithThatName] = useState<string>('');
  const location = useLocation();
  const locationSplitted = location.pathname.split('/')
  const { isAuthenticated,user,logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {

      const  loadDsOrgId = async() =>{
          let DsOrganizationIdentifier = await fetchOrganizationIdentifier('digitalspine')
          // check if ths User is authenticated with digitalspine organization
          if(DsOrganizationIdentifier === user?.org_id){
            if(locationSplitted[3] === 'asset'){
              navigate('/salesforce/documents/asset/' + locationSplitted[4])
            }
          else if(locationSplitted[3] === 'contract'){
              navigate('/salesforce/documents/contract/' + locationSplitted[4])
            }
          }
          else{
            let userOrgId = user?.org_id
            if(userOrgId){
              let organization = await fetchOrganizationName(userOrgId)
              if(organization){
                setAuthenticatedByWrongOrganizationWithThatName(organization.displayName)
              }
            }
            setAuthenticatedByWrongOrganization(true)
          }
      }

      loadDsOrgId()
    }
  }, [isAuthenticated ]);
 
  const logoutAuth0 = () => {
    logout({ 
      logoutParams: {
        returnTo:  window.location.origin + '/redirect/?to=' + encodeURIComponent(window.location.pathname)
      }
    })
  }

  return (
    <div className="outer">
      <div className="flex items-center flex-col justify-between w-full">
        {authenticatedByWrongOrganization && <>
          <div>Eingeloggt als Organisation {authenticatedByWrongOrganizationWithThatName} steht dieser Bereich nicht zur Verfügung.<br/><br/></div>
          <div onClick={logoutAuth0}><Button label='Logout' size='medium' theme='light' /></div>
        </>}
        {!authenticatedByWrongOrganization && !isAuthenticated && <div>Sie müssen sich einloggen.<br/><br/></div>}
        {!authenticatedByWrongOrganization && <a href="/salesforce/init-salesforce-iframe-auth" target="_blank" rel="opener" className="hover:text-white"><Button label='Login' size='medium' theme='light' /></a>}
      </div>
    </div>
  );
};
