import { strings as stringLocal} from './../../pages/InstallationDetailLocalization'
import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { mergeDeepRight } from 'ramda'
import React from 'react'
import { useEffect } from 'react'
import { useGlobalState } from '../../../utils/globalStateProvider'
import { getLangByLocalStorage } from '../../../utils/helpers'

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

const TimePeriodLable = (props: { translateKey:string }) => {
    const { state } = useGlobalState();
    
    const [translatedElement,setTranslatedElement] = React.useState<String>('')

    useEffect(() => {
      setTranslatedElement(I18n.get(props.translateKey))
    }, [state.language,props.translateKey]);

  return translatedElement;
};

export default TimePeriodLable;
