import {DoorSouth, DoorNorthSouth,DoorLoading} from './DoorAnimations'
const DoorState = (props: { doorCount:number,doorSouthOpen:boolean,doorNorthOpen:boolean}) => {

  if(props.doorCount === 2){
    return <DoorNorthSouth southIsOpen={props.doorSouthOpen} northIsOpen={props.doorNorthOpen}  />
  }
  else if(props.doorCount === 1){
    return <DoorSouth southIsOpen={props.doorSouthOpen}  />
  }
  else{
    return <DoorLoading />
  }
}
export default DoorState;
