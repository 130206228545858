import React, { useEffect, useRef, useState } from "react";
import TableMain, { TableProps } from "../../../components/molecules/TableMain";
import { Category } from "../../interfaces/Category";
import { getCategoriesForCagtegory, getfilesForCategory } from "../../utils/helpersFetch";
import { Library } from "../../interfaces/Library";
import { useAuth0 } from "@auth0/auth0-react";
import { DsFile, DsFilePreloaded, DsFileResponse } from "../../interfaces/File";
import { Pagination, Spin } from "antd";
import { I18n } from "@aws-amplify/core";
import { useGlobalState } from "../../../utils/globalStateProvider";
import moment from "moment";
import { DOCUMENTS_PREFERENCES_VIEWTYPES } from "../../../utils/consts";
import DocumentDialog from "./documentDialog";
import { CaretDownOutlined,CaretUpOutlined } from '@ant-design/icons';
import { UserHasPermissionsForDocumentsUpload, formatBytes, getAuth0Token } from "../../../utils/helpers";
import ViewTileList from "./viewTileList";
import ShowCatagoryListCategorySelect from "./showCatgegoryListCategoySelect";
import { useNavigate, useParams } from "react-router-dom";
import FileUploadCategories from "./uploadModal";
import { mergeDeepRight } from "ramda";
import { strings as stringLocal} from './uploadModalLocalization';
import { strings as stringGlobalDocuments } from '../../utils/globalLocalization';
import { strings as stringGlobal } from '../../../utils/globalLocalization';
import { Contract } from "../../interfaces/Contract";

const ShowCatagoryList = (props:{category:Category,library:Library,initSubCategory:Category|null,initFile:DsFile|null,contract?:Contract,setActiveSubCategoryByUserSelection:Function}) => {

  interface PreferencesOptions { options: 'List' | 'Tile' | null }
  interface PreferencesViews {[index: string] : PreferencesOptions['options']}

  const [tableProps, setTableProps] = useState<TableProps | null >(null)
  const [fileResponse,setFileResponse] = useState<DsFileResponse | null >(null)
  const [allfFileResponse,setAllFileResponse] = useState<DsFileResponse | null >(null)
  const [isLoading,setIsLoading] = useState<boolean>(false)
  const [userHasUploadPermissions,setUserHasUploadPermissions] = useState<boolean>(false)
  const { getAccessTokenSilently } = useAuth0();
  const { state, setState } = useGlobalState()
  const [paginationPage, setPaginationPage] = React.useState<number>(1)
  const [paginationSize, setPaginationSize] = React.useState<number | null>(null)
  const toGalleryIcon = <svg onClick={()=>{setViewOfFiles('Tile')}} className="cursor-pointer fill-blueLight" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_518_5564)"><g clipPath="url(#clip1_518_5564)"><rect width="13.8039" height="13.8039" /><rect y="18.1973" width="13.8039" height="13.8039" /><rect x="17.5693" width="13.8039" height="13.8039" /><rect x="17.5693" y="18.1973" width="13.8039" height="13.8039" /></g></g><defs><clipPath id="clip0_518_5564"><rect width="31.3725" height="32" fill="white"/></clipPath><clipPath id="clip1_518_5564"><rect width="31.3725" height="32" fill="white"/></clipPath></defs></svg>
  const toListIcon = <svg onClick={()=>{setViewOfFiles('List')}} className="cursor-pointer fill-blueLight" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_518_8744)"><rect y="6" width="31" height="4" /><rect y="14" width="31" height="4" /><rect y="22" width="41.3333" height="4" /></g><defs><clipPath id="clip0_518_8744"><rect width="31.3725" height="32" fill="white"/></clipPath></defs></svg>
  const [viewOfFiles,setViewOfFiles] = useState< PreferencesOptions['options'] >(null)
  const [selectedFile, setSelectedFile] = React.useState<DsFilePreloaded | null>(null)
  const [sortedInfo, setSortedInfo] = React.useState({ columnKey: '', order: '' })
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<Category|undefined>(undefined);
  let { installationId,libraryId, organizationName } = useParams();
  const navigate = useNavigate();
  const controllerRef = useRef<AbortController | null>();

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,mergeDeepRight(stringGlobalDocuments,stringGlobal)));
    const CheckUploadPermissions = async () => {
      setUserHasUploadPermissions(await UserHasPermissionsForDocumentsUpload(await getAuth0Token(getAccessTokenSilently)))
    };
    CheckUploadPermissions();
  }, []);

    useEffect(() => {
      if(state.language){
        I18n.setLanguage(state.language)
        moment.locale(state.language)
        document.documentElement.lang = state.language
      }
    }, [state.language]);

    useEffect(() => {
      if(selectedFile){
        // the modal component is setting its deeplink
        // if the modal component set selectedFile to undefined the parent component takes control about the deeplink
        return
      }
      // create deeplink url
      let targetPath = '/' + organizationName + '/unknown'
      if(libraryId){
        targetPath = '/' + organizationName + '/library/' + libraryId        
      }else if(installationId){
        targetPath = '/' + organizationName + '/installations/' + installationId
      }

      if(selectedSubCategory){ 
        targetPath += '/c/' + selectedSubCategory.id 
      }
      else{
        targetPath += '/c/' + props.category.id
      }
      navigate(targetPath)
    }, [selectedSubCategory,selectedFile]);

    useEffect(() => {
      props.setActiveSubCategoryByUserSelection(selectedSubCategory)
      setPaginationPage(1)

      let preferences:string|null = localStorage.getItem(DOCUMENTS_PREFERENCES_VIEWTYPES)
      let preferencesViews:PreferencesViews = {}
      if(preferences !== null){
        preferencesViews = JSON.parse(preferences)
      }

      if(selectedSubCategory && preferencesViews[selectedSubCategory.id] !== undefined){
        setViewOfFiles(preferencesViews[selectedSubCategory.id])
      }
      else if(preferencesViews[props.category.id] !== undefined){
        setViewOfFiles(preferencesViews[props.category.id])
      }
      else{
        // default behavior
        if(props.category.view === 'gallery'){
          setViewOfFiles('Tile')
        }
        else if(props.category.view === 'list'){
          setViewOfFiles('List')
        }
        else{
          setViewOfFiles('List')
        }
      }
    }, [selectedSubCategory]);

    useEffect(() => {
      const fetchSubcategories = async () => {
          const accessToken = await getAuth0Token(getAccessTokenSilently);
          const subcategories = await getCategoriesForCagtegory(props.category.id, accessToken);
          setSubCategories(subcategories.subCategories);
          if(props.initSubCategory){
            setSelectedSubCategory(props.initSubCategory)
            if(props.initFile){
              setSelectedFile({file:props.initFile,imageSrc:null})
            }
          }
      };
      fetchSubcategories();
    }, [props.category]);

    useEffect(() => {
      if(viewOfFiles === 'List'){
        setPaginationSize(10)
      }else if(viewOfFiles === 'Tile'){
        setPaginationSize(16)
      }
      var cookieString = document.cookie;
      if(cookieString.includes("cookieconsent=True") && viewOfFiles !== null){
        let preferences:string|null = localStorage.getItem(DOCUMENTS_PREFERENCES_VIEWTYPES)
        let preferencesViews:PreferencesViews = {}
        if(preferences !== null){
          preferencesViews = JSON.parse(preferences)
        }
        if(selectedSubCategory){
          preferencesViews[selectedSubCategory.id] = viewOfFiles
        }else{
          preferencesViews[props.category.id] = viewOfFiles
        }
        localStorage.setItem(DOCUMENTS_PREFERENCES_VIEWTYPES, JSON.stringify(preferencesViews));
      }
    }, [viewOfFiles]);

    useEffect(() => {
        loadFiles()
      }, [paginationPage,paginationSize,sortedInfo,selectedSubCategory]);

    useEffect(() => {
      loadAllFiles()
      }, [sortedInfo,props.initSubCategory]);

    useEffect(() => {
      buildListTable(setTableProps,fileResponse,state.language,setSelectedFile)
    }, [fileResponse,state.language,sortedInfo]);

    useEffect(() => {
      if(selectedFile){
        document.body?.classList.add('modalOpen')
      }
      else{
        document.body?.classList.remove('modalOpen')
      }
    }, [selectedFile]);

    const sortChange = (sorter: { columnKey: string, order: string }) => {

      if (sorter.order === '') {
        setSortedInfo({ columnKey: '', order: '' })
      }
      else {
        setSortedInfo(sorter)
      }
      let sortBy = ''
      if (sorter.columnKey && sorter.order) {
        sortBy = sorter.columnKey + ':' + (sorter.order === 'asc' ? 'asc' : 'desc')
      }
      setPaginationPage(1)
    };

    const getSortVisualisation = (columnKey: string) => {

      if (columnKey === sortedInfo.columnKey) {
        if (sortedInfo.order === 'asc') {
          return <CaretUpOutlined className="ml-4" style={{ 'color': 'white' }} />
        }
        else {
          return <CaretDownOutlined className="ml-4" style={{ 'color': 'white' }} />
        }
      }
      return <CaretUpOutlined className="ml-4" style={{ 'color': 'grey' }} />
    }

    const getSortOrder = (columnKey: string): string => {
      if (sortedInfo.columnKey === columnKey) {
        if (sortedInfo.order === 'asc') {
          return 'desc'
        }
        else if (sortedInfo.order === 'desc') {
          return ''
        }
        else {
          return 'asc'
        }
      }
      else {
        return 'asc'
      }
    }

    const buildListTable = (setTableProps:Function,fileResponse:DsFileResponse|null,language:string|undefined,setSelectedFile:Function) => {
      let tableP:TableProps = {th:[],tr:[] ,noDataLabel:I18n.get('noFiles'),height:'unset',thead:'sticky-documents-detail'}
      
      let sortNameCategory = state.language==='de'?'categoryGerman':'categoryEnglish'

      tableP.th = [
        /* desktop */
        {
          text:<>{I18n.get('SubCategory')}{getSortVisualisation(sortNameCategory)}</>,
          class:'hidden m:flex w-2/12 pb-4',
          ellipsis:true,
          onCellClick:()=>sortChange({ columnKey: sortNameCategory, order: getSortOrder(sortNameCategory) })
        },
        {
          text:<>{I18n.get('Title')}{getSortVisualisation('title')}</>,
          class:'hidden m:flex w-6/12 pb-4 ',
          ellipsis:true,
          onCellClick:()=>sortChange({ columnKey: 'title', order: getSortOrder('title') })
        },
        {
          text:<>{I18n.get('Added')}{getSortVisualisation('date')}</>,
          class:'hidden m:flex w-2/12 pb-4 ',
          onCellClick:()=>sortChange({ columnKey: 'date', order: getSortOrder('date') })
        },
        {text:<>{I18n.get('Size')}</>,class:'hidden m:flex w-2/12 pb-4 '},
    

        /* mobile */
        {
          text:<span className="flex flex-col s:flex-row">
            <span className="flex items-center" onClick={()=>sortChange({ columnKey: sortNameCategory, order: getSortOrder(sortNameCategory) })} >{I18n.get('SubCategory')}{getSortVisualisation(sortNameCategory)}</span>
            <span className="hidden s:inline-block mr-3 ml-3">/</span>
            <span className="flex items-center" onClick={()=>sortChange({ columnKey: 'title', order: getSortOrder('title') })} ><span className="s:hidden mr-3">/</span>{I18n.get('Title')}{getSortVisualisation('title')}</span>
            </span>,
          class:'flex items-center pb-0 text-left m:hidden w-6/12 ',
          ellipsis:true
        },
        {
          text:<>{I18n.get('Added')}{getSortVisualisation('date')}</>,
          class:'flex items-center pb-0 text-left m:hidden w-3/12 ',
          ellipsis:true,
          onCellClick:()=>sortChange({ columnKey: 'date', order: getSortOrder('date') })
        },
        {text:<>{I18n.get('Size')}</>,class:'flex m:hidden w-3/12 items-center'},
      ]
    
        if(fileResponse){
          
          tableP.tr = fileResponse.files.map((file)=>{
          let f = file
    
          return {
            trOnClick:()=>{setSelectedFile({file:file,imageSrc:null})},
            cells:[

              {text:<>{language === 'de' ? f.category.titleGerman : f.category.titleEnglish}</>,class:"hidden m:flex w-2/12"},
              {text:<>{f.title}</>,class:"hidden m:flex w-6/12"},
              {text:<>{moment(f.createdAt).format('DD.MM.YYYY' )}</>,class:"hidden m:flex w-2/12"},
              {text:<>{formatBytes(f.sizeKb)}</>,class:"hidden m:flex w-2/12"},
    
              {text:<>{language === 'de' ? f.category.titleGerman : f.category.titleEnglish}<br/>{f.title}</>,class:' m:hidden w-6/12 ',ellipsis:true},
              {text:<>{moment(f.createdAt).format('DD.MM.YYYY' )}</>,class:' m:hidden w-3/12 ',ellipsis:true},
              {text:<>{formatBytes(f.sizeKb)}</>,class:"flex m:hidden w-3/12"},
            ]}
        })
      }
      setTableProps(tableP)
    }

    const loadFiles = async(dontShowLoadingProgress:boolean = false) => {
      if(!paginationSize){
        return
      }

      if(dontShowLoadingProgress === false){
        setIsLoading(true)
      }
      let accessToken =  await getAuth0Token(getAccessTokenSilently)
      let skip = ((paginationPage-1)*paginationSize)

      let categoryId = selectedSubCategory?selectedSubCategory.id:props.category.id
      try{
        const files = await getfilesForCategory(props.library.id,categoryId,accessToken,skip,paginationSize,sortedInfo,undefined,controllerRef)
        setFileResponse(files)
        if(dontShowLoadingProgress === false){
          setIsLoading(false)
        }  
      }
      catch{
        if(dontShowLoadingProgress === false){
          setIsLoading(false)
        }
      }
    }

    const loadAllFiles = async() => {
      let accessToken =  await getAuth0Token(getAccessTokenSilently)
      const files = await getfilesForCategory(props.library.id,props.category.id,accessToken,null,null,sortedInfo,undefined)
      setAllFileResponse(files)
    }

    const refreshContent = () => {
      loadAllFiles()
      loadFiles(true)
    }

  return (<Spin spinning={isLoading}>
      <div className="flex justify-between">
        <h1 className=" mb-5">{ state.language === 'de' ? props.category.titleGerman : props.category.titleEnglish }</h1>
        {userHasUploadPermissions && props.library && props.category && <FileUploadCategories libraryId={props.library.id} category={props.category} goBack={()=>{}} refreshCallback={refreshContent} />}
      </div>
      <div className=" flex justify-between mb-16">
        <div>
          <ShowCatagoryListCategorySelect selectedMainCategory={props.category} allFilesForCategory={allfFileResponse} selectedSubCategory={selectedSubCategory} setSelectedSubCategory={setSelectedSubCategory} subCategories={subCategories} />
        </div>        
        <div className=""></div>
        {viewOfFiles ==='List'? toGalleryIcon : toListIcon}
      </div>
        {viewOfFiles === 'List' && <TableMain tableContent={tableProps} className="cp_library_category_table" />}
        {viewOfFiles === 'Tile' && fileResponse && <ViewTileList 
          paginationPage={paginationPage}
          paginationSize={paginationSize}
          fileResponse={fileResponse}
          allFileResponse={allfFileResponse}
          category={props.category}
          library={props.library}
          sorter={sortedInfo}
          />}
      {paginationPage !== null && paginationSize !== null && fileResponse && fileResponse.count > 0 &&
        <div className={(viewOfFiles ==='List'? 'bg-blueBaby ' : ' mt-5' ) +" h-24 flex justify-center items-center paginatorDS"}>
          <Pagination current={paginationPage} pageSize={paginationSize} showSizeChanger={false} total={fileResponse?.count} onChange={(page, pageSize) => {
            setPaginationPage(page);
          }} />
        </div>
      }
      {selectedFile && fileResponse &&
      <DocumentDialog
        isLastSeenMode={false}
        allFileResponse={fileResponse}
        file={selectedFile}
        setSelectedFile={setSelectedFile}
        paginationPage={paginationPage}
        paginationSize={paginationSize}
        category={props.category}
        library={props.library}
        sorter={sortedInfo}
        contract={props.contract}
        mode={(libraryId)?'Library':'Installation'}
        />}
    </Spin>);

}
export default ShowCatagoryList