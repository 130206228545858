
import { useAuth0 } from '@auth0/auth0-react';
import { Input, InputRef, Modal, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { DsFile } from '../../interfaces/File';
import { patchFileTitle } from '../../utils/helpersFetch';
import { Button } from './../../../components/atoms/Button';
import { getAuth0Token } from '../../../utils/helpers';

const ModalDialogRename = (props:{file:DsFile,setShowModal:Function,updateTrigger:Function}) => {

    const inputTitleRef = useRef<InputRef>(null)
    const { getAccessTokenSilently } = useAuth0()
    const [inputValue,setInputValue] = useState<string>('')
    const [unchangedTitle,setUnchangedTitle] = useState<string>('')
    const [orgFileValue,setOrgFileValue] = useState<string>('')
    const [orgFileExtension,setOrgFileExtension] = useState<string>('')
    const [inputStatus,setInputStatus] = useState<''|'error'|'warning'>('')
    const [apiMessage,setApiMessage] = useState<string|undefined>(undefined)
    const [isSaving,setIsSaving] = useState<boolean>(false)

    useEffect(() => {
        let inputParts = props.file.title.split('.')
        if(inputParts ){
            let extension = inputParts.pop()
            setUnchangedTitle(inputParts.join(''))
            setInputValue(inputParts.join(''))
        }

        let inputPartsOrg = props.file.originalTitle.split('.')
        if(inputPartsOrg ){
            let extension = inputPartsOrg.pop()
            if(extension){
                setOrgFileExtension(extension)
            }
            setOrgFileValue(inputPartsOrg.join(''))
        }
        
      }, [props.file]);

    const handleCancelOk = () => {
        props.setShowModal(null);
    };

    const handleSave = async () => {

        if(inputStatus!==''){
            return 
        }

        if(inputValue === unchangedTitle){
            // no change
            props.setShowModal(null);
        }
        else{
            setIsSaving(true)
            let accessToken =  await getAuth0Token(getAccessTokenSilently)
            let response = await patchFileTitle(props.file.id,inputValue+'.'+orgFileExtension,accessToken)
            const data = await response.json()
            if(response.status === 200){
                props.updateTrigger()
                props.setShowModal(null);
            }
            else{
                setApiMessage(data.message);
            }
            setIsSaving(false)
        }
    };

    const handleChange = (e:any) => {
        let newValue = e.target.value
        setInputValue(newValue)
        const validFilNameInput = new RegExp(/^[a-zA-ZÀ-Ÿ0-9-_]+$/);
        if(validFilNameInput.test(newValue)){
            setInputStatus('')
        }
        else{
            setInputStatus('error')
        }
    };

    const handleKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if(event.key === 'Enter' ) {
            event.preventDefault();
            handleSave()
            return false;
        }    
    }

    return (
        <Modal title="Datei umbenennen" open={true} onOk={handleCancelOk} onCancel={handleCancelOk} footer={[]}>
            <Spin size="large" spinning={isSaving} >
                <div className="pb-4">Originalname: <strong>{orgFileValue}</strong>.{orgFileExtension}</div>
                <div className="flex">
                    <Input 
                        placeholder={unchangedTitle} 
                        status={inputStatus} 
                        className="mr-4" 
                        value={inputValue} 
                        onChange={handleChange} 
                        onKeyDown={handleKeydown}
                        onFocus={async e => {                            
                            e.target.select();
                            }  
                        }
                        suffix={'.'+orgFileExtension}
                        ref={inputTitleRef} />
                    <Button key="1" onClick={ handleSave} label="Umbenennen" size='extra small' theme="light" disabled={inputStatus!==''?true:false} />
                </div>
                {inputStatus !== ''? <div className="text-warnRed mt-4">Bitte nur Zahlen, Buchstaben, "_" oder "-"  benutzen!</div> : <></>}
                {apiMessage ? <div className="text-warnRed mt-4">{apiMessage}</div> : <></>}
            </Spin>
        </Modal>
    );
};

export default ModalDialogRename;
