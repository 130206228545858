export const USER_CHOICE_LANGUAGE = 'user-choice-language'
export const DOCUMENTS_PREFERENCES_VIEWTYPES = 'documents_preferences_viewtypes'
export const SF_DOCUMENTS_PREFERENCES_VIEWTYPES = 'sf_documents_preferences_viewtypes'
export const DOCUMENTS_LAST_VIEWED = 'documents_last_viewed'

export const API_URL = process.env.REACT_APP_API_URL || 'https://devices.api.test.aufzughelden.com'
export const DOCUMENTS_API_URL = process.env.REACT_APP_DOCUMENTS_API_URL || 'https://drive.api.test.aufzughelden.com'
export const LEARN_API_URL = process.env.REACT_APP_LEARN_API_URL || 'https://learn.api.test.aufzughelden.com'

export const INCIDENTS_API_URL = process.env.REACT_APP_INCIDENTS_API_URL || 'https://incidents.api.test.aufzughelden.com'
export const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN || "login.test.digitalspine.io"
export const auth0_clientId = process.env.REACT_APP_AUTH0_CLIENTID || "HsTOosfbLZpO6RIMFq1Lk4HVOi15rGeR"
export const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE || "https://dev.api.aufzughelden.com"
export const gtm_id = process.env.REACT_APP_GTM_ID || null
export const ga4_id = process.env.REACT_APP_GA4_ID || null
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || null
export const google_maps_api_code = process.env.REACT_APP_GOOGLE_MAPS_API_CODE || "AIzaSyBkfKhUyZfovKZ8pwhZtIS7a6jquwSPuBg"

export const arrColors = ['#FF0066', '#FFFF05', '#0099CC', '#99CC00', '#CC6699', '#05FFC9', '#996633', '#663399', '#339966', '#9305FF']

export const APPLICATION = process.env.REACT_APP_APPLICATION || 'devices'

export const app_url_devices = process.env.REACT_APP_APP_URL_DEVICES || "https://devices.aufzughelden.com"
export const app_url_documents = process.env.REACT_APP_APP_URL_DOCUMENTS || "https://documents.aufzughelden.com"
export const app_url_contracts = process.env.REACT_APP_APP_URL_CONTRACTS || "https://contracts.aufzughelden.com"
export const app_url_incidents = process.env.REACT_APP_APP_URL_INCIDENTS || "https://incidents.aufzughelden.com"
export const app_url_portal = process.env.REACT_APP_APP_URL_PORTAL || "https://portal.aufzughelden.com"
export const app_url_learn = process.env.REACT_APP_APP_URL_LEARN || "https://learn.aufzughelden.com"

export const DOCUMENTS_DOWNLOAD_SOCKET_URL = process.env.REACT_APP_DOCUMENTS_DOWNLOAD_SOCKET_URL || 'wss://agyl7hm4y7.execute-api.eu-central-1.amazonaws.com/production/'

export const app_url_learn_documents = process.env.REACT_APP_APP_URL_LEARN_DOCUMENTS || "https://learn-documents.test.aufzughelden.com"

export const PUBLIC_POSTHOG_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY || "phc_MaB0n6JJeBQ2aqh1zlmJPBnM6OxoVOfiRr6oMbxen7q"
export const PUBLIC_POSTHOG_HOST = process.env.REACT_APP_PUBLIC_POSTHOG_HOST || "https://d2esudi8r1lh7v.cloudfront.net" // "https://eu.i.posthog.com"