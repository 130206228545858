export const strings = {
    en: {
      resetFilter: 'Reset filter',
      closed: 'Closed',
      closing: 'Closing',
      opens: 'Opens',
      open: 'Open',
      moving: 'Moving',
      stop: 'Stop',
      endOfRun: 'End of run',
      endOfDoorMovement: 'End of doormovement',
      doorMoving: 'Moving',
      deviceGoalFloorsChanged : 'Goalfloor changed',
      deviceFloorInnerButtonPress: 'Inner button pressed',
      deviceFloorOuterButtonPress: 'Outer button pressed',
      deviceNotArrivingAlert: 'Device not arriving',
      deviceNotMovingAlert: 'Lift not moving',
      deviceNotMovingDoorMovingAlert: 'Lift not moving but door moving',
      deviceNoCANMessagesAlert: 'No CAN messages',
      deviceLowCanMessagesAlert: 'Low CAN messages',
      deviceDoorNotOpeningTimeAlert: 'Doors not opening',
      deviceDoorNotOpeningAttemptsAlert: 'Doors not opening after attempts',
      deviceDoorNotClosingTimeAlert: 'Doors not closing',
      deviceErrorStateAlert: 'Controller in error state',
      deviceErrorState: 'Controller in error state',
      deviceDoorNotClosingAttemptsAlert: 'Doors not closing after attempts',
      doorIsMoving:'moving',
      goalFloor:'call to floor',
      leveling:'Leveling run',
    },
    de: {
        resetFilter: 'Filter zurücksetzen',
        closed: 'Geschlossen',
        closing: 'Schließt',
        opens: 'Öffnet',
        open: 'Offen',
        moving: 'Bewegend',
        stop: 'Halt',
        endOfRun: 'Ende der Fahrt',
        endOfDoorMovement: 'Ende der Türbewegung',
        doorMoving: 'Bewgend',
        deviceGoalFloorsChanged : 'Ziel geändert',
        deviceFloorInnerButtonPress: 'Tasten innen betätigt',
        deviceFloorOuterButtonPress: 'Tasten außen betätigt',
        deviceNotArrivingAlert: 'Ziel nicht erreicht',
        deviceNoCANMessagesAlert: 'Keine CAN Nachrichten',
        deviceNotMovingAlert: 'Aufzug bewegt sich nicht',        
        deviceNotMovingDoorMovingAlert: 'Heben Sie nicht beweglich, sondern Tür bewegt',        
        deviceLowCanMessagesAlert: 'Wenige CAN Nachrichten',
        deviceDoorNotOpeningTimeAlert: 'Türen öffnen sich nicht',
        deviceDoorNotOpeningAttemptsAlert: 'Türen öffnen sich nicht nach mehreren Versuchen',
        deviceDoorNotClosingTimeAlert: 'Türen schließen sich nicht',
        deviceDoorNotClosingAttemptsAlert: 'Türen schließen sich nicht nach mehreren Versuchen',
        deviceErrorStateAlert: 'Controller Fehler',
        deviceErrorState: 'Controller Fehler',
        doorIsMoving:'In bewegung',
        goalFloor:'Zieletage',
        leveling:'Nivellierungsfahrt',
    },
  };