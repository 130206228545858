import React from "react";

export interface BreadCrumbNavigationItem {
    lable: string,
    onClick?: Function
}

const BreadCrumbNavigation = (props:{items:BreadCrumbNavigationItem[]}) => {

  let items = props.items

  const splitter = <svg key="splitter" className="ml-4 mr-4 h-3 mt-[2px] " width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L0.999999 1" stroke="#102A68" strokeWidth="2"/></svg>
  let breadCrumbItems:React.ReactNode[] = []
  items.forEach((item,index) => {
    breadCrumbItems.push(<span className={(item.onClick?"cursor-pointer ":" ") + ' '} key={index} onClick={()=>{item.onClick?item.onClick():void(0)}} >{item.lable}</span>)
  })

  if(breadCrumbItems.length <= 0){
    return <></>
  }
  else{
    return <div key="wrap" className="flex items-center mb-10 text-xl cp_breadcrumb_container">{breadCrumbItems.map((item,index)=>{
      if(breadCrumbItems.length > (index+1)){
        return ( [item,splitter] )
      }
      else{
        return ( [item] )
      }
    })}</div>
  }

}
export default BreadCrumbNavigation