import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spin } from 'antd';

const colorsTheme = require('../../../../src/utils/aufzugheldenTheme')
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import { BarChart } from 'echarts/charts';

import { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../../utils/globalStateProvider';
import { strings as stringGlobal } from '../../../utils/globalLocalization';
import { strings as stringLocal} from './DoorOpenClosingsLocalization'
import { DateRange } from '../../../interfaces/DateRange';
import moment from 'moment';
import { mergeDeepRight } from 'ramda';
import { DoorCyclesOverTimeResponse } from '../../../interfaces/DoorCyclesOverTime';
import React from 'react';

const DoorOpenClosings = (props: { response:  DoorCyclesOverTimeResponse | null; isLoading: boolean; dateRange:DateRange }) => {
  const [noData, setNoData] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [dataPoints, setDataPoints] = useState<string[]>([])
  const [timeFormat, setTimeFormat] = useState<string>('ddd DD.MM')
  const [visualizePerHours, setVisualizePerHours] = useState<boolean>(false)
  const [labelOpen, setLabelOpen] = useState<string>('')
  const [labelClose, setLabelClose] = useState<string>('')
  const { state, setState } = useGlobalState()
  const eChartsRef = React.useRef(null as any)
  const [chartIsReady, setChartIsReady] = useState(false)

  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language]);

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    const interval = setInterval(() => {
      if (eChartsRef && eChartsRef.current){
        // stop interval and init chart
        setChartIsReady(true)
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if(visualizePerHours){
      setTimeFormat('ddd DD.MM HH:mm') // hour scale
    }
    else{
      setTimeFormat('ddd DD.MM') // daily scale
    }
  }, [visualizePerHours]);

  useEffect(() => {
    if (eChartsRef && eChartsRef.current){
      eChartsRef.current?.getEchartsInstance().setOption(getOption(data));
    }
  }, [data,chartIsReady,eChartsRef,timeFormat,props.isLoading,state.language]);

  useEffect(() => {

    const dataTmp:any = {} 
    if(!props.response ||  !Array.isArray(props.response) || props.response.length <= 0){
      setNoData(true);
      return
    }

    let startdate = moment(props.dateRange.startDate)
    let enddate = moment(props.dateRange.endDate)
    let datapoints: string[]= []
    
    while(startdate.isSameOrBefore(enddate)){
      datapoints.push(startdate.startOf('day').toISOString())
      startdate.add(1,'day')
    }

    // prefill with 0 values
      if(!dataTmp)
      {
        let dataTmp = {}
      }
      datapoints.forEach((elem) => {
        dataTmp[elem] = []
      })

      for (const item of props.response) {
        let time = moment(item.timestamp).startOf('day').toISOString()
        let door_openings_count = item.openingCount ? item.openingCount:0
        let door_opened_count = item.openedCount ? item.openedCount:0

        let door_closings_count = item.closingCount ? item.closingCount:0

        let openValue = 0
        if(door_openings_count >= door_opened_count){
          openValue = door_openings_count 
          setLabelOpen(I18n.get('doorChartOpenings'))
        }
        else{
          openValue = door_opened_count
          setLabelOpen(I18n.get('doorChartOpens'))
        }
        
        let closeValue = door_closings_count
        setLabelClose(I18n.get('doorChartClosings'))
        
        let cyclesValue = item.doorCycleCount ? item.doorCycleCount : openValue 

        dataTmp[time] = [cyclesValue,openValue,closeValue]
      }

    if(moment(datapoints[1]).diff(moment(datapoints[0]), 'hours') <= 1){
      setVisualizePerHours(true)
    }
    else{
      setVisualizePerHours(false)
    }

    setDataPoints(datapoints)
    setData(dataTmp);

    if (Object.keys(dataTmp).length > 0) {
      setNoData(false);
    } else {
      setNoData(true);
    }

  }, [props.response,eChartsRef,chartIsReady,props.isLoading,state.language]);

  echarts.use([
    BarChart,
  ]);

  echarts.registerTheme('light', {
    backgroundColor: '#fff',
  });

  const getOption = (data:any[]) => {

    let seriesTmp:any[] = []

    let arrColors = colorsTheme.doorColors
    let openings:any[] = []
    let closings:any[] = []
    let cycles:any = []

    Object.entries(data).forEach(([key, value], index) => {
      cycles.push(value[0])
      openings.push(value[1])
      closings.push(value[2])
    })

    seriesTmp.push(
      {
        name: I18n.get('Cycles'),
        type: 'bar',
        barGap: 0,
        blur: {
          itemStyle:{
            color: colorsTheme.doorBlurColors[0],
            opacity:colorsTheme.doorBlurOpacity[0]
          }
        },
        emphasis: {
          focus: 'series',
          itemStyle:{
            color: arrColors[0]
          }
        },
        data: cycles,
        itemStyle: {
          color: arrColors[0]
        }
      }
    )

    seriesTmp.push(
      {
        name: labelOpen,
        type: 'bar',
        barGap: 0,
        blur: {
          itemStyle:{
            color: colorsTheme.doorBlurColors[1],
            opacity:colorsTheme.doorBlurOpacity[1]
          }
        },
        emphasis: {
          focus: 'series',
          itemStyle:{
            color: arrColors[1]
          }
        },
        data: openings,
        itemStyle: {
          opacity: 5,
          color: arrColors[1]
        }
      }
    )

    seriesTmp.push(
      {
        name: labelClose,
        type: 'bar',
        barGap: 0,
        blur: {
          itemStyle:{
            color: colorsTheme.doorBlurColors[2],
            opacity:colorsTheme.doorBlurOpacity[2]
          }
        },
        emphasis: {
          focus: 'series',
          itemStyle:{
            color: arrColors[2]
          }
        },
        data: closings,
        itemStyle: {          
          color: arrColors[2]
        }
      }
    )


    return {
      grid: {
        left: '1%',
        right: '1%',
        bottom: '15%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'item',
        backgroundColor:colorsTheme.chartTooltipBackground,
        borderWidth:0,
        textStyle:{
          color:colorsTheme.chartTooltipTextColor
        },
        formatter: function (params: any) {
          return params.seriesName + ': ' + params.value;
        }
      },
      legend: {
        bottom: '0%',
        left:'0%',
      },
      xAxis: {
        boundaryGap: true,
        axisLine:{
          show:false,
        },
        type: 'category',
        axisTick: { show: false },
        date:dataPoints,
        axisLabel: {
          formatter: (value:any)=>{ 
            return moment(dataPoints[value]).format(timeFormat)  
          } 
      },
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '0%'],
        splitLine:{
          show:true,
          lineStyle:{
            color:colorsTheme.chartBackgroundLineColor,
            opacity: colorsTheme.chartBackgroundLineOpacity
          }
        },
        axisLabel: {
          formatter: (value:any)=>{ return value.toLocaleString('de-DE')} 
      },
      },
      series: seriesTmp,
    }
  }

  return (
    <>
      {noData && (
        <div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight">{I18n.get('noDataForTimePeriod')}</div>
        </div>
      )}
      {!noData && (
        <Spin size="large" spinning={props.isLoading} key="spin">
          <ReactEChartsCore
            key="chartTurnchart"
            echarts={echarts}
            option={{}}
            notMerge={true}
            lazyUpdate={true}
            theme={'light'}
            showLoading={false}
            ref={eChartsRef}
            style={{ height: '300px', width: '100%' }}
          />
        </Spin>
      )}
    </>
  );
};
export default DoorOpenClosings;
