export const strings = {
  en: {
    'Report_a_problem':'Report a problem for',
    'Report_a_problem_mobile':'Report a problem',
    'Problem description':'Problem description',
    'Incident Type':'Incident Type',
    'Incident_Emergency':'Emergency',
    'Incident_Breakdown':'Breakdown',
    'Incident_Question':'Question',
    'Success':'Success',
    'Successfully reported a problem with asset':'Successfully reported a problem with asset',
    'Please try':'Please try',
    'again':'again',
    'or contact via':'or contact via',
    'phone':'phone',
    'Welcome_to_the_Aufzughelden_support':'Welcome to the Aufzughelden support',
    'Report_a_problem_desc': 'Let us know what problem you are experiencing with this asset. The more detailed the report is the faster we can take care of it.',
    'Please_fill_case_form': 'Please select an Incident Type and give a short description.'
  },
  de: {
      'Report_a_problem':'Problem melden für',
      'Report_a_problem_mobile':'Problem melden',
      'Problem description':'Problembeschreibung',
      'Incident Type':'Art der Störung',
      'Incident_Emergency':'Notfall',
      'Incident_Breakdown':'Fehlfunktion',
      'Incident_Question':'Frage',
      'Success':'Erfolgreich',
      'Successfully reported a problem with asset':'Sie haben erfoglreich ein Problem kommunizert zur Anlage:',
      'Please try':'Bitte versuchen sie es',
      'again':'nochmal',
      'or contact via':'oder kontaktieren Sie uns per',
      'phone':'Telefon',
      'Welcome_to_the_Aufzughelden_support':'Willkommen zum Aufzughelden Support',
      'Report_a_problem_desc': 'Teilen Sie uns mit, welches Problem Sie mit diesem Asset haben. Je detaillierter der Bericht ist, desto schneller können wir uns darum kümmern.',
      'Please_fill_case_form': 'Bitte wählen Sie die Art der Störung aus und geben Sie uns eine kurze Information über den Vorfall.'
    },
};