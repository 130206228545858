import { I18n } from '@aws-amplify/core';
import { Input, InputRef, Modal, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { strings as stringGlobal } from '../../utils/globalLocalization';

I18n.putVocabularies(stringGlobal);
I18n.setLanguage('de')

const ModalDialogCopyText = (props:{copyText:string,setTextToCopy:Function,message?:string}) => {

    const inputRef = useRef<InputRef>(null);
    const handleCancelOk = () => {
        props.setTextToCopy(null)
    };

    const sharedProps = {
        style: { width: '100%' },
        defaultValue: props.copyText,
        ref: inputRef,
      };

    useEffect(() => {
        inputRef.current!.focus({
            cursor: 'all',
            });
    }, []);  
    

    return (
        <Modal title="Link kopieren... " open={true} onOk={handleCancelOk} onCancel={handleCancelOk} footer={null} maskClosable={true}>
            <div className="pb-8">{I18n.get('copyMessage')}</div>
            <Input {...sharedProps} /> 
        </Modal>
    );
};

export default ModalDialogCopyText;
