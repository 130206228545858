import React, { ReactNode, useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import Menu from './HeaderMenu'
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState, GlobalStateInterface } from '../utils/globalStateProvider';
import { strings as stringHeader } from './HeaderLocalization';
import { strings as stringGlobal } from '../utils/globalLocalization';
import { mergeDeepRight } from 'ramda';
import Header from './Header';
import EditableTitle from './EditableTitle';
import { getLangByLocalStorage, useClickOutsideListenerRef } from '../utils/helpers';
import { Installation, InstallationCoords } from '../interfaces/Installation';
import TileMapDetail from '../devices/components/detail/Map';
import arrowBack from '../assets/images/icons/arrow_back.svg';
import { Contract } from '../documents/interfaces/Contract';
import moment from 'moment';
import { Case } from '../incidents/interfaces/Case';
import { getTransformedIncidentStatus } from '../incidents/utils/helpers';
import HeaderDetailMessages from './HeaderDetailMessages';

export type HeaderDetailProps =
  | { 
    mode:'Installation',
    goBack?:()=>void,
    installation:Installation,
    contract:null,
    loadInstallation:Function,
    dynContent:ReactNode,
    dynContentMobile:ReactNode,
    }
  | { 
    mode:'Contract',
    goBack?:()=>void,
    contract:Contract|null,
    dynContent:ReactNode,
    dynContentMobile:ReactNode,
    }
  | { 
    mode:'Incident',
    goBack?:()=>void,
    case:Case|null,
    assetCoords: InstallationCoords[],
    dynContent:ReactNode,
    dynContentMobile:ReactNode,
    };


function HeaderDetail(props:HeaderDetailProps) {
  const [menuOpen, setMenuOpen] = useState(false)
  const { logout} = useAuth0()
  const { state, setState } = useGlobalState()
  const [enablingEditMode,setEnablingEditMode] = useState(false)
  const [showSubMenuMobile,setShowSubMenuMobile] = useState(false)
  const [showSubMenu,setShowSubMenu] = useState(false)
  const [currentContract, setCurrentContract] = useState<Contract | null>(null);

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringHeader,stringGlobal));
    I18n.setLanguage(getLangByLocalStorage())

  }, []);  

  if(props.mode === 'Installation'){
    return (
      <>
        <div className="hidden m:flex sticky top-0 z-30"><Header /></div>
        <section className="Header h-16 bg-blueDark sticky top-0  z-30 flex m:hidden">
          <div className="flex text-white items-center justify-between w-full m-0 pt-0" >
            <div className=" w-2/12">
              <div className="text-white flex items-center text-lg cursor-pointer" onClick={props.goBack}>
                <svg className="mr-6" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  < path d="M9 2L2 9L9 16" stroke="white" strokeWidth="2.91" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>
            <div className=" text-xl w-8/12 items-center text-center">
              {props.installation.label}
            </div>
            <div className=" w-2/12 " >
              <div className="text-lg float-right flex items-right justify-items-end">
                <div className="mr-2 m:hidden  cursor-pointer">
                  <svg onClick={()=>setMenuOpen(!menuOpen)}  width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0.272461 1.45455C0.272461 0.651222 0.923683 0 1.72701 0H16.2725C17.0758 0 17.727 0.651222 17.727 1.45455C17.727 2.25787 17.0758 2.90909 16.2725 2.90909H1.72701C0.923685 2.90909 0.272461 2.25787 0.272461 1.45455ZM0.272483 8C0.272483 7.19668 0.923705 6.54545 1.72703 6.54545H16.2725C17.0758 6.54545 17.727 7.19668 17.727 8C17.727 8.80332 17.0758 9.45455 16.2725 9.45455H1.72703C0.923707 9.45455 0.272483 8.80332 0.272483 8ZM1.72703 13.0909C0.923705 13.0909 0.272483 13.7421 0.272483 14.5455C0.272483 15.3488 0.923707 16 1.72703 16H16.2725C17.0758 16 17.727 15.3488 17.727 14.5455C17.727 13.7421 17.0758 13.0909 16.2725 13.0909H1.72703Z" fill="white"/></svg>
                </div>
              </div>
            </div>
          </div>
          { menuOpen && <Menu onClick={() => setMenuOpen(false)}/>}
        </section>
        <section className="bg-blueDark welcome  m:hidden">
          <div className="flex flex-col text-white pb-5 pt-10 ">
              <HeaderDetailMessages mode='Installation' installation={props.installation}/>
              <div className="flex">
                <div className="grow">
                  <EditableTitle
                  installation={props.installation}
                  isEditMode={enablingEditMode}
                  setterIsEditMode={setEnablingEditMode}
                  loadInstallation={props.loadInstallation}
                  cssIdForTest='cp_field_editable_title_mobile'
                  />
                </div>
                <div ref={ useClickOutsideListenerRef(()=>{setShowSubMenuMobile(false) }) } >
                  <div onClick={()=>{setShowSubMenuMobile(!showSubMenuMobile)}} className=" w-10 flex justify-center pt-2 pb-2 relative cursor-pointer">
                    <div className={showSubMenuMobile?"absolute z-10 right-9 -top-7":"hidden"}>
                      <div className=" border border-blueDark bg-white hover:bg-blueDark cursor-pointer">
                        <h3 onClick={()=>{setEnablingEditMode(true)}} className=" text-blueDark hover:text-white whitespace-nowrap pl-20 pr-20 pt-8 pb-8 ">{I18n.get('renameInstallation')}</h3>
                      </div>
                    </div>
                    <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="2" cy="2" r="1.5" fill="#F2F4F7" stroke="#F2F4F7"/>
                      <circle cx="2" cy="9" r="1.5" fill="#F2F4F7" stroke="#F2F4F7"/>
                      <circle cx="2" cy="16" r="1.5" fill="#F2F4F7" stroke="#F2F4F7"/>
                      </svg>
                    </div>
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">{I18n.get('elevatorLocation')}</div>
                <div className="w-1/2 ">{props.installation?.building?.street} {props.installation?.building?.houseNumber},<br/>
                {props.installation?.building?.postalCode} {props.installation?.building?.city}</div>
              </div>
              <div className="flex pt-20">{props.dynContentMobile}</div>
            </div>
          </section>
          <section className="bg-blueLight welcome hidden m:block ">
          <div className=" pt-9">
            <div className="text-white text-lg cursor-pointer inline-flex cp_header_back_desktop" onClick={props.goBack}>
              <img className=" mr-6 " src={arrowBack} alt="" /> {I18n.get('backLabel')}
            </div>
            <div className="flex m:min-h-[64px] xl:min-h-[122px]" >
              <HeaderDetailMessages mode='Installation' installation={props.installation}/>
            </div>
            <div className="flex text-white">
              <div className="bg-blueDark w-1/2 pl-9 pt-9 flex flex-col">
                <div className="flex">
                  <div>
                    <EditableTitle
                      installation={props.installation} 
                      isEditMode={enablingEditMode} 
                      setterIsEditMode={setEnablingEditMode} 
                      loadInstallation={props.loadInstallation}
                      cssIdForTest='cp_field_editable_title_dektop' 
                      />
                    <div className="text-sm m:text-base xl:text-lg"><span className="font-black">{I18n.get('fabricationNumber')}</span>: {props.installation?.label}</div>
                    <div className="text-sm m:text-base xl:text-lg"><span className="font-black">{I18n.get('elevatorLocation')}</span>: {props.installation?.building?.street}, {props.installation?.building?.postalCode} {props.installation?.building?.city}</div>
                  </div>
                  <div ref={ useClickOutsideListenerRef(()=>{setShowSubMenu(false) }) } >
                    <div onClick={()=>{setShowSubMenu(!showSubMenu)}} className=" w-10 flex justify-center pt-2 pb-2 relative cursor-pointer cp_btn_open_header_detail_menu">
                      <div className={showSubMenu?"absolute z-10 left-9 -top-7":"hidden"}>
                        <div className=" border border-blueDark bg-white hover:bg-blueDark cursor-pointer"><h3 onClick={()=>{setEnablingEditMode(true)}} className=" text-blueDark hover:text-white whitespace-nowrap pl-20 pr-20 pt-8 pb-8 cp_btn_edit_title">{I18n.get('renameInstallation')}</h3></div>
                      </div>
                      <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="2" r="1.5" fill="#F2F4F7" stroke="#F2F4F7"/>
                        <circle cx="2" cy="9" r="1.5" fill="#F2F4F7" stroke="#F2F4F7"/>
                        <circle cx="2" cy="16" r="1.5" fill="#F2F4F7" stroke="#F2F4F7"/>
                        </svg>
                      </div>
                  </div>
                </div>
                <div className=" grow flex  place-items-end">
                  <div className="flex flex-col w-full pb-9">
                    {props.dynContent}
                  </div>
                </div>
              </div>
              <div className="bg-greyLighter w-1/2"><TileMapDetail installations={[props.installation]}/></div>
            </div>
          </div>
        </section>      
      </>
    );
  }
  else if(props.mode === 'Contract'){
    return  (
      <>
        <div className="hidden m:flex sticky top-0 z-30"><Header /></div>
        <section className="Header h-16 bg-blueDark sticky top-0  z-30 flex m:hidden">
          <div className="flex text-white items-center justify-between w-full m-0 pt-0" >
            <div className=" w-2/12">
              <div className="text-white flex items-center text-lg cursor-pointer" onClick={props.goBack}>
                <svg className="mr-6" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  < path d="M9 2L2 9L9 16" stroke="white" strokeWidth="2.91" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>
            <div className=" text-xl w-8/12 items-center text-center">
              {props.contract?.contractNumber}
            </div>
            <div className=" w-2/12 " >
              <div className="text-lg float-right flex items-right justify-items-end">
                <div className="mr-2 m:hidden  cursor-pointer">
                  <svg onClick={()=>setMenuOpen(!menuOpen)}  width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0.272461 1.45455C0.272461 0.651222 0.923683 0 1.72701 0H16.2725C17.0758 0 17.727 0.651222 17.727 1.45455C17.727 2.25787 17.0758 2.90909 16.2725 2.90909H1.72701C0.923685 2.90909 0.272461 2.25787 0.272461 1.45455ZM0.272483 8C0.272483 7.19668 0.923705 6.54545 1.72703 6.54545H16.2725C17.0758 6.54545 17.727 7.19668 17.727 8C17.727 8.80332 17.0758 9.45455 16.2725 9.45455H1.72703C0.923707 9.45455 0.272483 8.80332 0.272483 8ZM1.72703 13.0909C0.923705 13.0909 0.272483 13.7421 0.272483 14.5455C0.272483 15.3488 0.923707 16 1.72703 16H16.2725C17.0758 16 17.727 15.3488 17.727 14.5455C17.727 13.7421 17.0758 13.0909 16.2725 13.0909H1.72703Z" fill="white"/></svg>
                </div>
              </div>
            </div>
          </div>
          { menuOpen && <Menu onClick={() => setMenuOpen(false)}/>}
        </section>
        <section className="bg-blueDark welcome  m:hidden">
          <div className="flex flex-col text-white pb-5 pt-10 ">
              <div className="flex flex-col">
                <h1 className="text-white">{props.contract?.contractNumber}</h1>
                <div className="grow font-black">
                {props.contract?.recordType? I18n.get('ContractType'+props.contract?.recordType) : I18n.get('UnknownContractType')}
                </div>
              </div>
              <div className="flex mt-4">
                <div className="w-1/2 ">{I18n.get('ContractLength')}</div>
                <div className="w-1/2 ">{ moment(props.contract?.startDate).format('DD.MM.YYYY') } - { moment(props.contract?.endDate).format('DD.MM.YYYY') }</div>
              </div>
              <div className="flex pt-20">{props.dynContentMobile}</div>
            </div>
          </section>
          <section className="bg-blueLight welcome hidden m:block ">
          <div className=" pt-9">
            <div className="text-white text-lg cursor-pointer inline-flex" onClick={props.goBack}>
              <img className=" mr-6 " src={arrowBack} alt="" /> {I18n.get('backLabel')}
            </div>
            {true && <div className=" m:h-16 xl:h-28" />}
            <div className="flex text-white">
              <div className="bg-blueDark w-1/2 pl-9 pt-9 flex flex-col">
                <div className="flex">
                  <div>
                    <h1 className="text-white">{props.contract?.contractNumber}</h1>
                    <div className="">
                      <div className="text-sm m:text-base xl:text-lg font-black">{props.contract?.recordType? I18n.get('ContractType'+props.contract?.recordType) : I18n.get('UnknownContractType')}</div>
                      <div className=" mt-4 text-sm m:text-base xl:text-lg " ><span className="font-black">{I18n.get('ContractLength')}</span>: { moment(props.contract?.startDate).format('DD.MM.YYYY') } - { moment(props.contract?.endDate).format('DD.MM.YYYY') }</div>
                    </div>
                  </div>
                </div>
                <div className=" grow flex  place-items-end">
                  <div className="flex flex-col w-full pb-9">
                    {props.dynContent}
                  </div>
                </div>
              </div>
              <div className="bg-greyLighter w-1/2"><TileMapDetail installations={props.contract?.assets}/></div>
            </div>
          </div>
        </section>
      </>
    )
  }
  else if(props.mode === 'Incident'){
    return  (
      <>
        <div className="hidden m:flex sticky top-0 z-30"><Header /></div>
        <section className="Header h-16 bg-blueDark sticky top-0  z-30 flex m:hidden">
          <div className="flex text-white items-center justify-between w-full m-0 pt-0" >
            <div className=" w-2/12">
              <div className="text-white flex items-center text-lg cursor-pointer" onClick={props.goBack}>
                <svg className="mr-6" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  < path d="M9 2L2 9L9 16" stroke="white" strokeWidth="2.91" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>
            <div className=" text-xl w-8/12 items-center text-center">
              {props.case?.caseNumber ? parseInt(props.case?.caseNumber) : ''}
            </div>
            <div className=" w-2/12 " >
              <div className="text-lg float-right flex items-right justify-items-end">
                <div className="mr-2 m:hidden  cursor-pointer">
                  <svg onClick={()=>setMenuOpen(!menuOpen)}  width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0.272461 1.45455C0.272461 0.651222 0.923683 0 1.72701 0H16.2725C17.0758 0 17.727 0.651222 17.727 1.45455C17.727 2.25787 17.0758 2.90909 16.2725 2.90909H1.72701C0.923685 2.90909 0.272461 2.25787 0.272461 1.45455ZM0.272483 8C0.272483 7.19668 0.923705 6.54545 1.72703 6.54545H16.2725C17.0758 6.54545 17.727 7.19668 17.727 8C17.727 8.80332 17.0758 9.45455 16.2725 9.45455H1.72703C0.923707 9.45455 0.272483 8.80332 0.272483 8ZM1.72703 13.0909C0.923705 13.0909 0.272483 13.7421 0.272483 14.5455C0.272483 15.3488 0.923707 16 1.72703 16H16.2725C17.0758 16 17.727 15.3488 17.727 14.5455C17.727 13.7421 17.0758 13.0909 16.2725 13.0909H1.72703Z" fill="white"/></svg>
                </div>
              </div>
            </div>
          </div>
          { menuOpen && <Menu onClick={() => setMenuOpen(false)}/>}
        </section>
        <section className="bg-blueDark welcome  m:hidden">
          <div className="flex flex-col text-white pb-5 pt-10 ">
              <div className="flex flex-col">
                <h1 className="text-white">{props.case?.caseNumber ? parseInt(props.case?.caseNumber) : ''}</h1>
              </div>
              <div className="flex pt-2">
                <div className="w-1/2">{I18n.get('RecordType')}</div>
                <div className="w-1/2 grow font-black">{props.case?.recordType? I18n.get('IncidentType_'+props.case?.recordType) : I18n.get('UnknownIncidentType')}</div>
              </div>
              <div className="flex pt-2">
                <div className="w-1/2">{I18n.get('Title')}</div>
                <div className="w-1/2 grow font-black">{props.case?.subject}</div>
              </div>
              <div className="flex pt-20">
                {props.dynContentMobile}
                <div className="w-1/2">
                  <div className="">{I18n.get('DateUpdated')}</div>
                  <div className="">{moment(props.case?.updatedAt).format('DD.MM.YYYY')}</div>
                </div>
                <div className="w-1/2">
                  <div className="">{I18n.get('Status')}</div>
                  <div className="">{I18n.get('IncidentStatus_' + getTransformedIncidentStatus(props.case?.status || ''))}</div>
                </div>
              </div>
            </div>
        </section>
        <section className="bg-blueLight welcome hidden m:block ">
          <div className=" pt-9">
            <div className="text-white text-lg cursor-pointer inline-flex" onClick={props.goBack}>
              <img className=" mr-6 " src={arrowBack} alt="" /> {I18n.get('backLabel')}
            </div>
            {true && <div className=" m:h-16 xl:h-28" />}
            <div className="flex text-white">
              <div className="bg-blueDark w-1/2 pl-9 pt-9 flex flex-col">
                <div className="flex">
                  <div>
                    <h1 className="text-white">{props.case?.caseNumber ? parseInt(props.case?.caseNumber) : ''}</h1>
                    <div className="text-sm m:text-base xl:text-lg"><span className="font-black">{I18n.get('RecordType')}</span>: {props.case?.recordType? I18n.get('IncidentType_'+props.case?.recordType) : I18n.get('UnknownIncidentType')}</div>
                    <div className="text-sm m:text-base xl:text-lg"><span className="font-black">{I18n.get('Title')}</span>: {props.case?.subject}</div>
                  </div>
                </div>
                <div className=" grow flex  place-items-end">
                  <div className="flex flex-col w-full pb-9">
                    {props.dynContent}
                    <div className="flex">
                      <div className=" w-1/2 pr-4">
                        <h3 className="uppercase text-white">{I18n.get('DateUpdated')}</h3>
                        <h2 className="text-white">{moment(props.case?.updatedAt).format('DD.MM.YYYY')}</h2>
                      </div>
                      <div className=" w-1/2 pr-4">
                        <h3 className="uppercase text-white">{I18n.get('Status')}</h3>
                        <h2 className="text-white">{I18n.get('IncidentStatus_' + getTransformedIncidentStatus(props.case?.status || ''))}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-greyLighter w-1/2"><TileMapDetail installations={undefined} installationCoords={props.assetCoords}/></div>
            </div>
          </div>
        </section>
      </>
    )
  }
  else{
    return <>undefined</>
  }
}

export default HeaderDetail;
