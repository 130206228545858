export const strings = {
  en: {
    'Report_a_problem':'Report a problem',
    'Please select an installation':'Please select an installation',
    'Select':'Select'
  },
  de: {
    'Report_a_problem':'Problem melden',
    'Please select an installation':'Bitte wählen Sie eine Installation',
    'Select':'Auswählen'
  },
};