import { strings as stringLocal } from './TableInstallationsLocalization'
import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { mergeDeepRight } from 'ramda'
import { app_url_devices } from '../../../utils/consts'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Pagination } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GlobalStateInterface, useGlobalState } from "../../../utils/globalStateProvider"
import { useEffect } from 'react'
import { getAuth0Token, getLangByLocalStorage, scrollTop } from '../../../utils/helpers'
import { getInstallationsByContract } from './../../utils/helpers' 
import { Installation } from '../../../interfaces/Installation'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../../utils/globalStateProviderApp'
import TableMain, { TableProps } from '../../../components/molecules/TableMain'
import './TableInstallations.css'

I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
const lang = getLangByLocalStorage()
I18n.setLanguage(lang)

moment.locale(lang ? lang : 'de');

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface PrevRef {
  searchTerm: string | undefined
  sortBy: string | undefined
}

const TableInstallations = (props: { }) => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();

  const query = useQuery();

  const [pageUrlParam, setPageUrlParam] = React.useState<number | undefined>()
  const [searchValueUrlParam, setSearchValueUrlParam] = React.useState<string>()
  const [sortByUrlParam, setSortByUrlParam] = React.useState<string | undefined>()
  const [totalNumberInstalations, setTotalNumberInstalations] = React.useState<number>(0)
  const [sortedInfo, setSortedInfo] = React.useState({ columnKey: '', order: '' })
  const [itemList, setItemList] = React.useState<JSX.Element | null>(null)
  const prevRef = useRef<PrevRef>({ searchTerm: undefined,sortBy:undefined });
  const topTableElementRef = useRef<null | HTMLTableElement>(null);
  const [tableProps, setTableProps] = useState<TableProps | null >(null)

  let { contractId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [ state.language]);

  const onRowClicked = (installation: Installation) => {
    document.location.href = (app_url_devices + '/org_' + state.organizationName + '/installations/' + installation._id)
  }

  const sortChange = (sorter: { columnKey: string, order: string }) => {

    if (sorter.order === '') {
      setSortedInfo({ columnKey: '', order: '' })
    }
    else {
      setSortedInfo(sorter)
    }
    let sortBy = ''
    if (sorter.columnKey && sorter.order) {
      sortBy = sorter.columnKey + ':' + (sorter.order === 'asc' ? 'asc' : 'desc')
    }
    setPageUrlParam(1)
    setSortByUrlParam(sortBy)
  };

  useEffect(() => {
    if (!searchValueUrlParam && !pageUrlParam && !sortByUrlParam) {

      if (stateApp.search) {
        // case hits if components gets loaded > like coming from installation detail page
        // 2 cases can happen / Back button pressd or logo in header 
        if (query.get('back') === 'true') {
          const pageParam = query.get("page") ? query.get("page") : '1'
          if (pageParam !== null) {
            setPageUrlParam(parseInt(pageParam))
          }
          else {
            setPageUrlParam(1)
          }

          const searchParam = query.get("search")
          if (searchParam !== undefined && searchParam !== null) {
            setSearchValueUrlParam(searchParam)
          }
          else {
            setSearchValueUrlParam('')
          }

          const sortByParam = query.get("sortBy")
          if (sortByParam !== undefined && sortByParam !== null) {
            const arrSplit = sortByParam.split(':')
            if (arrSplit.length === 2) {
              setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
              setSortByUrlParam(sortByParam)
            }
          }
          else {
            setSortByUrlParam('')
          }
          return
        }

        // logo clicked case
        setPageUrlParam(1)
        setSearchValueUrlParam('')
        setSortByUrlParam('')

      }
      else {
        // case hits if url gets opened directly by browser
        // init params from saved state
        const pageParam = query.get("page") ? query.get("page") : '1'
        if (pageParam !== null) {
          setPageUrlParam(parseInt(pageParam))
        }
        else {
          setPageUrlParam(1)
        }

        const searchParam = query.get("search")
        if (searchParam !== undefined && searchParam !== null) {
          setSearchValueUrlParam(searchParam)
        }
        else {
          setSearchValueUrlParam('')
        }

        const sortByParam = query.get("sortBy")
        if (sortByParam !== undefined && sortByParam !== null) {
          const arrSplit = sortByParam.split(':')
          if (arrSplit.length === 2) {
            setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
            setSortByUrlParam(sortByParam)
          }
        }
        else {
          setSortByUrlParam('')
        }
      }
      return
    }

    // storing url for having it for the backButton at the installation detail list
    const dataGlobal: Partial<GlobalStateInterfaceApp> = {
      search: {
        page: (pageUrlParam ? pageUrlParam : 1),
        searchterm: (searchValueUrlParam ? searchValueUrlParam : ''),
        sortBy: (sortByUrlParam ? sortByUrlParam : ''),
        parametersUpdatedByUrl: true
      }
    }
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))

  }, [pageUrlParam, searchValueUrlParam, sortByUrlParam, state.organizationName]);

  useEffect(() => {
    BuildItemList()
  }, [state.language]);

  useEffect(() => {
    // on reload getInstallations gets called with uninitialised parameters, so looking to parametersUpdatedByUrl
    if (stateApp.search?.parametersUpdatedByUrl) {

      let forceUpdateCoordinates = false
      if (prevRef.current.searchTerm === undefined || prevRef.current.searchTerm !== stateApp.search?.searchterm || prevRef.current.sortBy !== stateApp.search?.sortBy) {
        forceUpdateCoordinates = true
      }
      loadInstallations()
    }
    prevRef.current.searchTerm = stateApp.search?.searchterm
    prevRef.current.sortBy = stateApp.search?.sortBy
  }, [stateApp.search?.page, stateApp.search?.searchterm, stateApp.search?.sortBy]);

  async function loadInstallations() {
    if(!contractId){return}
    const accessToken =  await getAuth0Token(getAccessTokenSilently)
    getInstallationsByContract(setStateApp, stateApp.search?.page, 10, contractId , stateApp.search?.sortBy , accessToken)
  }

  const getSortOrder = (columnKey: string): string => {
    if (sortedInfo.columnKey === columnKey) {
      if (sortedInfo.order === 'asc') {
        return 'desc'
      }
      else if (sortedInfo.order === 'desc') {
        return ''
      }
      else {
        return 'asc'
      }
    }
    else {
      return 'asc'
    }
  }

  const getLabel = (installation:Installation) => {
    if(installation.customLabel && installation.customLabel.trim().length > 0 
      && installation.customLabel?.trim() !== installation.label){
      return installation.customLabel + '/' + installation.label
    }
    else{
      return installation.label
    }
  }

  useEffect(() => {
    BuildItemList()
    setTotalNumberInstalations(stateApp.installationCount ? stateApp.installationCount : 0)
  }, [stateApp.installationCount, stateApp.installations]);

  const getSortVisualisation = (columnKey: string) => {

    if (columnKey === sortedInfo.columnKey) {
      if (sortedInfo.order === 'asc') {
        return <CaretUpOutlined style={{ 'color': 'white' }} />
      }
      else {
        return <CaretDownOutlined style={{ 'color': 'white' }} />
      }
    }
    return <CaretUpOutlined style={{ 'color': 'grey' }} />
  }

  const BuildItemList = () => {

    let tableP:TableProps = {th:[],tr:[],noDataLabel:'Dieser Ordner ist leer',height:'unset',thead:'sticky-documents-detail'}

    tableP.th = [
      
      // mobile
      {
        text:<><span className="mr-4">{I18n.get('elevator')}</span>{getSortVisualisation('label')}</>,
        class:'flex l:hidden w-2/12 items-center pb-0',
        ellipsis:true,
        onCellClick:() => sortChange({ columnKey: 'label', order: getSortOrder('label') })
      },          
      {
        text:<><span className="mr-4">{I18n.get('address')}</span>{getSortVisualisation('address')}</>,
        class:'flex l:hidden w-5/12 items-center pb-0',
        twoLinesEllipses:true,
        onCellClick:() => sortChange({ columnKey: 'address', order: getSortOrder('address') })
      },
      {
        text:<span className="flex flex-col pb-0 text-left">
          <span className="" >{I18n.get('Last')} / {I18n.get('Next')}</span>
          <span className="" >{I18n.get('Maintenance')}</span>
        </span>,        
        class:'flex l:hidden w-5/12 items-center pb-0 text-left',
        ellipsis:false,
      },

      // desktop
      {
        text:<><span className="mr-4">{I18n.get('elevator')}</span>{getSortVisualisation('label')}</>,
        class:'hidden l:flex w-2/12 ',
        twoLinesEllipses:true,
        onCellClick:() => sortChange({ columnKey: 'label', order: getSortOrder('label') })
      },          
      {
        text:<><span className="mr-4">{I18n.get('address')}</span>{getSortVisualisation('address')}</>,
        class:'hidden l:flex w-3/12 ',
        twoLinesEllipses:true,
        onCellClick:() => sortChange({ columnKey: 'address', order: getSortOrder('address') })
      },
      {
        text:<>{I18n.get('MaintenanceInterval')}</>,
        class:'hidden l:flex w-2/12 text-left',
        ellipsis:false,
      },
      {
        text:<>{I18n.get('Last')} / {I18n.get('Next')} <br/>{I18n.get('Maintenance')} </>,
        class:'hidden l:flex w-2/12 text-left ',
        ellipsis:false,
      },
      {
        text:<span className="text-left">{I18n.get('AuditInterval')} / <br/> {I18n.get('LastDateOfMainAudit')}</span>,
        class:'hidden l:flex w-2/12 ',
        ellipsis:false,
      },
      {text:<></>,class:'hidden l:flex w-1/12 '}
    ]

    if(stateApp.installations){
      tableP.tr = stateApp.installations?.map((itemInstallation, index) => {

        let buildingLabel = (itemInstallation.building && itemInstallation.building.label)?itemInstallation.building.label:''

        let dataRow:TableProps["tr"][0] =  {cells:[
          //itemInstallation.
          // mobile
          {text:<>{getLabel(itemInstallation)}</>,textPlain:getLabel(itemInstallation) ,class:'flex l:hidden text-left items-center w-2/12 hover:cursor-pointer '},
          {text:<>{buildingLabel}</>,textPlain:buildingLabel,class:'flex l:hidden w-5/12 hover:cursor-pointer'},
          {text:<>{(itemInstallation.lastDateOfMaintenance?moment(itemInstallation.lastDateOfMaintenance).format('DD.MM.YYYY'):'' )}
            {((!itemInstallation.lastDateOfMaintenance && !itemInstallation.lastDateOfMaintenance)?'':' / ')}<br/>
            {(itemInstallation.nextDateOfMaintenance?moment(itemInstallation.nextDateOfMaintenance).format('DD.MM.YYYY'):'')}</>,class:'flex l:hidden w-5/12 hover:cursor-pointer'},

          // desktop
          {text:<>{getLabel(itemInstallation)}</>,textPlain:getLabel(itemInstallation) ,class:'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer '},
          {text:<>{buildingLabel}</>,textPlain:buildingLabel,class:'hidden l:flex w-3/12 hover:cursor-pointer'},
          {text:<>{itemInstallation.maintenanceInterval?(itemInstallation.maintenanceInterval + ' ' + I18n.get('Months')):''}</>,class:'hidden l:flex w-2/12 hover:cursor-pointer'},
          {text:<>{(itemInstallation.lastDateOfMaintenance?moment(itemInstallation.lastDateOfMaintenance).format('DD.MM.YYYY'):'' )}
            {((!itemInstallation.lastDateOfMaintenance && !itemInstallation.lastDateOfMaintenance)?'':' / ')}<br/>
            {(itemInstallation.nextDateOfMaintenance?moment(itemInstallation.nextDateOfMaintenance).format('DD.MM.YYYY'):'')}</>,class:'hidden l:flex w-2/12 hover:cursor-pointer'},
          {text:<>{
            (itemInstallation.auditInterval? itemInstallation.auditInterval : '') + ' ' }
             / <br/> 
            {(itemInstallation.lastDateOfMainAudit?moment(itemInstallation.lastDateOfMainAudit).format('DD.MM.YYYY'):'')}</>,class:'hidden l:flex w-2/12 hover:cursor-pointer'},
          {text:<><svg className="mr-14" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></>,class:'hidden l:flex flex-col w-1/12 justify-center hover:cursor-pointer'}
        ],trOnClick:(e)=>{onRowClicked(itemInstallation)}}
        return dataRow
      })
    }

    setTableProps(tableP)
  }

  return (
    <div className="idTableInstallationsForContract flex flex-col">
      <TableMain tableContent={tableProps} />
      <div className=" h-24 bg-blueBaby flex justify-center items-center">
        <Pagination current={stateApp.search?.page ? stateApp.search?.page : 1} pageSize={10} showSizeChanger={false} total={totalNumberInstalations} onChange={(page, pageSize) => {
            setPageUrlParam(page);
            window.scrollTo({
              top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
              behavior: 'smooth',
            });
        }} />
      </div>
    </div>
  );
};

export default TableInstallations;
