import { DeviceInfo, DeviceInfoDictionary, DeviceInfoResponse, DeviceShadow } from '../interfaces/DeviceInfo';
import { DoorStateObj } from '../interfaces/DoorStateObj';
import { Installation } from '../interfaces/Installation';
import { GlobalStateInterface } from './globalStateProvider';
import { GlobalStateInterfaceApp } from '../devices/utils/globalStateProviderApp';
import { customFetchWithAuthHeader, fetchDevicesStatus, fetchOrganizationIdentifier, fetchOrganizationName, fetchUserIntallations, fetchUserIntallationsCoords } from './helpersFetch';
import DoorState from '../devices/components/detail/DoorState'
import { FeedMessage, FeedMessageDictionary } from '../interfaces/Lifefeed';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import { USER_CHOICE_LANGUAGE, gtm_id, ga4_id, app_url_devices, app_url_documents, app_url_contracts, app_url_incidents, APPLICATION, app_url_portal, INCIDENTS_API_URL, app_url_learn } from './consts'
import { strings as stringGlobal } from './globalLocalization'
import { Spin } from 'antd';
import { analyticsParameters, WindowWithDataLayer } from '../interfaces/WindowWithDataLayer';
import ReactGA from "react-ga4";
import CryptoJS from 'crypto-js'
import jwt_decode from 'jwt-decode';
import { JwtPayloadAuth0 } from './../interfaces/JwtPayloadAuth0';
import { Contract } from './../documents/interfaces/Contract';
import { User } from '@auth0/auth0-react';
import moment from 'moment';
import { mergeDeepRight } from 'ramda';
import { appPermissions } from '../utils/appLaunchConfig';
import { Case } from './../incidents/interfaces/Case';
import { errResponse } from './../interfaces/RunBetweenFloors';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const colorsTheme = require('../../src/utils/aufzugheldenTheme')

declare const window: WindowWithDataLayer;

export const allowedLangValue = ['de','en']

export const IsStorybook = () => {
  if(location.port == '6006' || location.hostname === 'storybook.test.aufzughelden.com' || location.hostname === 'storybook.aufzughelden.com'){
    return true
  }else{
    return false
  }
}

export const getAuth0Token = async (getAuth0Silent:Function)  => {
  if(IsStorybook()){
    return localStorage.getItem('storybookToken')
  }else{
    return await getAuth0Silent()
  }
}

export const getAuth0IsAuthenticatedStatus = (isAuthenticated:boolean)  => {
  if(IsStorybook()){
    return true
  }else{
    return isAuthenticated
  }
}

export const getLangByLocalStorage = () => {
  let lang = localStorage.getItem(USER_CHOICE_LANGUAGE)?localStorage.getItem(USER_CHOICE_LANGUAGE):'de'
  if( !(lang && allowedLangValue.includes(lang)) ){ lang = 'de' }
  return lang
}

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

export const getDoorStateByInstallation = (deviceInfo: DeviceInfoResponse | null, installation: Installation, storedFeedMessages: FeedMessageDictionary | null, deviceSadow: DeviceShadow | null) => {
  var doorStates: DoorStateObj | undefined
  doorStates = {
    doorCount: 1,
    doorSoutOpen: false,
    doorNorthOpen: false
  }

  if (deviceSadow && doorStates) {
    deviceSadow.state.reported.deviceStatus.doors.forEach((door, index) => {
      let doorstate = false
      switch (door.doorState) {
        case 'doorClosed':
          doorstate = false
          break;
        case 'doorClosing':
          doorstate = false
          break;
        case 'doorOpening':
          doorstate = true
          break;
        case 'doorOpened':
          doorstate = true
          break;
      }

      if (index === 0 && doorStates) {
        doorStates.doorSoutOpen = doorstate
      }
      else if (index === 1 && doorStates) {
        doorStates.doorCount = 2
        doorStates.doorNorthOpen = doorstate
      }
    })
    return <DoorState doorCount={doorStates['doorCount']} doorSouthOpen={doorStates['doorSoutOpen']} doorNorthOpen={doorStates['doorNorthOpen']} />
  }
  else if (storedFeedMessages && installation && (deviceInfo?.device && deviceInfo.device.deviceId in storedFeedMessages)) {
    let doorStatesByLiFe = getDoorStateByLiveFeed(storedFeedMessages[deviceInfo.device.deviceId])
    if (doorStatesByLiFe) {
      return <DoorState doorCount={doorStatesByLiFe['doorCount']} doorSouthOpen={doorStatesByLiFe['doorSoutOpen']} doorNorthOpen={doorStatesByLiFe['doorNorthOpen']} />
    }
  }
  return null
}

export const getStatusMessage = ( incidentsData: Case[] | undefined, deviceInfo: DeviceInfo | null, installationHasDevice = true, lastDeviceErrorStateByLivefeed?: string | undefined): string => {
  
  if(incidentsData && incidentsData.length > 0){
    return I18n.get('incidentExists');
  }
  if (!installationHasDevice) {
    return I18n.get('noBoxInstalled');
  }
  if (!deviceInfo) {
    return I18n.get('notConnectedToElevator');
  }
  if ((lastDeviceErrorStateByLivefeed && lastDeviceErrorStateByLivefeed === '1')) { // if its stop than ignore
    return I18n.get('warningControl')
  }
  else {
    if (deviceInfo.ragErrorState && deviceInfo.ragErrorState === "2") {
      return I18n.get('errorControl');
    } else if (deviceInfo.ragMqtt && deviceInfo.ragMqtt === "1") {
      return I18n.get('notConnectedToElevator');
    } else if (deviceInfo.ragCan && deviceInfo.ragCan === "1") {
      return I18n.get('notConnectedToElevator');
    } else if (deviceInfo.ragErrorState && deviceInfo.ragErrorState === "1") {
      return I18n.get('warningControl');
    } else {
      return I18n.get('connectedToElevator');
    }
  }
}

export const getStatus = (
  incidentsData: Case[] | undefined,
  deviceInfo: DeviceInfo | null,
  svgClassNames: string,
  isLoadingDeviceState: boolean,
  resultAsMessage?: boolean,
  deviceInstalled = true,
  lastDeviceErrorStateByLivefeed?: string | undefined
): JSX.Element => {
  const svgNoBoxInstalled = <svg className={svgClassNames} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15.0" fill="#808080" /></svg>
  const svgNoConnection = <svg className={svgClassNames} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.08085 0.5L0 2.56619L3.94189 6.50808C2.03689 8.98459 0.893886 12.0766 0.893886 15.4323C0.893886 19.4768 2.53512 23.1402 5.18747 25.7926L7.26832 23.7117C5.14351 21.6016 3.82466 18.6708 3.82466 15.4323C3.82466 12.8679 4.65993 10.5232 6.03739 8.60359L8.1329 10.6991C7.26832 12.0619 6.75543 13.6885 6.75543 15.4323C6.75543 17.8648 7.75189 20.0483 9.33451 21.6455L11.4154 19.5647C10.3456 18.5096 9.6862 17.0589 9.6862 15.4323C9.6862 14.5091 9.90601 13.6299 10.287 12.8532L12.6463 15.2125C12.6463 15.2858 12.617 15.359 12.617 15.4323C12.617 16.2383 12.954 16.9709 13.4816 17.4985C14.0091 18.026 14.7418 18.3631 15.5478 18.3631C15.621 18.3631 15.6943 18.3484 15.7822 18.3338L26.9485 29.5L29.0147 27.4338L2.08085 0.5ZM23.9005 18.1726C24.1789 17.308 24.3401 16.3848 24.3401 15.4323C24.3401 10.5819 20.3982 6.63997 15.5478 6.63997C14.5952 6.63997 13.6721 6.78651 12.8075 7.07959L15.3133 9.5854C15.3866 9.57074 15.4745 9.57074 15.5478 9.57074C18.7863 9.57074 21.4093 12.1938 21.4093 15.4323C21.4093 15.5056 21.4093 15.5935 21.3946 15.6668L23.9005 18.1726ZM15.5478 3.7092C22.0248 3.7092 27.2708 8.95528 27.2708 15.4323C27.2708 17.2201 26.8752 18.9053 26.1425 20.4146L28.3259 22.598C29.5129 20.4879 30.2016 18.0407 30.2016 15.4323C30.2016 7.34336 23.6367 0.778424 15.5478 0.778424C12.9394 0.778424 10.5068 1.4525 8.38201 2.65412L10.5508 4.82289C12.0748 4.10485 13.76 3.7092 15.5478 3.7092Z" fill="#102A68" /></svg>
  const svgCircleGreen = <svg className={svgClassNames} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15.0" fill={colorsTheme.green} /></svg>
  const svgCircleYellow = <svg className={svgClassNames} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15.0" fill="#FF9900" /></svg>
  const svgCircleRed = <svg className={svgClassNames} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15.0" fill="#FE4365" /></svg>

  let retrunValue = <></>
  let retrunValueAsMessage = <></> // gets used at the detailpage

  if(incidentsData && incidentsData.length > 0){
    retrunValueAsMessage = <>
      <div className=" text-sm xxl:text-lg">{I18n.get('MachineUnderService')}</div>
    </>

    retrunValue = <div className=" text-grey ">
      {svgCircleRed}
    </div>
  }
  else if (!deviceInstalled) {
    retrunValueAsMessage = <>
      <div className=" text-sm xxl:text-lg">{I18n.get('noBoxInstalled')}</div>
    </>
    retrunValue = <div className=" text-grey ">
      {svgNoBoxInstalled}
    </div>
  } else if (!deviceInfo) {
    // unloaded state
    retrunValueAsMessage = <>
      <ExclamationCircleOutlined className=" hidden text-l xxl:text-lg text-warnYellow " />
      <div className=" text-sm xxl:text-lg">{I18n.get('noCloudConnection')}</div>
    </>
    if (isLoadingDeviceState) {
      retrunValue = <div className=" text-grey  ml-3">
        <Spin size="small" spinning={isLoadingDeviceState}></Spin>
      </div>
    }
    else {
      retrunValue = <div className=" text-green ml-0 ">
        {svgNoConnection}
      </div>
    }
  } else {

    if (lastDeviceErrorStateByLivefeed && lastDeviceErrorStateByLivefeed === '1') { // if its stop than ignore
      if (lastDeviceErrorStateByLivefeed === '1') {
        retrunValueAsMessage = <>
          <div className=" text-sm xxl:text-lg">{I18n.get('warningControl')}</div>
        </>
        retrunValue = <div className=" text-warnYellow ">
          {svgCircleRed}
        </div>
      }
    }
    else {
      if (deviceInfo.ragErrorState && deviceInfo.ragErrorState === "2") {
        retrunValueAsMessage = <>
          <div className=" text-sm xxl:text-lg">{I18n.get('errorControl')}</div>
        </>
        retrunValue = <div className=" text-warnYellow ">
          {svgCircleRed}
        </div>
      } else if (deviceInfo.ragMqtt && deviceInfo.ragMqtt === "1") {
        retrunValueAsMessage = <>
          <div className=" text-sm xxl:text-lg">{I18n.get('notConnectedToElevator')}</div>
        </>
        retrunValue = <div className=" text-green ">
          {svgCircleYellow}
        </div>
      } else if (deviceInfo.ragCan && deviceInfo.ragCan === "1") {
        retrunValueAsMessage = <>
          <div className=" text-sm xxl:text-lg">{I18n.get('notConnectedToElevator')}</div>
        </>
        retrunValue = <div className=" text-warnYellow ">
          {svgCircleYellow}
        </div>
      } else if (deviceInfo.ragErrorState && deviceInfo.ragErrorState === "1") {
        retrunValueAsMessage = <>
          <div className=" text-sm xxl:text-lg">{I18n.get('warningControl')}</div>
        </>
        retrunValue = <div className=" text-warnYellow ">
          {svgCircleYellow}
        </div>
      } else {
        retrunValueAsMessage = <>
          <div className="hidden m:block m:ml-0 text-sm xxl:text-l">{I18n.get('connectedToElevator')}</div>
        </>
        retrunValue = <div className=" text-green ">
          {svgCircleGreen}
        </div>
      }
    }
  }

  if (resultAsMessage) {
    return retrunValueAsMessage
  }
  else {
    return retrunValue
  }
}


export const getHumanReadableFloorLabel = (floor: string, mapping:{[index: string]: string}|undefined) => {
 // console.log(mapping,floor)
  if(mapping){
    return mapping[floor]
  }
  else{
    return floor?floor:'' 
  }
}

export const setMaxAssetCount = async (setState: React.Dispatch<React.SetStateAction<any | null>>, accessToken: string) => {
  const installationsResponse = await fetchUserIntallations(1, 1, '', '', '',accessToken);
  let dataGlobal: Partial<GlobalStateInterfaceApp> = {
    maxInstallationCount: installationsResponse.totalCount
  }
  setState((prev: any) => ({ ...prev, ...dataGlobal }))
};

export const getInstallations = async (
  setState: React.Dispatch<React.SetStateAction<any | null>>,
  page = 1,
  pageSize = 10,
  searchText = '',
  sortBy = '',
  filterBy = '',
  forceUpdateCoordinates = false,
  accessToken: string,
  controllerRef: React.MutableRefObject<AbortController | null | undefined>) => {
  const installationsResponse = await fetchUserIntallations(page, pageSize, searchText, sortBy, filterBy, accessToken, controllerRef);
  let dataGlobal: Partial<GlobalStateInterfaceApp> = {
    installations: installationsResponse.assets,
    installationCount: installationsResponse.totalCount,
  }
  setState((prev: any) => ({ ...prev, ...dataGlobal }))

  if (forceUpdateCoordinates) {
    const installationsResponseCoordinates = await fetchUserIntallationsCoords(page, pageSize, searchText, sortBy, accessToken, controllerRef);
    let dataGlobalCoordinates: Partial<GlobalStateInterfaceApp> = {
      installationsBySearch: installationsResponseCoordinates.assets,
    }
    setState((prev: any) => ({ ...prev, ...dataGlobalCoordinates }))
  }
};

export const updateInstallationDeviceStatus = async (setState: React.Dispatch<React.SetStateAction<any | null>>, state: Partial<GlobalStateInterfaceApp>, accessToken: string, setIsLoadingDeviceState: (value: React.SetStateAction<boolean>) => void) => {
  // updates the global list of devices

  let allreadyLoadedDevices: DeviceInfoDictionary = {}
  if (state.devices) {
    allreadyLoadedDevices = JSON.parse(JSON.stringify(state.devices)) as DeviceInfoDictionary
  }
  const deviceNamesToFetch: string[] = []

  state.installations?.forEach(function (installation: Installation) {
    deviceNamesToFetch.push(installation.iotBoxes[0])
  })


  const devicesStatus = await fetchDevicesStatus(deviceNamesToFetch, accessToken);
  deviceNamesToFetch.forEach(function (deviceNameToLoad: string) {
    let respondedDeviceInfo = null
    devicesStatus.forEach(function (device: DeviceInfoResponse) {
      if (device.device?._id === deviceNameToLoad) {
        respondedDeviceInfo = device.device
      }
    })
    allreadyLoadedDevices[deviceNameToLoad] = respondedDeviceInfo
  })

  let dataGlobal: Partial<GlobalStateInterfaceApp> = {
    devices: allreadyLoadedDevices
  }
  setState((prev: any) => ({ ...prev, ...dataGlobal }))
  setIsLoadingDeviceState(false)

};


export const getIncidentsByAssetId = async (installationId: string ,accessToken:string): Promise< { [key: string] : Case[] }> => {

  const url = INCIDENTS_API_URL + '/v1/cases/activeByAssets/';

  const response = await customFetchWithAuthHeader(url, {
    method: 'POST',
    body: JSON.stringify({'assetIds': [installationId]}),
  },accessToken);

  const data = await response.json();

  return data;
};

export const updateIncidentsByAssetIds = async (setState: React.Dispatch<React.SetStateAction<any | null>>, state: Partial<GlobalStateInterfaceApp>,installations: Installation[] | undefined ,accessToken:string, setIsLoadingIncidentsState: (value: React.SetStateAction<boolean>) => void) => {

  if(!installations){ return {} }

  const url = INCIDENTS_API_URL + '/v1/cases/activeByAssets/';
  try{
    const response = await customFetchWithAuthHeader(url, {
      method: 'POST',
      body: JSON.stringify({'assetIds': installations.map((elem => elem._id))}),
    },accessToken);
  
    const data = await response.json();
  
    let dataGlobal: Partial<GlobalStateInterfaceApp> = {
      incidents: data
    }
    setState((prev: any) => ({ ...prev, ...dataGlobal }))
    setIsLoadingIncidentsState(false)
  }
  catch(error){
    console.log(error)
  }
};

const getDoorStateByLiveFeed = (messages: FeedMessage[]): DoorStateObj | undefined => {

  if (messages !== undefined) {
    if (messages.length > 0) {
      // its required that the message on position 0 is for the current device
      if (messages[0].body?.name === "deviceDoorStateChanged") {

        var doorStates: DoorStateObj = {
          doorCount: 1,
          doorSoutOpen: false,
          doorNorthOpen: false
        }
        messages[0].body.attributes?.doors?.forEach((door, index) => {
          let doorstate = false
          switch (door.doorState) {
            case 'doorClosed':
              doorstate = false
              break;
            case 'doorClosing':
              doorstate = false
              break;
            case 'doorOpening':
              doorstate = true
              break;
            case 'doorOpened':
              doorstate = true
              break;
          }
          if (index === 0) {
            doorStates.doorSoutOpen = doorstate
          }
          else if (index === 1) {
            doorStates.doorCount = 2
            doorStates.doorNorthOpen = doorstate
          }
        })
        return doorStates
      }
    }
  }
  return undefined
}

export const instanceOfDeviceShadow = (object: any): object is DeviceShadow => {
  return 'state' in object;
}

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const analyticsEvent = (event: analyticsParameters) => {

  var cookieString = document.cookie;
  if (cookieString.includes("cookieconsent=True") && ga4_id) {
    ReactGA.event({
      category: event.category,
      action: event.action,
      ...(event.label ? { label: event.label } : null),
      ...(event.value ? { value: event.value } : null),
      //nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }
}

export const useClickOutsideListenerRef = (onClose: () => void) => {
  const ref = useRef(null)
  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose()
    }
  }, [])
  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!(ref.current! as any).contains(e.target)) {
        onClose?.()
      }
    },
    [ref.current],
  )
  useEffect(() => {
    document.addEventListener('click', clickListener)
    document.addEventListener('keyup', escapeListener)
    return () => {
      document.removeEventListener('click', clickListener)
      document.removeEventListener('keyup', escapeListener)
    }
  }, [])
  return ref
}

export function formatBytes(kbytes: number, decimals = 2) {
  if (!+kbytes) return '0 Bytes'
  const bytes = kbytes * 1024
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const md5FromFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async (fileEvent) => {
      if (fileEvent && fileEvent.target && fileEvent.target.result) {
        resolve(CryptoJS.enc.Base64.stringify(CryptoJS.MD5(CryptoJS.enc.Latin1.parse(fileEvent.target.result.toString()))));
      }
    }
    reader.onerror = () => {
      reject('oops, something went wrong with the file reader.')
    }
    reader.readAsBinaryString(file)
  })
}

export const fileChecksum = async (file: File) => {
  const md5: any = await md5FromFile(file)
  return md5
}

export const IsDsMember = async (accessToken: any): Promise<boolean> => {
  try{
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if (tokenObj && tokenObj.org_id) {
      let organization = await fetchOrganizationName(tokenObj?.org_id)
      if (organization && organization.name === 'digitalspine') {
        return true
      }
    }  
  }
  catch(err){
    console.log(err)
    return false
  }
  return false
}

export const IsDsMemberLocal = (accessToken: any): boolean => {
  try{
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if(tokenObj && (tokenObj.orgName === 'digitalspine' || tokenObj.orgName === 'Digital Spine')) {
        return true
    }  
  }
  catch(err){
    console.log(err)
    return false
  }
  return false
}

export const IsStorybookAndDsMember = async (accessToken: any): Promise<boolean> => {
  try{
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if(IsStorybook() && tokenObj.orgName === 'digitalspine'){
      return true
    }    
  }
  catch{
    return false
  }
  return false
}

export const UserHasPermissionsForContracts = async (accessToken: any): Promise<boolean> => {
  try{
    let needePermissionFound = false
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if (tokenObj) {
      tokenObj.permissions.forEach(itemGranted => {
        if (itemGranted === 'app:contracts') { needePermissionFound = true }
      })
    }
    return needePermissionFound    
  }
  catch{
    return false
  }
}

export const UserHasPermissionsForIncidents = async (accessToken: any): Promise<boolean> => {
  try{
    let needePermissionFound = false
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if (tokenObj) {
      tokenObj.permissions.forEach(itemGranted => {
        if (itemGranted === 'app:incidents') { needePermissionFound = true }
      })
    }
    return needePermissionFound
  }
  catch{
    return false
  }
}

export const UserHasPermissionsForDevices = async (accessToken: any): Promise<boolean> => {
  try{
    let needePermissionFound = false
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if (tokenObj) {
      tokenObj.permissions.forEach(itemGranted => {
        if (itemGranted === 'app:devices') { needePermissionFound = true }
      })
    }
    return needePermissionFound
  }
  catch{
    return false
  }
}

export const UserHasPermissionsForDocuments = async (accessToken: any): Promise<boolean> => {
  try{
    let needePermissionFound = false
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if (tokenObj) {
      tokenObj.permissions.forEach(itemGranted => {
        if (itemGranted === 'app:documents') { needePermissionFound = true }
      })
    }
    return needePermissionFound
  }
  catch{
    return false
  }
}

export const UserHasPermissionsForDocumentsUpload = async (accessToken: any): Promise<boolean> => {
  try{
    let needePermissionFound = false
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if (tokenObj) {
      tokenObj.permissions.forEach(itemGranted => {
        if (itemGranted === 'create:files') { needePermissionFound = true }
      })
    }
    return needePermissionFound
  }
  catch{
    return false
  }
}

export const UserHasPermissionsToReadCases = async (accessToken: any): Promise<boolean> => {
  try{
    let needePermissionFound = false
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)
    if (tokenObj) {
      tokenObj.permissions.forEach(itemGranted => {
        if (itemGranted === 'read:cases') { needePermissionFound = true }
      })
    }
    return needePermissionFound
  }
  catch{
    return false
  }
}

export const isTestEnv = () => {
  if(app_url_devices === 'https://devices.test.aufzughelden.com'){
    return true
  }
  else{
    return false
  }
};

export const appLinkFor = (appIdentifier: Apps, state: Partial<GlobalStateInterface>,  location?: string, additionalParams?: { [key: string]: any }) => {

  const appUrls = {
    Devices: app_url_devices,
    Documents: app_url_documents,
    Contracts: app_url_contracts,
    Incidents: app_url_incidents,
    Portal: app_url_portal,
    Learn: app_url_learn
  };

  const appUrl = appUrls[appIdentifier] || app_url_devices; // Default to devices

  let url = `${appUrl}/org_${state.organizationName}/`;

  if (location) {
    url += location
  }

  url += `?lang=${state.language}`
  
  if (additionalParams) {
    const queryParams = new URLSearchParams(additionalParams).toString();
    url += `&${queryParams}`;
  }

  return url;
};

export const setDocumentTitle = (installation?: Installation, contract?: Contract) => {

  if (APPLICATION === 'documents') {
    document.title = I18n.get('AppTitleDocuments')
  }
  else if (APPLICATION === 'portal') {
    document.title = I18n.get('AppTitlePortal')
  }
  else if (APPLICATION === 'learn') {
    document.title = I18n.get('AppTitleLearn')
  }
  else if (APPLICATION === 'incidents') {
    document.title = I18n.get('AppTitleIncidents')
  }
  else if (APPLICATION === 'contracts') {
    document.title = I18n.get('AppTitleContracts')
  }
  else if (APPLICATION === 'devices') {
    if (installation) {
      document.title = I18n.get('AppTitleDevicesWithInstallatiion').replace('###name###', installation.label)
    }
    else {
      document.title = I18n.get('AppTitleDevices')
    }
  }
  else {
    document.title = I18n.get('AppTitleDevices')
  }
}

export const checkUrlOrganizationAgainSessionOrganizationAndLogoutIfRequired = async (user: User, logout: Function) => {
  try{
    var returnHref = localStorage.getItem('onload.window.location.href')
    var returnPath = localStorage.getItem('onload.window.location.pathname')
  
    if (!returnPath || !returnHref) {
      return
    }
  
    // check if the expected organization (by url) matches the allready given one (session)
    let partsUrl = returnPath.split('/')
    if (partsUrl[1] && partsUrl[1].indexOf('org_') === 0) {
      let organizationName = partsUrl[1].substring(4)
      let requestedOrganizationIdentifier = await fetchOrganizationIdentifier(organizationName)
      let seesionBasedOrgaqnizationIdentifier = user.org_id
      if (seesionBasedOrgaqnizationIdentifier !== requestedOrganizationIdentifier) {
        logout({
          logoutParams: {
            returnTo: window.location.origin + '/redirect/?to=' + encodeURIComponent(returnHref)
          }
        })
      }
    }
  }
  catch(error){
    console.log(error)
  }
}

export function delay(milliSeconds: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, milliSeconds);
  });
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function langInit(props?: { stringLocal?: any, setStateGlobal?: React.Dispatch<React.SetStateAction<Partial<GlobalStateInterface>>>, updateDocumentTitle?: boolean }) {

  if (!props) { return }

  if (props.stringLocal) {
    I18n.putVocabularies(mergeDeepRight(stringGlobal, props.stringLocal));
  }
  else {
    I18n.putVocabularies(stringGlobal);
  }
  I18n.setLanguage(getLangByLocalStorage())

  if (props.setStateGlobal) {
    let lang = getLangByLocalStorage()
    let dataGlobal: Partial<GlobalStateInterface> = { language: lang ? lang : 'de' }
    try{// in case of storyook this fails
      props.setStateGlobal((prev) => ({ ...prev, ...dataGlobal }));
    }
    catch(err){
      //console.log(err)
    }
  }

  if (props.updateDocumentTitle) { setDocumentTitle() }
}

export function langChange(props?: { state: Partial<GlobalStateInterface>, updateDocumentTitle?: boolean }) {
  if (!props || !props.state.language) { return }
  I18n.setLanguage(props.state.language)
  moment.locale(props.state.language);
  document.documentElement.lang = props.state.language

  if (props.updateDocumentTitle) { setDocumentTitle() }

}
import { Apps } from '../interfaces/Apps'

export const UserHasPermissionsFor = (appIdentifier: Apps ,accessToken: string) => {
  try{
    let neededPermissionFound = false;
    let tokenObj = jwt_decode<JwtPayloadAuth0>(accessToken)

    appPermissions[appIdentifier]?.forEach(itemNeeded => {
      tokenObj.permissions?.forEach(itemGranted => {
        if (itemGranted === itemNeeded) { neededPermissionFound = true }
      })
    })

    return neededPermissionFound
  }
  catch{
    return false
  }
}

export const scrollToLocation = (hash:string|undefined) => {
  //const scrolledRef = React.useRef(false);
  if (hash ) {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      //scrolledRef.current = true;
    }
  }
};

export const getSilentTokenOrLogout = async (props:{getAccessTokenSilently:Function,logout:Function}) => {
  try {
    return await getAuth0Token(props.getAccessTokenSilently)
  } catch (error) {
    console.log('ERROR: getAccessTokenSilently',error)
    props.logout({ logoutParams: { returnTo: window.location.origin + '/logout' } })
  }
}

export const getSiPrefixedNumber = (number: number,addPerHour:boolean,zeroUnit?:null|string): string => {

  // https://gist.github.com/cho45/9968462?permalink_comment_id=3522694#gistcomment-3522694
  const SI_PREFIXES_CENTER_INDEX = 8;
  const siPrefixes: readonly string[] = [ 'y', 'z', 'a', 'f', 'p', 'n', 'μ', 'm', '', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y' ];

  if (number === 0){ 
    if(zeroUnit){
      return number.toString() + zeroUnit
    }
    else{
      return number.toString()
    }
  };

  const EXP_STEP_SIZE = 3;
  const base = Math.floor(Math.log10(Math.abs(number)));
  const siBase = (base < 0 ? Math.ceil : Math.floor)(base / EXP_STEP_SIZE);
  const prefix = siPrefixes[siBase + SI_PREFIXES_CENTER_INDEX];

  // return number as-is if no prefix is available
  if (siBase === 0) return `${number.toFixed(2).toString()} W`+(addPerHour?'/h':'')

  // We're left with a number which needs to be devided by the power of 10e[base]
  // This outcome is then rounded two decimals and parsed as float to make sure those
  // decimals only appear when they're actually requird (10.0 -> 10, 10.90 -> 19.9, 10.01 -> 10.01)
  const baseNumber = parseFloat((number / Math.pow(10, siBase * EXP_STEP_SIZE)).toFixed(2));

  return `${baseNumber} ${prefix}W`+(addPerHour?'/h':'');
};

export const distanceLowerOrEqualThan = (dateA:string,dateB:string,timePeriod:'seconds'|'minutes'|'hours'|'days'|'weeks'|'months'|'years',amount:number=1) => {
  //console.log(dateA,dateB,timePeriod,moment(dateA).diff(moment(dateB), timePeriod) <= amount)
  if(Math.abs(moment(dateA).diff(moment(dateB), timePeriod)) <= amount){
    return true
  }
  else{
    return false
  }
}

export const isErrResponseType = <T,>(toBeDetermined: errResponse | T): toBeDetermined is errResponse => {
  if ((toBeDetermined as errResponse).errorMsg) {
    return true;
  }
  return false;
};

export const useContainerDimensions = (myRef:any) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!myRef || !myRef.current)
      return

    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};

export const postHogVar = (postHogFlagName:string) => {

  try{
    const varFlag = useFeatureFlagEnabled(postHogFlagName)
  
    if(isTestEnv()){
      const testEnvFlagOn = useFeatureFlagEnabled(postHogFlagName+'_TEST_ON')
      const testEnvFlagOff = useFeatureFlagEnabled(postHogFlagName+'_TEST_OFF')
  
      if(testEnvFlagOn){
        return true
      }
      else if(testEnvFlagOff){
        return false
      }
      else{
        return varFlag
      }
    }
    else{
      return varFlag
    }
  }
  catch(error){
    console.log(error)
    return false
  }
  
}

export const setSearchHistory = (searchTerm:string) => {
  var cookieString = document.cookie;
  let searchOption: any = []
  let storedValues = localStorage.getItem('search')
  if (storedValues) {
    searchOption = JSON.parse(storedValues)
  }
  if (storedValues === null && searchTerm.trim().length > 0) {
    searchOption.push(searchTerm.trim())
    if(cookieString.includes("cookieconsent=True")){
      analyticsEvent({action:'Startpage',category:'Search used',label:'SearchValue: '+ searchTerm.trim()})
      localStorage.setItem('search', JSON.stringify(searchOption))
    }
  } else {
    searchOption.forEach((el: string, index: number) => {
      if (el === searchTerm.trim()) {
        searchOption.splice(index, 1)
      }
    })
    if (searchTerm.trim().length > 0) {
      searchOption.unshift(searchTerm.trim())
      if(cookieString.includes("cookieconsent=True")){
        analyticsEvent({action:'Startpage',category:'Search used',label:'SearchValue: '+ searchTerm.trim()})
        localStorage.setItem('search', JSON.stringify(searchOption))
      }
    }
  }
}
