import { strings as stringLocal} from './ContractDetailLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { USER_CHOICE_LANGUAGE, app_url_documents } from '../../utils/consts';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderDetail from '../../components/HeaderDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserHasPermissionsForDevices, getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, setDocumentTitle } from '../../utils/helpers';
import { useGlobalStateApp } from '../utils/globalStateProviderApp';
import { Contract } from '../../documents/interfaces/Contract';
import { getContractById } from '../../utils/helpersFetch';
import { getCategoriesForLibrary, getLibraryForEntity } from '../../documents/utils/helpersFetch';
import { Library } from '../../documents/interfaces/Library';
import TableInstallations from '../components/welcome/TableInstallations';
import InformationDetails, { getInformationsDetailsDataElement } from '../../components/InformationDetails';
const arrow = <svg className="h-4 fill-blueDark group-hover:fill-white  mb-2" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6043 0.791992L17.5481 2.84824L24.2272 9.54199H0.645996V12.4587H24.2272L17.5335 19.1524L19.6043 21.2087L29.8127 11.0003L19.6043 0.791992Z" /></svg>


const ContractDetail = () => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const { isAuthenticated ,logout} = useAuth0()
  const { getAccessTokenSilently } = useAuth0();
  const [dynHeadeContent, setDynHeadeContent] = useState<ReactNode>(<></>);
  const [dynHeadeContentMobile, setDynHeadeContentMobile] = useState<ReactNode>(<></>);
  const [currentContract, setCurrentContract] = useState<Contract|null>(null);
  const [currentContractLibrary, setCurrentContractLibrary] = useState<Library|null>(null);
  const [documentsCount, setDocumentsCount] = useState<number|null>(null);
  const [userHasDevicesPermissions, setUserHasDevicesPermissions] = useState<boolean>(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  let { contractId,organizationName } = useParams();

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let query =  new URLSearchParams(search);
      let langDefaulvalue = query.get("lang")
      let lang:string|null=null
      if(langDefaulvalue){
        lang = langDefaulvalue
        localStorage.setItem(USER_CHOICE_LANGUAGE, lang);
      }
      else{
        lang = getLangByLocalStorage()
      }
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
      setDocumentTitle()
    }

    const setPermissions = async () => {
      setUserHasDevicesPermissions(await UserHasPermissionsForDevices(await getAuth0Token(getAccessTokenSilently)))
    }
    setPermissions()
  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  useEffect(() => {
    //console.log(currentContract)
  }, [currentContract]);

  useEffect(() => {
    if(contractId){
      const initContract = async () => {
        if(contractId){
          let contract = await getContractById(contractId,await getAuth0Token(getAccessTokenSilently))
          if(contract){
            setCurrentContract(contract)
          }
          else{
            navigate('/404')
          }
        }
      }
      initContract()

      const loadDocuments = async (contractId:string) => {
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        const contractLibrary = await getLibraryForEntity(contractId,"Contract",accessToken);
        if(contractLibrary && contractLibrary.count > 0){
          
          contractLibrary.libraries.forEach(async (lib)=>{
            var count = 0 
            if(lib.title==='sf_contract_lib'){
              setCurrentContractLibrary(lib) // expecting only one
              const categories = await getCategoriesForLibrary(lib.id,accessToken)
              categories?.forEach((cat)=>{
                  count += cat.count
              })
            }
            setDocumentsCount(count)
          })
          
        }
      }
      loadDocuments(contractId)
    }
  }, [contractId]);

  useEffect(() => {
    setDynHeadeContent(<></>
    )
    setDynHeadeContentMobile(<></>)
  }, [state.language]);  
  
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const goBack = async () => {
    scrollTop()
    let urlParams = '?'
    +'page='+(stateApp.search?.page ? stateApp.search?.page : 1)
    +'&search='+(stateApp.search?.searchterm ? encodeURIComponent(stateApp.search.searchterm) : '')
    +'&sortBy='+(stateApp.search?.sortBy ? stateApp.search.sortBy : '')
    +'&back=true'

    navigate('/org_'+state.organizationName+'/welcome'+urlParams)
  }

  const getContractData = (contract:Contract | null) => {

    let arrData:JSX.Element[] = []

    if(contract){ 
      if(contract.status){ arrData.push(getInformationsDetailsDataElement('Status',contract.status,'contracts')) }
      if(contract.terminationDate){ arrData.push(getInformationsDetailsDataElement('TerminationDate',moment(contract.terminationDate).format('DD.MM.YYYY'),'contracts')) }
      if(contract.recordType){ arrData.push(getInformationsDetailsDataElement('Type',contract.recordType,'contracts')) }
      if(contract.contractNumber){ arrData.push(getInformationsDetailsDataElement('ContractNumber',contract.contractNumber,'contracts')) }
      if(contract.startDate){ arrData.push(getInformationsDetailsDataElement('Startdate',moment(contract.startDate).format('DD.MM.YYYY'),'contracts')) }
      if(contract.endDate){ arrData.push(getInformationsDetailsDataElement('Enddate',moment(contract.endDate).format('DD.MM.YYYY'),'contracts')) }
      if(contract.amount){ arrData.push(getInformationsDetailsDataElement('Costs',contract.amount.toLocaleString('de-DE') + ' €','contracts')) }
      if(contract.autoRenewal === false || contract.autoRenewal){ arrData.push(getInformationsDetailsDataElement('AutoRenewal',contract.autoRenewal.toString(),'contracts')) }
      if(contract.cancellationPeriod){ arrData.push(getInformationsDetailsDataElement('CancellationPeriod',contract.cancellationPeriod,'contracts')) }
      if(contract.billingCycle){ arrData.push(getInformationsDetailsDataElement('BillingCycle',contract.billingCycle,'contracts')) }
      if(contract.billingAddress){ arrData.push(getInformationsDetailsDataElement('BillingAddress',contract.billingAddress,'contracts')) }
      if(contract.accountName){ arrData.push(getInformationsDetailsDataElement('AccountName',contract.accountName,'contracts')) }
      }
    
    return arrData
  }

  const linkOutToDocuments = () => {
    if(!currentContractLibrary){return}
    
    document.location.href = (app_url_documents
    + '/' + organizationName 
    + '/library' 
    + '/' + currentContractLibrary?.id
    + '/?lang=' + state.language)
    
  }

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      {currentContract && <HeaderDetail 
        goBack={goBack}
        mode='Contract'
        contract={currentContract}
        dynContent={dynHeadeContent}
        dynContentMobile={dynHeadeContentMobile}
        />}
      <section className="Content">
        {documentsCount !== null && documentsCount > 0 && 
          <div className=" pt-16 mt-10 grid  s:grid-cols-2 m:grid-cols-2 l:grid-cols-4 xl:grid-cols-4 gap-4">
            <div onClick={()=>linkOutToDocuments()}  className=" bg-blueBaby h-36 l:h-192px flex items-end justify-between p-6 hover:bg-blueDark group cursor-pointer">
              <div className="flex items-end pr-4"><h2 className="w-min inline-block pr-3 group-hover:text-white">{I18n.get('Documents')}</h2> {arrow}</div>
              <h2 className="group-hover:text-white">{documentsCount}</h2>
            </div>
          </div>}
        {<InformationDetails data={getContractData(currentContract)} headline='Details' />}
        {userHasDevicesPermissions && 
        <div className="flex flex-col pt-16 pb-14">
          <h1 data-testid="headline_assets_in_contract" className="mb-12">{I18n.get('Assets in contract')}</h1>
          <TableInstallations />
        </div>}
      </section>
      <Footer />
    </div>
  );
};

export default ContractDetail;
