import React, { useEffect, useState } from 'react';
import { strings as stringLocal} from './WelcomeLocalization';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, langChange, langInit } from '../../utils/helpers';
import { iProduct } from '../interfaces/Products';
import ProductsSection from '../components/ProductsSection';
import { Breadcrumb } from '../utils/helpers';
import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { useAuth0 } from '@auth0/auth0-react';
import { fetchProducts } from '../utils/helpersFetch';
import { Spin } from 'antd';

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

const ProductOverview = () => {

  const { state, setState } = useGlobalState()
  const [ products, setProducts ] = useState<iProduct[]>([])
  const [ product, setProduct ] = useState<iProduct|undefined>(undefined)
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [ isLoadingMdFile, setIsLoadingMdFile] = React.useState<boolean>(true)
  
  const navigate = useNavigate();

  let { productId } = useParams();

  useEffect(() => {
    if(state.language){ langChange({state:state,updateDocumentTitle:true}) } else { langInit( {stringLocal:stringLocal,setStateGlobal:setState} ) }
  }, [state.language]);

  useEffect(() => {
    if(products.length <= 0){
      return
    }

    let foundProduct:iProduct|undefined = undefined
    foundProduct = products.filter((element)=>{if(element.pathId===productId){return true}})[0]
    if(foundProduct){
      setProduct(foundProduct)
    }
    else{
      navigate('/welcome')
    }

  }, [products]);

  async function loadProducts() {
    setIsLoadingMdFile(true)
    try {
      const accessToken = await getAuth0Token(getAccessTokenSilently);
      let pagedResult = await fetchProducts(accessToken)
      setProducts(pagedResult)
    } catch (error) {
      console.error('Error fetching News and Updates:', error.message);
      // Handle the error here if needed
    } finally {
      setIsLoadingMdFile(false)
    }
  }

  useEffect(() => {

    loadProducts()

  }, []);

  return (
    <div className={ getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      <Header />
      <section className="bg-blueLight Content">
        <div className="flex">
          <div className="w-full l:w-6/12 flex flex-col justify-between">
            <Breadcrumb>
              <div className=" cursor-pointer" onClick={()=>{ navigate('/')}}>{I18n.get('HelpCenter')}</div>
              <div className=" cursor-default ">{state.language? product?.title[state.language]:''}</div>
            </Breadcrumb>
            <div className=" text-lg m:text-3xl xl:text-5xl xxl:text-[50px] font-bold xl:leading-[60px] text-white mb-10 l:mb-32 xl:mb-36 xxl:mb-40 l:mr-16">{state.language && product?.headline[state.language]}</div>
          </div>
          <div className="hidden l:inline-block w-6/12 self-end  ">
            <img src={product?.image} className="" />
          </div>
        </div>
      </section>
      <section className="Content">
        <Spin spinning={isLoadingMdFile}>
          <div className="w-full pt-10">
            {product && product.sections.map((section,index) => <ProductsSection productSection={section} key={index} />) }
          </div>
        </Spin>
      </section>
      <Footer />
    </div>
  );
};

export default ProductOverview