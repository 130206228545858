import { I18n } from "@aws-amplify/core"
import { useEffect, useRef, useState } from "react"
import { useGlobalState } from "../../../utils/globalStateProvider"
import React from "react"
import { Spin } from "antd"
import { DateRange } from "../../../interfaces/DateRange"
import {  fetchRunsOverTime, setOrReportError } from "../../../utils/helpersFetch"
import { useAuth0 } from "@auth0/auth0-react"
import { DeviceInfo } from "../../../interfaces/DeviceInfo"
import TurnsChart from "./TurnsChart"
import { RunsOverTimeResponse } from "../../../interfaces/RunsOverTime"
import moment from "moment"
import { getAuth0Token } from "../../../utils/helpers"

const TurnsChartMemo = React.memo(TurnsChart)

const TurnsChartWrapper = (props: { device: DeviceInfo | null; dateRange:DateRange; speakingTimePeriod:string }) => {

  const { state, setState } = useGlobalState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [runsOverTime, setRunsOverTime] = useState<null | RunsOverTimeResponse>(null)
  const [countRunsPerFloor, setCountRunsPerFloor] = useState<number | null>(null)
  const { getAccessTokenSilently } = useAuth0()
  const controllerRef1 = useRef<AbortController | null>()

  const isDayOrHourView = (startData:string, endDate:string):'day'|'hour' => {
      let interval:'day'|'hour' = 'day'
      if(moment(endDate).diff(moment(startData), 'day') <= 7){
          interval = 'hour'
      }
      return interval
    }

  const fetchAllData = async (deviceId: string,accessToken:string, dateRange?: DateRange) => {

    let interval = isDayOrHourView(props.dateRange.startDate,props.dateRange.endDate)

    const queryString = dateRange ? `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&interval=`+interval : ''
  
    return Promise.all([
      fetchRunsOverTime(deviceId, queryString,accessToken,controllerRef1)
    ])
  }

  const fetchData = async () => {
    setIsLoading(true)

    if (props.device?._id) {
      const [runsOverTime] = await fetchAllData(props.device._id, await getAuth0Token(getAccessTokenSilently), props.dateRange)
      setRunsOverTime(null)

      if ( Array.isArray(runsOverTime) && runsOverTime.length > 0) { // is an result not an error
        // add for each day/hour a null based element if no values comes from the api

        let interval = isDayOrHourView(props.dateRange.startDate,props.dateRange.endDate)
        let dateToCheck = moment(props.dateRange.startDate)
        let formatToCheck = (interval=='day')?'YYYY-MM-DDT00:00:00.000':'YYYY-MM-DDTHH:00:00.000'
        while(dateToCheck.isBefore(props.dateRange.endDate)){
          if(runsOverTime.filter(elem => { 
            return (elem.timestamp == (dateToCheck.format(formatToCheck)+'Z'))
          }).length <= 0){
            runsOverTime.push({ runcount:null, timestamp: dateToCheck.format(formatToCheck)+'Z'})
          }
          dateToCheck = moment(dateToCheck).add(1, interval)
        }
      }

      setOrReportError(setRunsOverTime, runsOverTime)
      
      if ( Array.isArray(runsOverTime)) {
        let count = 0;
        runsOverTime.forEach(function (item) {
          if(item.runcount){
            count += item.runcount;
          }
        });
        setCountRunsPerFloor(count)
      }

    }

    setIsLoading(false);
  }

  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language])

  useEffect(() => {
    fetchData()
  }, [ props.dateRange,props.device?.deviceId])


  return (
    <>
      <section>
        <div className="Content bg-blueBaby text-blueDark text-base xxl:text-lg">
          <div className="flex flex-col m:flex-row pt-6 xxl:pt-12 pb-6 xxl:pb-12">
            <div className=" w-full m:w-1/2 m:pr-24">
              <h2 className="uppercase xl:text-3xl">{I18n.get('runsOverTime')}</h2>
              <div className=" pt-2 xxl:pt-4 xl:text-lg font-bold">{I18n.get(props.speakingTimePeriod)}</div>
              <div className="text-sm  pt-2 xl:text-lg  xxl:pt-4">{I18n.get('messageRunsOverTime')}</div>
            </div>
            <div className=" w-full m:w-1/2 flex pt-4 m:pt-0">
              <div className="">
                <h3 className="pr-8 m:pr-0 pb-2 m:pb-4 uppercase">{I18n.get('runsTotal')}</h3>
                <div><h1 className=" normal inline-block"><Spin size="default" spinning={isLoading} key="spin">{countRunsPerFloor?countRunsPerFloor:'-'}</Spin></h1></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Content">
        <div className=" pb-14 pt-6">
          <TurnsChartMemo response={runsOverTime} isLoading={isLoading} visualizePerHours={isDayOrHourView(props.dateRange.startDate,props.dateRange.endDate)} />
        </div>
      </section>
     </>
  );
};
export default TurnsChartWrapper;
