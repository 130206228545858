import { strings as stringLocal } from './TableInstallationsLocalization'
import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { mergeDeepRight, set } from 'ramda'
import moment from 'moment'
import React, { useRef } from 'react'
import { Badge, Checkbox, message, Pagination, Switch, Select, Spin } from 'antd'
import useWindowDimensions from '../../../utils/UseWindowDimensions'
import { useLocation, useNavigate } from 'react-router-dom'
import { GlobalStateInterface, useGlobalState } from "../../../utils/globalStateProvider"
import { useEffect } from 'react'
import { analyticsEvent, getAuth0Token, getInstallations, getLangByLocalStorage, getSilentTokenOrLogout, getStatus, getStatusMessage, instanceOfDeviceShadow, scrollTop, updateIncidentsByAssetIds, updateInstallationDeviceStatus } from '../../../utils/helpers'
import { io, Socket } from 'socket.io-client'
import { Installation } from '../../../interfaces/Installation'
import { FeedMessageDictionary } from '../../../interfaces/Lifefeed'
import { LevelVisualizationSmall } from './LevelVisualizationSmall'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { DeviceShadowDictionary } from '../../../interfaces/DeviceInfo'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../../utils/globalStateProviderApp'
import { Button } from '../../../components/atoms/Button'
import { ListItemStatus } from './ListItemStatus'
import SearchHistory from '../../../components/SearchHistory'
import { getIcon } from '../../../utils/icons'
import { DeviceFilter, deviceFilterData, DeviceFilterEnum } from '../../../interfaces/DeviceFilters'
import MobileSearchOverlay from './MobileSearchOverlay'
import OverlayWrapper from '../../../components/OverlayWrapper'
import MobileFilterOverlay from './MobileFilterOverlay'

I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
const lang = getLangByLocalStorage()
I18n.setLanguage(lang)

moment.locale(lang ? lang : 'de');

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface PrevRef {
  searchTerm: string | undefined
  sortBy: string | undefined
  filterBy: DeviceFilter | undefined
}

const TableInstallations = (props: { isAddMode: boolean }) => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();

  const query = useQuery();

  const { height, width } = useWindowDimensions();
  const [maxNumberOfCompare, setMaxNumberOfCompare] = React.useState<number>(10);
  const [pageUrlParam, setPageUrlParam] = React.useState<number | undefined>()
  const [searchValueUrlParam, setSearchValueUrlParam] = React.useState<string>('')
  const [sortByUrlParam, setSortByUrlParam] = React.useState<string | undefined>()
  const [filterByUrlParam, setFilterByUrlParam] = React.useState<DeviceFilter>(DeviceFilterEnum.All)
  const [totalNumberInstalations, setTotalNumberInstalations] = React.useState<number>(0)
  const [sortedInfo, setSortedInfo] = React.useState({ columnKey: '', order: '' })
  const [storedFeedMessages, setStoredFeedMessages] = React.useState<FeedMessageDictionary>({});
  const [itemList, setItemList] = React.useState<JSX.Element | null>(null)
  const prevRef = useRef<PrevRef>({ searchTerm: undefined, sortBy: undefined, filterBy: undefined });
  const topTableElementRef = useRef<null | HTMLTableElement>(null);
  const [devicesShadow, setDevicesShadow] = React.useState<DeviceShadowDictionary>({});
  const [isCompareMode, setIsCompareMode] = React.useState<boolean>(false)
  const [compareSelected, setCompareSelected] = React.useState<Installation[]>([])
  const [isLoadingDeviceState, setIsLoadingDeviceState] = React.useState<boolean>(false)
  const [isLoadingAssets, setIsLoadingAssets] = React.useState<boolean>(false)
  const [isLoadingIncidentsState, setIsLoadingIncidentsState] = React.useState<boolean>(true)
  const [socketIo, setSocketIo] = React.useState<Socket | null>(null)
  const [showSearchDialog, setShowSearchDialog] = React.useState<boolean>(false)
  const [showFilterDialog, setShowFilterDialog] = React.useState<boolean>(false)
  const controllerRef = useRef<AbortController | null>();

  const { getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [state.language]);

  useEffect(() => {
    if (width < 768) {
      if (compareSelected.length > maxNumberOfCompare) {
        const localCompareSelected = JSON.parse(JSON.stringify(compareSelected))
        setCompareSelected(localCompareSelected.splice(0, 4))
      }
      setMaxNumberOfCompare(4)
    }
    else {
      setMaxNumberOfCompare(10)
    }
  }, [width]);

  useEffect(() => {
    setPageUrlParam(1)
  }, [filterByUrlParam]);
  
  useEffect(() => {
    if (socketIo) {
      socketIo.connect();
      socketIo.on('live-feed-event', (data: any) => {
        if (instanceOfDeviceShadow(data)) {
          devicesShadow[data.state.reported.deviceId] = data
          setDevicesShadow(JSON.parse(JSON.stringify(devicesShadow)))
        }
        else {
          if (data && !(data.deviceId in storedFeedMessages)) {
            storedFeedMessages[data.deviceId] = []
          }
          storedFeedMessages[data.deviceId] = [data, ...storedFeedMessages[data.deviceId]].slice(0, 10)
          setStoredFeedMessages(JSON.parse(JSON.stringify(storedFeedMessages)))
        }
      });
    }
  }, [socketIo]);

  useEffect(() => {
    const doSocket = async () => {
      const accessToken = await getAuth0Token(getAccessTokenSilently)
      let socketinstance = io(process.env.REACT_APP_API_URL ?? 'http://localhost:8080', {
        auth: {
          token: `${accessToken}`,
        },
        transports: ['websocket'],
      });
      setSocketIo(socketinstance)
    }
    doSocket()

    if (props.isAddMode) {
      setIsCompareMode(true)
      if (stateApp.compareSelected) {
        setCompareSelected(stateApp.compareSelected)
      }
    }

    const filterByParam = query.get("filterBy") as DeviceFilterEnum
    if (filterByParam !== undefined && filterByParam !== null && Object.values(DeviceFilterEnum).includes(filterByParam)) {
      setFilterByUrlParam(filterByParam)
    } else {
      setFilterByUrlParam(DeviceFilterEnum.All)
    }

    return () => {
      // no disconnect ... livemessages are collected globaly and are getting used at detailpage
      //socketIo?.off('live-feed-event')
      //socketIo?.disconnect();
    }
  }, [])

  useEffect(() => {
    const dataGlobal: Partial<GlobalStateInterfaceApp> = {
      liveData: JSON.parse(JSON.stringify(storedFeedMessages))
    }
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))
  }, [storedFeedMessages]);

  useEffect(() => {
    const dataGlobal: Partial<GlobalStateInterfaceApp> = {
      compareSelected: compareSelected
    }
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))

  }, [compareSelected]);

  function positionOfInstallation(listOfInstallations: Installation[], installation: Installation) {
    var i;
    for (i = 0; i < listOfInstallations.length; i++) {
      if (listOfInstallations[i]._id === installation._id) {
        return i;
      }
    }

    return -1;
  }

  const onRowClicked = (installation: Installation) => {
    if (isCompareMode) {
      const localCompareSelected = JSON.parse(JSON.stringify(compareSelected))
      const positonOfElement = positionOfInstallation(localCompareSelected, installation)
      if (positonOfElement > -1) {
        localCompareSelected.splice(positonOfElement, 1);
      }
      else if (localCompareSelected.length < maxNumberOfCompare) {
        localCompareSelected.push(installation)
      }
      else if (localCompareSelected.length >= maxNumberOfCompare - 1) {
        message.error(I18n.get('tooManyCompareSelectedPre') + ' ' + maxNumberOfCompare + ' ' + I18n.get('tooManyCompareSelectedPost'))
      }
      setCompareSelected(localCompareSelected)
    }
    else {
      scrollTop()
      navigate('/org_' + state.organizationName + '/installations/' + installation._id)
    }
  }

  const sortChange = (sorter: { columnKey: string, order: string }) => {

    if (sorter.order === '') {
      setSortedInfo({ columnKey: '', order: '' })
    }
    else {
      setSortedInfo(sorter)
    }
    let sortBy = ''
    if (sorter.columnKey && sorter.order) {
      sortBy = sorter.columnKey + ':' + (sorter.order === 'asc' ? 'asc' : 'desc')
    }
    setPageUrlParam(1)
    setSortByUrlParam(sortBy)
  };

  useEffect(() => {
    if (!searchValueUrlParam && !pageUrlParam && !sortByUrlParam && !filterByUrlParam) {
      if (stateApp.search) {
        // 2 cases can happen / Back button pressd or logo in header")
        // case hits if components gets loaded > like coming from installation detail page
        // 2 cases can happen / Back button pressd or logo in header 
        if (query.get('back') === 'true') {
          const pageParam = query.get("page") ? query.get("page") : '1'
          if (pageParam !== null) {
            setPageUrlParam(parseInt(pageParam))
          }
          else {
            setPageUrlParam(1)
          }

          const searchParam = query.get("search")
          if (searchParam !== undefined && searchParam !== null) {
            setSearchValueUrlParam(searchParam)
          }
          else {
            setSearchValueUrlParam('')
          }

          const sortByParam = query.get("sortBy")
          if (sortByParam !== undefined && sortByParam !== null) {
            const arrSplit = sortByParam.split(':')
            if (arrSplit.length === 2) {
              setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
              setSortByUrlParam(sortByParam)
            }
          }
          else {
            setSortByUrlParam('')
          }

          const filterByParam = query.get("filterBy") as DeviceFilterEnum
          if (filterByParam !== undefined && filterByParam !== null && Object.values(DeviceFilterEnum).includes(filterByParam)) {
            setFilterByUrlParam(filterByParam)
          } else {
            setFilterByUrlParam(DeviceFilterEnum.All)
          }

          return
        }

        // logo clicked case
        setPageUrlParam(1)
        setSearchValueUrlParam('')
        setSortByUrlParam('')
        setFilterByUrlParam(DeviceFilterEnum.All)
      }
      else {
        // case hits if url gets opened directly by browser
        // init params from saved state
        const pageParam = query.get("page") ? query.get("page") : '1'
        if (pageParam !== null) {
          setPageUrlParam(parseInt(pageParam))
        }
        else {
          setPageUrlParam(1)
        }

        const searchParam = query.get("search")
        if (searchParam !== undefined && searchParam !== null) {
          setSearchValueUrlParam(searchParam)
        }
        else {
          setSearchValueUrlParam('')
        }

        const sortByParam = query.get("sortBy")
        if (sortByParam !== undefined && sortByParam !== null) {
          const arrSplit = sortByParam.split(':')
          if (arrSplit.length === 2) {
            setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
            setSortByUrlParam(sortByParam)
          }
        }
        else {
          setSortByUrlParam('')
        }

        const filterByParam = query.get("filterBy") as DeviceFilterEnum
        if (filterByParam !== undefined && filterByParam !== null && Object.values(DeviceFilterEnum).includes(filterByParam)) {
          setFilterByUrlParam(filterByParam)
        } else {
          setFilterByUrlParam(DeviceFilterEnum.All)
        }
      }
      return
    }

    const urlParams = '?'
      + 'page=' + (pageUrlParam ? pageUrlParam : 1)
      + '&search=' + (searchValueUrlParam ? encodeURIComponent(searchValueUrlParam) : '')
      + '&sortBy=' + (sortByUrlParam ? sortByUrlParam : '')
      + '&filterBy=' + (filterByUrlParam ? filterByUrlParam : '')

    const setBookableUrlTo = async () => {
      let goTo = '/org_' + state.organizationName + '/welcome' + urlParams
      if (props.isAddMode) {
        goTo = '/org_' + state.organizationName + '/compare-assets' + urlParams
      }
      if (window.location.href !== goTo) {
        navigate(goTo)
      }
    }
    if (state.organizationName) {
      setBookableUrlTo()
    }

    // storing url for having it for the backButton at the installation detail list
    const dataGlobal: Partial<GlobalStateInterfaceApp> = {
      search: {
        page: (pageUrlParam ? pageUrlParam : 1),
        searchterm: (searchValueUrlParam ? searchValueUrlParam : ''),
        sortBy: (sortByUrlParam ? sortByUrlParam : ''),
        filterBy: (filterByUrlParam ? filterByUrlParam : ''),
        parametersUpdatedByUrl: true
      },
    }
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))

  }, [pageUrlParam, searchValueUrlParam, sortByUrlParam, filterByUrlParam, state.organizationName]);

  useEffect(() => {
    if (stateApp.installations) {
      setIsLoadingDeviceState(true)
      setIsLoadingIncidentsState(true)
      const updateInstallationDeviceStatusAsync = async () => {
        const accessToken = await getSilentTokenOrLogout({ getAccessTokenSilently, logout })
        updateInstallationDeviceStatus(setStateApp, stateApp, accessToken, setIsLoadingDeviceState)
      }
      updateInstallationDeviceStatusAsync()
      stateApp.installations.forEach((installation: Installation) => {
        socketIo?.emit('get-live-feed', installation.iotBoxes[0]); // init livefeed
      })

      // update incidents data for installations
      const updateInstallationIncidentStatusAsync = async () => {
        const accessToken = await getSilentTokenOrLogout({ getAccessTokenSilently, logout })
        try {
          updateIncidentsByAssetIds(setStateApp, stateApp, stateApp.installations, accessToken, setIsLoadingIncidentsState)
        }
        catch (error) {
          console.log(error)
        }
      }
      updateInstallationIncidentStatusAsync()
    }
  }, [stateApp.installations]);

  useEffect(() => {
    BuildItemList()
  }, [stateApp.devices, state.language, stateApp.incidents]);

  useEffect(() => {
    BuildItemList()
  }, [isLoadingDeviceState]);


  useEffect(() => {
    // on reload getInstallations gets called with uninitialised parameters, so looking to parametersUpdatedByUrl
    if (stateApp.search?.parametersUpdatedByUrl) {

      let forceUpdateCoordinates = false
      if (prevRef.current.searchTerm === undefined || prevRef.current.searchTerm !== stateApp.search?.searchterm || prevRef.current.sortBy !== stateApp.search?.sortBy || prevRef.current.filterBy !== stateApp.search?.filterBy) {
        forceUpdateCoordinates = true
      }
      loadInstallations(forceUpdateCoordinates)
    }
    prevRef.current.searchTerm = stateApp.search?.searchterm
    prevRef.current.sortBy = stateApp.search?.sortBy
    prevRef.current.filterBy = stateApp.search?.filterBy
  }, [stateApp.search?.page, stateApp.search?.searchterm, stateApp.search?.sortBy, stateApp.search?.filterBy]);

  async function loadInstallations(forceUpdateCoordinates: boolean) {
    const accessToken = await getAuth0Token(getAccessTokenSilently)
    setIsLoadingAssets(true)
    try {
      await getInstallations(setStateApp, stateApp.search?.page, 10, stateApp.search?.searchterm, stateApp.search?.sortBy, stateApp.search?.filterBy, forceUpdateCoordinates, accessToken, controllerRef)
      setIsLoadingAssets(false)
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error
      }
    }
  }

  const getSortOrder = (columnKey: string): string => {
    if (sortedInfo.columnKey === columnKey) {
      if (sortedInfo.order === 'asc') {
        return 'desc'
      }
      else if (sortedInfo.order === 'desc') {
        return ''
      }
      else {
        return 'asc'
      }
    }
    else {
      return 'asc'
    }
  }

  const areAllVisibleSelected = () => {
    let retrunValue = true
    stateApp.installations?.forEach((itemInstallation, index) => {
      if (positionOfInstallation(compareSelected, itemInstallation) < 0) {
        retrunValue = false
      }
    })
    return retrunValue
  }

  const getLabel = (installation: Installation) => {
    if (installation.customLabel && installation.customLabel.trim().length > 0
      && installation.customLabel?.trim() !== installation.label) {
      return installation.customLabel + '/' + installation.label
    }
    else {
      return installation.label
    }
  }

  const BuildItemList = () => {
    const tableRowItems: any = []
    stateApp.installations?.forEach((itemInstallation, index) => {
      const rowClassName = index % 2 === 0 ? ' h-16 m:h-24  hover:bg-blueBaby cursor-pointer' : 'bg-greyLighter h-16 m:h-24 hover:bg-blueBaby cursor-pointer'

      let deviceName = ''
      if (stateApp.devices) {
        const tempName = stateApp.devices[itemInstallation.iotBoxes[0]]?.deviceId
        deviceName = tempName !== undefined ? tempName : ''
      }

      const serialNumberClassname = sortedInfo.columnKey === 'label' ? ' sortedColumn   w-3/12 ' : '  w-3/12 '
      const addressClassname = sortedInfo.columnKey === 'address' ? ' sortedColumn ellipsis  w-6/12  ' : ' ellipsis  w-6/12 '

      const deviceInfo = stateApp.devices ? stateApp.devices[itemInstallation.iotBoxes[0]] : null

      const installationHasDevice = itemInstallation.iotBoxes.length > 0

      let incidentsData = (stateApp.incidents && stateApp.incidents[itemInstallation._id]) ? stateApp.incidents[itemInstallation._id] : undefined

      tableRowItems.push(
        <tr key={itemInstallation._id} className={rowClassName} onClick={() => onRowClicked(itemInstallation)}>
          {isCompareMode && <td className="text-center pl-4 pr-2 m:pl-4 m:pr-4 xl:pl-0 xl:pr-0"><Checkbox checked={positionOfInstallation(compareSelected, itemInstallation) >= 0} /></td>}
          <ListItemStatus
            deviceInfo={deviceInfo}
            installationHasDevice={installationHasDevice}
            isLoadingDeviceState={isLoadingDeviceState}
            liveData={(stateApp.liveData && stateApp.liveData[deviceName]) ? stateApp.liveData[deviceName] : undefined}
            incidentsData={incidentsData}
          />
          <td className={serialNumberClassname}>
            <span className="m:hidden flex flex-row items-center mr-3">
              <span className=" pr-2 pl-2 ">{getStatus(incidentsData, deviceInfo, ' w-5 m:w-7 ', isLoadingDeviceState, undefined, installationHasDevice)}</span>
              <span className="w-full">
                {incidentsData &&
                  <div className="mt-1 ml-1">
                    <Badge count={incidentsData.length} size='default' overflowCount={9}>
                      {getIcon({ type: 'Incidents', ownClassName: 'group-hover/item:fill-blueBaby fill-blueDark w-6 h-6' })}
                    </Badge>
                  </div>}
              </span>
            </span>
            <span className="hidden m:block m:ml-8">{getLabel(itemInstallation)}</span>
          </td>
          <td className={addressClassname}>
            <span className="m:hidden mr-3 -mt-3 " >{getLabel(itemInstallation)}<br />{itemInstallation.building ? <>{itemInstallation.building.street} {itemInstallation.building.houseNumber} {itemInstallation.building.city}</> : ''}</span>
            <span className="hidden  m:ml-4 m:block m:-mt-6 l:-mt-7 xxl:-mt-8">{itemInstallation.building ? itemInstallation.building.label : ''}</span>
          </td>
          <td className="w-3/12">
            <div className="flex items-center justify-between">
              <div>
                <LevelVisualizationSmall
                  devicesShadow={devicesShadow}
                  deviceName={deviceName}
                  state={stateApp}
                  liveData={(stateApp.liveData && stateApp.liveData[deviceName]) ? stateApp.liveData[deviceName] : undefined}
                />
              </div>
              {!isCompareMode && <div className="mr-1 m:hidden">
                <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg>
              </div>}
            </div>
          </td>
          {!isCompareMode && <td className=" hidden m:table-cell"><svg className="mr-14" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></td>}
        </tr>)
    })

    if (tableRowItems.length <= 0) {
      tableRowItems.push(<tr key={2} className={' h-32 m:h-48 '} ><td colSpan={5} className=" text-center text-greyLight">{I18n.get('noData')}</td></tr>)
    }

    setItemList(<table className="table-installations text-sm m:text-lbase l:text-l xxl:text-lg select-none" align="left" ref={topTableElementRef} >
      <thead>
        <tr className={props.isAddMode ? " h-12 m:align-bottom " : " h-16 m:h-24 m:align-bottom "}>
          {isCompareMode && <th className="text-center pl-2 m:p-4 "><Checkbox onChange={onSelectAll} checked={areAllVisibleSelected()} /></th>}
          <th className="hidden m:table-cell m:p-4 text-left ">Status</th>

          <th className="hidden m:table-cell w-4/12 m:p-4 cursor-pointer text-left" onClick={() => sortChange({ columnKey: 'label', order: getSortOrder('label') })}><div className="flex items-center "><div className="mr-4 pl-4 -ml-2 m:ml-0">{I18n.get('elevator')}</div>{getSortVisualisation('label')}</div></th>
          <th className="m:hidden w-4/12 m:p-4 cursor-pointer text-left"><div className="flex items-center "><div className="mr-4 pl-4 -ml-2 m:ml-0">Status</div></div></th>

          <th className="hidden m:table-cell  w-5/12 m:p-4 cursor-pointer text-left" onClick={() => sortChange({ columnKey: 'address', order: getSortOrder('address') })}><div className="flex items-center "><div className="mr-4">{I18n.get('address')}</div> {getSortVisualisation('address')}</div></th>
          <th className="m:hidden  w-5/12 m:p-4 cursor-pointer text-left">
            <div className="flex items-left flex-col ">
              <div onClick={() => sortChange({ columnKey: 'address', order: getSortOrder('address') })} className="flex items-center"><div className="mr-4">{I18n.get('address')}</div> {getSortVisualisation('address')}</div>
              <div onClick={() => sortChange({ columnKey: 'label', order: getSortOrder('label') })} className="flex items-center"><div className="mr-4">/ {I18n.get('elevator')}</div> {getSortVisualisation('label')}</div>
            </div>
          </th>

          <th className=" w-3/12 m:p-4 text-left">Live</th>
          {!isCompareMode && <th className=" hidden m:table-cell"></th>}
        </tr>
      </thead><tbody>{tableRowItems}</tbody></table>)
  }

  const onSelectAll = (e: CheckboxChangeEvent) => {

    var localCompareSelected = JSON.parse(JSON.stringify(compareSelected))
    let throwMaximumSelectedMessage = false
    stateApp.installations?.forEach((installation, index) => {

      if (e.target.checked) {
        // add checkboxes in view  

        //const positonOfElement = localCompareSelected.indexOf(installation._id)
        const positonOfElement = positionOfInstallation(localCompareSelected, installation)
        if (positonOfElement < 0 && localCompareSelected.length < maxNumberOfCompare) {

          localCompareSelected.push(installation)
        }
        else if (localCompareSelected.length >= maxNumberOfCompare) {
          throwMaximumSelectedMessage = true
        }
      }
      else {
        // remove checkboxes in view
        //const positonOfElement = localCompareSelected.indexOf(installation._id)
        const positonOfElement = positionOfInstallation(localCompareSelected, installation)
        if (positonOfElement > -1) {
          localCompareSelected.splice(positonOfElement, 1)
        }
      }
    })
    if (throwMaximumSelectedMessage) {
      message.error(I18n.get('tooManyCompareSelectedPre') + ' ' + maxNumberOfCompare + ' ' + I18n.get('tooManyCompareSelectedPost'))
    }
    setCompareSelected(localCompareSelected)
  }

  useEffect(() => {
    BuildItemList()
    setTotalNumberInstalations(stateApp.installationCount ? stateApp.installationCount : 0)
  }, [stateApp.installationCount, stateApp.installations, stateApp.liveData, devicesShadow, compareSelected, isCompareMode]);

  const getSortVisualisation = (columnKey: string) => {

    if (columnKey === sortedInfo.columnKey) {
      if (sortedInfo.order === 'asc') {
        return <CaretUpOutlined style={{ 'color': 'white' }} />
      }
      else {
        return <CaretDownOutlined style={{ 'color': 'white' }} />
      }
    }
    return <CaretUpOutlined style={{ 'color': 'grey' }} />
  }

  const handleSearchChange = (searchTerm: string) => {
    if (searchValueUrlParam !== searchTerm) {
      setPageUrlParam(1)
    }
    setSearchValueUrlParam(searchTerm)
  }

  const onCompareChange = (checked: boolean) => {
    if (checked) {
      analyticsEvent({ action: 'Startpage', category: 'Enabling comparemode' })
    }
    else {
      analyticsEvent({ action: 'Startpage', category: 'Disabling comparemode' })
    }
    setIsCompareMode(!isCompareMode)
  };

  const disconnectSocket = (installations: Installation[] | undefined): boolean => {
    installations?.forEach((itemInstallation: Installation) => {
      //socket.emit(‘STOP-live-feed’, deviceName )
      //socket.disconnect('something');
    })
    return true
  }

  const navogateToComparePage = () => {

    if (!isCompareMode || compareSelected.length < 2) {
      return void (0)
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigate('/org_' + state.organizationName + '/compare-assets/')
  }

  return (
    <div className="idTableInstallations flex flex-col">
      {(!props.isAddMode) &&
        <>
          <div className=" mt-12  m:mt-24 flex justify-between flex-col m:flex-row">
            <h1>{I18n.get('overview')}</h1>
            <div className={(stateApp.maxInstallationCount && stateApp.maxInstallationCount > 1) ? "text-lg flex items-center justify-between" : "hidden"}>
              <div className="mr-3 text-sm m:text-lg">{I18n.get('compareElevators')}</div>
              <div className="flex items-center">
                <div className="mr-3"><Switch onChange={onCompareChange} /></div>
                <Button
                  disabled={(isCompareMode && compareSelected.length > 1) ? false : true}
                  theme='light'
                  size='medium'
                  ownStyle=''
                  onClick={() => { navogateToComparePage() }}
                  label={((isCompareMode) ? compareSelected.length : '') + ' ' + I18n.get('compare')} />
              </div>
            </div>
          </div>

          {showSearchDialog && <OverlayWrapper title={I18n.get('Search')} closerFunction={()=>setShowSearchDialog(false)} children={<MobileSearchOverlay closerFunction={()=>setShowSearchDialog(false)} handleSearchChange={handleSearchChange}  />} /> }
          {showFilterDialog && <OverlayWrapper title={I18n.get('Filter')} closerFunction={()=>setShowFilterDialog(false)} children={<MobileFilterOverlay closerFunction={()=>setShowFilterDialog(false)} handleChange={setFilterByUrlParam} filterByUrlParam={filterByUrlParam} filterData={deviceFilterData} />} /> }

          <div className='flex flex-col m:flex-row md:items-center justify-between pt-[50px] m:pt-10 pb-[26px] m:pb-10 gap-4 m:gap-10'>          
              <div className="w-full flex m:hidden  ">
                <span className=" mr-[78px]" onClick={()=>setShowSearchDialog(true)}>
                  <Badge count={(searchValueUrlParam?.trim().length>0)?1:0} size='default' overflowCount={9}>
                    {getIcon({type:'Search',ownClassName:'fill-blueDark h-6 cursor-pointer'})}
                  </Badge>
                </span>
                <span onClick={()=>setShowFilterDialog(true)}>
                  <Badge count={(filterByUrlParam !== DeviceFilterEnum.All)?1:0} size='default' overflowCount={9}>
                    {getIcon({type:'Filter',ownClassName:'fill-blueDark h-6 cursor-pointer'})}
                  </Badge>
                </span>
              </div>
              <div className="w-full hidden m:block m:w-96 ">
                <SearchHistory setSearchValue={handleSearchChange} />
              </div>

              <div className='hidden m:flex mt-6 m:mt-0 gap-2 flex-col m:flex-row m:items-center m:justify-center'>
                <p className='text-nowrap'>{I18n.get('filterBy')}</p>
                <div className='w-full m:w-96'>
                  <Select
                    value={filterByUrlParam ? filterByUrlParam : DeviceFilterEnum.All}
                    onChange={(value) => { setFilterByUrlParam(value) }}
                    options={deviceFilterData.map((item) => { return { label: I18n.get(item), value: item } })}
                    size='large'
                    style={{
                      width: '100%',
                      color: '#102A68'
                    }}
                  />
                </div>
              </div>
          </div>
          <Spin spinning={ isLoadingDeviceState || isLoadingAssets }>
            <div className="flex flex-col">
            {itemList}
            <div className=" h-24 bg-blueBaby flex justify-center items-center">
              <Pagination current={stateApp.search?.page ? stateApp.search?.page : 1} pageSize={10} showSizeChanger={false} total={totalNumberInstalations} onChange={(page, pageSize) => {
                if (disconnectSocket(stateApp.installations)) {
                  setPageUrlParam(page);
                  window.scrollTo({
                    top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
                    behavior: 'smooth',
                  });
                }
              }} />
            </div>
            </div>
          </Spin>
        </>
      }
      {(props.isAddMode) &&
        <>
          <div className=" flex flex-col m:flex-row m:justify-between m:pl-10 m:pr-12 m:pt-8 m:pb-7">
            <div className="pt-5 m:pt-0">{I18n.get('chooseToCompare')} {compareSelected.length}/{maxNumberOfCompare}</div>
            <div className=" w-full pt-5 pb-5 m:pt-0 m:pb-0 m:w-96 flex  items-end justify-items-end">
              <SearchHistory setSearchValue={handleSearchChange} />
            </div>
          </div>
          {itemList}
          <div className=" text-center m:text-left m:pl-10 mt-10">
            <Pagination current={stateApp.search?.page ? stateApp.search?.page : 1} pageSize={10} showSizeChanger={false} total={totalNumberInstalations} onChange={(page, pageSize) => {
              if (disconnectSocket(stateApp.installations)) {
                setPageUrlParam(page);
                window.scrollTo({
                  top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
                  behavior: 'smooth',
                });
              }
            }} />
          </div>
        </>
      }

    </div>
  );
};

export default TableInstallations;
