import React, { useEffect, useRef, useState } from 'react';
import { strings as stringLocal} from './WelcomeLocalization';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { IsStorybook, getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, langChange, langInit } from '../../utils/helpers';
import { iNewsOrUpdataElement } from '../interfaces/NewsOrUpdate';
import NewsUpdateItem from '../components/NewsUpdateItem';
import { Breadcrumb } from '../utils/helpers';
import { I18n } from '@aws-amplify/core';
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { useAuth0 } from '@auth0/auth0-react';
import { fetchNewsAndUpdates } from '../utils/helpersFetch'
import { Pagination, Spin } from 'antd';
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../utils/globalStateProviderApp';

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const NewsUpdatesAll = () => {

  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()

  const [ allNewsUpdates, setAllNewsUpdates ] = useState<iNewsOrUpdataElement[]>([])
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [pageUrlParam, setPageUrlParam] = React.useState<number|undefined>()
  const pageSizeUrlParam = 10
  const [totalNumberNewsAndUpdates, setTotalNumberNewsAndUpdates] = React.useState<number | undefined>()
  const query = useQuery();
  const topTableElementRef = useRef<null | HTMLTableElement>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const navigate = useNavigate();

  useEffect(() => {
    if(state.language){ langChange({state:state,updateDocumentTitle:true}) } else { langInit( {stringLocal:stringLocal,setStateGlobal:setState} ) }
  }, [state.language])

  useEffect(() => {
    if (!pageUrlParam) {

      // case hits if url gets opened directly by browser
      // init params from saved state
      const pageParam = query.get("page") ? query.get("page") : '1'
      if (pageParam !== null) {
        setPageUrlParam(parseInt(pageParam))
      }
      else {
        setPageUrlParam(1)
      }
      
      return
    }

    const urlParams = '?'
      + 'page=' + (pageUrlParam ? pageUrlParam : 1)

    const setBookableUrlTo = async () => {
      let goTo = '/news-and-updates/' + urlParams
      if (window.location.href !== goTo) {
        navigate(goTo)
      }
    }
  
    if(!IsStorybook()){
      setBookableUrlTo()
    }

    // storing url for having it for the backButton at the installation detail list
    const dataGlobal: Partial<GlobalStateInterfaceApp> = {
      search: {
        page: (pageUrlParam ? pageUrlParam : 1),
        parametersUpdatedByUrl: true
      }
    }
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))

    loadNewsAndUpdates()

}, [pageUrlParam]);

async function loadNewsAndUpdates() {
  setIsLoading(true)
  try {    
    const accessToken = await getAuth0Token(getAccessTokenSilently);
    let page = pageUrlParam || 1
    let pagedResult = await fetchNewsAndUpdates((page-1)*pageSizeUrlParam, pageSizeUrlParam, accessToken)
    setAllNewsUpdates(pagedResult.result)
    setTotalNumberNewsAndUpdates(pagedResult.totalCount)

  } catch (error) {
    console.error('Error fetching News and Updates:', error.message);
    // Handle the error here if needed
  } finally {
    setIsLoading(false)
  }
}

  return (
    <div className={ getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      <Header />
      <section className="bg-blueLight welcome">
        <Breadcrumb >
          <div className=" cursor-pointer" onClick={()=>{ navigate('/')}}>{I18n.get('HelpCenter')}</div>
          <div className=" cursor-default" >{I18n.get('NewsAndUpdates')}</div>
        </Breadcrumb>
      </section>
      <section className="Content">
        <div className="w-full pt-10 pb-20 xl:pb-36 idTableInstallations"  ref={topTableElementRef}>
          <Spin spinning={isLoading}>
          { allNewsUpdates.map((element,index) => { return <NewsUpdateItem newsUpdateITem={element} key={index} /> })}
          </Spin>
          <div className=" h-12 bg-blueBaby flex justify-center items-center">
          <Pagination current={stateApp.search?.page ? stateApp.search?.page : 1} pageSize={pageSizeUrlParam} showSizeChanger={false} total={totalNumberNewsAndUpdates} onChange={(page, pageSize) => {
              setPageUrlParam(page);
              window.scrollTo({
                top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
                behavior: 'smooth',
              });
            }} />
          </div>
        </div>

      </section>
      <Footer />
    </div>
  );
};

export default NewsUpdatesAll