import { strings as stringLocal} from './LevelVisualizationLocalization'
import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { useEffect, useState } from 'react';
import arrowIotDown from '../../../assets/images/icons/arrow_down_iot.svg'
import arrowIotUp from '../../../assets/images/icons/arrow_up_iot.svg'
import { DeviceShadow } from '../../../interfaces/DeviceInfo';
import { Spin } from 'antd';
import React from 'react';
import { getLangByLocalStorage } from '../../../utils/helpers';

I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

function LevelVisualization(props:{liveMessages:any,device:any,devicesShadow:DeviceShadow|undefined}) {

    enum DirectionStatus {
        UP = "up",
        DOWN = "down",
        UNSET = "unset",
      }

    const [isLoading, setIsloading] = useState<boolean>(true)
    const [currentFloor, setCurrentFloor] = useState<string>('')
    const [goalFloors , setGoalFloors] = useState<any[]>([])
    const [direction , setDirection] = useState<DirectionStatus>(DirectionStatus.UNSET)

    useEffect(() => {
        if(props.liveMessages?.length > 0){
            // detecting direction
            if(props.liveMessages[0].body?.name === "deviceDirectionChanged" ){
                setIsloading(false)
                if(props.liveMessages[0].body.attributes.direction === "directionUp"){
                    setDirection(DirectionStatus.UP)
                }
                else if(props.liveMessages[0].body.attributes.direction === "directionDown"){
                    setDirection(DirectionStatus.DOWN)
                }
                else{
                    setDirection(DirectionStatus.UNSET)
                }
            }

            if(props.liveMessages[0].body?.name === "deviceDoorStateChanged"){
                // sometimes the deviceDirectionChanged > directionStop isnt appearing
                // so hide arrow if doors are moving
                setDirection(DirectionStatus.UNSET)
            }

            // detecting arrived floor
            if(props.liveMessages[0].body?.name === "deviceMoved" ){
                setIsloading(false)
                setCurrentFloor(props.liveMessages[0].body.attributes.mappedFloor)
            }

            // detecting goal floor
            if(props.liveMessages[0].body?.name === "deviceGoalFloorsChanged" ){
                setIsloading(false)
                setGoalFloors([...props.liveMessages[0].body.attributes.mappedGoalFloors])
            }
        } 
    }, [props.liveMessages]);

    useEffect(() => {
        // detecting current floor
        if(props.devicesShadow){
            setIsloading(false)
            setCurrentFloor(props.devicesShadow.state.reported.deviceStatus.mappedFloor)
        }
      }, [props.devicesShadow]);

    function getNextFloors(){
        let nextFloors:any[] = []

        for (const floor of goalFloors){
            nextFloors.push(<div  key={floor} className=" border border-blueDark text-5xl w-32 h-32 flex justify-center items-center mr-4 "><div>{ floor }</div></div>)
        }
        return ( 
            <div className=" ml-9 mr-6 hidden m:block ">
                <div className="w-[180px]"><h3 className="h-20 text-center hidden m:block uppercase ">{I18n.get('next')}<br/>{I18n.get('stop')}</h3></div>
                <div className="flex ">
                    {nextFloors}
                </div>
            </div>
        )
    }

    return (
        <Spin size="large" spinning={isLoading} key="spin">
            <div className="flex ">
                <div className="mr-6 flex flex-col items-center ">
                    <h3 className={"h-20 text-center hidden m:block uppercase " }>{I18n.get('current')}<br/>{I18n.get('level')}</h3>
                    {currentFloor !== null && 
                        <div className="flex ">
                            <div className="w-12 m:w-32 h-12 m:h-32  bg-blueDark border border-blueDark text-white text-xl m:text-5xl flex justify-center items-center"><div>{  currentFloor }</div></div>
                            {direction === DirectionStatus.UP && <img className=" w-4 ml-6 m:absolute m:left-32 m:bottom-14" src={arrowIotUp} alt="" /> }
                            {direction === DirectionStatus.DOWN && <img className=" w-4 ml-6 m:absolute m:left-32 m:bottom-14" src={arrowIotDown} alt="" /> }
                            {direction === DirectionStatus.UNSET && false && <div className=" " /> }
                        </div>
                    }
                    </div>
                {goalFloors?.length > 0 && getNextFloors() }
            </div>
            </Spin>
        
    );
}
export default LevelVisualization;
