import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Spin } from 'antd';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import { BarChart } from 'echarts/charts';

import { useEffect, useState } from 'react';
const colorsTheme = require('../../../../src/utils/aufzugheldenTheme')
import { Installation } from '../../../interfaces/Installation';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../../utils/globalStateProvider';
import { strings as stringGlobal } from '../../../utils/globalLocalization';
import { DoorBlockagesResponse } from '../../../interfaces/DoorBlockages';
import { DateRange } from '../../../interfaces/DateRange';
import moment from 'moment';
import React from 'react';

const DoorBlockages = (props: { response: DoorBlockagesResponse[]; installations: Installation[] | undefined ; isLoading: boolean; dateRange:DateRange }) => {
  const [noData, setNoData] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [series, setSeries] = useState<any[]>([]);
  const [dataPoints, setDataPoints] = useState<string[]>([]);
  const [timeFormat, setTimeFormat] = useState<string>('ddd DD.MM');
  const [visualizePerHours, setVisualizePerHours] = useState<boolean>(false);
  const { state, setState } = useGlobalState()

  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language]);  

  useEffect(() => {
    I18n.putVocabularies(stringGlobal);
  }, []);

  useEffect(() => {
    if(visualizePerHours){
      setTimeFormat('ddd DD.MM HH:mm') // hour scale
    }
    else{
      setTimeFormat('ddd DD.MM') // daily scale
    }
  }, [visualizePerHours]);

  useEffect(() => {
    let seriesTmp:any[] = []
    Object.entries(data).forEach(([key, value], index) => {

      let label = (props.installations && props.installations[index])? props.installations[index].label : 'unknown'
      let dataTmp:any[] = []
      Object.entries(value).forEach(([key, value], index) => {
        dataTmp.push(value)
      })  

      seriesTmp.push(
        {
          name: label,
          type: 'bar',
          barGap: 0,
    
          emphasis: {
            focus: 'series'
          },
          data: dataTmp,
          itemStyle: {
            color: colorsTheme.chartColors[index]
          }
        }
      )
    });

    setSeries(seriesTmp);
  }, [data]);
  
  useEffect(() => {
    const dataTmp:any = {} ;
    if(!props.response || props.response.length === 0 || props.installations?.length === 0){
      setNoData(true);
      return
    }

    let startdate = moment(props.dateRange.startDate)
    let enddate = moment(props.dateRange.endDate)
    let datapoints: string[]= []
    
    while(startdate.isSameOrBefore(enddate)){
      datapoints.push(startdate.startOf('day').toISOString())
      startdate.add(1,'day')
    }

    // prefill with 0 values
    props.response.map((singleResponse,index)=>{
      if(!dataTmp[index])
      {
        dataTmp[index] = {}
      }
      datapoints.forEach((elem) => {
        dataTmp[index][elem] = 0
      })
    })

    if(Array.isArray(props.response))
    {

      props.response.map((singleResponse,index)=>{
        if(Array.isArray(singleResponse)){
          for (const item of singleResponse) {
            let time = moment(item.timestamp).startOf('day').toISOString()
            dataTmp[index][time] = dataTmp[index][time] + item.attempts
          }
        }
      })

    }


    if(moment(datapoints[1]).diff(moment(datapoints[0]), 'hours') <= 1){
      setVisualizePerHours(true)
    }
    else{
      setVisualizePerHours(false)
    }

    setDataPoints(datapoints)
    setData(dataTmp);

    if (Object.keys(dataTmp).length > 0) {
      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [props.response]);

  echarts.use([
    BarChart,
  ]);

  echarts.registerTheme('light', {
    backgroundColor: '#fff',
  });

  const option = {
    grid: {
      left: '1%',
      right: '1%',
      bottom: '15%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'item',
      backgroundColor:colorsTheme.chartTooltipBackground,
      borderWidth:0,
      textStyle:{
        color:colorsTheme.chartTooltipTextColor
      },
      formatter: function (params: any) {
        return I18n.get('blockages') + ': ' + params.value + '<br/>'+params.seriesName;
      }
    },
    legend: {
      bottom: '0%',
    },
    xAxis: {
      boundaryGap: true,
      axisLine:{
        show:false,
      },
      type: 'category',
      axisTick: { show: false },
      date:dataPoints,
      axisLabel: {
        formatter: (value:any)=>{ 
          return moment(dataPoints[value]).format(timeFormat)  
        } 
    },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '0%'],
      splitLine:{
        show:true,
        lineStyle:{
          color:colorsTheme.chartBackgroundLineColor,
          opacity: colorsTheme.chartBackgroundLineOpacity
        }
      },
      axisLabel: {
        formatter: (value:any)=>{ return value.toLocaleString('de-DE')} 
    },
    },
    series: series,
  };

  return (
    <Spin size="large" spinning={props.isLoading} key="spin">
      {noData && (
        <div className="flex" style={{ height: '100px' }}>
          <div className="m-auto text-sm m:text-lg text-greyLight">{I18n.get('noDataForTimePeriod')}</div>
        </div>
      )}
      {!noData && (
          <ReactEChartsCore
            key="chartTurnchart"
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            theme={'light'}
            showLoading={false}
            style={{ height: '300px', width: '100%' }}
          />
      )}
    </Spin>
  );
};
export default DoorBlockages;
