import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Category } from '../../interfaces/Category'
import { EyeInvisibleOutlined} from '@ant-design/icons';

const ShowLibrary = (props: { assetLibrary: Category[] }) => {
    let { assetId,contractId,libraryId } = useParams();
    const navigate = useNavigate();

    const clickCategory = (id:string) => {
        if(contractId){
            navigate('/salesforce/documents/contract/' + contractId + '/' + libraryId + '/' + id);
        }
        else if(assetId){
            navigate('/salesforce/documents/asset/' + assetId + '/' + libraryId + '/' + id);
        }
    }
    
    return (
        <div className="mt-108 h-144 overflow-auto">
            <div className="grid grid-cols-2 gap-4">
                {props.assetLibrary && props.assetLibrary.map((category)=>{
                    return <div onClick={()=>clickCategory(category.id)} key={category.id} className=" bg-blueBaby h-36 flex flex-col justify-between p-6 hover:bg-blueDark group cursor-pointer">
                    <div className="text-right">{category.dsOnly && category.dsOnly === true && <EyeInvisibleOutlined className=' group-hover:text-white text-lg ' />}</div>
                    <div className="flex items-end justify-between">
                        <div className="flex items-end pr-4"><h3 className="inline-block pr-3 group-hover:text-white w-36 [hyphens:auto]" lang="de">{category.titleGerman}</h3> </div>
                        <h3 className="cp_count group-hover:text-white">{category.count}</h3>
                    </div>
                    </div>
                })}
                {props.assetLibrary && props.assetLibrary.length <= 0 && <div>Keine Kategorien vorhanden</div>}
            </div>
        </div>

    );
};

export default ShowLibrary;
