import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { mergeDeepRight } from 'ramda';
import { strings as stringLocal} from './WelcomeLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../utils/globalStateProviderApp';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TableContracts from '../components/welcome/TableContracts';
import { getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, setDocumentTitle } from '../../utils/helpers';
import { setMaxContractsCount } from '../utils/helpersFetch';
import { getIcon } from '../../utils/icons';
import { ContractFilterEnum } from '../../interfaces/ContractFilters';

const Dashboard = () => {
  
  const { getAccessTokenSilently,isAuthenticated,user } = useAuth0();
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const filterBy = query.get('filterBy') as ContractFilterEnum
    
    let dataGlobal:Partial<GlobalStateInterfaceApp> = {
      search:{
        page: stateApp.search?.page?stateApp.search.page:1,
        searchterm: stateApp.search?.searchterm?stateApp.search.searchterm:'',
        sortBy: stateApp.search?.sortBy?stateApp.search.sortBy:'',
        filterBy:Object.values(ContractFilterEnum).includes(filterBy) ? filterBy : '',
        parametersUpdatedByUrl: stateApp.search?.parametersUpdatedByUrl?stateApp.search.parametersUpdatedByUrl:true,
      }}

    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))
  }, []);

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let lang = getLangByLocalStorage()
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
      setDocumentTitle()
    }

    const asyncInit = async () => {
      let accessToken =  await getAuth0Token(getAccessTokenSilently)
      await setMaxContractsCount(setStateApp,accessToken)
    }
    asyncInit()
    
  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  useEffect(() => {
    if(state.organizationName && document.location.pathname === '/welcome'){
        navigate('/'+state.organizationName+'/welcome')
    }
  }, [state.organizationName]);

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      <Header />
      <section className="bg-blueLight welcome">
        <div className=" pt-7 xxl:pt-14 pb-11 xxl:pb-14">
          <div className="flex items-center">
            <div className="  w-[55px] h-[55px] rounded-lg bg-white flex items-center justify-center">
              {getIcon({ type: 'Contracts', ownClassName: ' fill-blueLight w-9' })}
            </div>
            <div className=" ml-4 m:ml-6 text-base m:text-xl text-white font-bold ">{I18n.get('nameApp')}</div>
          </div>
          <h1 className="text-white mt-5">{I18n.get('welcomeMessage')} {(getAuth0IsAuthenticatedStatus(isAuthenticated) && user && user.email && <>{user.name}</>)}</h1>
          <p className="mt-1 xxl:mt-0 text-white text-lbase m:text-l xl:text-xl xxl:text-4xl">{I18n.get('welcomeMessageFollow').replace('###number###', stateApp.maxContractsCount ? stateApp.maxContractsCount: 0)}</p>
        </div>
      </section>
      <section className="Content">
        <div className="flex flex-col">
          <TableContracts />
        </div>
      </section>
      <Footer />
    </div>

  );
};

export default Dashboard