import { strings as stringLocal} from './DocumentsDetailLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { auth0_audience, USER_CHOICE_LANGUAGE } from '../../utils/consts';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderDetail from '../../components/HeaderDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Installation } from '../../interfaces/Installation';
import { loadInstallation } from '../utils/helpers';
import ShowCatagoryList from '../components/detail/showCatgegoryList';
import BreadCrumbNavigation, { BreadCrumbNavigationItem } from '../../components/BreadcrumbNavigation';
import { getAllCategories, getCategoriesForLibrary, getFile, getLibraryForEntity } from '../utils/helpersFetch';
import { Library } from '../interfaces/Library';
import ShowLibraryOverview from '../components/detail/showLibraryOverview';
import { Category } from '../interfaces/Category';
import { DsFile } from '../interfaces/File';
import { message } from 'antd';
import { getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, IsDsMember, setDocumentTitle, UserHasPermissionsForDevices } from '../../utils/helpers';
import { useGlobalStateApp } from '../utils/globalStateProviderApp';
import UploadFileDropForSubCategory from '../components/detail/uploadFileDropForSubCategory';

const DocumentsDetail = () => {
  const { state, setState } = useGlobalState()
  const { user, isAuthenticated ,logout} = useAuth0()
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forceInterpretationOfDeeplinkParameters, setForceInterpretationOfDeeplinkParameters] = useState<boolean>(true);
  const [deeplinkRequestForSubcategory, setDeeplinkRequestForSubcategory] = useState<boolean>(false);
  const [deeplinkInitFile, setDeeplinkInitFile] = useState<DsFile|null>(null);
  const [activeCategory, setActiveCategory] = useState<Category|null>(null);
  const [activeSubCategory, setActiveSubCategory] = useState<Category|null>(null);
  const [isLoadingLibrary, setIsLoadingLibrary] = useState<boolean>(false);
  const [userHasDevicesPermissions, setUserHasDevicesPermissions] = useState<boolean>(false);
  const [isLoadingMainCategories, setIsLoadingMainCategories] = useState<boolean>(false);
  const [library, selibrary] = useState<Library[]>([]);
  const [mainCategories, setMainCategories] = useState<Category[]>([]);
  const [currentInstallation, setCurrentInstallation] = useState<Installation | null>(null);
  const [dynHeadeContent, setDynHeadeContent] = useState<ReactNode>(<></>);
  const [dynHeadeContentMobile, setDynHeadeContentMobile] = useState<ReactNode>(<></>);
  const [breadCrumbNavigationItems,setBreadCrumbNavigationItems] = useState<BreadCrumbNavigationItem[]>([])
  const { search } = useLocation();
  const navigate = useNavigate();
  const { stateApp, setStateApp } = useGlobalStateApp()
  let { installationId, organizationName, categoryMainOrSubId, fileId } = useParams();
  const [activeSubCategoryByUserSelection, setActiveSubCategoryByUserSelection] = useState<Category|null>(null);
  const [showUploadForm,setShowUploadForm] = useState<boolean>(false)
  const [uploadWasStarted,setUploadWasStarted] = useState<boolean>(false)
  const refUploadWasStarted = React.useRef<boolean >(false);

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let query =  new URLSearchParams(search);
      let langDefaulvalue = query.get("lang")
      let lang:string|null=null
      if(langDefaulvalue){
        lang = langDefaulvalue
        localStorage.setItem(USER_CHOICE_LANGUAGE, lang);
      }
      else{
        lang = getLangByLocalStorage()
      }
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
      setDocumentTitle()
    }

    const setPermissions = async () => {
      setUserHasDevicesPermissions(await UserHasPermissionsForDevices(await getAuth0Token(getAccessTokenSilently)))
    }
    setPermissions()

  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  useEffect(() => {
    if(installationId){
      const initInstallation = async () => {
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        if(installationId){
          loadInstallation(installationId,setIsLoading,setCurrentInstallation,accessToken,'/404',navigate)
        }
      }
      initInstallation()
    }

    // load all categories
    const interpretDeeplinkParameters = async () => {
      let accessToken =  await getAuth0Token(getAccessTokenSilently)
      let allCategories = await getAllCategories(accessToken,'Asset')

      let categoryToDisplay

      if(categoryMainOrSubId){
        categoryToDisplay = categoryMainOrSubId
      }
      else if(fileId){
        let deeplinkFile = await getFile(fileId,accessToken)
        if(deeplinkFile){
          categoryToDisplay = deeplinkFile.category.id
          setDeeplinkInitFile(deeplinkFile)
        }
      }

      // descide sub or main category
      var categorieFound = false
      allCategories.forEach( categoryItem => {
        if(categoryItem.id === categoryToDisplay){
          categorieFound = true
          if(categoryItem.parent){
            setDeeplinkRequestForSubcategory(true)
            // subcategory
            let maincategory = allCategories.filter(item => { return(item.id === categoryItem.parent)? true:false})
            if(maincategory[0]){
              setActiveCategory(maincategory[0]) // main category
            }
            setActiveSubCategory(categoryItem) // set subcategory
          }
          else{
            setActiveCategory(categoryItem) // main category
          }

        }
      })

      // in case the category isnt available show message
      if(!categorieFound && !fileId){
        message.error(I18n.get('MatchDeepLinkCategoryError'));
      }
    }

    if((categoryMainOrSubId || fileId) && forceInterpretationOfDeeplinkParameters){
      interpretDeeplinkParameters()
      setForceInterpretationOfDeeplinkParameters(false)
    }
  }, [installationId,categoryMainOrSubId,fileId]);
  
  useEffect(() => {
    if (currentInstallation) {

      const loadLibraryData = async() => {
        setIsLoadingLibrary(true)
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        let library = await getLibraryForEntity(currentInstallation._id,'Asset',accessToken)
        if(library && library.count > 0){
          selibrary(library.libraries)
        }
        setIsLoadingLibrary(false)
      }
      
      loadLibraryData()
    }
  }, [currentInstallation]);

  useEffect(() => {
    setDynHeadeContent(<></>
    )
    setDynHeadeContentMobile(<></>)
  }, [state.language]);  

  useEffect(() => {
    const loadCategories = async() => {
      if(library[0]){
        setIsLoadingMainCategories(true)
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        let categories = await getCategoriesForLibrary(library[0].id,accessToken)
        if(categories){
          setMainCategories(categories)
        }
        setIsLoadingMainCategories(false)
      }
    }
    loadCategories()
  }, [library]);
  
  useEffect(() => {

    let breadItems:BreadCrumbNavigationItem[] = [
      {lable:currentInstallation?.label?currentInstallation?.label:'', onClick: (userHasDevicesPermissions ? (()=>goBackDevicesInstallation()) : undefined) },
      {lable:I18n.get('Documents'), onClick:()=>{
        setActiveSubCategory(null)
        setActiveCategory(null)
      }}
    ]

    if(activeCategory){
      let label = state.language === 'de' ?activeCategory.titleGerman:activeCategory.titleEnglish
      breadItems.push({lable:label})
    }
    setBreadCrumbNavigationItems(breadItems)
  }, [activeCategory,currentInstallation,state.language]);
  
  useEffect(() => {
    // set deeplink url
    if(forceInterpretationOfDeeplinkParameters === false && !deeplinkRequestForSubcategory){
      let targetPath = '/' + organizationName + '/installations/' + installationId
      if(activeCategory){ targetPath += '/c/' + activeCategory.id }
      navigate(targetPath)
    }
  }, [activeCategory]);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const goBack = async () => {
    if(await IsDsMember(await getAuth0Token(getAccessTokenSilently))){
      console.log('go back')
      scrollTop()
      let urlParams = '?'
      +'page='+(stateApp.search?.page ? stateApp.search?.page : 1)
      +'&search='+(stateApp.search?.searchterm ? encodeURIComponent(stateApp.search.searchterm) : '')
      +'&sortBy='+(stateApp.search?.sortBy ? stateApp.search.sortBy : '')
      +'&back=true'
  
      navigate('/org_'+state.organizationName+'/welcome'+urlParams)      
    }
    else{
    document.location.href = (auth0_audience.replace('.api','') + '/?lang=' + state.language)
  }
  }

  const goBackDevicesInstallation = () => {
    document.location.href = (auth0_audience.replace('.api','').replace('documents','devices')
    + '/' + state.organizationName 
    + '/installations' 
    + '/' +installationId
    + '/?lang=' + state.language)
  }

  function uploadCallback(subCategoryId:string ,componentIsUploading:boolean) {

    if(componentIsUploading){
      refUploadWasStarted.current = true;
      setUploadWasStarted(true)
    }
//    setIsUploading(componentIsUploading)
  }

  function refreshCallback(activeSubcategory:Category) {
    setUploadWasStarted(false)
    setShowUploadForm(false)
    setActiveSubCategory(activeSubcategory)
  }

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      {currentInstallation && <HeaderDetail 
        goBack={goBack}
        mode='Installation'
        contract={null}
        installation={currentInstallation}
        loadInstallation={loadInstallation}
        dynContent={dynHeadeContent}
        dynContentMobile={dynHeadeContentMobile}
        />}
      <section className="Content">
        <div className=" pb-14 pt-24 "
          onDragEnterCapture={()=>{ if(activeSubCategoryByUserSelection){setUploadWasStarted(false),setShowUploadForm(true)}}}  
        >
        { showUploadForm && activeSubCategoryByUserSelection && library[0].id && <UploadFileDropForSubCategory 
            uploadCallback={uploadCallback} 
            subCategory={activeSubCategoryByUserSelection} 
            libraryId={library[0].id}
            refreshCallback={refreshCallback} />}          
          { !showUploadForm && currentInstallation && <BreadCrumbNavigation items={breadCrumbNavigationItems}/>}
          { !showUploadForm && !activeCategory && <ShowLibraryOverview categories={mainCategories} isLoading={isLoadingLibrary || isLoadingMainCategories} setActiveCategory={setActiveCategory} library={library[0]} />}
          { !showUploadForm && activeCategory && library[0] && <ShowCatagoryList setActiveSubCategoryByUserSelection={setActiveSubCategoryByUserSelection} category={activeCategory} library={library[0]} initSubCategory={activeSubCategory} initFile={deeplinkInitFile} />}
        </div>
      </section>
    <Footer />
    </div>
  );
};

export default DocumentsDetail;
