import { I18n } from "@aws-amplify/core";
import { useGlobalState } from "../../../utils/globalStateProvider";

export const DoorLoading = () => {

    const { state, setState } = useGlobalState()

    let classes = "w-12 m:w-32 h-12 m:h-32 border-2 m:border border-blueDark flex justify-center items-center text-xl m:text-5xl "
    if(state.language === 'en'){
        classes = "w-12 m:w-32 h-12 m:h-32 border-2 m:border border-blueDark flex justify-center items-center text-sm m:text-3xl "
    }

    return (
        <div className="flex flex-row">
            <div className="flex flex-col items-center">
                <h3 className="h-20 text-center hidden m:block uppercase">{I18n.get('doorState')}<br/>{I18n.get('door')} 01</h3>
                <div className={' bg-blueDark text-white ' + classes}><div></div></div>
            </div>
        </div>
    );
}

export const DoorSouth = (props:{southIsOpen:boolean}) => {

    const { state, setState } = useGlobalState()

    let classes = "w-12 m:w-32 h-12 m:h-32 border-2 m:border border-blueDark flex justify-center items-center text-xl m:text-5xl "
    if(state.language === 'en'){
        classes = "w-12 m:w-32 h-12 m:h-32 border-2 m:border border-blueDark flex justify-center items-center text-sm m:text-3xl "
    }

    return (
        <div className="flex flex-row">
            <div className="flex flex-col items-center">
                <h3 className="h-20 text-center hidden m:block uppercase">{I18n.get('doorState')}<br/>{I18n.get('door')} 01</h3>
                <div className={(props.southIsOpen ? '' : ' bg-blueDark text-white ') + classes}><div>{props.southIsOpen ? I18n.get('doorOpend') : I18n.get('doorClosed')}</div></div>
            </div>
        </div>
    );
}

export const DoorNorthSouth = (props:{southIsOpen:boolean,northIsOpen:boolean}) => {
    
    const { state, setState } = useGlobalState()

    let classes = " w-12 m:w-32 h-12 m:h-32 border-2 m:border border-blueDark flex justify-center items-center text-xl m:text-5xl"
    if(state.language === 'en'){
        classes = " w-12 m:w-32 h-12 m:h-32 border-2 m:border border-blueDark flex justify-center items-center text-sm m:text-3xl "
    }
    return (
        <div className="flex flex-row">
            <div className="flex flex-col items-center mr-3 m:mr-20">
                <h3 className="h-20 text-center hidden m:block uppercase">{I18n.get('doorState')}<br/>{I18n.get('door')} 01</h3>
                <div className={(props.southIsOpen ? '' : ' bg-blueDark text-white') + classes} >{props.southIsOpen ? I18n.get('doorOpend') : I18n.get('doorClosed')}</div>
            </div>
            <div className="flex flex-col items-center ">
                <h3 className="h-20 text-center hidden m:block uppercase">{I18n.get('doorState')}<br/>{I18n.get('door')}  02</h3>
                <div className={(props.northIsOpen ? '' : ' bg-blueDark text-white') + classes} >{props.northIsOpen ? I18n.get('doorOpend') : I18n.get('doorClosed')}</div>
            </div>
        </div>
    );

}
