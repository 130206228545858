import { I18n } from "@aws-amplify/core";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../../utils/globalStateProvider";
import { Category } from "../../interfaces/Category";
import { DsFile } from "../../interfaces/File";
import DocumentDialog from "./documentDialog";
import { useNavigate, useParams } from "react-router-dom";
import { Library } from "../../interfaces/Library";
import RecentlyViewedFiles from "./recentlyViewedFiles";
import { setPathForSelectedFile } from "../../utils/helpers";

const ShowLibraryOverview = (props:{categories:Category[],isLoading:boolean,setActiveCategory:Function,library?:Library}) => {

    const arrow = <svg className="h-4 fill-blueDark group-hover:fill-white  mb-2" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6043 0.791992L17.5481 2.84824L24.2272 9.54199H0.645996V12.4587H24.2272L17.5335 19.1524L19.6043 21.2087L29.8127 11.0003L19.6043 0.791992Z" /></svg>
    const { state, setState } = useGlobalState()
    const [selectedFile, setSelectedFile] = React.useState<DsFile | null>(null)
    let { libraryId,categoryMainOrSubId } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
      if(state.language){
        I18n.setLanguage(state.language)
        document.documentElement.lang = state.language
      }
    }, [state.language]);

    useEffect(() => {
      if(selectedFile){
        document.body?.classList.add('modalOpen')
      }
      else{
        document.body?.classList.remove('modalOpen')
      }
    }, [selectedFile]);

    useEffect(() => {
      if(state.organizationName){
        setPathForSelectedFile(selectedFile,libraryId,state.organizationName,categoryMainOrSubId,navigate)
      }
    }, [selectedFile,state.organizationName]);

    return (<Spin spinning={props.isLoading} >
      <h1>{I18n.get('Categories')}</h1>
      <div className=" mt-10 grid  s:grid-cols-2 m:grid-cols-2 l:grid-cols-4 xl:grid-cols-4 gap-4 cp_container_library_categories">
        {props.categories?.map((category)=>{
          return <div onClick={()=>props.setActiveCategory(category)} key={category.id} className="cp_library_categories_element bg-blueBaby h-36 l:h-192px flex items-end justify-between p-6 hover:bg-blueDark group cursor-pointer">
            <div className="flex items-end pr-4"><h2 className="w-min inline-block pr-3 group-hover:text-white">{state.language==='de'?category.titleGerman:category.titleEnglish}</h2> {arrow}</div>
            <h2 className="cp_count group-hover:text-white">{category.count}</h2>
          </div>
        })}
      </div>
      <RecentlyViewedFiles 
        libraryIdToFilterFor={libraryId?libraryId:props.library?.id }
        setSelectedFile={setSelectedFile}
        isOverView={false}
      />
      {selectedFile && 
      <DocumentDialog 
        isLastSeenMode={true}
        file={{file:selectedFile,imageSrc:null}}
        setSelectedFile={setSelectedFile}
        mode={(libraryId)?'Library':'Installation'}
        />}        
    </Spin>);
}
export default ShowLibraryOverview