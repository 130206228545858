import { strings as stringLocal } from './DocumentsDetailLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { app_url_devices, auth0_audience, USER_CHOICE_LANGUAGE } from '../../utils/consts';
import moment from 'moment';
import React, { createRef, ReactNode, useEffect, useRef, useState } from 'react';
import Footer from '../../components/Footer';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderDetail from '../../components/HeaderDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Installation } from '../../interfaces/Installation';
import { Contract } from '../interfaces/Contract';
import { loadInstallation } from '../utils/helpers';
import ShowCatagoryList from '../components/detail/showCatgegoryList';
import { getContractById, handleApiError } from '../../utils/helpersFetch';
import BreadCrumbNavigation, { BreadCrumbNavigationItem } from '../../components/BreadcrumbNavigation';
import { getAllCategories, getCategoriesForLibrary, getFile, getLibraryById, getLibraryForEntity } from '../utils/helpersFetch';
import { Library } from '../interfaces/Library';
import ShowLibraryOverview from '../components/detail/showLibraryOverview';
import { Category } from '../interfaces/Category';
import { DsFile } from '../interfaces/File';
import { message } from 'antd';
import { delay, getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, IsDsMember, setDocumentTitle, UserHasPermissionsForContracts, UserHasPermissionsForDevices, UserHasPermissionsForDocumentsUpload } from '../../utils/helpers';
import { useGlobalStateApp } from '../utils/globalStateProviderApp';
import UploadFileDropForSubCategory from '../components/detail/uploadFileDropForSubCategory';
import { ApiError } from "../utils/api-error";

const LibraryDetail = () => {
  const { state, setState } = useGlobalState()
  const { user, isAuthenticated, logout } = useAuth0()
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forceInterpretationOfDeeplinkParameters, setForceInterpretationOfDeeplinkParameters] = useState<boolean>(true);
  const [deeplinkRequestForSubcategory, setDeeplinkRequestForSubcategory] = useState<boolean>(false);
  const [deeplinkInitFile, setDeeplinkInitFile] = useState<DsFile | null>(null);
  const [activeCategory, setActiveCategory] = useState<Category | null>(null);
  const [activeSubCategory, setActiveSubCategory] = useState<Category | null>(null);
  const [activeSubCategoryByUserSelection, setActiveSubCategoryByUserSelection] = useState<Category | null>(null);
  const [isLoadingLibrary, setIsLoadingLibrary] = useState<boolean>(false);
  const [isContractLibrary, setIsContractLibrary] = useState<boolean | undefined>(undefined);
  const [isLoadingMainCategories, setIsLoadingMainCategories] = useState<boolean>(false);
  const [library, setLibrary] = useState<Library[]>([]);
  const [mainCategories, setMainCategories] = useState<Category[]>([]);
  const [currentInstallation, setCurrentInstallation] = useState<Installation | null>(null);
  const [currentContract, setCurrentContract] = useState<Contract | null>(null);
  const [dynHeadeContent, setDynHeadeContent] = useState<ReactNode>(<></>);
  const [dynHeadeContentMobile, setDynHeadeContentMobile] = useState<ReactNode>(<></>);
  const [breadCrumbNavigationItems, setBreadCrumbNavigationItems] = useState<BreadCrumbNavigationItem[]>([])
  const { search } = useLocation();
  const navigate = useNavigate();
  const { stateApp, setStateApp } = useGlobalStateApp()
  let { libraryId, organizationName, categoryMainOrSubId, fileId } = useParams();
  const [userHasDevicesPermissions, setUserHasDevicesPermissions] = useState<boolean>(false);
  const [showUploadForm, setShowUploadForm] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [uploadWasStarted, setUploadWasStarted] = useState<boolean>(false)
  const refUploadWasStarted = React.useRef<boolean>(false);
  const [userHasUploadPermissions, setUserHasUploadPermissions] = useState<boolean>(false)
  const [userHasContactsPermissions, setUserHasContactsPermissions] = useState<boolean>(false)

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
    if (!state.language) {
      let query = new URLSearchParams(search);
      let langDefaulvalue = query.get("lang")
      let lang: string | null = null
      if (langDefaulvalue) {
        lang = langDefaulvalue
        localStorage.setItem(USER_CHOICE_LANGUAGE, lang);
      }
      else {
        lang = getLangByLocalStorage()
      }
      let dataGlobal: Partial<GlobalStateInterface> = { language: lang ? lang : 'de' }
      setState((prev) => ({ ...prev, ...dataGlobal }));
    }

    const setPermissions = async () => {
      setUserHasContactsPermissions(await UserHasPermissionsForContracts(await getAuth0Token(getAccessTokenSilently)))
      setUserHasDevicesPermissions(await UserHasPermissionsForDevices(await getAuth0Token(getAccessTokenSilently)))
      setUserHasUploadPermissions(await UserHasPermissionsForDocumentsUpload(await getAuth0Token(getAccessTokenSilently)))
    }
    setPermissions();

  }, []);

  useEffect(() => {
    if (state.language) {
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
      setDocumentTitle()
    }
  }, [state.language]);

  useEffect(() => {

    if (libraryId) {
      const loadLibraryData = async (libraryId) => {
        setIsLoadingLibrary(true)
        let accessToken = await getAuth0Token(getAccessTokenSilently)
        try {
          let library = await getLibraryById(libraryId, accessToken)

          if (library) {
            setLibrary([library])
            if (library.title.indexOf('contract') > -1) {
              setIsContractLibrary(true)
              let contract = await getContractById(library.uuid, accessToken)
              setCurrentContract(contract)
            }
            else if (library.title.indexOf('asset') > -1) {
              if (library.assets?.length && library.assets?.length > 0) { // for assets
                let assets = library.assets
                setCurrentInstallation(assets[0])
              }
            }

            if ((categoryMainOrSubId || fileId || libraryId) && forceInterpretationOfDeeplinkParameters) {
              await interpretDeeplinkParameters(library)
              setForceInterpretationOfDeeplinkParameters(false)
            }
          }
        } catch (error) {
          handleApiError(error,navigate);
        }
        finally {
          setIsLoadingLibrary(false)
        }

      }

      loadLibraryData(libraryId);

    }

  }, [libraryId, categoryMainOrSubId, fileId, isContractLibrary]);

  useEffect(() => {
    setDynHeadeContent(<></>)
    setDynHeadeContentMobile(<></>)
  }, [state.language]);

  useEffect(() => {
    const loadCategories = async () => {
      if (library && library.length > 0) {
        setIsLoadingMainCategories(true)
        let accessToken = await getAuth0Token(getAccessTokenSilently)
        let categories = await getCategoriesForLibrary(library[0].id, accessToken)
        if (categories) {
          setMainCategories(categories)
        }
        setIsLoadingMainCategories(false)
      }
    }
    loadCategories()
  }, [library]);

  useEffect(() => {
    if (currentContract) {
      // its only loaded if its a contract library
      let breadItems: BreadCrumbNavigationItem[] = [
        { lable: currentContract.contractNumber },
        {
          lable: I18n.get('Documents'), onClick: () => {
            setActiveSubCategory(null)
            setActiveCategory(null)
          }
        }
      ]

      if (activeCategory) {
        let label = state.language === 'de' ? activeCategory.titleGerman : activeCategory.titleEnglish
        breadItems.push({ lable: label })
      }

      setBreadCrumbNavigationItems(breadItems)
    }
    else if (currentInstallation) {
      // case asset library
      let breadItems: BreadCrumbNavigationItem[] = [
        { lable: currentInstallation?.customLabel ? currentInstallation.customLabel : currentInstallation.label, onClick: (userHasDevicesPermissions ? (() => goBackDevicesInstallation()) : undefined) },
        {
          lable: I18n.get('Documents'), onClick: () => {
            setActiveSubCategory(null)
            setActiveCategory(null)
          }
        }
      ]

      if (activeCategory) {
        let label = state.language === 'de' ? activeCategory.titleGerman : activeCategory.titleEnglish
        breadItems.push({ lable: label })
      }

      setBreadCrumbNavigationItems(breadItems)
    }

  }, [activeCategory, currentContract, currentInstallation, state.language]);

  useEffect(() => {
    // set deeplink url

    if (forceInterpretationOfDeeplinkParameters === false && !deeplinkRequestForSubcategory) {
      let targetPath = '/' + organizationName + '/library/' + libraryId
      if (activeCategory) { targetPath += '/c/' + activeCategory.id }
      navigate(targetPath)
    }

  }, [activeCategory]);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const goBackDevicesInstallation = () => {
    if (currentInstallation) {
      document.location.href = (app_url_devices
        + '/' + state.organizationName
        + '/installations'
        + '/' + currentInstallation._id
        + '/?lang=' + state.language)
    }
  }

  const goBack = async () => {

    scrollTop()
    let urlParams = '?'
      + 'page=' + (stateApp.search?.page ? stateApp.search?.page : 1)
      + '&search=' + (stateApp.search?.searchterm ? encodeURIComponent(stateApp.search.searchterm) : '')
      + '&sortBy=' + (stateApp.search?.sortBy ? stateApp.search.sortBy : '')
      + '&back=true'
    navigate('/org_' + state.organizationName + '/welcome' + urlParams)
  }

  function uploadCallback(subCategoryId: string, componentIsUploading: boolean) {

    if (componentIsUploading) {
      refUploadWasStarted.current = true;
      setUploadWasStarted(true)
    }
    setIsUploading(componentIsUploading)
  }

  function refreshCallback(activeSubcategory: Category) {
    setUploadWasStarted(false)
    setShowUploadForm(false)
    setActiveSubCategory(activeSubcategory)
  }

  const interpretDeeplinkParameters = async (library) => {
    let accessToken = await getAuth0Token(getAccessTokenSilently)
    let loadCategoriesFor: 'Asset' | 'Contract' = 'Asset'
    if (library) {
      if (library && library.title.indexOf('contract') > -1) {
        loadCategoriesFor = 'Contract';
      }

      let allCategories = await getAllCategories(accessToken, loadCategoriesFor)
      let categoryToDisplay

      if (categoryMainOrSubId) {
        categoryToDisplay = categoryMainOrSubId
      }
      else if (fileId) {
        try {
          let deeplinkFile = await getFile(fileId, accessToken)
          if (deeplinkFile) {
            categoryToDisplay = deeplinkFile.category.id
            setDeeplinkInitFile(deeplinkFile)
          }
        } catch (error) {
          handleApiError(error,navigate);
        }
        finally {
          setIsLoadingLibrary(false)
        }
      }

      // descide sub or main category
      var categorieFound = false
      allCategories.forEach(categoryItem => {
        if (categoryItem.id === categoryToDisplay) {
          categorieFound = true
          if (categoryItem.parent) {
            setDeeplinkRequestForSubcategory(true)
            // subcategory
            let maincategory = allCategories.filter(item => { return (item.id === categoryItem.parent) ? true : false })
            if (maincategory[0]) {
              setActiveCategory(maincategory[0]) // main category
            }
            setActiveSubCategory(categoryItem) // set subcategory
          }
          else {
            setActiveCategory(categoryItem) // main category
          }
        }
      })

      // in case the category isnt available show message
      if (!categorieFound && !fileId && !isContractLibrary === undefined && !isContractLibrary === false) {
        message.error(I18n.get('MatchDeepLinkCategoryError'));
      }
    }
  }

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      {isContractLibrary && <HeaderDetail
        goBack={goBack}
        mode='Contract'
        contract={currentContract}
        dynContent={dynHeadeContent}
        dynContentMobile={dynHeadeContentMobile}
      />}
      {!isContractLibrary && currentInstallation && <HeaderDetail
        goBack={goBack}
        mode={'Installation'}
        contract={null}
        installation={currentInstallation}
        loadInstallation={loadInstallation}
        dynContent={dynHeadeContent}
        dynContentMobile={dynHeadeContentMobile}
      />}
      <section className="Content">
        <div className=" pb-14 pt-24 "
          onDragEnterCapture={() => { if (activeSubCategoryByUserSelection && userHasUploadPermissions) { setUploadWasStarted(false), setShowUploadForm(true) } }}
        >
          {showUploadForm && activeSubCategoryByUserSelection && libraryId && <UploadFileDropForSubCategory
            uploadCallback={uploadCallback}
            subCategory={activeSubCategoryByUserSelection}
            libraryId={libraryId}
            refreshCallback={refreshCallback} />}
          {!showUploadForm && <BreadCrumbNavigation items={breadCrumbNavigationItems} />}
          {!showUploadForm && !activeCategory && <ShowLibraryOverview categories={mainCategories} isLoading={isLoadingLibrary || isLoadingMainCategories} setActiveCategory={setActiveCategory} />}
          {!showUploadForm && activeCategory && library[0] && <ShowCatagoryList setActiveSubCategoryByUserSelection={setActiveSubCategoryByUserSelection} category={activeCategory} library={library[0]} initSubCategory={activeSubCategory} initFile={deeplinkInitFile} contract={currentContract ? currentContract : undefined} />}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LibraryDetail;
