
export const strings = {
    en: {
      Devices: 'Devices',
      Documents: 'Documents',
      Contracts: 'Contracts',
      Account: 'Account',
      for: 'for',
    },
    de: {
      Devices: 'Geräte',
      Documents: 'Dokumente',
      Contracts: 'Verträge',
      Account: 'Account',
      for: 'für',
    },
  };