export const strings = {
    en: {
        'DropHereToUpload':'Drop files here to upload to category:',
        'FolderErrorMessage':'Folders cant get uploaded!',
        'Assets in incident':'Affected Assets',
        'NoAffectedAssets':'No Assets affected',
        'NoIncidentsFound':'No Incidents found',
        'NoSignalsFound':'No Insights found',
        'IncidentType_Maintenance':'Maintenance',
        'IncidentType_Support':'Support',
        'IncidentType_Malfunction':'Malfunction',
        'IncidentType_TÜV Audit':'TÜV Audit',
        'IncidentType_Repair':'Repair',
        'IncidentType_People rescue':'People rescue',
        'IncidentType_Malfunction (IoT Box)':'Malfunction (IoT Box)',
        'IncidentType_Feedback':'Feedback',
        'IncidentType_QBR':'QBR',
        'IncidentType_Customer Sucess':'Customer Sucess',
        'IncidentType_Onboarding':'Onboarding',
        'IncidentType_Rechnung':'Bill',
        'IncidentType_Contract adjustments':'Contract adjustments',
        'IncidentType_Asset Status':'Asset Status',
        'IncidentStatus_Closed':'Closed',
        'IncidentStatus_New':'New',
        'IncidentStatus_Update':'Update',
        'IncidentStatus_In Progress':'In Progress',
        'IncidentStatus_Waiting':'Waiting',
        'IncidentStatus_Done':'Done',
        'IncidentStatus_1st Data check': '1st Data check',
        'IncidentStatus_Introduction':'Introduction',
        'IncidentStatus_Date is available':'Date is available',
        'IncidentStatus_Customer informed':'Customer informed',
        'IncidentStatus_Kick-Off':'Kick-Off',
        'IncidentStatus_Repair offer':'Repair offer',
        'IncidentStatus_Appointment propoasal received from TÜV':'Appointment propoasal received from TÜV',
        'IncidentStatus_Waiting for customer feedback':'Waiting for customer feedback',
        'IncidentStatus_Suplier informed':'Suplier informed',
        'IncidentStatus_Appointment agreed':'Appointment agreed',
        'IncidentStatus_Check by Teck Team':'Check by Teck Team',
        'IncidentStatus_2nd data check':'2nd data check',
        'IncidentStatus_Get offer':'Get offer',
        'IncidentStatus_Maintenance company informed':'Maintenance company informed',
        'IncidentStatus_Negotiation / cancellation completed':'Negotiation / cancellation completed',
        'IncidentStatus_Maintenance performed':'Maintenance performed',
        'IncidentStatus_Malfunction done':'Malfunction done',
        'IncidentStatus_Documents in review':'Documents in review',
        'IncidentStatus_AH offer created and sent':'AH offer created and sent',
        'IncidentStatus_Proof of work received':'Proof of work received',
        'IncidentStatus_Invoice received':'Invoice received',
        'IncidentStatus_Fault clearance performed':'Fault clearance performed',
        'IncidentStatus_Customer confirmed':'Customer confirmed',
        'IncidentStatus_Repair performed':'Repair performed',
        'IncidentStatus_TÜV Audit performed':'TÜV Audit performed',
        'IncidentStatus_Company confirmed':'Company confirmed',
        'IncidentStatus_Proof of work sent':'Proof of work sent',
        'IncidentStatus_Preparation of installation':'Preparation of installation',
        'IncidentStatus_Installation date confirmed':'Installation date confirmed',
        'IncidentStatus_Dashboard presentation':'Dashboard presentation',
        'IncidentStatus_New contract/cancellation documented':'New contract/cancellation documented',
        'Casenumber':'Inicdent Number',
        'ContactName':'Contact Name',
        'RecordType':'Type',
        'Origin':'Origin',
        'DateUpdated':'Date Updated',
        'CreatedAt':'Created At',
        'Title':'Title',
        'Status':'Status',
        'Btn_Report_a_problem':'Report a problem',
    },
    de: {
        'DropHereToUpload':'Dateien hier einfügen um sie der folgenden Kategorie hinzuzufügen:',
        'FolderErrorMessage':'Verzeichnisse können nicht hochgeladen werden!',
        'Assets in incident':'Betroffene Anlagen',
        'NoAffectedAssets':'Keine betroffenen Anlagen',
        'NoIncidentsFound':'Keine Vorfälle gefunden',
        'NoSignalsFound':'Keine Vorfälle gefunden',
        'IncidentType_Maintenance':'Wartung ',
        'IncidentType_Support':'Support',
        'IncidentType_Malfunction':'Störung',
        'IncidentType_TÜV Audit':'TÜV Prüfung',
        'IncidentType_Repair':'Reparatur',
        'IncidentType_People rescue':'Personenbefreiung',
        'IncidentType_Malfunction (IoT Box)':'Störung (IoT-Box)',
        'IncidentType_Feedback':'Rückmeldung',
        'IncidentType_QBR':'QBR',
        'IncidentType_Customer Sucess':'Kundenerfolg',
        'IncidentType_Onboarding':'Onboarding',
        'IncidentType_Rechnung':'Rechnung',
        'IncidentType_Contract adjustments':'Vertragsanpassungen',
        'IncidentType_Asset Status':'Vermögensstatus',
        'IncidentStatus_Closed':'Geschlossen',
        'IncidentStatus_New':'Neu',
        'IncidentStatus_Update':'Update',
        'IncidentStatus_In Progress':'In Bearbeitung',
        'IncidentStatus_Waiting':'Warten',
        'IncidentStatus_Done':'Abgeschlossen',
        'IncidentStatus_1st Data check': '1st Data check',
        'IncidentStatus_Introduction':'Vorstellung / Rechnung',
        'IncidentStatus_Date is available':'Termin liegt vor',
        'IncidentStatus_Customer informed':'Kunden informiert',
        'IncidentStatus_Kick-Off':'Kick-Off',
        'IncidentStatus_Repair offer':'Reparaturangebot eingeholt',
        'IncidentStatus_Appointment propoasal received from TÜV':'Terminvorschlag von TÜV liegt vor',
        'IncidentStatus_Waiting for customer feedback':'Warten auf Kunden Rückmeldung',
        'IncidentStatus_Suplier informed':'Dienstleister informiert',
        'IncidentStatus_Appointment agreed':'Termin abgestimmt',
        'IncidentStatus_Check by Teck Team':'Überprüfung durch Tech-Team',
        'IncidentStatus_2nd data check':'2nd data check',
        'IncidentStatus_Get offer':'Vergleichsangebot eingeholt',
        'IncidentStatus_Maintenance company informed':'Wartungsunternehmen informiert',
        'IncidentStatus_Negotiation / cancellation completed':'Verhandlung / Kündigung abgeschlossen',
        'IncidentStatus_Maintenance performed':'Maintenance performed',
        'IncidentStatus_Malfunction done':'Wartung abgeschlossen',
        'IncidentStatus_Documents in review':'Einbaudokumente in Prüfung',
        'IncidentStatus_AH offer created and sent':'AH-Angebot erstellt/versendet',
        'IncidentStatus_Proof of work received':'Nachweis über die geleistete Arbeit',
        'IncidentStatus_Invoice received':'Rechnung erhalten',
        'IncidentStatus_Fault clearance performed':'Störungsbeseitigung durchgeführt',
        'IncidentStatus_Customer confirmed':'Kunde hat bestätigt',
        'IncidentStatus_Repair performed':'Reparatur abgeschlossen',
        'IncidentStatus_TÜV Audit performed':'TÜV Audit performed',
        'IncidentStatus_Company confirmed':'Dienstleister hat bestätigt',
        'IncidentStatus_Proof of work sent':'Übermittlung des Arbeitsnachweises',
        'IncidentStatus_Preparation of installation':'Vorbereitung der Installation',
        'IncidentStatus_Installation date confirmed':'Installationsdatum bestätigt',
        'IncidentStatus_Dashboard presentation':'Dashboard Präsentation',
        'IncidentStatus_New contract/cancellation documented':'New contract/cancellation documented',
        'Casenumber':'Vorfallnummer',
        'ContactName':'Kontaktname',
        'RecordType':'Typ',
        'Origin':'Herkunft',
        'DateUpdated':'Änderungdatum',
        'CreatedAt':'Erstelldatum',
        'Title':'Titel',
        'Status':'Status',
        'Btn_Report_a_problem':'Problem melden',
    },
};