import React, { useEffect, useRef, useState } from "react";
import { I18n } from "@aws-amplify/core";
import { useGlobalState } from "../../utils/globalStateProvider";
import TableMain, { TableProps } from "../../components/molecules/TableMain";
import moment from "moment";
import { Spin } from "antd";
import { getAuth0Token, langChange, langInit } from "../../utils/helpers";
import { strings as stringLocal} from './../utils/globalLocalization';
import { useAuth0 } from "@auth0/auth0-react";
import { DsFileLatest } from "../../documents/interfaces/File";
import { fetchRecentDocuments } from "../../utils/helpersFetch";
import { app_url_documents, app_url_incidents } from "../../utils/consts";

const colorsTheme = require('../../../src/utils/aufzugheldenTheme')

const DocumentsLatestTable = () => {

  const tablePropsDefault: TableProps = {
    height: "unset",
    noDataLabel: I18n.get('Loading...'),
    th: [],
    tr: []
  };

  const { state, setState } = useGlobalState()
  const [tableProps, setTableProps] = useState<TableProps>({...tablePropsDefault});
  const { organizationName } = state;
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const topTableElementRef = useRef<null | HTMLTableElement>(null)
  const [latestDocs,setLatestDocs] = useState<DsFileLatest[]>([])
  const { getAccessTokenSilently } = useAuth0()

  async function loadData() {
    try {
      setIsLoading(true);
      const accessToken = await getAuth0Token(getAccessTokenSilently)
      const recentDocumentsResponse = await fetchRecentDocuments(accessToken)

      setLatestDocs(recentDocumentsResponse)
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching incidents:', error.message)
      // Handle the error here if needed
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadData()
  }, []);

  useEffect(() => {
    if (state.language) { langChange({ state: state }) } else { langInit({ stringLocal }) }
  }, [state.language]);

  useEffect(() => {

    const tableProps: TableProps = tablePropsDefault;

    tableProps.th = [

      // mobile
      {
        text: 
        <span className="flex flex-col m:flex-row">
           <span className="flex items-center">{I18n.get('DocumentName')}<span className="m:hidden ml-3">/</span></span>
           <span className="hidden m:inline-block mr-3 ml-3">/</span>
           <span className="flex">
            <span className="flex items-center">{I18n.get('Category')}</span>
            <span className="mr-3 ml-3">/</span>
            <span className="flex items-center ">{I18n.get('Asset/ContractLibraryname')}</span>
           </span>
        </span>,
        class: 'flex l:hidden w-7/12 items-center ',
        twoLinesEllipses: true,
      },
      {
        text: <>{I18n.get('ResourceType')}</>,
        class: 'hidden xl:hidden w-2/12 ',
        ellipsis: false,
      },
      {
        text: <>{I18n.get('UploadDate')}</>,
        class: 'flex l:hidden w-4/12 s:w-3/12 text-left items-center',
        ellipsis: false,
      },

      // desktop
      {
        text: <>{I18n.get('DocumentName')}</>,
        class: 'hidden l:flex w-3/12 ',
        twoLinesEllipses: true,
      },
      {
        text: <>{I18n.get('Category')}</>,
        class: 'hidden l:flex w-2/12  break-all ',
        ellipsis: false,
        twoLinesEllipses: true,
      },
      {
        text: <>{I18n.get('Asset/ContractLibraryname')}</>,
        class: 'hidden l:flex w-2/12 ',
        twoLinesEllipses: true,
      },
      {
        text: <>{I18n.get('ResourceType')}</>,
        class: 'hidden xl:flex w-2/12 ',
        ellipsis: false,
      },
      {
        text: <>{I18n.get('UploadDate')}</>,
        class: 'hidden l:flex w-2/12 text-left',
        ellipsis: false,
      },

      // desktop & mobile
      { text: <></>, class: 'w-2/12 l:w-1/12 flex ' }

    ]

    tableProps.tr = latestDocs.map((docItem) => {
      return {
        trOnClick:()=> {
          let deviceDetailsUrl = app_url_documents + '/org_' + organizationName + '/library/' + docItem.library + '/f/' + docItem.fileId;
          window.location.href = deviceDetailsUrl;
        },
        cells:
        [
          // mobile
          { text: 
            <div className="flex flex-col">
              <span className="font-semibold">{docItem.title}</span>
              <span>{ (state.language !== 'en')?  docItem.category.titleGerman  : docItem.category.titleEnglish }</span>
              <span>{ docItem.libraryLabel}</span>
            </div>, 
            textPlain: docItem.title, 
            class: 'flex l:hidden w-7/12 hover:cursor-pointer' },
          //{ text: <>{ (state.language !== 'en')?  docItem.category.titleGerman  : docItem.category.titleEnglish }</>, textPlain: ( (state.language !== 'en')?  docItem.category.titleGerman  : docItem.category.titleEnglish ) , 
          //  class: 'hidden l:hidden text-left items-center w-2/12 hover:cursor-pointer' },
          //{ text: <>{ docItem.libraryLabel}</>, textPlain: docItem.libraryLabel, 
          //  class: 'hidden l:hidden text-left items-center w-2/12 hover:cursor-pointer' },
          //{ text: <>{ docItem.resourceType }</>, textPlain: docItem.resourceType, 
          //  class: 'hidden xl:hidden text-left items-center w-2/12 hover:cursor-pointer whitespace-nowrap' },
          { text: <>{ moment(docItem.uploadedAt).format('DD.MM.YYYY') }</>, textPlain: moment(docItem.uploadedAt).format('DD.MM.YYYY'), 
            class: 'flex l:hidden text-left items-center w-4/12 s:w-3/12 hover:cursor-pointer whitespace-nowrap' },
          // desktop
          { text: <>{docItem.title}</>, textPlain: docItem.title, 
            class: 'hidden l:flex w-3/12 hover:cursor-pointer' },
          { text: <>{ (state.language !== 'en')?  docItem.category.titleGerman  : docItem.category.titleEnglish }</>, textPlain: ( (state.language !== 'en')?  docItem.category.titleGerman  : docItem.category.titleEnglish ) , 
            class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer' },
          { text: <>{ docItem.libraryLabel}</>, textPlain: docItem.libraryLabel, 
            class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer' },
          { text: <>{ docItem.resourceType }</>, textPlain: docItem.resourceType, 
            class: 'hidden xl:flex text-left items-center w-2/12 hover:cursor-pointer whitespace-nowrap' },
          { text: <>{ moment(docItem.uploadedAt).format('DD.MM.YYYY') }</>, textPlain: moment(docItem.uploadedAt).format('DD.MM.YYYY'), 
            class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer whitespace-nowrap' },
          { text: <><svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></>, 
            class: 'flex l:flex w-2/12 l:w-1/12 justify-center hover:cursor-pointer' }
        ],
      }
    })

    if(latestDocs.length <= 0 ){
        tableProps.noDataLabel = I18n.get('noRecentDocuments')
    }

    setTableProps(tableProps)
  }, [state.language,latestDocs]);

  return (
  <>
    <div className="flex justify-between items-center mb-5 mt-10 " data-testid="test_document_latest_table">
      <h1>{I18n.get('Latest Documents')}</h1>
    </div>
    <Spin spinning={isLoading}>
      <div ref={topTableElementRef} className="idTableInstallations">
        <TableMain tableContent={tableProps} />
      </div>
    </Spin>
  </>)

  
}
export default DocumentsLatestTable;