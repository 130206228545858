import { I18n } from "@aws-amplify/core";
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { strings as stringLocal} from './helpersLocalization';
import { API_URL } from './../../utils/consts';
import { mergeDeepRight } from 'ramda';
import { GlobalStateInterfaceApp } from "./globalStateProviderApp";
import { InstalltionsResponse } from "./../../interfaces/Installation";
import { getLangByLocalStorage } from "./../../utils/helpers";
import { customFetchWithAuthHeader } from "../../utils/helpersFetch";
import { ContractFilterEnum } from "./../../interfaces/ContractFilters";


I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)



export const fetchContractIntallations = async (page:number, pageSize:number, sortBy:string,contractId:string,accessToken:string): Promise<InstalltionsResponse> => {

  let allInstallations = undefined
  let sortByParam = (!sortBy || sortBy === '')? '' : '&sortBy='+encodeURIComponent(sortBy)
  const response = await customFetchWithAuthHeader(API_URL + '/api/v1/assets?contract='+contractId+'&take='+pageSize.toString()+'&skip='+((page-1)*pageSize).toString()+''+sortByParam, {
    method: 'GET',
  },accessToken);
  allInstallations = await response.json()
  
  return allInstallations;
};

export const fetchUserContracts = async (
  page:number = 1,
  pageSize:number,
  searchText:string = '',
  sortBy:string = '',
  filterBy:string = '',
  accessToken:string,
  controllerRef?: React.MutableRefObject<AbortController | null | undefined>): Promise<any> => {
  let allInstallations = undefined
  let sortByParam = (!sortBy || sortBy === '')? '' : '&sortBy='+encodeURIComponent(sortBy)
  let filterByParam = (!filterBy || filterBy === '' || filterBy === ContractFilterEnum.All)? '' : '&filterBy='+encodeURIComponent(filterBy)
  const response = await customFetchWithAuthHeader(API_URL + '/api/v1/contracts?take='+pageSize.toString()+'&skip='+((page-1)*pageSize).toString()+'&search='+encodeURIComponent(searchText)+sortByParam+filterByParam, {
    method: 'GET',
  },accessToken, controllerRef);
  allInstallations = await response.json()
  
  return allInstallations;
};

export const setMaxContractsCount = async (setState: React.Dispatch<React.SetStateAction<any | null>>, accessToken: string)  => {
  const librariesResponse = await fetchUserContracts(1, 1, '', '', '', accessToken);
  let dataGlobal: Partial<GlobalStateInterfaceApp> = {
    maxContractsCount: librariesResponse.totalCount
  }
  setState((prev: any) => ({ ...prev, ...dataGlobal }))
};

