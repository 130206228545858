import { I18n } from "@aws-amplify/core";
import { useEffect, useRef, useState } from "react";
import { useGlobalState } from "../../../utils/globalStateProvider";
import React from "react";
import { DateRange } from "../../../interfaces/DateRange";
import { fetchRunsOverTime, setOrReportError } from "../../../utils/helpersFetch";
import { useAuth0 } from "@auth0/auth0-react";
import { RunsOverTimeResponse } from "../../../interfaces/RunsOverTime";
import TurnsChart from "./TurnsChart";
import { Installation } from "../../../interfaces/Installation";
import moment from "moment";
import { getAuth0Token } from './../../../utils/helpers';

const TurnsChartMemo = React.memo(TurnsChart)

const TurnsChartWrapper = (props: { compareSelected: Installation[] | undefined; dateRange:DateRange }) => {

  const { state, setState } = useGlobalState()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [runsOverTime, setRunsOverTime] = useState<RunsOverTimeResponse[] >([]);
  const { getAccessTokenSilently } = useAuth0()
  const controllerRef = useRef<AbortController[]>([])

  for(let i=0;i<=10;i++){
    controllerRef[i] = useRef<AbortController | null>()
  }

  const fetchAllData = async (compareSelected: Installation[],accessToken:string, dateRange?: DateRange) => {

    let interval = 'day' //interval = hour, day, week, month
    if(moment(props.dateRange.endDate).diff(moment(props.dateRange.startDate), 'hours') <= 24){
        interval = 'hour'
    }

    const queryString = dateRange ? `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&interval=`+interval : '';
  
    return Promise.all(
      compareSelected.map((installation,index)=>{return fetchRunsOverTime(installation.iotBoxes[0], queryString,accessToken,controllerRef[index])})
    );
  };

  const fetchData = async () => {
    setIsLoading(true)

    if (props.compareSelected) {
      const runsOverTimeList = await fetchAllData(props.compareSelected, await getAuth0Token(getAccessTokenSilently), props.dateRange)
      setOrReportError(setRunsOverTime, runsOverTimeList)      
    }
    
    setIsLoading(false);
  }

  useEffect(() => {
   //console.log(isLoading)
  }, [ isLoading]);

  useEffect(() => {
    I18n.setLanguage(state.language)
  }, [ state.language]);

  useEffect(() => {
    fetchData()
  }, [ props.dateRange,props.compareSelected]);


  return (
    <div className="pt-14 m:pt-28 pb-0 m:pb-14">
      <h2 className="pb-3 uppercase">{I18n.get('runsOverTime')}</h2>
      <div className="pb-7 text-sm m:text-base xl:text-lg">{I18n.get('sublineRunsOverTime')}</div>
      <TurnsChartMemo response={runsOverTime} installations={props.compareSelected} isLoading={isLoading} />
    </div>
  );
};
export default TurnsChartWrapper;