import { I18n } from "@aws-amplify/core";
import { strings as stringGlobal } from "../../utils/globalLocalization";
import { strings as stringLocal } from "./helpersLocalization";
import { mergeDeepRight } from "ramda";
import { getLangByLocalStorage } from "./../../utils/helpers";
import { AssetStatisticsResponse } from "./../../interfaces/AssetStatistics";
import { API_URL } from "./../../utils/consts";
import { customFetchWithAuthHeader } from "./../../utils/helpersFetch";
import { ContractStatisticsResponse } from "./../../interfaces/ContractStatistics";

I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
const lang = getLangByLocalStorage();
I18n.setLanguage(lang);

export const fetchAssetStatistics = async (
  accessToken: string
): Promise<AssetStatisticsResponse> => {
  try {
    const url = `${API_URL}/api/v1/assets/statistics`;
    const response = await customFetchWithAuthHeader(
      url,
      { method: "GET" },
      accessToken
    );

    if (response.status === 200) {
      const data = await response.json();
      return data.assetStatistics;
    } else {
      throw new Error();
    }
  } catch (error: any) {
    console.log(error);
    return { errorMsg: error.toString() };
  }
};

export const fetchContractStatistics = async (
  accessToken: string
): Promise<ContractStatisticsResponse> => {
  try {
    const url = `${API_URL}/api/v1/contracts/statistics`;
    const response = await customFetchWithAuthHeader(
      url,
      { method: "GET" },
      accessToken
    );

    if (response.status === 200) {
      const data = await response.json();
      return data.contractStatistics;
    } else {
      throw new Error();
    }
  } catch (error: any) {
    console.log(error);
    return { errorMsg: error.toString() };
  }
};
