import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { strings as stringLocal } from './WelcomeLocalization';
import { I18n } from '@aws-amplify/core';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TableIncidents from '../components/TableIncidents';
import moment from 'moment';
import { mergeDeepRight } from 'ramda';
import { getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, setDocumentTitle } from '../../utils/helpers';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { useGlobalStateApp } from '../utils/globalStateProviderApp';
import { setMaxIncidentsCount } from '../utils/helpersFetch';
import { Spin } from 'antd';
import { getIcon } from '../../utils/icons';

const Dashboard = () => {

  const { isAuthenticated, user } = useAuth0();
  const { state, setState } = useGlobalState();
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));

    if (!state.language) {
      let lang = getLangByLocalStorage()
      let dataGlobal: Partial<GlobalStateInterface> = { language: lang ? lang : 'de' }
      setState((prev) => ({ ...prev, ...dataGlobal }));
      setDocumentTitle()
    }

    const asyncInit = async () => {
      let accessToken = await getAuth0Token(getAccessTokenSilently);

      await setMaxIncidentsCount(setStateApp, accessToken)
    }
    asyncInit()
  }, []);

  useEffect(() => {
    if (state.language) {
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  useEffect(() => {
    if (state.organizationName && document.location.pathname === '/welcome') {
      navigate('/org_' + state.organizationName + '/welcome')
    }
  }, [state.organizationName]);
  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      <Header />
      <section className="bg-blueLight welcome">
        <div className=" pt-7 xxl:pt-14 pb-11 xxl:pb-14">
          <div className="flex items-center">
            <div className="  w-[55px] h-[55px] rounded-lg bg-white flex items-center justify-center">
              {getIcon({ type: 'Incidents', ownClassName: ' fill-blueLight w-8' })}
            </div>
            <div className=" ml-4 m:ml-6 text-base m:text-xl text-white font-bold ">{I18n.get('nameApp')}</div>
          </div>
          <h1 className="text-white mt-5">{I18n.get('welcomeMessage')} {(getAuth0IsAuthenticatedStatus(isAuthenticated) && user && user.email && <>{user.name}</>)}</h1>
          <p className=" mt-1 xxl:mt-0 text-white text-lbase m:text-l xl:text-xl xxl:text-4xl">{I18n.get('welcomeMessageFollow').replace('###number###', stateApp.maxCasesCount ? stateApp.maxCasesCount: 0)}</p>
        </div>
      </section>
      <section className="Content">
        <div className="flex flex-col pb-10">
          <TableIncidents />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Dashboard