import { strings as stringGlobal } from '../../../utils/globalLocalization'
import { strings as stringLocal } from '../../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Pagination, Spin, Select, Badge } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { GlobalStateInterface, useGlobalState } from "../../../utils/globalStateProvider"
import { useEffect } from 'react'
import { getAuth0Token, getLangByLocalStorage, isTestEnv, scrollTop } from '../../../utils/helpers'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import SearchHistory from '../../../components/SearchHistory'
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../../utils/globalStateProviderApp'
import TableMain, { TableProps } from '../../../components/molecules/TableMain'
import { fetchUserContracts } from './../../utils/helpersFetch'
import { Contract } from '../../../documents/interfaces/Contract'
import { mergeDeepRight } from 'ramda';
import { ContractFilter, ContractFilterEnum, contractFilterData } from '../../../interfaces/ContractFilters';
import { getIcon } from '../../../utils/icons'
import OverlayWrapper from '../../../components/OverlayWrapper'
import MobileSearchOverlay from '../../../devices/components/welcome/MobileSearchOverlay'
import MobileFilterOverlay from '../../../devices/components/welcome/MobileFilterOverlay'

I18n.putVocabularies(mergeDeepRight(stringLocal, stringGlobal));
const lang = getLangByLocalStorage()
I18n.setLanguage(lang)

moment.locale(lang ? lang : 'de');

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface PrevRef {
  searchTerm: string | undefined
  sortBy: string | undefined
  filterBy: ContractFilter | undefined
}

const TableContracts = (props: {}) => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const navigate = useNavigate();

  const query = useQuery();

  const [pageUrlParam, setPageUrlParam] = React.useState<number | undefined>()
  const [searchValueUrlParam, setSearchValueUrlParam] = React.useState<string>('')
  const [sortByUrlParam, setSortByUrlParam] = React.useState<string | undefined>()
  const [filterByUrlParam, setFilterByUrlParam] = React.useState<ContractFilter>(ContractFilterEnum.All)
  const [totalNumberContracts, setTotalNumberContracts] = React.useState<number>(0)
  const [sortedInfo, setSortedInfo] = React.useState({ columnKey: '', order: '' })
  const prevRef = useRef<PrevRef>({ searchTerm: undefined, sortBy: undefined, filterBy: undefined });
  const topTableElementRef = useRef<null | HTMLTableElement>(null);
  const [tableProps, setTableProps] = useState<TableProps | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [showSearchDialog, setShowSearchDialog] = React.useState<boolean>(false)
  const [showFilterDialog, setShowFilterDialog] = React.useState<boolean>(false)
  const controllerRef = useRef<AbortController | null>();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const filterByParam = query.get("filterBy") as ContractFilterEnum
    if (filterByParam !== undefined && filterByParam !== null && Object.values(ContractFilterEnum).includes(filterByParam)) {
      setFilterByUrlParam(filterByParam)
    } else {
      setFilterByUrlParam(ContractFilterEnum.All)
    }
  }, []);

  useEffect(() => {
    setPageUrlParam(1)
  }, [filterByUrlParam]);

  useEffect(() => {
    I18n.setLanguage(state.language)
    moment.locale(state.language);
  }, [state.language]);

  const sortChange = (sorter: { columnKey: string, order: string }) => {

    if (sorter.order === '') {
      setSortedInfo({ columnKey: '', order: '' })
    }
    else {
      setSortedInfo(sorter)
    }
    let sortBy = ''
    if (sorter.columnKey && sorter.order) {
      sortBy = sorter.columnKey + ':' + (sorter.order === 'asc' ? 'asc' : 'desc')
    }
    setPageUrlParam(1)
    setSortByUrlParam(sortBy)
  };

  useEffect(() => {
    if (!searchValueUrlParam && !pageUrlParam && !sortByUrlParam && !filterByUrlParam) {

      if (stateApp.search) {
        // case hits if components gets loaded > like coming from installation detail page
        // 2 cases can happen / Back button pressd or logo in header 
        if (query.get('back') === 'true') {
          const pageParam = query.get("page") ? query.get("page") : '1'
          if (pageParam !== null) {
            setPageUrlParam(parseInt(pageParam))
          }
          else {
            setPageUrlParam(1)
          }

          const searchParam = query.get("search")
          if (searchParam !== undefined && searchParam !== null) {
            setSearchValueUrlParam(searchParam)
          }
          else {
            setSearchValueUrlParam('')
          }

          const sortByParam = query.get("sortBy")
          if (sortByParam !== undefined && sortByParam !== null) {
            const arrSplit = sortByParam.split(':')
            if (arrSplit.length === 2) {
              setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
              setSortByUrlParam(sortByParam)
            }
          }
          else {
            setSortByUrlParam('')
          }

          const filterByParam = query.get("filterBy") as ContractFilterEnum
          if (filterByParam !== undefined && filterByParam !== null && Object.values(ContractFilterEnum).includes(filterByParam)) {
            setFilterByUrlParam(filterByParam)
          } else {
            setFilterByUrlParam(ContractFilterEnum.All)
          }

          return
        }

        // logo clicked case
        setPageUrlParam(1)
        setSearchValueUrlParam('')
        setSortByUrlParam('')
        setFilterByUrlParam(ContractFilterEnum.All)
      }
      else {
        // case hits if url gets opened directly by browser
        // init params from saved state
        const pageParam = query.get("page") ? query.get("page") : '1'
        if (pageParam !== null) {
          setPageUrlParam(parseInt(pageParam))
        }
        else {
          setPageUrlParam(1)
        }

        const searchParam = query.get("search")
        if (searchParam !== undefined && searchParam !== null) {
          setSearchValueUrlParam(searchParam)
        }
        else {
          setSearchValueUrlParam('')
        }

        const sortByParam = query.get("sortBy")
        if (sortByParam !== undefined && sortByParam !== null) {
          const arrSplit = sortByParam.split(':')
          if (arrSplit.length === 2) {
            setSortedInfo({ columnKey: arrSplit[0], order: arrSplit[1] })
            setSortByUrlParam(sortByParam)
          }
        }
        else {
          setSortByUrlParam('')
        }

        const filterByParam = query.get("filterBy") as ContractFilterEnum
        if (filterByParam !== undefined && filterByParam !== null && Object.values(ContractFilterEnum).includes(filterByParam)) {
          setFilterByUrlParam(filterByParam)
        } else {
          setFilterByUrlParam(ContractFilterEnum.All)
        }
      }
      return
    }

    const urlParams = '?'
      + 'page=' + (pageUrlParam ? pageUrlParam : 1)
      + '&search=' + (searchValueUrlParam ? encodeURIComponent(searchValueUrlParam) : '')
      + '&sortBy=' + (sortByUrlParam ? sortByUrlParam : '')
      + '&filterBy=' + (filterByUrlParam ? filterByUrlParam : '')

    const setBookableUrlTo = async () => {
      let goTo = '/org_' + state.organizationName + '/welcome' + urlParams
      if (window.location.href !== goTo) {
        navigate(goTo)
      }
    }
    if (state.organizationName) {
      setBookableUrlTo()
    }

    // storing url for having it for the backButton at the installation detail list
    const dataGlobal: Partial<GlobalStateInterfaceApp> = {
      search: {
        page: (pageUrlParam ? pageUrlParam : 1),
        searchterm: (searchValueUrlParam ? searchValueUrlParam : ''),
        sortBy: (sortByUrlParam ? sortByUrlParam : ''),
        filterBy: (filterByUrlParam ? filterByUrlParam : ''),
        parametersUpdatedByUrl: true
      }
    }
    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))

  }, [pageUrlParam, searchValueUrlParam, sortByUrlParam, filterByUrlParam, state.organizationName]);

  useEffect(() => {
    BuildItemList()
    setTotalNumberContracts(stateApp.contractsCount ? stateApp.contractsCount : 0)

  }, [state.language, stateApp.contracts]);

  useEffect(() => {
    // on reload getInstallations gets called with uninitialised parameters, so looking to parametersUpdatedByUrl
    if (stateApp.search?.parametersUpdatedByUrl) {
      loadContracts()
    }
    prevRef.current.searchTerm = stateApp.search?.searchterm
    prevRef.current.sortBy = stateApp.search?.sortBy
    prevRef.current.filterBy = stateApp.search?.filterBy
  }, [stateApp.search?.page, stateApp.search?.searchterm, stateApp.search?.sortBy, stateApp.search?.filterBy]);

  async function loadContracts() {
    setIsLoading(true)
    const accessToken = await getAuth0Token(getAccessTokenSilently)
    try {
      let contracts = await fetchUserContracts(stateApp.search?.page, 10, stateApp.search?.searchterm, stateApp.search?.sortBy, stateApp.search?.filterBy, accessToken, controllerRef)
      
      let dataGlobal: Partial<GlobalStateInterfaceApp> = {
        contracts: contracts.contracts,
        contractsCount: contracts.totalCount,
      }

      setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))
      setIsLoading(false)
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error
      }
    }
  }

  const getSortOrder = (columnKey: string): string => {
    if (sortedInfo.columnKey === columnKey) {
      if (sortedInfo.order === 'asc') {
        return 'desc'
      }
      else if (sortedInfo.order === 'desc') {
        return ''
      }
      else {
        return 'asc'
      }
    }
    else {
      return 'asc'
    }
  }

  const BuildItemList = () => {

    let tableP: TableProps = { th: [], tr: [], noDataLabel: 'Dieser Ordner ist leer', height: 'unset', thead: 'sticky-documents-detail' }

    tableP.th = [

      // mobile
      {
        text: <>{I18n.get('Active')}{getSortVisualisation('status')}</>,
        class: 'flex l:hidden w-4/12 items-center pb-0  ',
        ellipsis: false,
        onCellClick: () => sortChange({ columnKey: 'status', order: getSortOrder('status') })
      },
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'recordType', order: getSortOrder('recordType') })} >{I18n.get('Type')}{getSortVisualisation('recordType')}</span>
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'contractNumber', order: getSortOrder('contractNumber') })} ><span className=" mr-3">/</span>{I18n.get('Number')}{getSortVisualisation('contractNumber')}</span>
        </span>,
        class: 'flex l:hidden w-4/12 items-center pb-0',
        ellipsis: true,
      },
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'startDate', order: getSortOrder('startDate') })} >{I18n.get('Startdate')}{getSortVisualisation('startDate')}</span>
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'endDate', order: getSortOrder('endDate') })} ><span className=" mr-3">/</span>{I18n.get('Enddate')}{getSortVisualisation('endDate')}</span>
        </span>,
        class: 'flex l:hidden items-center pb-0 text-left w-4/12 ',
      },

      // desktop
      {
        text: <>{I18n.get('Active')}{getSortVisualisation('status')}</>,
        class: 'hidden l:flex w-2/12 ',
        ellipsis: false,
        onCellClick: () => sortChange({ columnKey: 'status', order: getSortOrder('status') })
      },
      {
        text: <>{I18n.get('Number')}{getSortVisualisation('contractNumber')}</>,
        class: 'hidden l:flex w-2/12 ',
        ellipsis: false,
        onCellClick: () => sortChange({ columnKey: 'contractNumber', order: getSortOrder('contractNumber') })
      },
      {
        text: <>{I18n.get('Type')}{getSortVisualisation('recordType')}</>,
        class: 'hidden l:flex w-2/12 ',
        ellipsis: false,
        onCellClick: () => sortChange({ columnKey: 'recordType', order: getSortOrder('recordType') })
      },
      {
        text: <span className="flex flex-col ">
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'startDate', order: getSortOrder('startDate') })} >{I18n.get('Startdate')}{getSortVisualisation('startDate')}</span>
          <span className="flex items-center" onClick={() => sortChange({ columnKey: 'endDate', order: getSortOrder('endDate') })} ><span className=" mr-3">/</span>{I18n.get('Enddate')}{getSortVisualisation('endDate')}</span>
        </span>,
        class: 'hidden l:flex w-2/12 ',
      },
      {
        text: <>{I18n.get('AccountName')}{getSortVisualisation('accountName')}</>,
        class: 'hidden l:flex w-2/12 ',
        twoLinesEllipses: true,
        onCellClick: () => sortChange({ columnKey: 'accountName', order: getSortOrder('accountName') })
      },
      {
        text: <>{I18n.get('Costs')}{getSortVisualisation('amount')}</>,
        class: 'hidden l:flex w-2/12 ',
        ellipsis: false,
        onCellClick: () => sortChange({ columnKey: 'amount', order: getSortOrder('amount') })
      },
      { text: <></>, class: 'hidden l:flex w-1/12 ' }
    ]

    if (stateApp.contracts) {
      tableP.tr = stateApp.contracts.map((itemContract, index) => {
        let dataRow: TableProps["tr"][0] = {
          cells: [

            // mobile
            { text: <>{I18n.get('ContractStatus_' + itemContract.status)}</>, class: 'flex l:hidden text-left items-center w-4/12 hover:cursor-pointer ' },
            { text: <>{itemContract.recordType ? I18n.get('ContractType' + itemContract.recordType) : I18n.get('UnknownContractType')}<br />{itemContract.contractNumber}</>, class: 'flex l:hidden w-4/12 hover:cursor-pointer' },
            { text: <>{moment(itemContract.startDate).format('DD.MM.YYYY')}<br />{moment(itemContract.endDate).format('DD.MM.YYYY')}</>, class: 'flex l:hidden text-left items-center w-4/12 hover:cursor-pointer ' },

            // desktop
            { text: <>{I18n.get('ContractStatus_' + itemContract.status)}</>, class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer ' },
            { text: <>{itemContract.contractNumber}</>, class: 'hidden l:flex w-2/12 hover:cursor-pointer' },
            { text: <>{itemContract.recordType ? I18n.get('ContractType' + itemContract.recordType) : I18n.get('UnknownContractType')}</>, class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer' },
            { text: <>{moment(itemContract.startDate).format('DD.MM.YYYY')} / {moment(itemContract.endDate).format('DD.MM.YYYY')}</>, class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer' },
            { text: <>{itemContract.accountName}</>, textPlain: itemContract.accountName, class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer' },
            { text: <>{itemContract.amount ? itemContract.amount.toLocaleString('de-DE') + ' €' : ''}</>, class: 'hidden l:flex text-left items-center w-2/12 hover:cursor-pointer whitespace-nowrap' },
            { text: <><svg className="mr-14" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 17L9 9L1 1" stroke="#10265A" strokeWidth="2" /></svg></>, class: 'hidden l:flex flex-col w-1/12 justify-center hover:cursor-pointer' }
          ], trOnClick: (e) => { onRowClicked(itemContract) }
        }

        return dataRow
      })
    }

    setTableProps(tableP)

  }

  const onRowClicked = (contract: Contract) => {
    scrollTop()
    navigate('/org_' + state.organizationName + '/contract/' + contract._id)
  }

  const getSortVisualisation = (columnKey: string) => {

    if (columnKey === sortedInfo.columnKey) {
      if (sortedInfo.order === 'asc') {
        return <CaretUpOutlined className="ml-4" style={{ 'color': 'white' }} />
      }
      else {
        return <CaretDownOutlined className="ml-4" style={{ 'color': 'white' }} />
      }
    }
    return <CaretUpOutlined className="ml-4" style={{ 'color': 'grey' }} />
  }

  const handleSearchChange = (searchTerm: string) => {
    if (searchValueUrlParam !== searchTerm) {
      setPageUrlParam(1)
    }
    setSearchValueUrlParam(searchTerm)
  }

  return (
    <div className="idTableContracts flex flex-col mb-24">
      <div className=" mt-12 m:mt-24 flex justify-between flex-col m:flex-row">
        <h1>{I18n.get('overview')}</h1>
      </div>

      {showSearchDialog && <OverlayWrapper title={I18n.get('Search')} closerFunction={()=>setShowSearchDialog(false)} children={<MobileSearchOverlay closerFunction={()=>setShowSearchDialog(false)} handleSearchChange={handleSearchChange}  />} /> }
      {showFilterDialog && <OverlayWrapper title={I18n.get('Filter')} closerFunction={()=>setShowFilterDialog(false)} children={<MobileFilterOverlay closerFunction={()=>setShowFilterDialog(false)} handleChange={setFilterByUrlParam} filterByUrlParam={filterByUrlParam} filterData={contractFilterData} />} /> }


      <div className='flex flex-col m:flex-row md:items-center justify-between pt-[50px] m:pt-10 pb-[26px] m:pb-10 gap-4 m:gap-10'>
          <div className="w-full flex m:hidden  ">
            <span className=" mr-[78px]" onClick={()=>setShowSearchDialog(true)}>
              <Badge count={(searchValueUrlParam?.trim().length>0)?1:0} size='default' overflowCount={9}>
                {getIcon({type:'Search',ownClassName:'fill-blueDark h-6 cursor-pointer'})}
              </Badge>
            </span>
            <span onClick={()=>setShowFilterDialog(true)}>
              <Badge count={(filterByUrlParam !== ContractFilterEnum.All)?1:0} size='default' overflowCount={9}>
                {getIcon({type:'Filter',ownClassName:'fill-blueDark h-6 cursor-pointer'})}
              </Badge>
            </span>
          </div>
          <div className="w-full hidden m:block m:w-96 ">
            <SearchHistory setSearchValue={handleSearchChange} />
          </div>

          <div className='hidden m:flex mt-6 m:mt-0 gap-2 flex-col m:flex-row m:items-center m:justify-center'>
            <p className='text-nowrap'>{I18n.get('filterBy')}</p>
            <div className='w-full m:w-96'>
              <Select
                value={filterByUrlParam ? filterByUrlParam : ContractFilterEnum.All}
                onChange={(value) => { setFilterByUrlParam(value) }}
                options={contractFilterData.map((item) => { return { label: I18n.get(item), value: item } })}
                size='large'
                style={{
                  width: '100%',
                  color: '#102A68'
                }}
              />
            </div>
          </div>
      </div>

      <Spin spinning={isLoading}>
        <TableMain tableContent={tableProps} />
        <div className="h-24 bg-blueBaby flex justify-center items-center">
          <Pagination current={stateApp.search?.page ? stateApp.search?.page : 1} pageSize={10} showSizeChanger={false} total={totalNumberContracts} onChange={(page, pageSize) => {
            setPageUrlParam(page);
            window.scrollTo({
              top: topTableElementRef?.current?.offsetTop ? (topTableElementRef?.current?.offsetTop - 130) : 0,
              behavior: 'smooth',
            });
          }} />
        </div>
      </Spin>
    </div>
  );
};

export default TableContracts;
