import { strings as stringGlobal } from '../utils/globalLocalization'
import { I18n } from '@aws-amplify/core'
import { getLangByLocalStorage } from '../utils/helpers';
import React from 'react';

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

const Callback = () => {
  return (
    <div className="flex w-full h-screen items-center justify-center flex-col">
        <img src="/assets/images/organizations/aufzughelden.png" />
        <br/>
        <br/>
        {I18n.get('messageRedirect')}
    </div>
  );
};

export default Callback;
