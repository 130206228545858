export const strings = {
  en: {
    FabricationContractNumberFirst: 'Fabrication-',
    FabricationContractNumberSecond: 'Contractnumber',
    UndefinedIdentifier:'Name',
  },
  de: {
    FabricationContractNumberFirst: 'Fabrikations-',
    FabricationContractNumberSecond: 'Vertragsnummer',
    UndefinedIdentifier:'Name',
  },
};