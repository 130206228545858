import { strings as stringLocal} from '../utils/globalLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { mergeDeepRight } from 'ramda';
import { USER_CHOICE_LANGUAGE, app_url_documents } from '../../utils/consts';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderDetail from '../../components/HeaderDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserHasPermissionsForDevices, getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, setDocumentTitle } from '../../utils/helpers';
import { useGlobalStateApp } from '../utils/globalStateProviderApp';
import { fetchIncidentsIntallationsCoords, getCaseById } from '../utils/helpersFetch';
import { Case, CaseAsset } from '../interfaces/Case';
import { fetchInstallation } from '../../utils/helpersFetch';
import { Installation, InstallationCoords } from '../../interfaces/Installation';
import InformationDetails, { getInformationsDetailsDataElement } from '../../components/InformationDetails';
import TableInstallations from '../components/TableInstallations';
import { Spin } from 'antd';
const arrow = <svg className="h-4 fill-blueDark group-hover:fill-white  mb-2" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6043 0.791992L17.5481 2.84824L24.2272 9.54199H0.645996V12.4587H24.2272L17.5335 19.1524L19.6043 21.2087L29.8127 11.0003L19.6043 0.791992Z" /></svg>


const IncidentsDetail = () => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isAuthenticated ,logout} = useAuth0()
  const { getAccessTokenSilently } = useAuth0();
  const [dynHeadeContent, setDynHeadeContent] = useState<ReactNode>(<></>);
  const [dynHeadeContentMobile, setDynHeadeContentMobile] = useState<ReactNode>(<></>);
  const [currentCase, setCurrentCase] = useState<Case|null>(null);
  const [currentInstallation, setCurrentInstallation] = useState<Installation|null>(null);
  const [currentInstallationCoords, setCurrentInstallationCoords] = useState<InstallationCoords[]>([]);
  const [incidentData, setIncidentData] = useState<JSX.Element[]>([]);
  const [userHasDevicesPermissions, setUserHasDevicesPermissions] = useState<boolean>(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  let { incidentId,organizationName } = useParams();

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let query =  new URLSearchParams(search);
      let langDefaulvalue = query.get("lang")
      let lang:string|null=null
      if(langDefaulvalue){
        lang = langDefaulvalue
        localStorage.setItem(USER_CHOICE_LANGUAGE, lang);
      }
      else{
        lang = getLangByLocalStorage()
      }
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
      setDocumentTitle()
    }

    const setPermissions = async () => {
      setUserHasDevicesPermissions(await UserHasPermissionsForDevices(await getAuth0Token(getAccessTokenSilently)))
    }
    setPermissions()

  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  const loadInstallation = async (caseAssets: CaseAsset[]) => {
    if(caseAssets && caseAssets.length === 0) return;

    const firstAsset = caseAssets[0];

    if(!firstAsset.assetId) return;

    let installationResponse = await fetchInstallation(firstAsset.assetId, await getAuth0Token(getAccessTokenSilently))
    
    if(installationResponse.status === 200){
      const installation = await installationResponse.json()
      
      setCurrentInstallation(installation);
    }
    else{
      const resonseMessage = await installationResponse.json()
      console.log(resonseMessage)
      //navigate('/404')
    }
  }

  useEffect(() => {

    if(currentCase){
      loadInstallation(currentCase.assets)
      setIncidentData(getIncidentsData(currentCase))

      const loadCoords = async () => {

        let assetIDs = currentCase.assets.map(item => { return item.assetId })
        //let assetIDs = ["63ea025d99bf4a22da239754","63ea025d99bf4a22da239766","63ea025d99bf4a22da239760","63ea025d99bf4a22da239794","63ea025e99bf4a22da2397fc","63ea025e99bf4a22da239808","63ea025e99bf4a22da23980e","63ea025e99bf4a22da239814","63ea025e99bf4a22da23981a","63ea025e99bf4a22da239820","63ea025e99bf4a22da239826","63ea025e99bf4a22da239832","63ea025e99bf4a22da239838","63ea025e99bf4a22da23983e","63ea025e99bf4a22da239844","63ea025e99bf4a22da23995d","63ea025f99bf4a22da239a27","63ea026099bf4a22da239c0d","63ea026099bf4a22da239c3a","63ea026199bf4a22da239ca8","63ea025d99bf4a22da239772","63ea026799bf4a22da23a6e2","63c01f9fb633ea71bbea5b6c","63c01f9fb633ea71bbea5b78","63ea025d99bf4a22da23973f","63ea025d99bf4a22da23976c","63ea025d99bf4a22da23979a","63ea025e99bf4a22da239802","63ea025e99bf4a22da23982c","63ea025e99bf4a22da23996f","63ea026099bf4a22da239c1c","63ea026099bf4a22da239c2e","63ea026099bf4a22da239c75","64513059ef71389a2914fa9f","63ea026799bf4a22da23a6e8","63c01fa1b633ea71bbea601e","63c01fa2b633ea71bbea61b9","63c01fa0b633ea71bbea5e2f","63ea026799bf4a22da23a59f","63ea025d99bf4a22da239739","64994df0ed920ed815d9e6b5","64994e73ed920ed815d9e6cc","63c01f9fb633ea71bbea5b72","63ea026099bf4a22da239c22","64995af9ed920ed815da7d4a","6499744ced920ed815db38b0","6499744ced920ed815db38bc","63c01f9fb633ea71bbea5b66","63ea026099bf4a22da239c6f","6499744ced920ed815db38b6","63c01fa1b633ea71bbea60fc","63c01fa1b633ea71bbea6102","63ea026099bf4a22da239bfb","63ea026099bf4a22da239c01","63ea026099bf4a22da239c07","63ea026099bf4a22da239c16","63ea026099bf4a22da239c28","63ea026099bf4a22da239c34","649970b6ed920ed815dacbe4","6499744ced920ed815db38aa","63ea025d99bf4a22da23975a","63ea025d99bf4a22da239745"]
        let coord = await fetchIncidentsIntallationsCoords(1,100,'',assetIDs,await getAuth0Token(getAccessTokenSilently))
        setCurrentInstallationCoords(coord.assets)
      }
      loadCoords()

    }

  }, [currentCase]);

  useEffect(() => {

    if(currentCase){
      setIncidentData(getIncidentsData(currentCase))
    }

    if(currentInstallation){
      setIsLoading(false)
    }
  }, [currentInstallation,currentCase,state.language]);

  useEffect(() => {
    if(incidentId){
      const initCase = async () => {
        if(incidentId){
          let loadedCase = await getCaseById(incidentId,await getAuth0Token(getAccessTokenSilently))
          if(loadedCase){
            setCurrentCase(loadedCase)
          }
          else{
            navigate('/404')
          }
        }
      }
      initCase()
    }
  }, [incidentId]);

  useEffect(() => {
    setDynHeadeContent(<></>
    )
    setDynHeadeContentMobile(<></>)
  }, [state.language]);  
  
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const goBack = async () => {
    scrollTop()
    let urlParams = '?'
    +'page='+(stateApp.search?.page ? stateApp.search?.page : 1)
    +'&search='+(stateApp.search?.searchterm ? encodeURIComponent(stateApp.search.searchterm) : '')
    +'&sortBy='+(stateApp.search?.sortBy ? stateApp.search.sortBy : '')
    +'&back=true'

    navigate('/org_'+state.organizationName+'/welcome'+urlParams)
  }

  const getIncidentsData = (dsCase:Case ) => {

    let arrData:JSX.Element[] = []

    if(dsCase.caseNumber){ arrData.push(getInformationsDetailsDataElement('Casenumber',parseInt(dsCase.caseNumber).toString(),'incidents')) }
    if(dsCase.recordType){ arrData.push(getInformationsDetailsDataElement('RecordType',dsCase.recordType,'incidents')) }
    if(dsCase.contactName){ arrData.push(getInformationsDetailsDataElement('ContactName',dsCase.contactName,'incidents')) }
    if(dsCase.origin){ arrData.push(getInformationsDetailsDataElement('Origin',dsCase.origin,'incidents')) }
    if(dsCase.status){ arrData.push(getInformationsDetailsDataElement('Status',dsCase.status,'incidents')) }
    if(dsCase.createdAt){ arrData.push(getInformationsDetailsDataElement('CreatedAt',moment(dsCase.createdAt).format('DD.MM.YYYY'),'incidents')) }

    return arrData
  }

  return (

    <Spin spinning={isLoading}>
      <div className={(getAuth0IsAuthenticatedStatus(isAuthenticated)) ? '' : 'hidden'}>
        {currentInstallation && currentCase && <HeaderDetail 
          goBack={goBack}
          mode='Incident'
          case={currentCase}
          assetCoords={currentInstallationCoords}
          dynContent={dynHeadeContent}
          dynContentMobile={dynHeadeContentMobile}
          />}
        <section className="Content pb-10">
          {<InformationDetails data={incidentData} headline='Details' />}

          {userHasDevicesPermissions && currentCase && 
            <div className="flex flex-col pt-16 pb-14">
              <h1 className="mb-12">{I18n.get('Assets in incident')}</h1>
              <TableInstallations incident={currentCase} />
            </div>}

          <div className="flex flex-col pt-8 pb-0">
            {currentCase?.subject && <h1>{currentCase.subject}</h1>}
            {currentCase?.description && <div dangerouslySetInnerHTML={{__html: currentCase.description.replace(/(?:\r\n|\r|\n)/g, '<br />')}} ></div>}
          </div>  

        </section>
        <Footer />
      </div>
    </Spin>
  );
};

export default IncidentsDetail;
