export const strings = {
    en: {
        'DropHereToUpload':'Drop files here to upload to category:',
        'FolderErrorMessage':'Folders cant get uploaded!',
        copyMessage:'To copy the link, right-click on the pre-highlighted text and select "Copy" from the menu.'
    },
    de: {
        'DropHereToUpload':'Dateien hier einfügen um sie der folgenden Kategorie hinzuzufügen:',
        'FolderErrorMessage':'Verzeichnisse können nicht hochgeladen werden!',
        copyMessage:'Um den Link zu kopieren, klicken Sie mit der rechten Maustaste auf den vormarkierten Text und wählen Sie "Kopieren"'
    },
};