import { strings as stringLocal} from './WelcomeLocalization';
import { strings as stringGlobal } from '../../utils/globalLocalization';
import { I18n } from '@aws-amplify/core';
import { filter, mergeDeepRight, prop } from 'ramda';
import moment from 'moment';
import React, { useEffect } from 'react';
import TableInstallations from '../components/welcome/TableInstallations';
import TileMap from '../components/welcome/Map';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { allowedLangValue, getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, setDocumentTitle, setMaxAssetCount } from '../../utils/helpers';
import { GlobalStateInterfaceApp, useGlobalStateApp } from '../utils/globalStateProviderApp';
import { getIcon } from '../../utils/icons';
import { DeviceFilterEnum } from '../../interfaces/DeviceFilters';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const { state, setState } = useGlobalState()
  const { stateApp, setStateApp } = useGlobalStateApp()
  const { user, isAuthenticated ,logout} = useAuth0()
  const { getAccessTokenSilently } = useAuth0();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const filterBy = query.get('filterBy') as DeviceFilterEnum

    let dataGlobal:Partial<GlobalStateInterfaceApp> = {
      search:{
        page: stateApp.search?.page?stateApp.search.page:1,
        searchterm: stateApp.search?.searchterm?stateApp.search.searchterm:'',
        sortBy: stateApp.search?.sortBy?stateApp.search.sortBy:'',
        filterBy:Object.values(DeviceFilterEnum).includes(filterBy) ? filterBy : '',
        parametersUpdatedByUrl: stateApp.search?.parametersUpdatedByUrl?stateApp.search.parametersUpdatedByUrl:true,
      }}

    setStateApp((prev: any) => ({ ...prev, ...dataGlobal }))
  },[])

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    if(!state.language){
      let lang = getLangByLocalStorage()
      let dataGlobal:Partial<GlobalStateInterface> = {language:lang?lang:'de'}
      setState((prev) => ({ ...prev, ...dataGlobal }));
      setDocumentTitle()
    }

    const asyncInit = async () => {
      let accessToken =  await getAuth0Token(getAccessTokenSilently)
      setMaxAssetCount(setStateApp,accessToken)  
    }
    
    asyncInit()
  }, []);

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      moment.locale(state.language);
      document.documentElement.lang = state.language
    }
  }, [state.language]);

  return (
    <div className={getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
    <Header />
    <section className="bg-blueLight welcome">
      <div className=" pt-7 xxl:pt-14 pb-11 xxl:pb-14">
        <div className="flex items-center">
          <div className="  w-[55px] h-[55px] rounded-lg bg-white flex items-center justify-center">
            {getIcon({ type: 'Devices', ownClassName: ' fill-blueLight w-9' })}
          </div>
          <div className=" ml-4 m:ml-6 text-base m:text-xl text-white font-bold ">{I18n.get('nameApp')}</div>
        </div>
        <h1 className="text-white mt-5">{I18n.get('welcomeMessage')} {(isAuthenticated && user && user.email && <>{user.name}</>)}</h1>
        <p className=" mt-1 xxl:mt-0 text-white text-lbase m:text-l xl:text-xl xxl:text-4xl">{I18n.get('welcomeMessageFollow').replace('###number###',stateApp.maxInstallationCount ? stateApp.maxInstallationCount: 0)}</p>
      </div>
    </section>
    <section className="Content">
      <div className="flex flex-col">
        <TableInstallations isAddMode={false} />
        <h2 className="pb-10 pt-24">{I18n.get('placesOnMap')}</h2>
      </div>
    </section>
    <section>
      <TileMap />
    </section>
    <Footer />
    </div>
  );
};

export default Dashboard;
