import { LEARN_API_URL } from "../../utils/consts";
import { customFetchWithAuthHeader } from "../../utils/helpersFetch";
import { PaginatebleResponse } from "../interfaces/PaginatebleResponse";
import { iProduct } from "../interfaces/Products";

export const fetchNewsAndUpdates = async (
    skip:number = 0,
    take:number = 10,
    accessToken: string): Promise<PaginatebleResponse> => {
  
    try {
      const url = `${LEARN_API_URL}/v1/news-and-updates/?skip=${skip}&take=${take}`
  
      const response = await customFetchWithAuthHeader(url, {
        method: 'GET',
      }, accessToken);
  
      return await response.json();
      
    } catch (error) {
      throw new Error(`An error occured while fetching News and Updates`)
    }
  };

  export const fetchProducts = async (
    accessToken: string): Promise<iProduct[]> => {
  
    try {
      const url = `${LEARN_API_URL}/v1/products/`
  
      const response = await customFetchWithAuthHeader(url, {
        method: 'GET',
      }, accessToken);
  
      return await response.json();
      
    } catch (error) {
      throw new Error(`An error occured while fetching News and Updates`)
    }
  };

