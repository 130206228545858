import { useEffect } from 'react'
import React from 'react';
const colorsTheme = require('../../src/utils/aufzugheldenTheme')

export default function PopupWrapper(props) {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
    document.body?.classList.add('modalOpen')

    return () => {
      document.body?.classList.remove('modalOpen')
    };

  }, []);
  
  return (
    <div>
      <div className=" absolute top-0 left-0 m:hidden  w-full bg-white z-40 ">
        <div className=" text-white m-0 m:hidden w-full overflow-hidden p-0 bg-blueDark flex items-center h-16 fixed top-0 z-10">
          <svg onClick={()=> {props.closerFunction()}} width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" className=" ml-4 cursor-pointer z-[1]"><path d="M10 17L2 9L10 1" stroke="white" strokeWidth="2"></path></svg>
          <div className=" absolute text-center text-xl w-full">{props.mobileTitle}</div>
        </div>
        <div className="p-4 overflow-scroll pt-20 h-[100vh] ">
          {props.children}
        </div>
      </div>
      <div className={" z-30 hidden m:flex  justify-center items-center text-white fixed top-0 left-0 w-full  h-screen bg-opacity-50 bg-blueLight "}>
        <div className=' w-11/12 borded border-warnRed max-w-[1540px]'>
          <div className=' h-10 flex justify-end bg-blueDark'>
            <svg className=" w-8 l:w-10 -mt-20 l:-mt-20 -mr-8 l:-mr-10 cursor-pointer" onClick={()=> {props.closerFunction()}} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22" cy="22" r="22" fill={colorsTheme.blueDark}/>
              <path d="M27.922 29.0411L22.3662 23.4852L16.8104 29.0411L14.9584 27.1891L20.5143 21.6333L14.9584 16.0775L16.8104 14.2255L22.3662 19.7814L27.922 14.2255L29.774 16.0775L24.2182 21.6333L29.774 27.1891L27.922 29.0411Z" fill="white"/>
            </svg>
          </div>
          <div className='flex items-left flex-col p-8 pb-8 text-blueDark bg-white max-h-[80vh] overflow-scroll'>
              {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
