import React from "react"

type Options =
    {
        type: 'Info' | 'Warning' | 'Error' | 'Success'
        ownClassName: string
    }

export function getIconMessages(props: Options) {
    // generate icons with these setup > https://digitalspine.atlassian.net/browse/AP-1975

    if (props.type === 'Info') {
        return <svg
                width="30"
                height="30" 
                fill="none" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <path d="M 13.396 7.51 L 16.368 7.51 L 16.368 10.483 L 13.396 10.483 L 13.396 7.51 Z M 13.396 13.456 L 16.368 13.456 L 16.368 22.373 L 13.396 22.373 L 13.396 13.456 Z M 14.882 0.079 C 6.678 0.079 0.019 6.738 0.019 14.942 C 0.019 23.147 6.678 29.805 14.882 29.805 C 23.087 29.805 29.745 23.147 29.745 14.942 C 29.745 6.738 23.087 0.079 14.882 0.079 Z M 14.882 26.832 C 8.328 26.832 2.991 21.497 2.991 14.942 C 2.991 8.388 8.328 3.051 14.882 3.051 C 21.437 3.051 26.772 8.388 26.772 14.942 C 26.772 21.497 21.437 26.832 14.882 26.832 Z" fill="black" ></path>
            </svg>
    }
    else if (props.type === 'Warning') {
        return <svg
                width="30"
                height="30" 
                fill="none" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1527_13518" maskUnits="userSpaceOnUse" x="0" y="0" width="38" height="38">
                <rect width="38" height="38" fill="#D9D9D9"></rect>
                </mask>
                <g mask="url(#mask0_1527_13518)" transform="matrix(0.863288, 0, 0, 0.863288, -1.435386, -0.51648)" >
                <path d="M1.5835 33.2498L19.0002 3.1665L36.4168 33.2498H1.5835ZM7.046 30.0832H30.9543L19.0002 9.49984L7.046 30.0832ZM19.0002 28.4998C19.4488 28.4998 19.8248 28.3481 20.1283 28.0446C20.4318 27.7412 20.5835 27.3651 20.5835 26.9165C20.5835 26.4679 20.4318 26.0919 20.1283 25.7884C19.8248 25.4849 19.4488 25.3332 19.0002 25.3332C18.5515 25.3332 18.1755 25.4849 17.872 25.7884C17.5686 26.0919 17.4168 26.4679 17.4168 26.9165C17.4168 27.3651 17.5686 27.7412 17.872 28.0446C18.1755 28.3481 18.5515 28.4998 19.0002 28.4998ZM17.4168 23.7498H20.5835V15.8332H17.4168V23.7498Z" fill="#FF9900"></path>
                </g>
            </svg>
        
    }
    else if (props.type === 'Error') {
        return <svg
                className={props.ownClassName}
                width="30"
                height="30" 
                fill="none" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1527_13526" maskUnits="userSpaceOnUse" x="0" y="0" width="37" height="37">
                <rect width="37" height="37" fill="#D9D9D9"></rect>
                </mask>
                <g mask="url(#mask0_1527_13526)" transform="matrix(1.078878, 0, 0, 1.078878, -4.943141, -4.941773)" >
                <path d="M12.7188 32.375L4.625 24.2812V12.7188L12.7188 4.625H24.2812L32.375 12.7188V24.2812L24.2812 32.375H12.7188ZM14.1062 25.0521L18.5 20.6583L22.8937 25.0521L25.0521 22.8937L20.6583 18.5L25.0521 14.1062L22.8937 11.9479L18.5 16.3417L14.1062 11.9479L11.9479 14.1062L16.3417 18.5L11.9479 22.8937L14.1062 25.0521ZM14.0292 29.2917H22.9708L29.2917 22.9708V14.0292L22.9708 7.70833H14.0292L7.70833 14.0292V22.9708L14.0292 29.2917Z" fill="#FE4365"></path>
                </g>
            </svg>
    }
    else if (props.type === 'Success') {
        return <svg 
            className={props.ownClassName}
            width="30"
            height="30" 
            fill="none" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1527_13534" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                    <rect width="36" height="36" fill="#D9D9D9"></rect>
                </mask>
                <g mask="url(#mask0_1527_13534)" transform="matrix(0.994507, 0, 0, 0.994507, -2.916848, -2.933408)">
                    <path d="M15.9 24.9L26.475 14.325L24.375 12.225L15.9 20.7L11.625 16.425L9.525 18.525L15.9 24.9ZM18 33C15.925 33 13.975 32.6062 12.15 31.8187C10.325 31.0312 8.7375 29.9625 7.3875 28.6125C6.0375 27.2625 4.96875 25.675 4.18125 23.85C3.39375 22.025 3 20.075 3 18C3 15.925 3.39375 13.975 4.18125 12.15C4.96875 10.325 6.0375 8.7375 7.3875 7.3875C8.7375 6.0375 10.325 4.96875 12.15 4.18125C13.975 3.39375 15.925 3 18 3C20.075 3 22.025 3.39375 23.85 4.18125C25.675 4.96875 27.2625 6.0375 28.6125 7.3875C29.9625 8.7375 31.0312 10.325 31.8187 12.15C32.6062 13.975 33 15.925 33 18C33 20.075 32.6062 22.025 31.8187 23.85C31.0312 25.675 29.9625 27.2625 28.6125 28.6125C27.2625 29.9625 25.675 31.0312 23.85 31.8187C22.025 32.6062 20.075 33 18 33ZM18 30C21.35 30 24.1875 28.8375 26.5125 26.5125C28.8375 24.1875 30 21.35 30 18C30 14.65 28.8375 11.8125 26.5125 9.4875C24.1875 7.1625 21.35 6 18 6C14.65 6 11.8125 7.1625 9.4875 9.4875C7.1625 11.8125 6 14.65 6 18C6 21.35 7.1625 24.1875 9.4875 26.5125C11.8125 28.8375 14.65 30 18 30Z" fill="#52C07A"></path>
                </g>
            </svg>
    }
}