import { useAuth0 } from '@auth0/auth0-react';
import { Spin, message } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Category } from '../../interfaces/Category';
import { getCategoriesForCagtegory } from '../../utils/helpersFetch';
import DragDropFile from './../salesforce/uploadFileDrop'
import { Button } from './../../../components/atoms/Button';
import { I18n } from '@aws-amplify/core';
import { useGlobalState } from '../../../utils/globalStateProvider';
import { getAuth0Token } from '../../../utils/helpers';

const FileUploadCategories = (props: { category: Category; libraryId: string; refreshCallback:Function,goBack:Function }) => {

  const { state, setState } = useGlobalState()
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [isLodingCategories, setIsLodingCategories] = useState<boolean>(false);
  const [runningUploads, setRunningUploads] = useState<string[]>([]);
  const [showFolderErrorMessage, setShowFolderErrorMessage] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if(state.language){
      I18n.setLanguage(state.language)
      document.documentElement.lang = state.language
    }
    setRunningUploads([])
  }, []);

  useEffect(() => {
    if(showUploadDialog){
      document.body.classList.add('overflow-hidden')
    }
    else{
      document.body.classList.remove('overflow-hidden')
    }
  }, [showUploadDialog]);

  useEffect(() => {
    const loadCategories = async () => {
      if(props.category.id){
        let accessToken =  await getAuth0Token(getAccessTokenSilently)
        let categoriesResult = await getCategoriesForCagtegory(props.category.id,accessToken)
        setSubCategories(categoriesResult.subCategories)
        setIsLodingCategories(false)
      }
    }
    setIsLodingCategories(true)
    loadCategories()
  }, [props.category.id]);

  const uploadCaller = (categoryId:string,isLoading:boolean) =>{
    if(isLoading){
        // add entry 
        let localRunningUploads = JSON.parse(JSON.stringify(runningUploads))
        localRunningUploads.push(categoryId)
        setRunningUploads(localRunningUploads)
    }
    else{
        //remove entry
      let localRunningUploads = JSON.parse(JSON.stringify(runningUploads))
      var index = localRunningUploads.indexOf(categoryId);
      if (index !== -1) {
        localRunningUploads.splice(index, 1);
        props.refreshCallback() // refresh only if a upload was finished
      }
      setRunningUploads(localRunningUploads)   
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
        window.removeEventListener('beforeunload', alertUser)
        window.removeEventListener('unload', handleTabClosing)
    }
  })

  const handleTabClosing = () => {
      console.log('handleTabClosing')
  }

  const alertUser = (event:any) => {
    if(runningUploads.length > 0){
      event.preventDefault()
      event.returnValue = ''
    } 
  }

  const closeUploadDialoge = (event:React.MouseEvent) => {

    event.preventDefault()
    if(runningUploads.length > 0 ){
      message.info(I18n.get('DontCloseBecauseUploadsAreRunning'))
    }
    else{
      props.refreshCallback()
      setShowUploadDialog(false)
    }
  }

  return (
    <>
    {showUploadDialog && <>
    <div className={" hidden m:flex Content justify-center items-center text-white fixed z-50 top-0 left-0 w-full h-screen bg-opacity-50 bg-blueLight "}>
      <div className=' w-full h-full p-4 '>
        <div className=' pl-9 pr-9 h-20 flex justify-between items-center bg-blueDark rounded-t '>
          <h3 className="text-white">{I18n.get('UploadFor') + ': ' + (state.language === 'de' ? props.category.titleGerman :props.category.titleEnglish)}</h3>
          <div className="flex items-center">
            <svg className={ (runningUploads.length > 0?" cursor-not-allowed":" cursor-pointer ")  + " w-8 l:w-10 "} onClick={(e)=> { closeUploadDialoge(e) }} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22" cy="22" r="22" fill="white"/>
              <path d="M27.922 29.0411L22.3662 23.4852L16.8104 29.0411L14.9584 27.1891L20.5143 21.6333L14.9584 16.0775L16.8104 14.2255L22.3662 19.7814L27.922 14.2255L29.774 16.0775L24.2182 21.6333L29.774 27.1891L27.922 29.0411Z" fill="#102A68"/>
            </svg>
          </div>
        </div>
        <div className= "  bg-white max-[900px]:h-10 h-20 "></div>

        <div className='flex items-center h-[calc(100%-15rem)] max-[900px]:h-[calc(100%-10rem)] overflow-hidden  text-blueDark bg-white justify-center '>
          <div>
            {runningUploads.length > 0 ? <div className=" text-center">{I18n.get('RunningUploads')}:{runningUploads.length}</div>:<></>}
            {showFolderErrorMessage ? <div className=" text-center text-warnRed">{I18n.get('FolderErrorMessage')}</div>:<></>}
            <Spin spinning={isLodingCategories} className="" >
              <div className="overflow-hidden  p-0 pt-16 flex flex-wrap  justify-center">
                {subCategories.map((subCategory,index) => {return <DragDropFile key={index} libraryId={props.libraryId} subCategory={subCategory} refreshCallback={props.refreshCallback} uploadCallback={uploadCaller} setShowFolderErrorMessage={setShowFolderErrorMessage} />})}
              </div>
            </Spin>
          </div>
        </div>
        <div className=" flex justify-center bg-white text-blueDark pb-7 rounded-b  max-[900px]:h-10 h-20 ">{/*12|<span className="font-bold">30</span>*/}</div>
      </div>
    </div>
    <div className={ "  m:hidden justify-center items-center text-white fixed z-30 top-0 left-0 w-full h-screen overflow-scroll overflow-x-hidden bg-white select-none"}>
      <div className=''>
        <div className=' pl-4 pr-4 h-20 flex justify-between items-center  bg-blueDark fixed top-0 w-full z-20'>
          <h3 className="text-white">{(state.language === 'de' ? props.category.titleGerman :props.category.titleEnglish)}</h3>
          <div className="flex items-center">
            <svg className={ (runningUploads.length > 0?" cursor-not-allowed":" cursor-pointer ") + " w-8 "} onClick={(e)=> { closeUploadDialoge(e) }} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22" cy="22" r="22" fill="#102A68"/>
              <path d="M27.922 29.0411L22.3662 23.4852L16.8104 29.0411L14.9584 27.1891L20.5143 21.6333L14.9584 16.0775L16.8104 14.2255L22.3662 19.7814L27.922 14.2255L29.774 16.0775L24.2182 21.6333L29.774 27.1891L27.922 29.0411Z" fill="white"/>
            </svg>
          </div>
        </div>
        <div className='  flex flex-col items-center pt-28 pb-11 text-blueDark pl-4 pr-4 bg-white  h-[calc(100vh-1rem)] '>
        <div>
            {runningUploads.length > 0 ? <div className=" text-center">{I18n.get('RunningUploads')}:{runningUploads.length}</div>:<></>}
            {showFolderErrorMessage ? <div className=" text-center text-warnRed">{I18n.get('FolderErrorMessage')}</div>:<></>}
            <Spin spinning={isLodingCategories} className="" >
              <div className=" p-0 pt-16 flex flex-wrap overflow-hidden justify-center">
                {subCategories.map((subCategory,index) => {return <DragDropFile key={index} libraryId={props.libraryId} subCategory={subCategory} refreshCallback={props.refreshCallback} uploadCallback={uploadCaller} setShowFolderErrorMessage={setShowFolderErrorMessage} />})}
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </div>
    </>

  }
  {!showUploadDialog && <div><Button label={I18n.get('UploadFiles')} size='extra small' onClick={()=>{setShowUploadDialog(true)}} theme='light' /></div>}
  </>
  )
}

export default FileUploadCategories;

