import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useGlobalState, GlobalStateInterface } from '../utils/globalStateProvider';
import { strings as stringLocal } from './HeaderDetailMessagesLocalization';
import { strings as stringGlobal } from '../utils/globalLocalization';
import { app_url_incidents } from '../utils/consts';
import { mergeDeepRight } from 'ramda';
import { Installation } from '../interfaces/Installation';
import { getIconMessages } from '../utils/iconsMessages';
import { getIcon } from '../utils/icons';
import { Spin, Steps } from 'antd';
import { Button } from './atoms/Button';
import { getAuth0Token, getLangByLocalStorage, UserHasPermissionsToReadCases } from '../utils/helpers';
import { fetchIncidentByAsset } from '../incidents/utils/helpersFetch';
import { useGlobalStateApp } from '../incidents/utils/globalStateProviderApp'
import { useAuth0 } from "@auth0/auth0-react";
import { IncidentByAsset } from '../incidents/interfaces/Case';
import moment from 'moment';
import { fetchOrganizationName } from '../utils/helpersFetch';
import { useParams } from 'react-router-dom';

export type HeaderDetailMessagesProps =
  | { 
      mode:'Installation',
      installation:Installation,
    }
  | { 
      mode:'Contract',
    }
  | { 
      mode:'Incident',
    };

type InstallationMessage = {
  type:'Info' | 'Warning' | 'Error' | 'Success'
  message:string
}

function HeaderDetailMessages(props:HeaderDetailMessagesProps) {
  const { state, setState } = useGlobalState()
  const [showAdditionalInformation,setShowAdditionalInformation] = useState(false)
  const [showAdditionalInformationIsAllowed,setShowAdditionalInformationIsAllowed] = useState(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [notificationData, setNotificationData] = React.useState<IncidentByAsset|undefined>(undefined)
  const { stateApp, setStateApp } = useGlobalStateApp()
  const { getAccessTokenSilently, user } = useAuth0();
  let { installationId } = useParams();
  const [userHasReadCasesPermissions, setUserHasReadCasesPermissions] = useState<boolean|undefined>(undefined);

  const getInstallationMessage = () => {
    let returnValue:InstallationMessage = {
      message: "",
      type: "Success"
    };
    if(notificationData?.prio == 1) {
      returnValue = {
        message: I18n.get('IncidentPrio1Title'),
        type:'Error'
      }
    } else if(notificationData?.prio == 2) {
      returnValue = {
        message: I18n.get('IncidentPrio2Title'),
        type:'Warning'
      }
    } else if(notificationData?.prio == 3) {
      returnValue = {
        message: I18n.get('IncidentPrio3Title'),
        type:'Info'
      }
    } else if(notificationData?.prio == 4) {
      returnValue = {
        message: I18n.get('IncidentPrio4Title'),
        type:'Success'
      }
    } 
    return returnValue;
  }

  useEffect(() => {
    if(installationId){
      loadIncidentByByAsset(installationId)
    }
  }, [installationId]);
  

  useEffect(() => {
    I18n.putVocabularies(mergeDeepRight(stringLocal,stringGlobal));
    I18n.setLanguage(getLangByLocalStorage());

    const CheckReadCasesPermissions = async () => {
      setUserHasReadCasesPermissions(await UserHasPermissionsToReadCases(await getAuth0Token(getAccessTokenSilently)));
    };
    CheckReadCasesPermissions();
  }, []);

  useEffect(() => {
    switch(notificationData?.prio) { 
      case 1: { 
        if(userHasReadCasesPermissions){
          setShowAdditionalInformationIsAllowed(true)
        }
        else{
        setShowAdditionalInformationIsAllowed(false)
        }
        break; 
      } 
      case 2: { 
        setShowAdditionalInformationIsAllowed(false)
         break; 
      } 
      case 3: { 
         if(userHasReadCasesPermissions){
          setShowAdditionalInformationIsAllowed(true)
         }
         else{
          setShowAdditionalInformationIsAllowed(false)
         }
         break; 
      } 
      case 4: { 
        if(userHasReadCasesPermissions){
          setShowAdditionalInformationIsAllowed(true)
         }
         else{
          // hide information at all
          setNotificationData(undefined)
         }
        
        break; 
      } 
      default: { 
        setShowAdditionalInformationIsAllowed(false)
        break; 
      } 
   } 
    
  }, [userHasReadCasesPermissions,notificationData]);

  const loadIncidentByByAsset = async (assetId: string) => {
    try {
      setIsLoading(true);
      const accessToken = await getAuth0Token(getAccessTokenSilently)
      let incident = await fetchIncidentByAsset(assetId, accessToken)
      if(incident && incident.prio && incident.incident ){
        setNotificationData(incident)
      }
      setIsLoading(false);
    }
    catch (error) {
      console.error('Error fetching incidents:', error.message);
      // Handle the error here if needed
    } finally {
      setIsLoading(false);
    }
  }

  const handleViewIncident = async () => {
    let userOrgId = user?.org_id;
    let organization = await fetchOrganizationName(userOrgId);
    if(organization.name){
      const incidentUrl = app_url_incidents + '/org_'+ organization.name+'/incidents/' + notificationData?.incident._id;
      window.open(incidentUrl, "_blank");
    }
  }

  const getTimeLabel = () => {
    if(I18n.get('TimeLabel') !== 'TimeLabel') {
      return I18n.get('TimeLabel');
    }
    return '';
  }

  if(props.mode === 'Installation'){
    let message = getInstallationMessage()
    let color = ''
    switch (message.type) {
      case 'Error':
        color = ' text-warnRed '
        break;
      case 'Info':
        color = ' text-black '
        break;
      case 'Success':
        color = ' text-green '
        break;
      case 'Warning':
        color = ' text-warnOrange '
        break;
      }
    
    if(!message.message || message.message.trim().length <= 0){
      return <></>
    }

    return (
      <>
        <div className={" bg-white m:rounded mt-9 flex items-center text-base m:text-xl font-bold mb-9 w-full flex-col" + (showAdditionalInformation?' m:rounded-b-none ':'')}>
          {isLoading ? (<Spin className="pt-8 pb-8" size="large" spinning={isLoading} key="spin" />)
          : <>
            <div className={showAdditionalInformationIsAllowed?
              ' flex flex-row w-full p-5 pt-3 pb-3 m:pt-[34px] m:pl-[28px] m:pr-[48px] m:pb-[36px] cursor-pointer'
              :
              ' flex flex-row w-full p-5 pt-3 pb-3 m:pt-[34px] m:pl-[28px] m:pr-[48px] m:pb-[36px] '} onClick={()=>showAdditionalInformationIsAllowed?setShowAdditionalInformation(!showAdditionalInformation):false}>
              <span className="w-[18px] m:w-6 xl:w-[30px] mr-[15px] m:mr-6 xl:mr-[33px] flex items-center">{getIconMessages({type:message.type,ownClassName:' w-[18px] m:w-6 xl:w-[30px]'})}</span>
              <div className={color + "flex-grow flex items-center text-base m:text-[22px]"}>{message.message}</div>
              {showAdditionalInformationIsAllowed && <span className="flex items-center">{getIcon({type:'ArrowLink',ownClassName:' rotate-90 ml-3 m:ml-5 xl:ml-8'})}</span>}
            </div>
            {showAdditionalInformation && showAdditionalInformationIsAllowed &&
              <>
                <div className="relative w-full ">
                  <div className=" 
                    shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]
                    absolute m:rounded-b z-10 w-full bg-white
                    pl-[22px] m:pl-[32px] xl:pl-[35px] 
                    pr-5 m:pr-[48px] xl:pr-[48px] 
                    pt-3 m:pt-4 xl:pt-[22px]
                    pb-[23px] m:pb-[44px] xl:pb-[56px]">
                    <Steps
                      progressDot
                      direction="vertical"
                      items={[
                          {
                            title: <div className=" text-sm m:text-l xl:text-[22px] mt-[9px] m:mt-[5px] xl:mt-[6px]">{moment(notificationData?.incident.createdAt).format('DD.MM.YYYY HH:MM')} {getTimeLabel()}</div>,
                            status: 'process',
                            description: <div className="text-sm m:text-l xl:text-[22px] text-blueDark pt-2">
                              <div className="font-bold mb-4 m:mb-[15px]">{notificationData?.incident.subject}</div>
                              <Button label={I18n.get('ViewIncident')} size="small" theme="dark" onClick={handleViewIncident} />
                            </div>,
                          },
                      ]
                    } />
                  </div>
                </div>
              </>
            }
          </>}
        </div>
      </>
    );
  }
  else if(props.mode === 'Contract'){
    return  (
      <></>
    )
  }
  else if(props.mode === 'Incident'){
    return  (
      <></>
    )
  }
  else{
    return <></>
  }
}

export default HeaderDetailMessages;
