
import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postLibraryForEntity } from '../../utils/helpersFetch';
import { Button,ButtonProps } from './../../../components/atoms/Button';
import { getAuth0Token } from '../../../utils/helpers';

const CreateLibrary = ({}: {}) => {

    let { assetId,contractId } = useParams();
    const { getAccessTokenSilently } = useAuth0()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const createLibrary = async () => {
        if(assetId){
            setIsLoading(true)
            let accessToken =  await getAuth0Token(getAccessTokenSilently)
            let result = await postLibraryForEntity(assetId,'sf_asset_lib','Asset',accessToken)
            setIsLoading(false)
            if(result){
                navigate('/salesforce/documents/asset/' + assetId + '/' + result.id);
            }
        }
        else if(contractId){
            setIsLoading(true)
            let accessToken =  await getAuth0Token(getAccessTokenSilently)
            let result = await postLibraryForEntity(contractId,'sf_contract_lib', 'Contract',accessToken)
            setIsLoading(false)
            if(result){
                navigate('/salesforce/documents/contract/' + contractId + '/' + result.id);
            }
        }        
    }

    return (
        <Spin tip="Loading..." spinning={isLoading}>
            <div className="">
                <Button  onClick={()=>createLibrary()} label='Create Library' size='medium' theme='light'  />
            </div>
        </Spin>
    );
};

export default CreateLibrary;