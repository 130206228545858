import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import { useGlobalState } from "../../../utils/globalStateProvider";
import React, { useEffect, useRef } from 'react';
import { EnvironmentTwoTone } from '@ant-design/icons';
import mapStyles from "./MapStyles"
import "./Map.css"
import { Link, useNavigate } from 'react-router-dom';
import * as uuid from 'uuid';
import { Spin } from 'antd';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { scrollTop } from '../../../utils/helpers';
import { useGlobalStateApp } from '../../utils/globalStateProviderApp';
import { google_maps_api_code } from '../../../utils/consts';

function TileMap() {
  const { state } = useGlobalState();
  const { stateApp, setStateApp } = useGlobalStateApp()
  var mapsApiCode = google_maps_api_code

  Geocode.setApiKey(mapsApiCode);
  Geocode.setLanguage("en")

  type objMarker = { lat:Number,lng:Number,name:string ,key:string, show:boolean, needToLoadLatLng:boolean,label:string};
  const [markersArr, setMarkers] = React.useState<objMarker[]>([])
  const [markersToLoadLatLng, setMarkersToLoadLatLng] = React.useState<objMarker[]>([])
  const [geoCoordiantesLoaded, setGeoCoordiantesLoaded] = React.useState<boolean>(false)
  const [map, setMap] = React.useState<any>(null)
  const [maps, setMaps] = React.useState<any>(null)
  const [cluster, SetCluster] = React.useState<MarkerClusterer>()
  const navigate = useNavigate();

  useEffect(() => {
    let allLoaded = true
    markersArr.forEach((place) => {
      if(place.needToLoadLatLng){
        allLoaded = false 
      }
    });
    
    if(allLoaded){
      setGeoCoordiantesLoaded(true)
    }
    if(map && maps){
      centerMap(map, maps, markersArr)
    }

   let renderer = undefined
   const markers: any[] = [];


   if(map && maps){


      markersArr?.forEach((location) => {

      const infoWindow = new maps.InfoWindow({
        content: "",
        disableAutoPan: true,
      });

      let myMarker = new maps.Marker({
        title:location.name,
        icon:pinSymbol("#00265E"),
        position: {
          lat: location.lat,
          lng: location.lng,
        },
        map,
      })

      myMarker.addListener("mouseover", () => {
        infoWindow.setContent('<div><a href="/org_'+state.organizationName+'/installations/'+location.key+'">'+location.name+'</a></div>');
        infoWindow.open(map, myMarker);
        });

        myMarker.addListener("mouseout", () => {
          infoWindow.close()
          });        

      myMarker.addListener("click", () => {
        scrollTop()
        navigate('/org_'+state.organizationName+'/installations/'+location.key)
        });

        markers.push(
          myMarker
        );
      });
    
      let renderer = {
        render({ count, position }:any) {
          return new maps.Marker({
            label: { text: String(count), color: "white", fontSize: "30px" },
            position,
            icon: "/assets/images/map_cluster.png",
            // adjust zIndex to be above other markers
            zIndex: Number(maps.Marker.MAX_ZINDEX) + count,
          })
        }
      }

      function onClusterClick(markerClusterer: any){
        map.setCenter({lat:markerClusterer.latLng.lat(),lng:markerClusterer.latLng.lng()}); 
        map.setZoom(map.getZoom()+2);
      }

      cluster?.clearMarkers()
      SetCluster(new MarkerClusterer({ markers, map,renderer, onClusterClick}))
     
      if(markersArr.length<=0){
        map.setCenter({lat:54,lng:15}); 
        map.setZoom(5);
      }

    }
  }, [ markersArr,maps ]);

  function pinSymbol(color:string) {
    return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: color,
        strokeWeight: 0,
        scale: 1,
   };
}

  useEffect(() => {

    let places = [{} as objMarker]
    places = []
    stateApp.installationsBySearch?.forEach(installation => {
            if(installation?.coords?.lat){
              let tmpObj = {} as objMarker
              tmpObj.key = installation.id
              tmpObj.lat = parseFloat(installation.coords.lat)
              tmpObj.lng = parseFloat(installation.coords.lng)
              tmpObj.name = installation?.label || ''
              tmpObj.show = false
              tmpObj.needToLoadLatLng = false
              tmpObj.label = installation?.label
              places.push(tmpObj)
            }         
          })
      
      setMarkers(places)
     
  }, [ stateApp.installationsBySearch ]);

  const showInfowindow = (id:any) =>{
    const index = markersArr.findIndex((e) => e.key === id);
    let myMarkers =  JSON.parse(JSON.stringify( markersArr ))
    console.log(myMarkers)
    myMarkers.forEach((element: { show: boolean; }) => {
      element.show = false
    });
    myMarkers[index].show = !markersArr[index].show
    setMarkers(myMarkers)
  }

  const MapMarker = ({ text,show,id }:any ) => {
    const markerRef = useRef(null);
    return (
      <div className="mapMarker"  ref={markerRef}>
        <EnvironmentTwoTone onClick={()=>{ showInfowindow(id)}} alt={text} twoToneColor="#34495E" style={{ fontSize: '26px' }}/>
        {show && <div className="googleMapsInfoWindow"><Link to={'/installations/'+id}>{text}</Link></div>}
      </div>
      )
    };

  // Return map bounds based on list of places
  const getMapBounds = (map: any, maps: { Marker?: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; LatLngBounds?: any; LatLng?: any; }) => {
    const bounds = new maps.LatLngBounds();
    markersArr.forEach((place) => {
      bounds.extend(new maps.LatLng(
        place.lat,
        place.lng,
      ));
    });
    return bounds;
  };

  const centerMap = (map: any, maps: { Marker: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; },markers: { lat: Number; lng: Number; name: string; key: string; }[]) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps);
    map.fitBounds(bounds);
    map.panToBounds(bounds);

    if(markers.length <= 1){
      map.setZoom(15);
    }
   return [];
  };

  const handleApiLoaded = (map: any, maps: { Marker: new (arg0: { position: { lat: any; lng: any; }; map: any; title: string; }) => any; },markers: { lat: Number; lng: Number; name: string; key: string; }[]) => {
    setMap(map)
    setMaps(maps)
    centerMap(map, maps, markers)
  };

  const getMap = () => {

    return <GoogleMapReact
      bootstrapURLKeys={{ key: mapsApiCode}}
      defaultCenter={{lat:59.95,lng:30.33}}
      defaultZoom={11}
      yesIWantToUseGoogleMapApiInternals
      options={{styles:mapStyles}}
      //resetBoundsOnResize={true}

      onGoogleApiLoaded={({map, maps}) => { handleApiLoaded(map, maps, markersArr)} }
      >
      { false && markersArr.map((place) => (
          <MapMarker
            key={place.key + '-' + uuid.v4()}
            id={place.key}
            text={place.name}
            lat={place.lat}
            lng={place.lng}
            show={place.show}
          />
        ))}
      </GoogleMapReact>
    }
    return (
        <div className={"mapContainer pl-4 pr-4 pb-24 m:p-0"} >{ geoCoordiantesLoaded ? getMap() : <Spin size="large" />}</div>
        );
  }
  
export default TileMap;
  