export const strings = {
  en: {
    welcomeMessage: 'Welcome back',
    welcomeMessageFollow : 'Here you will find an overview of ###number### incidents.',
    nameApp: 'Incidents'
  },
  de: {
    welcomeMessage: 'Willkommen zurück',
    welcomeMessageFollow : 'Hier finden Sie eine Übersicht zu ###number### Vorfälle/n.',
    nameApp: 'Vorfälle'
  },
};