import React, { useEffect, useState } from 'react'
import { strings as stringLocal} from './WelcomeLocalization'
import { GlobalStateInterface, useGlobalState } from '../../utils/globalStateProvider'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { IsStorybookAndDsMember, getAuth0IsAuthenticatedStatus, getAuth0Token, getLangByLocalStorage, langChange, langInit, scrollToLocation } from '../../utils/helpers'
import { Breadcrumb, getMD, getSidebar, removeMdMetaData } from '../utils/helpers'
import { I18n } from '@aws-amplify/core'
import { strings as stringGlobal } from '../../utils/globalLocalization'
import { baseUrl, urlToNewsFolder } from './Welcome'
import { getIcon } from '../../utils/icons'
import CopyToClipboard from '../../components/molecules/CopyToClipboard'
import { Spin, Tooltip } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'

I18n.putVocabularies(stringGlobal);
let lang = getLangByLocalStorage()
I18n.setLanguage(lang)

const NewsUpdatesDetail = () => {

  const { state, setState } = useGlobalState()
  const [ currentMdContent, setCurrentMdContent ] = useState<string>('')
  const [ showEditButton, setShowEditButton ] = useState<boolean>(false)
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [ isLoadingMdFile, setIsLoadingMdFile] = React.useState<boolean>(true)
  
  const navigate = useNavigate();

  let { fileId } = useParams();
  const { hash } = useLocation();

  useEffect(() => {
    if(state.language){ langChange({state:state,updateDocumentTitle:true}) } else { langInit( {stringLocal:stringLocal,setStateGlobal:setState} ) }
  }, [state.language]);

  useEffect(() => {

    const setEditPermission = async () => {
      
      if( await IsStorybookAndDsMember(await getAuth0Token(getAccessTokenSilently))){
        setShowEditButton(true)
      }
      else{
        if(state.organizationName === 'digitalspine'){
          setShowEditButton(true)
        }
        else{
          setShowEditButton(false)
        }    
      }
    }

    setEditPermission()

  }, [state.organizationName]);

  useEffect(() => {
    if(currentMdContent){
      // in case the url was pasted directly the anchor does not reach the target
      // we need to jum to the anchor after the md files was rendered
      const timer = setTimeout(() => {
        scrollToLocation(hash)
      }, 1000);
    }
  }, [currentMdContent]);

 useEffect(() => {
    setIsLoadingMdFile(true)
    // load latest news file
    let languageFolder = ''
    if(state.language && state.language !== 'en'){
      languageFolder = state.language + '/'
    }

    fetch(baseUrl+urlToNewsFolder+languageFolder+fileId+'.md')
    .then(r => r.text())
    .then(text => {setCurrentMdContent(removeMdMetaData(text))})
    .catch(function() {
        console.log("error");
    })
    .finally(function() {
      setIsLoadingMdFile(false)
    });

}, [state.language]);

const EditPage = () => {
  if(fileId){
    window.open('https://github.com/digitalspine/learn-docs-news/edit/main/'+fileId+'.md','github')
  }
}

  return (
    <div className={ getAuth0IsAuthenticatedStatus(isAuthenticated) ? '' : 'hidden'}>
      <Header />
      <section className="bg-blueLight welcome">
        <Breadcrumb >
          <div className=" cursor-pointer" onClick={()=>{ navigate('/')}}>{I18n.get('HelpCenter')}</div>
          <div className=" cursor-pointer" onClick={()=>{ navigate(`/news-and-updates`)}}>{I18n.get('NewsAndUpdates')}</div>
        </Breadcrumb>
      </section>
      {fileId && <section className="Content"><div data-testid="icon_container" className=" flex justify-end pt-9 items-center">
          
          <CopyToClipboard 
          classNames='text-[22px] m:text-[35px] text-blueLight w-5 m:w-9 cursor-pointer mr-3 m:mr-3'
          label={I18n.get('CopyLink')}
          textToCopy={location.origin + location.pathname} />

          {showEditButton && 
            <Tooltip placement="top" color={'white'} title={I18n.get('Edit')} >
              <span onClick={()=>{EditPage()}} title={I18n.get('Edit')}>{getIcon({type:'Edit',ownClassName:'w-[25px] h-[25px] m:w-[40px] m:h-[40px] fill-blueLight cursor-pointer'})}</span>
            </Tooltip>}
        </div>
      </section>}
      <section className="Content">
        <Spin spinning={isLoadingMdFile}>
          <div className="w-full markdocContent flex">
              <div className="w-3/12 sidebar hidden xl:block">{ getSidebar(currentMdContent)}</div>
              <div className="w-1/12 hidden xl:block"></div>
              <div className="w-full xl:w-8/12">{ state.language && getMD(currentMdContent,state.language)}</div>
          </div>
        </Spin>
      </section>
      <Footer />
    </div>
  );
};

export default NewsUpdatesDetail