export const strings = {
  en: {
    welcomeMessage: 'Welcome to Help Center.',
    ShowAll:'Show All',
    NewsAndUpdates:'News and Updates',
    Topics:'Topics',
    TopicsByProduct:'Topics by Product',
    HelpCenter:'Help Center',
  },
  de: {
    welcomeMessage: 'Willkommen im Hilfezentrum.',
    ShowAll:'Alle anzeigen',
    NewsAndUpdates:'News und Updates',
    Topics:'Themen',
    TopicsByProduct:'Themen nach Produkt',
    HelpCenter:'Hilfezentrum',
  },
};